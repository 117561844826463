import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {Box} from "@mui/material";
import TableFilterPanel from "../../../../../../components/TableConstructor/components/TableFilterPanel";
import { Header, Row } from "../../../../../../components/TableConstructor/types";
import ContentContainer from "../../../../../../components/ContentContainer/ContentContainer";
import { Dictionary } from "../../../../index";
import { getInvoices } from "../../../../services/fetchers";
import { AuthContext } from "../../../../../../context/AuthContext";
import { TopBarContext } from "../../../../../../context/TopBarContext";
import { thickRow } from "../../../../../../components/TableConstructor/constants";
import CustomTable from "../../../../../../components/TableConstructor/components/CustomTable";
import ReferralPromo from "../../../../../../components/ReferralPromo";

type InvoicesProps = {
    isTopBar?: boolean;
};

const InvoicesPage = ({ isTopBar = false }: InvoicesProps) => {
    const { setNotification } = useContext(AuthContext);
    const { handleChangePath, handleChangeTabs, handleSetActions } = useContext(TopBarContext);
    const [headers, setHeaders] = useState<Header[]>([]);
    const [rows, setRows] = useState<Row[]>([]);
    const [tableActions, setTableActions] = useState<any>({
        is_column_selection: true,
        is_filter: true,
        metrics: false,
        grouping: false,
        is_report_filter: false,
        view: false,
    });
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>("");
    const [allCounts, setAllCounts] = useState(0);
    const { id = 0 } = useParams();
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    const [request, setRequest] = useState<Dictionary<any>>({
        periods: {
            date_from: dayjs().startOf("month"),
            date_to: dayjs().add(-1, "day"),
            currency: "",
        },
    });
    const [table_name, setTableName] = useState<string>("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [rowDense, setRowDense] = useState<number>(thickRow);
    const { t } = useTranslation();

    useEffect(() => {
        if (isTopBar) {
            const labels = [t("documents"), t("invoices")];
            handleChangePath(labels);
            handleSetActions([]);
            handleChangeTabs([]);
        }
    }, [isTopBar]);

    useEffect(() => {
        setSelectedColumns(headers.map((column) => column.label));
    }, [headers]);

    const loadContract = async (signal: any = null) => {
        setLoading(true);
        const data = {
            ...request,
            filters: request.filters || {},
            periods: {
                ...request.periods,
                date_from: dayjs(request.periods.date_from).format("YYYY-MM-DD"),
                date_to: dayjs(request.periods.date_to).format("YYYY-MM-DD"),
            },
        };
        try {
            const res = await getInvoices(Number(id), data, signal);
            setTableActions(res.table_actions);
            setTableName(res.table_name);
            setHeaders(res.headers);
            setRows(res.rows);
            setAllCounts(res.all_counts);
            if (res.all_counts > res.size) {
                setSearchParams({
                    ...searchParams,
                    page: res.page || 1,
                    size: res.size || 50,
                });
            }
            setLoading(false);
        } catch (e) {
            setError(t("error.self"));
            setLoading(false);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        (async () => {
            await loadContract(controller.signal);
        })();
        return () => controller.abort();
    }, [request]);

    const newHeaders = useMemo(() => headers.filter((header) => selectedColumns.includes(header.label)), [selectedColumns, headers]);

    const confirmInvoice = async (invoiceId: number) => {
        try {
            const res = await axios.post(`/clients/${id}/invoices/${invoiceId}/confirm/`);
            await loadContract();
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (e: any) {
            console.log(e);
            setNotification({
                open: true,
                message: e.response.data.message,
                type: "error",
            });
        }
    };

    const declineInvoice = async (invoiceId: number) => {
        try {
            const res = await axios.post(`/clients/${id}/invoices/${invoiceId}/decline/`);
            await loadContract();
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (e: any) {
            console.log(e);
            setNotification({
                open: true,
                message: e.response.data.message,
                type: "error",
            });
        }
    };

    return (
        <ContentContainer>
            <TableFilterPanel
                rowDense={rowDense}
                setRowDense={setRowDense}
                rows={rows}
                tableActions={tableActions}
                setHeaders={setHeaders}
                headers={headers}
                newHeaders={newHeaders}
                request={request}
                setRequest={setRequest}
            />
            <CustomTable
                rowDense={rowDense}
                table_name={table_name}
                declineRowElement={declineInvoice}
                confirmRowElement={confirmInvoice}
                loading={loading}
                allCounts={allCounts}
                originalHeaders={newHeaders}
                rows={rows}
                setRows={setRows}
                tableActions={tableActions}
                request={request}
                setRequest={setRequest}
            />
            <Box>
                <ReferralPromo />
            </Box>
        </ContentContainer>
    );
};

export default InvoicesPage;
