import axios from "axios";
import BASE_URL from "../../../data/constants";

const getInvoicesForPayout = async (data: Record<string, any>, signal: AbortSignal | undefined) => {
    try {
        const res = await axios.post(`${BASE_URL}/documents/payout/`, data, {
            signal,
        });
        return res.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getMoneyBySystems = async (data: Record<string, any>, signal: AbortSignal | undefined) => {
    try {
        const res = await axios.post(`${BASE_URL}/report/rewards/`, data, {
            signal,
        });
        return res.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export default getInvoicesForPayout;
