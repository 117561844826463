import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FounderType, PaymentDetailsKeys } from "../../../../../types";
import AddBadge from "../../../../../../../components/Badges/AddBadge";
import RemoveBadge from "../../../../../../../components/Badges/RemoveBadge";
import ValueInputField from "../../../../../../../UI/Inputs/ValueInputField";
import SelectInputField from "../../../../../../../UI/Inputs/SelectInputField";
import { ReactComponent as ProfileIcon } from "../../../../../../../assets/images/icons/sidebar/profile.svg";
import { ReactComponent as LinkIcon } from "../../../../../../../assets/images/icons/client/linkIcon.svg";

const FoundersFields = ({ paymentDetails, countries, setPaymentDetails, readOnly }: any) => {
    const { founders } = paymentDetails;
    const { t } = useTranslation();
    const handleAddFounder = () => {
        const newFounders = [
            ...(founders as []),
            {
                fieldId: `id${Math.random().toString(16).slice(2)}`,
                fullName: "",
                citizenship: "",
                passportScanUrl: "",
            },
        ];
        handleChangeFounders("founders", newFounders);
    };
    const handleRemoveFounder = (fieldId: string) => {
        const newFounders = (founders as FounderType[]).filter((founder) => founder.fieldId !== fieldId);
        handleChangeFounders("founders", newFounders);
    };
    const handleChangeFounders = (key: PaymentDetailsKeys, value: any) => {
        setPaymentDetails({
            ...paymentDetails,
            [key]: value,
        });
    };
    const handleChangeFounder = (fieldId: string, fieldKey: string, value: string | null) => {
        const newFounders = (founders as FounderType[]).map((founder) => {
            if (founder.fieldId === fieldId) {
                return {
                    ...founder,
                    [fieldKey]: value,
                };
            }
            return founder;
        });
        handleChangeFounders("founders", newFounders);
    };
    return (
        founders &&
        founders.length > 0 &&
        founders.map((founder: any, index: number) => (
            <Box key={index}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "2px solid rgba(0, 0, 0, 0.20)",
                        height: "50px",
                        alignItems: "center",
                        mb: "1rem",
                        maxWidth: "740px",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "1rem",
                            fontWeight: "500",
                            color: "#1A1F27",
                        }}
                    >
                        {t("forms.payment.founder_label")} {index + 1}
                    </Typography>
                    {index === 0 ? (
                        <Box onClick={() => !readOnly && handleAddFounder()}>
                            <AddBadge />
                        </Box>
                    ) : (
                        <Box onClick={() => !readOnly && handleRemoveFounder(founder.fieldId)}>
                            <RemoveBadge />
                        </Box>
                    )}
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        gap: "1rem",
                        mb: "1rem",
                        flexWrap: "wrap",
                        maxWidth: "740px",
                    }}
                >
                    <ValueInputField
                        readOnly={readOnly}
                        Icon={<ProfileIcon />}
                        label={t("forms.common.full_name")}
                        value={founder?.fullName}
                        onChange={(e) => handleChangeFounder(founder.fieldId, "fullName", e.target.value)}
                    />
                    <SelectInputField
                        readOnly={readOnly}
                        label={t("forms.payment.citizenship")}
                        value={founder?.citizenship}
                        items={countries}
                        onChange={(value) => handleChangeFounder(founder.fieldId, "citizenship", value)}
                    />
                    <ValueInputField
                        readOnly={readOnly}
                        Icon={<LinkIcon />}
                        label={t("forms.payment.passport_link")}
                        value={founder?.passportScanUrl}
                        onChange={(e) => handleChangeFounder(founder.fieldId, "passportScanUrl", e.target.value)}
                    />
                </Box>
            </Box>
        ))
    );
};

export default FoundersFields;
