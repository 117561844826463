import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ContentContainer from "../../../../../components/ContentContainer/ContentContainer";
import { getValue } from "../../../services/helpers";
import CardDetails from "../../components/CardDetails";
import { CardType } from "../../types";
import { getClientInfo, getPaymentDetails } from "../../../services/fetchers";

const PartnerPaymentDetails = () => {
    const { t } = useTranslation();
    const [paymentDetails, setPaymentDetails] = useState<CardType[]>([]);
    const { id } = useParams();

    const paymentDetailsParser = (data: any) => {
        const [backPaymentDetails, client] = data;
        switch (backPaymentDetails.client_payment_method) {
            case "SWIFT":
                return [
                    {
                        key: "transfer_method",
                        value: getValue(backPaymentDetails.client_payment_method),
                    },
                    {
                        key: "bank_details",
                        value: [
                            {
                                key: "recipients_name",
                                value: getValue(backPaymentDetails.receiver_name),
                            },
                            {
                                key: "iban_account_number",
                                value: getValue(backPaymentDetails.client_payment_method.includes("SWIFT") ? backPaymentDetails.account_number : backPaymentDetails.ach_number),
                            },
                            {
                                key: "swift_bic",
                                value: getValue(backPaymentDetails.swift_bic),
                            },
                            {
                                key: "bank_name",
                                value: getValue(backPaymentDetails.bank_name),
                            },
                            {
                                key: "bank_country",
                                value: getValue(backPaymentDetails.bank_country),
                            },
                        ],
                    },
                    {
                        ...(client.organization_type !== "Legal Entity" && {
                            key: "recipients_nationality",
                            value: [
                                {
                                    key: "nationality",
                                    value: getValue(backPaymentDetails.receiver_details.receiver.country),
                                },
                                {
                                    key: "registration_address",
                                    value: getValue(backPaymentDetails.address),
                                },
                            ],
                        }),
                    },
                    {
                        ...(client.organization_type === "Legal Entity" && {
                            key: "company_details",
                            value: [
                                {
                                    key: "registration_address",
                                    value: getValue(backPaymentDetails.address),
                                },
                                {
                                    key: "director_name",
                                    value: getValue(backPaymentDetails.receiver_details.director.full_name),
                                },
                            ],
                        }),
                    },
                ];
            case "Payoneer":
                return [
                    {
                        key: "transfer_method",
                        value: "Payoneer to Payoneer",
                    },
                    {
                        key: "payoneer_details",
                        value: [
                            {
                                key: "recipients_name",
                                value: getValue(backPaymentDetails.receiver_name),
                            },
                            {
                                key: "payoneer_email",
                                value: getValue(backPaymentDetails.email),
                            },
                        ],
                    },
                ];
            case "Local":
                return [
                    {
                        key: "transfer_method",
                        value: getValue(backPaymentDetails.client_payment_method),
                    },
                    {
                        key: "bank_details",
                        value: [
                            {
                                key: "recipients_name",
                                value: getValue(backPaymentDetails.receiver_name),
                            },
                            {
                                key: "account_number",
                                value: getValue(backPaymentDetails.account_number),
                            },
                            {
                                key: "account_type",
                                value: getValue(backPaymentDetails.account_type),
                            },
                            {
                                key: "ach_routing_number",
                                value: getValue(backPaymentDetails.ach_number),
                            },
                            {
                                key: "bank_name",
                                value: getValue(backPaymentDetails.bank_name),
                            },
                            {
                                key: "bank_country",
                                value: getValue(backPaymentDetails.bank_country),
                            },
                        ],
                    },
                    {
                        ...(client.organization_type !== "Legal Entity" && {
                            key: "recipients_nationality",
                            value: [
                                {
                                    key: "nationality",
                                    value: getValue(backPaymentDetails.receiver_details.receiver.country),
                                },
                                {
                                    key: "registration_address",
                                    value: getValue(backPaymentDetails.address),
                                },
                            ],
                        }),
                    },
                    {
                        ...(client.organization_type === "Legal Entity" && {
                            key: "company_details",
                            value: [
                                {
                                    key: "registration_address",
                                    value: getValue(backPaymentDetails.address),
                                },
                                {
                                    key: "director_name",
                                    value: getValue(backPaymentDetails.receiver_details.director.full_name),
                                },
                            ],
                        }),
                    },
                ];
            case "USDT":
            case "WMZ":
                return [
                    {
                        key: "transfer_method",
                        value: getValue(backPaymentDetails.client_payment_method),
                    },
                    {
                        key: backPaymentDetails.client_payment_method === "WMZ" ? "wmz_wallet_number" : "usdt_wallet_number",
                        value: getValue(backPaymentDetails.account_number),
                    },
                ];
            default:
                return [];
        }
    };

    useEffect(() => {
        if (id) {
            (async () => {
                const res = await Promise.all([await getPaymentDetails(id, {}, null), await getClientInfo(id)]);
                setPaymentDetails(paymentDetailsParser(res) as CardType[]);
            })();
        }
    }, []);

    return (
        <ContentContainer>
            <CardDetails cols={1} title={t("transfer_method")} items={paymentDetails} />
        </ContentContainer>
    );
};

export default PartnerPaymentDetails;
