import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@mui/material";
import React, {useContext, useEffect} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useTranslation} from "react-i18next";
import ContentContainer from "../../../../components/ContentContainer/ContentContainer";
import {TopBarContext} from "../../../../context/TopBarContext";

const accordionStyles = {
    accordion: {boxShadow: "none", p: "0", borderBottom: "1px solid #ECECEF", "&::before": { display: "none"}},
    summary: {
        p: "0",
        "& .MuiAccordionSummary-expandIconWrapper": {
            color: "#000",
            background: "#EDEEF4",
            borderRadius: "50%"
        }},
    details: {p: "0"},
    title: {fontWeight: "500"}
}

const DetailsPage = () => {
    const { t } = useTranslation();
    const { handleChangeTabs, handleSetActions, handleChangePath } = useContext(TopBarContext);

    useEffect(() => {
        handleChangePath(["bonus"]);
        handleChangeTabs([
            {
                key: "details",
                label: "details",
            },
            {
                key: "my-referrals",
                label: "my-referrals",
            },
        ]);
        handleSetActions([]);
    }, []);

    return (
        <ContentContainer>
            <Box
                sx={{background: "#fff", p: "2rem"}}
            >
                <Typography sx={{fontSize: "1.25rem", color: "var(--main-purple-color)", fontWeight: "500", mb: "1rem"}}>
                    {t("ref_system.title")}
                </Typography>
                <Accordion defaultExpanded disableGutters sx={accordionStyles.accordion}>
                    <AccordionSummary
                        sx={accordionStyles.summary}
                        expandIcon={<KeyboardArrowDownIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography sx={accordionStyles.title}>1. {t("ref_system.general_info.title")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={accordionStyles.details}>
                        <Typography sx={{mb: "1rem"}}>
                            1.1. {t("ref_system.general_info.text1")}
                        </Typography>
                        <Typography sx={{mb: "1rem"}}>
                            1.2. {t("ref_system.general_info.text2")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters sx={accordionStyles.accordion}>
                    <AccordionSummary
                        sx={accordionStyles.summary}
                        expandIcon={<KeyboardArrowDownIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography sx={accordionStyles.title}>2. {t("ref_system.cond_of_part.text2")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={accordionStyles.details}>
                        <Typography sx={{mb: "1rem"}}>
                            2.1. {t("ref_system.cond_of_part.text1")}
                        </Typography>
                        <Typography sx={{mb: "1rem"}}>
                            2.2. {t("ref_system.cond_of_part.text2")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters sx={accordionStyles.accordion}>
                    <AccordionSummary
                        sx={accordionStyles.summary}
                        expandIcon={<KeyboardArrowDownIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography sx={accordionStyles.title}>3. {t("ref_system.connection.title")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={accordionStyles.details}>
                        <Typography sx={{mb: "1rem"}}>
                            {t("ref_system.connection.text1")}
                        </Typography>
                        <Typography sx={{mb: "1rem"}}>
                            3.1. {t("ref_system.connection.text2")}
                            <Box component="ul">
                                <li>
                                    {t("ref_system.connection.text3")}
                                </li>
                                <li>
                                    {t("ref_system.connection.text4")}
                                </li>
                            </Box>
                        </Typography>
                        <Typography sx={{mb: "1rem"}}>
                            {t("ref_system.connection.text5")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters sx={accordionStyles.accordion}>
                    <AccordionSummary
                        sx={accordionStyles.summary}
                        expandIcon={<KeyboardArrowDownIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography sx={accordionStyles.title}>4. {t("ref_system.cond_of_payout.title")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={accordionStyles.details}>
                        <Typography sx={{mb: "1rem"}}>
                            4.1. {t("ref_system.cond_of_payout.text1")}
                        </Typography>
                        <Typography sx={{mb: "1rem"}}>
                            4.2. {t("ref_system.cond_of_payout.text2")}
                        </Typography>
                        <Typography sx={{mb: "1rem"}}>
                            4.3. {t("ref_system.cond_of_payout.text3")}
                            <Box component="ul">
                                <li>
                                    {t("ref_system.cond_of_payout.text4")}
                                </li>
                                <li>
                                    {t("ref_system.cond_of_payout.text5")}
                                </li>
                            </Box>
                        </Typography>
                        <Typography sx={{mb: "1rem"}}>
                            4.4. {t("ref_system.cond_of_payout.text6")}
                        </Typography>
                        <Typography sx={{mb: "1rem"}}>
                            4.5. {t("ref_system.cond_of_payout.text7")}
                        </Typography>
                        <Typography sx={{mb: "1rem"}}>
                            4.6. {t("ref_system.cond_of_payout.text8")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded disableGutters sx={accordionStyles.accordion}>
                    <AccordionSummary
                        sx={accordionStyles.summary}
                        expandIcon={<KeyboardArrowDownIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography sx={accordionStyles.title}>5. {t("ref_system.payout_ex.title")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={accordionStyles.details}>
                        <Box component="ul" sx={{mb: "1rem"}}>
                            <li>
                                {t("ref_system.payout_ex.text1")}
                            </li>
                            <li>
                                {t("ref_system.payout_ex.text2")}
                            </li>
                            <li>
                                {t("ref_system.payout_ex.text3")}
                            </li>
                        </Box>
                        <Typography sx={{fontWeight: "500", mb: "1rem"}}>
                            {t("ref_system.payout_ex.text4")}
                        </Typography>
                        <Box component="ul" sx={{mb: "1rem"}}>
                            <li>
                                {t("ref_system.payout_ex.text5")}
                            </li>
                            <li>
                                {t("ref_system.payout_ex.text6")}
                            </li>
                        </Box>
                        <Typography sx={{fontWeight: "500",mb: "1rem"}}>
                            {t("ref_system.payout_ex.text7")}
                        </Typography>
                        <Box component="ul" sx={{mb: "1rem"}}>
                            <li>
                                {t("ref_system.payout_ex.text8")}
                            </li>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters sx={accordionStyles.accordion}>
                    <AccordionSummary
                        sx={accordionStyles.summary}
                        expandIcon={<KeyboardArrowDownIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography sx={accordionStyles.title}>6. {t("ref_system.payout.title")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={accordionStyles.details}>
                        <Typography sx={{mb: "1rem"}}>
                            6.1. {t("ref_system.payout.text1")}
                        </Typography>
                        <Typography sx={{mb: "1rem"}}>
                            6.2. {t("ref_system.payout.text2")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters sx={accordionStyles.accordion}>
                    <AccordionSummary
                        sx={accordionStyles.summary}
                        expandIcon={<KeyboardArrowDownIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography sx={accordionStyles.title}>7. {t("ref_system.changes.title")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={accordionStyles.details}>
                        <Typography sx={{mb: "1rem"}}>
                            7.1. {t("ref_system.changes.text1")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </ContentContainer>
    );
};

export default DetailsPage;
