import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Contract, ContractWithBonusType, PaymentDetailsType, WithContractType, WithoutContractType } from "../../../types";
import { getLegalEntityList } from "../../../services/fetchers";
import SelectInputField from "../../../../../UI/Inputs/SelectInputField";
import { handleChangeField } from "../../../services/helpers";
import ValueInputField from "../../../../../UI/Inputs/ValueInputField";
import DatePickerCustom from "../../../../../UI/Inputs/DatePicker";
import { ReactComponent as LinkIcon } from "../../../../../assets/images/icons/client/linkIcon.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/images/icons/common/editIcon.svg";
import { ReactComponent as CommissionIcon } from "../../../../../assets/images/icons/common/commissionIcon.svg";

// todo: перевести структуры
const clientContractTypes = [
    {
        name: "With contract",
        value: "With contract",
    },
    {
        name: "Without contract",
        value: "Without contract",
    },
    {
        name: "Without contract - Bonus",
        value: "Without contract - Bonus",
    },
];

const contractTypes = [
    {
        name: "Offer",
        value: "Offer",
    },
    {
        name: "Contract",
        value: "Contract",
    },
];

const transferFeeTypes = [
    {
        name: "Fix",
        value: "Fix",
    },
    {
        name: "In percentage",
        value: "In percentage",
    },
    {
        name: "No",
        value: "No",
    },
];
const vatPayerOptions = ["Yes", "No"];

interface IContractForm {
    readOnly?: boolean;
    contract: Contract;
    setContract: (contract: Contract) => void;
    currencies: string[];
    paymentDetails: PaymentDetailsType;
    setPaymentDetails: (paymentDetails: PaymentDetailsType) => void;
}
const ContractForm = ({ contract, setContract, currencies, paymentDetails, setPaymentDetails, readOnly = false }: IContractForm) => {
    const [legalEntities, setLegalEntities] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (contract.clientContractType === "With contract" && legalEntities.length === 0) {
            (async () =>
                setLegalEntities(
                    (await getLegalEntityList()).map((item: any) => ({
                        name: item.name,
                        value: item.name,
                    }))
                ))();
        }
    }, [contract.clientContractType]);
    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    gap: "1rem",
                    flexWrap: "wrap",
                    mb: "1rem",
                }}
            >
                <SelectInputField
                    readOnly={readOnly}
                    value={paymentDetails.contractCurrency}
                    label={t("forms.contract.currency")}
                    items={currencies}
                    onChange={(value) => handleChangeField("contractCurrency", value, paymentDetails, setPaymentDetails)}
                />
                <SelectInputField
                    readOnly={readOnly}
                    label={t("forms.contract.client_type")}
                    value={contract.clientContractType}
                    items={clientContractTypes}
                    onChange={(value) => handleChangeField("clientContractType", value, contract, setContract)}
                />
                {contract.clientContractType !== "Without contract - Bonus" && contract.clientContractType !== "" ? (
                    <>
                        {contract.clientContractType === "With contract" && (
                            <>
                                <SelectInputField
                                    readOnly={readOnly}
                                    value={(contract as WithContractType).contractType || ""}
                                    label={t("forms.contract.type")}
                                    items={contractTypes}
                                    onChange={(value) => handleChangeField("contractType", value, contract, setContract)}
                                />
                                <ValueInputField
                                    readOnly={readOnly}
                                    label={t("forms.contract.number")}
                                    value={(contract as WithContractType).contractNumber}
                                    Icon={<EditIcon />}
                                    onChange={(e) => handleChangeField("contractNumber", e.target.value, contract, setContract)}
                                />
                                <SelectInputField
                                    readOnly={readOnly}
                                    value={(contract as WithContractType).ourEntityName || ""}
                                    label={t("forms.contract.le")}
                                    items={legalEntities}
                                    onChange={(value) => handleChangeField("ourEntityName", value, contract, setContract)}
                                />
                                <ValueInputField
                                    readOnly={readOnly}
                                    label={`${t("forms.contract.link")} ${(contract as WithContractType).contractType}`}
                                    value={(contract as WithContractType).contractUrl}
                                    Icon={<LinkIcon />}
                                    onChange={(e) => handleChangeField("contractUrl", e.target.value, contract, setContract)}
                                />
                                {(contract as WithContractType).contractType === "Contract" && (
                                    <SelectInputField
                                        readOnly={readOnly}
                                        value={(contract as WithContractType).isVatPayer}
                                        label={t("forms.contract.VAT")}
                                        items={vatPayerOptions}
                                        onChange={(value) => handleChangeField("isVatPayer", value, contract, setContract)}
                                    />
                                )}
                                <DatePickerCustom
                                    readOnly={readOnly}
                                    label={t("forms.contract.date")}
                                    value={(contract as WithContractType).contractDate}
                                    onChange={(newValue) => handleChangeField("contractDate", newValue, contract, setContract)}
                                />
                            </>
                        )}
                        <SelectInputField
                            readOnly={readOnly}
                            value={(contract as WithContractType | WithoutContractType).transferCommissionCurrency || ""}
                            label={t("forms.contract.fee_currency")}
                            items={currencies}
                            onChange={(value) => handleChangeField("transferCommissionCurrency", value, contract, setContract)}
                        />
                        <SelectInputField
                            readOnly={readOnly}
                            value={(contract as WithContractType | WithoutContractType).transferCommissionType || ""}
                            label={t("forms.contract.fee_type")}
                            items={transferFeeTypes}
                            onChange={(value) => handleChangeField("transferCommissionType", value, contract, setContract)}
                        />
                        <ValueInputField
                            readOnly={readOnly}
                            type="number"
                            label={t("forms.contract.fee_size")}
                            value={(contract as WithContractType | WithoutContractType).commissionAmount}
                            Icon={<CommissionIcon />}
                            onChange={(e) => handleChangeField("commissionAmount", e.target.value, contract, setContract)}
                        />
                    </>
                ) : (
                    contract.clientContractType === "Without contract - Bonus" && (
                        <DatePickerCustom
                            readOnly={readOnly}
                            label={t("forms.contract.date")}
                            value={(contract as ContractWithBonusType).contractDate}
                            onChange={(newValue) => handleChangeField("contractDate", newValue, contract, setContract)}
                        />
                    )
                )}
            </Box>
            <DatePickerCustom
                readOnly={readOnly}
                label={t("forms.contract.start_date")}
                value={contract.startContractDate}
                onChange={(newValue) => handleChangeField("startContractDate", newValue, contract, setContract)}
            />
        </Box>
    );
};

export default ContractForm;
