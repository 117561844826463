import { Navigate, useLocation } from "react-router-dom";
import { FC, ReactElement, useContext } from "react";
import { Box } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import Loading from "../components/Loading";

type PrivateRouteType = {
    children?: ReactElement | null;
};

const PrivateRoute: FC<PrivateRouteType> = ({ children = null }): ReactElement | any => {
    const { me, isLoggedIn, loading } = useContext(AuthContext);
    const location = useLocation();

    if (Object.keys(me).length === 0) {
        if (loading) {
            return (
                <Box
                    sx={{
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Loading />
                </Box>
            );
        }
        if (!(isLoggedIn && Object.keys(me).length > 0)) {
            return <Navigate to="/login" state={{ from: location }} />;
        }
        return <></>;
    }
    return <>{children}</>;
};

export default PrivateRoute;
