import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SelectInputField from "../../../../../../../UI/Inputs/SelectInputField";
import { handleChangeInnerField } from "../../../../../services/helpers";
import ValueInputField from "../../../../../../../UI/Inputs/ValueInputField";
import { ReactComponent as LinkIcon } from "../../../../../../../assets/images/icons/client/linkIcon.svg";

const CitizenshipFields = ({ paymentDetails, setPaymentDetails, countries, readOnly }: any) => {
    const { receiverCitizenship } = paymentDetails;
    const { t } = useTranslation();
    return (
        <>
            <Typography
                sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    mb: "1rem",
                }}
            >
                {t("forms.payment.nationality_label")}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    gap: "1rem",
                    mb: "1rem",
                }}
            >
                <SelectInputField
                    readOnly={readOnly}
                    value={receiverCitizenship?.citizenship}
                    label={t("forms.payment.nationality")}
                    items={countries}
                    onChange={(value) => handleChangeInnerField("receiverCitizenship", "citizenship", value, setPaymentDetails)}
                />
                <ValueInputField
                    readOnly={readOnly}
                    Icon={<LinkIcon />}
                    label={t("forms.payment.passport_link")}
                    value={receiverCitizenship?.passportScanUrl}
                    onChange={(e) => handleChangeInnerField("receiverCitizenship", "passportScanUrl", e.target.value, setPaymentDetails)}
                />
            </Box>
        </>
    );
};

export default CitizenshipFields;
