import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ContentContainer from "../../../../../../components/ContentContainer/ContentContainer";
import { getBankList, getCurrencies, getLegalEntityList, getSystemList } from "../../../../../ClientsPage/services/fetchers";
import ValueInputField from "../../../../../../UI/Inputs/ValueInputField";
import { handleChangeField } from "../../../../../ClientsPage/services/helpers";
import SelectInputField from "../../../../../../UI/Inputs/SelectInputField";
import DatePickerCustom from "../../../../../../UI/Inputs/DatePicker";
import { ReactComponent as ProfileIcon } from "../../../../../../assets/images/icons/sidebar/profile.svg";
import { ReactComponent as MoneyIcon } from "../../../../../../assets/images/icons/common/moneyIcon.svg";
import { initialSupplier, Supplier } from "../SupplierViewPage/pages/SupplierPage";
import { AuthContext } from "../../../../../../context/AuthContext";
import { TopBarContext } from "../../../../../../context/TopBarContext";
import CreateButton from "../../../../../../UI/Buttons/Actions/CreateButton";
import CloseButton from "../../../../../../UI/Buttons/Actions/CloseButton";

const vatList = ["On top", "Included", "No Vat"];

const SupplierCreatePage = () => {
    const { setNotification } = useContext(AuthContext);
    const [supplier, setSupplier] = useState<Supplier>(initialSupplier);
    const [legalEntityList, setLegalEntityList] = useState([]);
    const [bankList, setBankList] = useState([]);
    const [systemList, setSystemList] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const navigate = useNavigate();
    const { handleChangePath, handleSetActions } = useContext(TopBarContext);
    const { t } = useTranslation();
    useEffect(() => {
        handleChangePath(["partners", "new_supplier"]);
        handleSetActions([<CreateButton action={handleSubmit} />, <CloseButton path="/properties/suppliers" />]);
    }, [supplier]);

    useEffect(() => {
        (async () => {
            const res: any = await Promise.all([getBankList(), getLegalEntityList(), getSystemList(), getCurrencies()]);
            setBankList(res[0]);
            setLegalEntityList(res[1]);
            setSystemList(res[2]);
            setCurrencies(res[3]);
        })();
    }, []);

    const handleSubmit = async () => {
        try {
            const res = await axios.post("/properties/suppliers/create/", supplier);
            handleClose();
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (e: any) {
            console.log(e.message);
            setNotification({
                open: true,
                message: e.response.data.message,
                type: "error",
            });
        }
    };
    const handleClose = () => navigate("/properties/suppliers");
    return (
        <ContentContainer>
            <Box
                sx={{
                    p: "3rem",
                    background: "#fff",
                    maxWidth: "736px",
                    gap: "1rem",
                    display: "flex",
                    flexWrap: "wrap",
                }}
            >
                <ValueInputField
                    label="forms.partners.supplier.name"
                    value={supplier.account}
                    Icon={<ProfileIcon />}
                    onChange={(e) => handleChangeField("account", e.target.value, supplier, setSupplier)}
                />
                <SelectInputField
                    value={supplier.ad_system}
                    label="forms.partners.supplier.system"
                    items={systemList}
                    onChange={(value) => handleChangeField("ad_system", value, supplier, setSupplier)}
                />
                <SelectInputField
                    value={supplier.currency_acceptance}
                    label="forms.partners.supplier.acceptance"
                    items={currencies}
                    onChange={(value) => handleChangeField("currency_acceptance", value, supplier, setSupplier)}
                />
                <SelectInputField
                    value={supplier.currency_account}
                    label="forms.partners.supplier.account"
                    items={currencies}
                    onChange={(value) => handleChangeField("currency_account", value, supplier, setSupplier)}
                />
                <SelectInputField
                    value={supplier.llp}
                    label="forms.partners.supplier.receiving_le"
                    items={legalEntityList}
                    onChange={(value) => handleChangeField("llp", value, supplier, setSupplier)}
                />
                <SelectInputField value={supplier.vat} label="forms.partners.supplier.options" items={vatList} onChange={(value) => handleChangeField("vat", value, supplier, setSupplier)} />
                <ValueInputField
                    label="forms.partners.supplier.sVAT"
                    value={supplier.vat_value}
                    type="number"
                    Icon={<MoneyIcon />}
                    onChange={(e) => handleChangeField("vat_value", e.target.value, supplier, setSupplier)}
                />
                <SelectInputField
                    value={supplier.bank_recipient}
                    label="forms.partners.supplier.reward"
                    items={bankList}
                    onChange={(value) => handleChangeField("bank_recipient", value, supplier, setSupplier)}
                />
                <DatePickerCustom label={t("forms.common.start_date")} value={supplier.created_at} onChange={(value) => handleChangeField("created_at", value, supplier, setSupplier)} />
                <FormControlLabel
                    sx={{ width: "100%" }}
                    control={
                        <Checkbox
                            sx={{
                                "&.Mui-checked": {
                                    color: "var(--main-black-color)",
                                },
                            }}
                            checked={supplier.is_active}
                        />
                    }
                    label={t("forms.partners.supplier.is_active")}
                    onChange={(e) => {
                        handleChangeField("is_active", !supplier.is_active, supplier, setSupplier);
                    }}
                />
                <FormControlLabel
                    sx={{ width: "100%" }}
                    control={
                        <Checkbox
                            sx={{
                                "&.Mui-checked": {
                                    color: "var(--main-black-color)",
                                },
                            }}
                            checked={supplier.is_bonus}
                        />
                    }
                    label={t("forms.partners.supplier.is_bonus")}
                    onChange={(e) => {
                        handleChangeField("is_bonus", !supplier.is_bonus, supplier, setSupplier);
                    }}
                />
            </Box>
        </ContentContainer>
    );
};

export default SupplierCreatePage;
