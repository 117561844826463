import { Box, CircularProgress } from "@mui/material";
import React from "react";

export const Loading = () => (
    <Box
        sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <CircularProgress />
    </Box>
);

export default Loading;
