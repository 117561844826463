import axios from "axios";

const getSupplierInfo = async (id: string | number | undefined) => {
    try {
        const res = await axios.get(`properties/suppliers/${id}/info/`);
        return await res.data;
    } catch (e) {
        console.error("Error");
        throw e;
    }
};

export default getSupplierInfo;
