import React, { FC } from "react";
import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    useTheme,
} from "@mui/material";

type CheckBoxFieldProps = {
    label: string;
    value: boolean;
    onChange: (value: boolean) => void;
    readOnly?: boolean;
};

const CheckBoxField: FC<CheckBoxFieldProps> = ({label, value, onChange, readOnly = false,}) => {
    const theme = useTheme();

    return (
        <FormControl sx={{ width: "100%" }}>
            <FormLabel id={`${label}-radio-buttons-label`}  color="warning" sx={{ "&.Mui-focused": { color: "text.primary" }, color: "text.primary", fontWeight: "500" }}>
                {label}
            </FormLabel>
            <RadioGroup
                row
                aria-labelledby={`${label}-radio-buttons-label`}
                name={`${label}-radio-buttons-group`}
                value={value.toString()}
                onChange={(event, value) => onChange(value === "true")}
            >
                <FormControlLabel
                    disabled={readOnly}
                    value
                    control={
                        <Radio
                            color="warning"
                            // sx={{color: "text.disabled"}}
                        />
                    }
                    label="Да"
                />
                <FormControlLabel
                    disabled={readOnly}
                    value={false}
                    control={
                        <Radio
                            color="warning"
                            // sx={{color: "text.disabled" }}
                        />
                    }
                    label="Нет"
                />
            </RadioGroup>
        </FormControl>
    );
};

export default CheckBoxField;
