import {Box, Typography} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CustomButton from "../../UI/Buttons/CustomButton";

const ReferralPromo = () => {
    const {t} = useTranslation();
    return (
        <Link
            to="/referral-system/details"
            style={{textDecoration: "none", marginTop: ".75rem", marginBottom: ".75rem", padding: "1.5rem", background: "linear-gradient(90deg, #DA8CFF 0%, #9A55FF 100%)",
                display: "flex", gap: "1.5rem", justifyContent: "space-between"
            }}
        >
            <Box>
                <Typography sx={{whiteSpace: "nowrap", fontSize: "1.5rem", color: "#fff", fontWeight: "700"}}>
                    {t("referral_program")}
                </Typography>
                <Typography sx={{fontSize: "1.5rem", color: "#fff", fontWeight: "700", opacity: ".6", mb: ".5rem"}}>
                    {t("unlimited_bonus")}
                </Typography>
                <CustomButton
                    label={t("learn_more")}
                    style={{background: "linear-gradient(0deg, #9A55FF 0%, #9A55FF 100%), linear-gradient(0deg, #DA8CFF 0%, #DA8CFF 100%), linear-gradient(90deg, #90CAF9 0%, #047EDF 100%)"}}
                    onChange={() => {}}
                    Icon={<ArrowForwardIosIcon />}
                />
            </Box>
            <Box sx={{p: "1rem", background: "rgba(255,255,255,0.08)"}}>
                <Typography sx={{color: "#fff", fontSize: "1.125rem", fontWeight: "500"}}>
                    <Box component="span" sx={{fontSize: "1.5rem", fontWeight: "700"}}>{t("get_5_percent.text1")}</Box> <br/>
                    {t("get_5_percent.text2")}&nbsp;
                    {t("get_5_percent.text3")}
                </Typography>
            </Box>
            <Box sx={{p: "1rem", background: "rgba(255,255,255,0.08)"}}>
                <Typography sx={{color: "#fff", fontSize: "1.125rem", fontWeight: "500"}}>
                    {t("get_2_percent.and")} <Box component="span" sx={{fontSize: "1.5rem", fontWeight: "700"}}>{t("get_2_percent.text1")}</Box> <br/>
                    {t("get_2_percent.text2")}
                </Typography>
            </Box>
        </Link>
    )
}

export default ReferralPromo;