import React, { useContext } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ClientCreateContext } from "../../../../../../context/ClientCreateContext";
import ClientCreateForm from "../../../../components/ClientCreateForm";

const ClientCreateController = () => {
    const { client, setClient } = useContext(ClientCreateContext);
    const { t } = useTranslation();
    return (
        <>
            <Typography
                sx={{
                    fontSize: "1.25rem",
                    fontWeight: "500",
                    mb: "3rem",
                }}
            >
                {t("client_create.new_client")}
            </Typography>
            <ClientCreateForm client={client} setClient={setClient} />
        </>
    );
};

export default ClientCreateController;
