import React from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import ValueInputField from "../../../../../../../../../../UI/Inputs/ValueInputField";
import { ReactComponent as HouseManIcon } from "../../../../../../../../../../assets/images/icons/common/house-man.svg";
import { handleChangeField } from "../../../../../../../../../ClientsPage/services/helpers";
import { BankType } from "../../pages/BanksCreate";

type BankFormType = {
    bank: BankType;
    setBank: (bonus: BankType) => void;
};

const BankForm: React.FC<BankFormType> = ({ bank, setBank }) => (
    <Box
        sx={{
            p: "3rem",
            background: "#fff",
            maxWidth: "736px",
            gap: "1rem",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
        }}
    >
        <ValueInputField Icon={<HouseManIcon />} label="Bank name" value={bank.name} onChange={(e) => handleChangeField("name", e.target.value, bank, setBank)} />
        <FormControlLabel
            control={
                <Checkbox
                    sx={{
                        "&.Mui-checked": {
                            color: "var(--main-black-color)",
                        },
                    }}
                    checked={bank.is_active || false}
                />
            }
            label="Active"
            onChange={(e) => handleChangeField("is_active", !bank.is_active, bank, setBank)}
        />
    </Box>
);

export default BankForm;
