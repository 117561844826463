import React, {useContext, useEffect, useState} from "react";
import dayjs from "dayjs";
import {TopBarContext} from "../../../../context/TopBarContext";
import {AuthContext} from "../../../../context/AuthContext";
import {Dictionary} from "../../../ClientsPage";
import {TableDataType} from "../../../../components/TableConstructor/types";
import {initialTableData} from "../../../../components/TableConstructor/constants";
import getReferrals from "../../services/fetchers";
import ContentContainer from "../../../../components/ContentContainer/ContentContainer";
import TableConstructor from "../../../../components/TableConstructor";

const MyReferralsPage = () => {
    const { setNotFound } = useContext(AuthContext);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState<Dictionary<any>>({
        periods: {
            date_from: dayjs().startOf("month"),
            date_to: dayjs().add(-1, "day"),
            currency: "",
        },
    });
    const [tableData, setTableData] = useState<TableDataType>(initialTableData);

    const { handleChangeTabs, handleSetActions, handleChangePath } = useContext(TopBarContext);

    useEffect(() => {
        handleChangePath(["bonus"]);
        handleChangeTabs([
            {
                key: "details",
                label: "details",
            },
            {
                key: "my-referrals",
                label: "my-referrals",
            },
        ]);
        handleSetActions([]);
    }, []);


    const loadReferrals = async (signal: any = null) => {
        setLoading(true);
        const data = {
            ...request,
            filters: request.filters || {},
        };
        try {
            const res = await getReferrals(data, signal);
            if (res?.status === "404") {
                setNotFound(true);
            }
            setTableData(res);
        } catch (e) {
            setError("Error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        (async () => {
            await loadReferrals(controller.signal);
        })();
        return () => controller.abort();
    }, [request]);


    return (
        <ContentContainer>
            <TableConstructor
                tableData={tableData}
                loading={loading}
                request={request}
                setRequest={setRequest}
            />
        </ContentContainer>
    );
};

export default MyReferralsPage;
