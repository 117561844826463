import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ValueInputField from "../../../../../../../UI/Inputs/ValueInputField";
import { handleChangeField } from "../../../../../services/helpers";
import { ReactComponent as CreditCardIcon } from "../../../../../../../assets/images/icons/client/creditCardIcon.svg";

const OnlineWalletPaymentFields = ({ paymentDetails, setPaymentDetails, readOnly }: any) => {
    const { t } = useTranslation();
    return (
        <Box sx={{ maxWidth: "740px" }}>
            <ValueInputField
                readOnly={readOnly}
                Icon={<CreditCardIcon style={{ color: "#000" }} />}
                label={`${paymentDetails.type} ${t("forms.payment.wallet_number")}`}
                value={paymentDetails?.accountNumberWMZorUSDT}
                style={{ width: "100%" }}
                onChange={(e) => handleChangeField("accountNumberWMZorUSDT", e.target.value, paymentDetails, setPaymentDetails)}
            />
        </Box>
    );
};

export default OnlineWalletPaymentFields;
