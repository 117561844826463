import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import PartnerContactsPage from "../partner/pages/ContactsPage";
import ContactsPage from "../pages/ClientViewPage/pages/ContactsPage";

const ContactsView = () => {
    const { me } = useContext(AuthContext);

    if (Object.keys(me).length > 0 && me.role === "client") {
        return <PartnerContactsPage />;
    }
    return <ContactsPage />;
};

export default ContactsView;
