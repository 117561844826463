import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import ValueInputField from "../../../../UI/Inputs/ValueInputField";
import { handleChangeField } from "../../../ClientsPage/services/helpers";
import { ReactComponent as ProfileIcon } from "../../../../assets/images/icons/sidebar/profile.svg";
import { ReactComponent as TelegramIcon } from "../../../../assets/images/icons/common/telegramIcon.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/images/icons/common/phoneIcon.svg";
import { ReactComponent as MailIcon } from "../../../../assets/images/icons/common/mailIcon.svg";
import SelectInputField from "../../../../UI/Inputs/SelectInputField";
import { ManagerType } from "../../types";
import { getPositionList } from "../../../ClientsPage/services/fetchers";

type ManagerFormType = {
    manager: ManagerType;
    setManager: (manager: ManagerType) => void;
    readonlyUsername: boolean;
};

const ManagerForm: React.FC<ManagerFormType> = ({ manager, setManager, readonlyUsername }) => {
    const [positions, setPositions] = useState([]);

    useEffect(() => {
        (async () => {
            const res = await Promise.all([getPositionList()]);
            setPositions(res[0]);
        })();
    }, []);
    return (
        <Box
            sx={{
                p: "3rem",
                background: "#fff",
                maxWidth: "736px",
                gap: "1rem",
                display: "flex",
                flexWrap: "wrap",
            }}
        >
            <ValueInputField label="last_name" value={manager.last_name} Icon={<ProfileIcon />} onChange={(e) => handleChangeField("last_name", e.target.value, manager, setManager)} />
            <ValueInputField label="first_name" value={manager.first_name} Icon={<ProfileIcon />} onChange={(e) => handleChangeField("first_name", e.target.value, manager, setManager)} />
            <ValueInputField
                label="username"
                value={manager.username}
                readOnly={readonlyUsername}
                Icon={<ProfileIcon />}
                onChange={(e) => handleChangeField("username", e.target.value, manager, setManager)}
            />
            <ValueInputField label="telegram" value={manager.tg_login} Icon={<TelegramIcon />} onChange={(e) => handleChangeField("tg_login", e.target.value, manager, setManager)} />

            <ValueInputField
                Icon={<PhoneIcon style={{ color: "#000" }} />}
                label="phone"
                value={manager?.phone_number || ""}
                onChange={(e) => handleChangeField("phone_number", e.target.value, manager, setManager)}
            />
            <ValueInputField label="E-mail" value={manager.email} Icon={<MailIcon />} onChange={(e) => handleChangeField("email", e.target.value, manager, setManager)} />
            <SelectInputField value={manager.position} label="position" items={positions} onChange={(value) => handleChangeField("position", value, manager, setManager)} />
        </Box>
    );
};

export default ManagerForm;
