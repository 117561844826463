import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import dayjs from "dayjs";
import ContentContainer from "../../../../../components/ContentContainer/ContentContainer";
import { getConditions } from "../../../services/fetchers";
import { TableDataType } from "../../../../../components/TableConstructor/types";
import { initialTableData } from "../../../../../components/TableConstructor/constants";
import { Dictionary } from "../../../index";
import TableConstructor from "../../../../../components/TableConstructor";

const PartnerFeesPage = () => {
    const { t } = useTranslation();
    const [tableData, setTableData] = useState<TableDataType>(initialTableData);
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState<Dictionary<any>>({
        periods: {
            date_from: dayjs().startOf("month"),
            date_to: dayjs().add(-1, "day"),
            currency: "",
        },
    });
    const { id } = useParams();

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                setTableData(await getConditions(id, {}, null));
                setLoading(false);
            } catch (e: unknown) {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <ContentContainer>
            <Box
                sx={{
                    backgroundColor: "#fff",
                    p: "2rem",
                    width: "100%",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "1.25rem",
                        fontWeight: "500",
                    }}
                >
                    {t("fees")}
                </Typography>
                <Divider sx={{ backgroundColor: "#9747FF3D", m: "1.5rem 0" }} />
                <TableConstructor loading={loading} isFilterPanel={false} tableData={tableData} request={request} setRequest={setRequest} />
            </Box>
        </ContentContainer>
    );
};

export default PartnerFeesPage;
