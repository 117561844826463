import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import axios, { AxiosError, isAxiosError } from "axios";
import dayjs, { Dayjs } from "dayjs";
import ContentContainer from "../../../../../../../../components/ContentContainer/ContentContainer";
import { initialClient, initialPaymentDetails } from "../../../../../../../../context/ClientCreateContext";
import { getClientInfo, getCurrencies } from "../../../../../../services/fetchers";
import BASE_URL from "../../../../../../../../data/constants";
import { initialSwiftData } from "../../../../../../../../data/initialData";
import { backendPaymentDetails, frontendPaymentDetails } from "../../../../../../services/parsers";
import DatePickerCustom from "../../../../../../../../UI/Inputs/DatePicker";
import { ClientType, PaymentDetailsType } from "../../../../../../types";
import { AuthContext } from "../../../../../../../../context/AuthContext";
import PaymentDetailsForm from "../../../../../../components/ClientCreateForm/PaymentDetailsForm";
import { TopBarContext } from "../../../../../../../../context/TopBarContext";
import CreateButton from "../../../../../../../../UI/Buttons/Actions/CreateButton";
import EditButton from "../../../../../../../../UI/Buttons/Actions/EditButton";
import CloseButton from "../../../../../../../../UI/Buttons/Actions/CloseButton";

const PaymentDetailsCreateOrEdit = () => {
    const { setNotification, setNotFound } = useContext(AuthContext);
    const { handleSetActions, handleChangePath, handleChangeTabs } = useContext(TopBarContext);
    const navigate = useNavigate();
    const { id, paymentId } = useParams();
    const [paymentDetails, setPaymentDetails] = useState<PaymentDetailsType>({
        ...initialPaymentDetails,
        ...initialSwiftData,
    });
    const [startDate, setStartDate] = useState<Dayjs | null | "">(null);
    const [countries, setCountries] = useState([] as string[]);
    const [currencies, setCurrencies] = useState([] as string[]);
    const [client, setClient] = useState<ClientType>(initialClient);
    const mode = paymentId ? "edit" : "create";

    useEffect(() => {
        const labelItems = [client.companyName, mode === "edit" ? "client_create.payment" : "client_create.new_payment"];
        handleChangePath(labelItems);
        handleSetActions([mode === "edit" ? <EditButton action={handleEditPayment} /> : <CreateButton action={handleCreatePayment} />, <CloseButton path={`/clients/${id}/payment_details`} />]);
        handleChangeTabs([]);
    }, [client.companyName, paymentDetails, startDate]);

    const getPaymentDetails = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/${id}/payment_details/${paymentId}/`);
            return await res.data;
        } catch (err: Error | AxiosError | unknown) {
            if (isAxiosError(err)) {
                if (err.response?.status === 404) {
                    setNotFound(true);
                }
            }
            return err;
        }
    };

    useEffect(() => {
        if (paymentId) {
            (async () => {
                const res = await getPaymentDetails();
                const data = frontendPaymentDetails(res);
                setPaymentDetails(data);
                setStartDate(data.startDate || null);
            })();
        }
    }, [paymentId]);

    const handleGoBack = () => navigate(`/clients/${id}/payment_details`);

    useEffect(() => {
        (async () => setCurrencies(await getCurrencies()))();
    }, []);

    useEffect(() => {
        (async () => {
            const data = await getClientInfo(id);
            setClient({
                ...client,
                companyType: data.organization_type,
                companyName: data.name,
            });
        })();
    }, [id]);

    const handleCreatePayment = async () => {
        const data = {
            ...backendPaymentDetails(paymentDetails),
            start_date: dayjs(startDate).format("YYYY-MM-DD"),
        };
        try {
            const res = await axios.post(`${BASE_URL}/clients/${id}/payment_details/create/`, data);
            handleGoBack();
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err) {
            console.error(err);
        }
    };
    const handleEditPayment = async () => {
        const data = {
            ...backendPaymentDetails(paymentDetails),
            start_date: dayjs(startDate).format("YYYY-MM-DD"),
        };
        try {
            const res = await axios.post(`${BASE_URL}/clients/${id}/payment_details/${paymentId}/edit/`, data);
            handleGoBack();
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <ContentContainer>
            <Box
                sx={{
                    p: "3rem",
                    background: "#fff",
                    maxWidth: "740px",
                }}
            >
                <DatePickerCustom style={{ width: "100%", marginBottom: "1rem" }} label="Start Date" value={startDate} onChange={(newValue) => setStartDate(newValue)} />
                <PaymentDetailsForm client={client} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} currencies={currencies} countries={countries} setCountries={setCountries} />
            </Box>
        </ContentContainer>
    );
};

export default PaymentDetailsCreateOrEdit;
