import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import axios from "axios";
import { getClientInfo, getPlatforms } from "../../../../../../services/fetchers";
import { initialClientInfo } from "../../../../../../../../context/ClientCreateContext";
import ContentContainer from "../../../../../../../../components/ContentContainer/ContentContainer";
import BASE_URL from "../../../../../../../../data/constants";
import { AuthContext } from "../../../../../../../../context/AuthContext";
import { ClientInfo } from "../../../../../../types";
import CreateButton from "../../../../../../../../UI/Buttons/Actions/CreateButton";
import CloseButton from "../../../../../../../../UI/Buttons/Actions/CloseButton";
import { TopBarContext } from "../../../../../../../../context/TopBarContext";
import SelectInputField from "../../../../../../../../UI/Inputs/SelectInputField";
import commTypes from "../../constants";
import { PlatformEditType } from "../PlatformView/pages/PlatformEdit";
import { handleChangeField } from "../../../../../../services/helpers";

const PlatformCreate = () => {
    const { t } = useTranslation();
    const { handleSetActions, handleChangePath, handleChangeTabs } = useContext(TopBarContext);
    const { setNotification } = useContext(AuthContext);
    const { id, platformId } = useParams();
    const navigate = useNavigate();
    const [platforms, setPlatforms] = useState<PlatformEditType>({
        name: "",
        commission_type: "",
    });
    const [platformList, setPlatformList] = useState<string[]>([]);
    const [clientInfo, setClientInfo] = useState<ClientInfo>(initialClientInfo);
    const mode = platformId ? "edit" : "create";

    useEffect(() => {
        const labelItems = [clientInfo.name, mode === "edit" ? "Placement" : "New Placement"];
        handleChangePath(labelItems);
        handleSetActions([mode === "edit" ? null : <CreateButton action={handleCreatePlatform} />, <CloseButton path={`/clients/${id}/platforms`} />]);
        handleChangeTabs([]);
    }, [clientInfo.name, platforms]);

    useEffect(() => {
        (async () => {
            const data = await getClientInfo(id);
            setClientInfo(data);
        })();
    }, [id]);

    useEffect(() => {
        (async () => setPlatformList(await getPlatforms()))();
    }, []);

    const getPlatform = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/${id}/platforms/${platformId}/`);
            return await res.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    };

    useEffect(() => {
        if (platformId) {
            (async () => {
                const data = await getPlatform();
                setPlatforms(data);
            })();
        }
    }, [platformId]);

    const handleGoBack = () => navigate(`/clients/${id}/platforms`);

    const handleCreatePlatform = async () => {
        try {
            const res = await axios.post(`${BASE_URL}/clients/${id}/platforms/create/`, platforms);
            handleGoBack();
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        }
    };

    return (
        <ContentContainer>
            <Box
                sx={{
                    p: "3rem",
                    background: "#fff",
                    width: "fit-content",
                    maxWidth: "740px",
                    display: "flex",
                    gap: "1rem",
                }}
            >
                <Autocomplete
                    value={platforms.name}
                    color="warning"
                    disablePortal
                    id="combo-box-demo"
                    options={platformList}
                    sx={{ width: 312 }}
                    onChange={(e, value) => handleChangeField("name", value, platforms, setPlatforms)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                sx: {
                                    borderRadius: "0",
                                },
                            }}
                            color="warning"
                            label={t<string>("Select a placement")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                />
                <SelectInputField
                    value={platforms.commission_type}
                    label="Commission Type"
                    items={commTypes}
                    onChange={(value) => handleChangeField("commission_type", value, platforms, setPlatforms)}
                />
            </Box>
        </ContentContainer>
    );
};

export default PlatformCreate;
