import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PercentIcon from "@mui/icons-material/Percent";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { getSystemList } from "../../../services/fetchers";
import { AgencyCommissionFix, AgencyCommissionRange, CommissionRange, SystemType } from "../../../types";
import AddBadge from "../../../../../components/Badges/AddBadge";
import RemoveBadge from "../../../../../components/Badges/RemoveBadge";
import SelectInputField from "../../../../../UI/Inputs/SelectInputField";
import ValueInputField from "../../../../../UI/Inputs/ValueInputField";
import DatePickerCustom from "../../../../../UI/Inputs/DatePicker";

// //todo: возможно здесь вообще не нужно было переводить
// const recipients = ["condition.recipients.we", "condition.recipients.mixed", "condition.recipients.client"];
// const bonuses = ["condition.bonuses.yes", "condition.bonuses.no"];
// const typeAgencyCommission = ["condition.commission_type.fix", "condition.commission_type.range"];
const recipients = ["We", "Mixed", "Client"];
const bonuses = ["Yes", "No"];
const typeAgencyCommission = ["Fix", "Range"];

interface IConditionForm {
    readOnly?: boolean;
    isAddMore?: boolean;
    systems: SystemType[];
    setSystems: (systems: SystemType[]) => void;
}
const ConditionForm = ({ systems, setSystems, isAddMore = true, readOnly = false }: IConditionForm) => {
    const [systemList, setSystemList] = useState([]);
    const { t } = useTranslation();
    useEffect(() => {
        (async () => setSystemList(await getSystemList()))();
    }, []);
    const handleAddSystem = () => {
        const newSystems = [
            ...(systems as SystemType[]),
            {
                fieldId: `id${Math.random().toString(16).slice(2)}`,
                systemName: "",
                moneyReceiver: "",
                isBonus: "",
                typeOfAgencyCommission: "",
            } as SystemType,
        ];
        setSystems(newSystems);
    };
    const handleAddRangeInSystem = (fieldId: string) => {
        const newSystems = (systems as SystemType[]).map((system) => {
            if (system.fieldId === fieldId) {
                return {
                    ...system,
                    ranges: [
                        ...(system as AgencyCommissionRange).ranges,
                        {
                            fieldId: `id${Math.random().toString(16).slice(2)}`,
                            agencyCommissionAmount: null,
                            from: null,
                            to: null,
                        },
                    ],
                };
            }
            return system;
        });
        setSystems(newSystems as SystemType[]);
    };
    const handleRemoveRangeInSystem = (fieldId: string, rangeId: string) => {
        const newSystems = (systems as SystemType[]).map((system) => {
            if (system.fieldId === fieldId) {
                return {
                    ...system,
                    ranges: (system as AgencyCommissionRange).ranges.filter((range) => range.fieldId !== rangeId),
                };
            }
            return system;
        });
        setSystems(newSystems as SystemType[]);
    };
    const handleRemoveFounder = (fieldId: string) => {
        const newSystems = (systems as SystemType[]).filter((founder) => founder.fieldId !== fieldId);
        setSystems(newSystems);
    };
    const handleChangeSystemRange = (systemId: string, rangeId: string, fieldKey: string, value: number) => {
        const newFounders = (systems as SystemType[]).map((system) => {
            if (system.fieldId === systemId) {
                return {
                    ...system,
                    ranges: (system as AgencyCommissionRange).ranges.map((range) => {
                        if (range.fieldId === rangeId) {
                            return {
                                ...range,
                                [fieldKey]: value,
                            };
                        }
                        return range;
                    }),
                };
            }
            return system;
        });
        setSystems(newFounders as SystemType[]);
    };
    const handleChangeFounder = (fieldId: string, fieldKey: string, value: string | number | null | Dayjs) => {
        const newFounders = (systems as SystemType[]).map((system) => {
            if (system.fieldId === fieldId && fieldKey === "typeOfAgencyCommission") {
                return {
                    ...system,
                    [fieldKey]: value,
                    ranges: [
                        {
                            fieldId: `id${Math.random().toString(16).slice(2)}`,
                            agencyCommissionAmount: null,
                            from: null,
                            to: null,
                        } as CommissionRange,
                    ],
                };
            }
            if (system.fieldId === fieldId) {
                return {
                    ...system,
                    [fieldKey]: value,
                };
            }
            return system;
        });
        setSystems(newFounders as SystemType[]);
    };
    return (
        <Box sx={{ maxWidth: "640px" }}>
            <Box
                sx={{
                    display: "flex",
                    gap: "1rem",
                    flexWrap: "wrap",
                    mb: "1rem",
                }}
            >
                {systems.length > 0 &&
                    systems.map((system, systemIndex) => (
                        <Box
                            key={systemIndex}
                            sx={{
                                display: "flex",
                                gap: "1rem",
                                flexWrap: "wrap",
                                mb: "1rem",
                                width: "100%",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    borderBottom: "2px solid rgba(0, 0, 0, 0.20)",
                                    height: "50px",
                                    alignItems: "center",
                                    mb: "1rem",
                                    width: "100%",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: "500",
                                        color: "#1A1F27",
                                    }}
                                >
                                    {t("common.system")} {systemIndex + 1}
                                </Typography>
                                {isAddMore &&
                                    (systemIndex === 0 ? (
                                        <Box onClick={() => !readOnly && handleAddSystem()}>
                                            <AddBadge />
                                        </Box>
                                    ) : (
                                        <Box onClick={() => !readOnly && handleRemoveFounder(system.fieldId)}>
                                            <RemoveBadge />
                                        </Box>
                                    ))}
                            </Box>
                            <SelectInputField
                                readOnly={readOnly}
                                value={system.systemName}
                                label={t("common.system")}
                                items={systemList}
                                onChange={(value) => handleChangeFounder(system.fieldId, "systemName", value)}
                            />
                            {system.systemName && (
                                <>
                                    <DatePickerCustom
                                        readOnly={readOnly}
                                        label={t("forms.common.start_date")}
                                        value={system.date_from}
                                        onChange={(newValue) => handleChangeFounder(system.fieldId, "date_from", newValue)}
                                    />
                                    <SelectInputField
                                        readOnly={readOnly}
                                        value={system.moneyReceiver}
                                        label={t("condition.other.recipient")}
                                        items={recipients.map((item) => t(item))}
                                        onChange={(value) => handleChangeFounder(system.fieldId, "moneyReceiver", value)}
                                    />
                                    <SelectInputField
                                        readOnly={readOnly}
                                        value={system.isBonus}
                                        label={t("condition.bonuses.label")}
                                        items={bonuses.map((item) => t(item))}
                                        onChange={(value) => handleChangeFounder(system.fieldId, "isBonus", value)}
                                    />
                                    {system.moneyReceiver === t("condition.recipients.mixed") && (
                                        <>
                                            <ValueInputField
                                                readOnly={readOnly}
                                                type="number"
                                                Icon={
                                                    <PercentIcon
                                                        style={{
                                                            color: "grey",
                                                        }}
                                                    />
                                                }
                                                label={t("condition.recipients.we")}
                                                value={system.we_part}
                                                onChange={(e) => handleChangeFounder(system.fieldId, "we_part", Number(e.target.value))}
                                            />
                                            <ValueInputField
                                                readOnly={readOnly}
                                                type="number"
                                                Icon={
                                                    <PercentIcon
                                                        style={{
                                                            color: "grey",
                                                        }}
                                                    />
                                                }
                                                label={t("condition.recipients.client")}
                                                value={system.client_part}
                                                onChange={(e) => handleChangeFounder(system.fieldId, "client_part", Number(e.target.value))}
                                            />
                                        </>
                                    )}
                                    <SelectInputField
                                        readOnly={readOnly}
                                        style={{ width: "100%" }}
                                        value={system.typeOfAgencyCommission}
                                        label={t("condition.commission_type.label")}
                                        items={typeAgencyCommission.map((item) => t(item))}
                                        onChange={(value) => handleChangeFounder(system.fieldId, "typeOfAgencyCommission", value)}
                                    />
                                    {system.typeOfAgencyCommission === "Fix" && (
                                        <ValueInputField
                                            readOnly={readOnly}
                                            type="number"
                                            Icon={<PercentIcon style={{ color: "grey" }} />}
                                            label={t("condition.other.agency_commission")}
                                            value={(system as AgencyCommissionFix).agencyCommissionAmount}
                                            onChange={(e) => handleChangeFounder(system.fieldId, "agencyCommissionAmount", Number(e.target.value))}
                                        />
                                    )}
                                    {system.typeOfAgencyCommission === "Range" &&
                                        (system as AgencyCommissionRange).ranges &&
                                        (system as AgencyCommissionRange).ranges.length > 0 &&
                                        (system as AgencyCommissionRange).ranges.map((item, index) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    display: "flex",
                                                    gap: "1rem",
                                                }}
                                            >
                                                <ValueInputField
                                                    readOnly={readOnly}
                                                    type="number"
                                                    Icon={
                                                        <PercentIcon
                                                            style={{
                                                                color: "grey",
                                                            }}
                                                        />
                                                    }
                                                    label={t("condition.other.agency_commission")}
                                                    value={item.agencyCommissionAmount}
                                                    onChange={(e) => handleChangeSystemRange(system.fieldId, item.fieldId, "agencyCommissionAmount", Number(e.target.value))}
                                                />
                                                <ValueInputField
                                                    readOnly={readOnly}
                                                    style={{ width: "148px" }}
                                                    type="number"
                                                    label={t("forms.common.from")}
                                                    value={item.from}
                                                    onChange={(e) => handleChangeSystemRange(system.fieldId, item.fieldId, "from", Number(e.target.value))}
                                                />
                                                <ValueInputField
                                                    readOnly={readOnly}
                                                    style={{ width: "148px" }}
                                                    type="number"
                                                    label={t("forms.common.to")}
                                                    value={item.to}
                                                    onChange={(e) => handleChangeSystemRange(system.fieldId, item.fieldId, "to", Number(e.target.value))}
                                                />
                                                {index === 0 ? (
                                                    <Box
                                                        onClick={() => !readOnly && handleAddRangeInSystem(system.fieldId)}
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <AddBadge />
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        onClick={() => !readOnly && handleRemoveRangeInSystem(system.fieldId, item.fieldId)}
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <RemoveBadge />
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}
                                </>
                            )}
                        </Box>
                    ))}
            </Box>
        </Box>
    );
};

export default ConditionForm;
