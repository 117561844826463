import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import { Header, Row, TableDataType } from "../../../../components/TableConstructor/types";
import { Dictionary } from "../../../ClientsPage";
import ContentContainer from "../../../../components/ContentContainer/ContentContainer";
import { getAllEntries } from "./services/fetchers";
import { TopBarContext } from "../../../../context/TopBarContext";
import { initialTableData, thickRow } from "../../../../components/TableConstructor/constants";
import CustomTable from "../../../../components/TableConstructor/components/CustomTable";
import TableFilterPanelStatistics from "../../../../components/TableConstructor/components/TableFilterPanelStatistics";
import Loading from "../../../../components/Loading";
import NothingFound from "../../../../components/NothingFound";

const CurrencyRatesPage = () => {
    const { handleChangePath, handleSetActions, handleChangeTabs } = useContext(TopBarContext);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [requests, setRequests] = useState<Dictionary<any>>({
        date: dayjs(),
        periods: {
            date_from: dayjs().startOf("month"),
            date_to: dayjs().add(-1, "day"),
        },
    });
    const [headers, setHeaders] = useState<Header[]>([]);
    const [tableData, setTableData] = useState<TableDataType>(initialTableData);

    const [rowDense, setRowDense] = useState(thickRow);
    const [rows, setRows] = useState<Row[]>([]);
    const [tableActions, setTableActions] = useState<any>({
        period: true,
        is_filter: true,
        grouping: false,
        metrics: false,
        is_column_selection: true,
        pick_row: false,
    });
    const [allCounts, setAllCounts] = useState(0);
    const [total, setTotal] = useState<Record<string, number>>({});
    const [table_name, setTableName] = useState<string>("-");

    useEffect(() => {
        handleChangePath(["partners", "currency_rates"]);
        handleSetActions([]);
        handleChangeTabs([]);
    }, []);

    const loadLegalEntities = async (signal: any = null) => {
        setLoading(true);
        const data = {
            filters: requests.filters || {},
            month: requests.date.get("month") + 1,
            year: requests.date.get("year"),
        };
        try {
            const res = await getAllEntries(data, signal);
            setTableData(res);
            setRows(res.rows);
            setHeaders(res.headers);
            setTableName(res.table_name);
            setTableActions(res.table_actions);
            setTotal(res.total);
            setAllCounts(res.all_counts);
        } catch (e) {
            setError("Error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        (async () => {
            await loadLegalEntities(controller.signal);
        })();
        return () => controller.abort();
    }, [requests]);

    return (
        <ContentContainer>
            <TableFilterPanelStatistics
                headers={headers}
                setHeaders={setHeaders}
                rowDense={rowDense}
                setRowDense={setRowDense}
                loading={loading}
                rows={tableData.rows || []}
                tableActions={tableActions}
                request={requests}
                setRequest={setRequests}
            />
            {headers.length > 0 && rows.length > 0 ? (
                <CustomTable
                    rowDense={rowDense}
                    table_name={table_name}
                    loading={loading}
                    allCounts={allCounts}
                    tableActions={tableActions}
                    originalHeaders={headers}
                    rows={rows}
                    setRows={setRows}
                    request={requests}
                    setRequest={setRequests}
                    total={total}
                />
            ) : (
                <NothingFound />
            )}
            {loading && !rows && (
                <Box
                    sx={{
                        height: "300px",
                        display: "flex",
                    }}
                >
                    <Loading />
                </Box>
            )}
        </ContentContainer>
    );
};

export default CurrencyRatesPage;
