import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import { useContext } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SaveIcon from "@mui/icons-material/Save";
import dayjs from "dayjs";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ClientCreateContext } from "../../../../context/ClientCreateContext";
import { ReactComponent as DraftIcon } from "../../../../assets/images/icons/draftIcon.svg";
import TopBarLayout from "../../../../components/TopBar";
import BASE_URL from "../../../../data/constants";
import { AuthContext } from "../../../../context/AuthContext";
import { backendContacts, backendContract, backendPaymentDetails, backendSystems } from "../../services/parsers";
import CustomButton from "../../../../UI/Buttons/CustomButton";
import TopBarBreadCrumbs from "../../../../components/TopBar/TopBarBreadCrumbs";

const TopBarCreate = () => {
    const { step, setStep, steps, client, clientAccount, paymentDetails, contactPeople, contract, systems, platforms } = useContext(ClientCreateContext);
    const { setNotification } = useContext(AuthContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleNextStep = () => {
        const nextStep = steps[steps.findIndex((item) => item.step === step) + 1].step;
        setStep((prevStep) => nextStep);
    };
    const handlePrevStep = () => {
        const nextStep = steps[steps.findIndex((item) => item.step === step) - 1].step;
        setStep((prevStep) => nextStep);
    };
    const handleCloseCreatePage = () => navigate("/clients");
    const saveDraft = () => {
        axios
            .post(`${BASE_URL}/clients/draft/create/`, {
                client,
                clientAccount,
                paymentDetails,
                contactPeople,
                contract,
                systems,
                platforms,
            })
            .then((res) => {
                setNotification({
                    open: true,
                    type: "success",
                    message: res.data.message,
                    duration: 3000,
                });
            })
            .catch((err) => console.error("Error", err.message));
    };
    const backendData = () => {
        const accountManagers = client.accountManagers.map((manager) => ({
            manager_type: "Account manager",
            username: manager.account,
            start_date: manager.startDate && dayjs(manager.startDate).format("YYYY-MM-DD"),
        }));
        const salesManager = client.salesManagers.map((manager) => ({
            manager_type: "Sales manager",
            username: manager.account,
            start_date: manager.startDate && dayjs(manager.startDate).format("YYYY-MM-DD"),
        }));
        const data = {
            name: client.companyName,
            organization_type: client.companyType,
            managers: [...accountManagers, ...salesManager],
            client_user_username: clientAccount.username,
            client_user_is_create: clientAccount.client_user_is_create,
            contacts: backendContacts(contactPeople),
            client_payment_details: backendPaymentDetails(paymentDetails),
            contract: backendContract(contract, paymentDetails.contractCurrency),
            conditions: backendSystems(systems),
            placements: platforms.map((platform) => platform.name),
        };
        return data;
    };

    const handleCreateClient = () => {
        const data = backendData();
        axios
            .post(`${BASE_URL}/clients/create/`, data)
            .then((res) => {
                handleCloseCreatePage();
                setNotification({
                    type: "success",
                    duration: 3000,
                    message: res.data.message,
                    open: true,
                });
            })
            .catch((err) => {
                setStep(err.response.data.step || 1);
                setNotification({
                    type: "error",
                    duration: 3000,
                    message: err.response.data.message,
                    open: true,
                });
            });
    };

    return (
        <TopBarLayout>
            <Box
                sx={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <TopBarBreadCrumbs items={[t("client_create.new_client")]} />
                <Box
                    sx={{
                        display: "flex",
                        gap: "1rem",
                    }}
                >
                    <CustomButton
                        mode="dark"
                        label="button.back"
                        onChange={() => {
                            if (step !== 1) handlePrevStep();
                        }}
                        leftIcon={<ArrowBackIosIcon />}
                        style={{
                            opacity: step === 1 ? "0.4" : "1",
                            cursor: step === 1 ? "not-allowed" : "pointer",
                        }}
                    />
                    {step === steps[steps.length - 1].step ? (
                        <CustomButton label="button.save" onChange={handleCreateClient} Icon={<SaveIcon style={{ color: "#fff" }} />} />
                    ) : (
                        <CustomButton mode="dark" label="button.next" onChange={handleNextStep} Icon={<ArrowForwardIosIcon style={{ color: "var(--main-black-color)" }} />} />
                    )}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        gap: "1rem",
                    }}
                >
                    <CustomButton Icon={<DraftIcon style={{ color: "var(--main-black-color)" }} />} onChange={saveDraft} label="button.draft" />
                    <CustomButton mode="dark" label="button.close" onChange={handleCloseCreatePage} Icon={<CloseIcon style={{ color: "var(--main-black-color)" }} />} />
                </Box>
            </Box>
        </TopBarLayout>
    );
};

export default TopBarCreate;
