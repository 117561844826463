import axios from "axios";
import BASE_URL from "../../../data/constants";

const getNewPlacements = async (data: any, signal: any) => {
    try {
        const res = await axios.post(`${BASE_URL}/properties/new_placements/`, data, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export default getNewPlacements;
