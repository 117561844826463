type DebounceFunction = <F extends (...args: any[]) => any>(
    func: F,
    delay: number
) => {
    (...args: Parameters<F>): void;
    flush(): void;
};

const debounce: DebounceFunction = (func, delay) => {
    // eslint-disable-next-line no-undef
    let timeoutId: NodeJS.Timeout | null;
    let isFlushing = false;
    let queuedArgs: Parameters<any>[] = [];

    const flush = () => {
        if (!isFlushing && queuedArgs.length > 0) {
            isFlushing = true;
            const argsToFlush = [...queuedArgs];
            queuedArgs = [];

            func(...argsToFlush);

            isFlushing = false;
        }
    };

    const debounced: any = (...args: any) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        queuedArgs.push(...args);

        timeoutId = setTimeout(() => {
            flush();
            timeoutId = null;
        }, delay);
    };

    debounced.flush = flush;

    return debounced;
};

export default debounce;
