import React from "react";
import { Box } from "@mui/material";
import ValueInputField from "../../../../../../UI/Inputs/ValueInputField";
import { handleChangeField } from "../../../../../ClientsPage/services/helpers";
import { ReactComponent as SystemIcon } from "../../../../../../assets/images/icons/Systems.svg";
import { SystemType } from "../../types";

type SystemsFormType = {
    system: SystemType;
    setSystem: (system: SystemType) => void;
};

const Form: React.FC<SystemsFormType> = ({ system, setSystem }) => (
    <Box
        sx={{
            p: "3rem",
            background: "#fff",
            maxWidth: "736px",
            gap: "1rem",
            display: "flex",
            flexWrap: "wrap",
        }}
    >
        <ValueInputField label="common.system" value={system.name} Icon={<SystemIcon />} onChange={(e) => handleChangeField("name", e.target.value, system, setSystem)} />
        <ValueInputField
            label="forms.partners.system.system_id"
            value={system.name_of_field}
            Icon={<SystemIcon />}
            onChange={(e) => handleChangeField("name_of_field", e.target.value, system, setSystem)}
        />
    </Box>
);

export default Form;
