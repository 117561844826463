import { Box } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { FC, useState } from "react";
import ValueInputField from "../../../../UI/Inputs/ValueInputField";
import CustomButton from "../../../../UI/Buttons/CustomButton";
import { ReactComponent as DraftIcon } from "../../../../assets/images/icons/draftIconBlack.svg";
import { ReactComponent as ExportIcon } from "../../../../assets/images/icons/common/export.svg";

type DraftReportsProps = {
    saveReportHandler: (name: string) => void;
    exportReportCSV: () => void;
    loading?: boolean;
};

const DraftReports: FC<DraftReportsProps> = ({ loading = false, exportReportCSV = () => {}, saveReportHandler = () => {} }) => {
    const [reportName, setReportName] = useState("");

    const handleClickSave = () => {
        if (reportName) {
            saveReportHandler(reportName);
            setReportName("");
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                gap: ".75rem",
                mb: ".75rem",
                pointerEvents: loading ? "none" : "auto",
                opacity: loading ? ".5" : "1",
            }}
        >
            <ValueInputField
                style={{
                    height: "42",
                    top: "-5",
                    width: "220px",
                    background: "#fff",
                }}
                Icon={<DraftIcon />}
                label="Report name"
                value={reportName}
                onChange={(e) => setReportName(e.target.value)}
            />
            <CustomButton Icon={<SaveIcon />} label="button.save" onChange={handleClickSave} />
            <CustomButton mode="dark" Icon={<ExportIcon />} label="button.export" onChange={exportReportCSV} />
        </Box>
    );
};

export default DraftReports;
