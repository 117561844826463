import axios from "axios";

export const getAuth = async (login: string, password: string) => {
    try {
        const res = await axios.post("/auth/", { username: login, password });
        return await res.data;
    } catch (err) {
        throw new Error("Incorrect login or password.");
    }
};

export const logout = async () => {
    try {
        await axios.post("/logout/");
    } catch (err) {
        throw new Error("Error while logout.");
    }
};

export const changePassword = async (data: { username: string; old_password: string; new_password: string }) => {
    try {
        const res = await axios.post("/users/change_password/", data);
        return await res.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
};
