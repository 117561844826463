import { Dayjs } from "dayjs";
import { PERMISSIONS } from "../data/permission-maps";
import {OptionObjectType} from "../services/common-types";

export const generateId = () => `id${Math.random().toString(16).slice(2)}`;

export const handleChangeInnerField = (key: string, fieldKey: string, value: string | number | boolean | Dayjs | null, setState: (state: any) => void) => {
    setState((prevValue: any) => ({
        ...prevValue,
        [key]: {
            ...(typeof prevValue[key] === "object" ? prevValue[key] : {}),
            [fieldKey]: value,
        },
    }));
};

export const isAllowedTo = (role: string, to: string) => PERMISSIONS[role].includes(to);

export const parseLabelKey = (item: string | OptionObjectType) => {
    let label;
    let key;
    if (typeof item === "object" && item !== null) {
        label = item.name;
        key = item.id;

        if (!label) {
            label = key;
        }
    } else {
        label = item;
        key = item;
    }

    return { label, key };
}