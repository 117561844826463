import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import ContentContainer from "../../../../components/ContentContainer/ContentContainer";
import { getNetworkCodes } from "../../services/fetchers";
import { TopBarContext } from "../../../../context/TopBarContext";
import TableConstructor from "../../../../components/TableConstructor";
import { TableDataType } from "../../../../components/TableConstructor/types";
import { initialTableData } from "../../../../components/TableConstructor/constants";

export interface Dictionary<T> {
    [key: string]: T;
}
export const NetworkCodes = () => {
    const { handleChangePath, handleSetActions, handleChangeTabs } = useContext(TopBarContext);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState<Dictionary<any>>({
        periods: {
            date_from: dayjs().startOf("month"),
            date_to: dayjs().add(-1, "day"),
            currency: "",
        },
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [tableData, setTableData] = useState<TableDataType>(initialTableData);

    useEffect(() => {
        handleChangePath(["network_codes"]);
        handleSetActions([]);
        handleChangeTabs([]);
    }, []);

    const loadNetworkCodes = async (signal: any = null) => {
        setLoading(true);
        const data = {
            ...request,
            filters: request.filters || {},
        };
        try {
            const res = await getNetworkCodes(data, signal);
            setTableData(res);
            if (res.all_counts > res.size) {
                setSearchParams({
                    ...searchParams,
                    page: res.page || 1,
                    size: res.size || 50,
                });
            }
            setLoading(false);
        } catch (e) {
            setError("Error");
            setLoading(false);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        (async () => {
            await loadNetworkCodes(controller.signal);
        })();
        return () => controller.abort();
    }, [request]);

    return (
        <ContentContainer>
            <TableConstructor tableData={tableData} loading={loading} request={request} setRequest={setRequest} />
        </ContentContainer>
    );
};

export default NetworkCodes;
