import { Box, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import React, { ReactElement, useContext } from "react";
import { TopBarContext } from "../../context/TopBarContext";
import { AuthContext } from "../../context/AuthContext";
import TopBarLayout from "./index";
import TopBarBreadCrumbs from "./TopBarBreadCrumbs";

const GlobalBar = () => {
    const { t } = useTranslation();
    const { tabs, path, actions, show } = useContext(TopBarContext);
    const { appState } = useContext(AuthContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const absolutePath = pathname.split("/").slice(0, -1).join("/");
    const appPath = appState.split(".");
    return (
        show && (
            <TopBarLayout>
                <TopBarBreadCrumbs items={path} />
                <Box
                    sx={{
                        display: "flex",
                    }}
                >
                    {tabs &&
                        tabs.map((tab: any, index: number) => (
                            <Box
                                key={index}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    p: "0 1rem",
                                    cursor: "pointer",
                                    fontWeight: "500",
                                    fontSize: "1rem",
                                    position: "relative",
                                    background: "inherit",
                                }}
                                onClick={() => navigate(`${absolutePath}/${tab.key}`)}
                            >
                                {t(tab.label)}
                                {appPath[appPath.length - 1] === tab.key && (
                                    <Divider
                                        sx={{
                                            background: "var(--main-purple-color)",
                                            width: "100%",
                                            height: "3px",
                                            bottom: "-19px",
                                            left: "0",
                                            position: "absolute",
                                        }}
                                    />
                                )}
                            </Box>
                        ))}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                    }}
                >
                    {actions && actions.map((component: ReactElement, index: number) => <div key={index}>{component}</div>)}
                </Box>
            </TopBarLayout>
        )
    );
};

export default GlobalBar;
