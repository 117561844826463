import {Box, Button, FormControl, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {Controller} from "react-hook-form";
import {FC} from "react";
import Input from "./Input";

const FormInputs: FC<any> = ({lang="ru", field={}, name, control, formValues = [], error = {}, readOnly=false, isNotView = true}) => {

    const {type, defaultValue, rules, order} = field;
    const title = field.title[lang || "ru"];
    const hint = field.hint[lang || "en"];
    let isBreak = false;
    if (Object.hasOwn(field, "dependencies")) {
        field.dependencies.forEach((dependency: any) => {
            if ((formValues as any)[dependency.fieldKey] !== undefined) {
                if (Object.hasOwn(dependency, "exclude") && dependency.exclude) {
                    if (dependency.value === (formValues as any)[dependency.fieldKey]) {
                        isBreak = true;
                    }
                } else if (dependency.value !== (formValues as any)[dependency.fieldKey]) {
                    isBreak = true;
                }
            }
        })
    }
    if (isBreak) {
        return null;
    }
    if (type === "array") {
        const {fieldsValues: arrFields, append, remove, requiredElementsCount} = field;
        return (
            <Box component="section" key={`${name}`} id={name} sx={{order, display: "flex", flexWrap: "wrap", width: "100%", gap: "1rem"}}>
                <Typography sx={{fontWeight: "500"}}>
                    {title}:
                </Typography>
                {arrFields.map((item: any, index: number) => (
                    <>
                        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                            <Typography sx={{fontWeight: "400", color: "#2196F3"}}>Element {index + 1}</Typography>
                            {isNotView &&
                                <IconButton disabled={index < requiredElementsCount} aria-label="delete" size="small" onClick={() => {
                                    console.log("remove", remove, index, field);
                                    remove(index)
                                }}>
                                    <DeleteIcon fontSize="small"/>
                                </IconButton>
                            }
                        </Box>
                        {Object.keys(item).map((key: string) => {
                            if (key === "id") return null;
                            const {rules, defaultValue, width} = field.fieldsSchema[key];
                            const title = field.fieldsSchema[key].title[lang || "ru"];
                            const hint = field.fieldsSchema[key].hint[lang || "ru"];
                            // const initialValue = field.defaultValue && field.defaultValue[index][key] || defaultValue;
                            const errors = error && error.length > 0 && Object.keys(error[0]).length > 0 ? error[0][key] : {};
                            return (
                                <>
                                    <Box key={`${name}.${index}.${key}`} sx={{
                                        display: "flex",
                                        alignItems: "self-end",
                                        flexDirection: "column",
                                        width: width || "100%"
                                    }}>
                                        <Controller
                                            key={item.id}
                                            name={`${name}.${index}.${key}` as any}
                                            control={control}
                                            rules={type !== "checkbox" ? rules : {}}
                                            // defaultValue={initialValue}
                                            render={({field: {onChange, value}}) =>
                                                <Input
                                                    readOnly={readOnly}
                                                    errors={errors}
                                                    label={title}
                                                    value={value}
                                                    onChange={onChange}
                                                    {...field.fieldsSchema[key]}
                                                    hint={hint}
                                                />
                                            }
                                        />
                                    </Box>
                                </>
                            )
                        })
                        }
                    </>
                ))}
                {!readOnly &&
                    <Button onClick={append} color="primary" size="large" variant="outlined" sx={{borderStyle: "dashed", width: "100%"}}>
                        Add element
                    </Button>
                }
            </Box>
        )
    }
    return (
        <section key={name} style={{width: field.width || "100%", height: "fit-content", order}}>
            <Controller
                name={name as any}
                control={control}
                rules={type !== "checkbox" ? rules : {}}
                defaultValue={defaultValue}
                render={({field: inputField}) =>
                    <Input
                        readOnly={readOnly}
                        errors={error}
                        label={title}
                        value={inputField.value}
                        onChange={inputField.onChange}
                        {...field}
                        hint={hint}
                    />
                }
            />
        </section>
    );
}

export default FormInputs;