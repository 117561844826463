import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../../../../context/AuthContext";
import { TopBarContext } from "../../../../../../context/TopBarContext";

const LegalEntityLayout = () => {
    const { appState } = useContext(AuthContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { handleChangeTabs } = useContext(TopBarContext);
    useEffect(() => {
        handleChangeTabs([
            {
                key: "edit",
                label: "le",
            },
            {
                key: "banks",
                label: "banks",
            },
        ]);
    }, []);

    useEffect(() => {
        if (pathname.split("/").length === 4) {
            navigate(`/properties/llp/${id}/edit`, { replace: true });
        }
    }, [appState]);

    return <Outlet />;
};

export default LegalEntityLayout;
