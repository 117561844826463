import { useTranslation } from "react-i18next";
import React, { ChangeEvent, Suspense, useContext, useEffect, useMemo, useState } from "react";
import { Box, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";
import axios from "axios";
import "../../../../assets/styles/custom-table.scss";
import { AuthContext } from "../../../../context/AuthContext";
import { Header, Row, TableProps } from "../../types";
import { defaultCellStyle, pinnedColumn, sticky, thickRow } from "../../constants";
import BASE_URL from "../../../../data/constants";
import debounce from "../../../../utils/debounce";
import { Dictionary } from "../../../../pages/ClientsPage";
import TablePagination from "../TablePagination";
import Loading from "../../../Loading";
import { deleteClient } from "../../../../pages/ClientsPage/services/fetchers";
import RenderRow from "./RenderRow";
import RenderColumns from "./RenderColumns";
import { SortDirections } from "./type";

const UploadFileModal = React.lazy(() => import("../../../UploadFileModal"));

const CustomTable = React.memo(
    ({
        rowDense = thickRow,
        table_name = "",
        declineRowElement = () => {},
        confirmRowElement = () => {},
        loading = true,
        allCounts = 0,
        tableActions,
        request,
        setRequest,
        originalHeaders,
        rows,
        selectedRows = [],
        setSelectedRows = (value: number[]) => {},
        total = {},
    }: TableProps) => {
        const { t } = useTranslation();
        const { setNotification } = useContext(AuthContext);
        const [headers, setHeaders] = useState<Header[]>(originalHeaders);
        const [sortedRows, setSortedRows] = useState<Row[]>(rows);
        const [sortColumn, setSortColumn] = useState<string | null>(null);
        const [sortDirection, setSortDirection] = useState<SortDirections>("desc");
        const navigate = useNavigate();
        const { pathname } = useLocation();
        const params = useParams();
        const [searchParams, setSearchParams] = useSearchParams();
        const [isUpdateHeaders, setIsUpdateHeaders] = useState(false);
        const [uploadFile, setUploadFile] = useState<{
            openModal: boolean;
            url: string;
        }>({
            openModal: false,
            url: "",
        });

        const submitUpdatedHeaders = async (updatedHeaders: Header[]) => {
            try {
                const url = `${BASE_URL}/users/change_table_settings/`;
                await axios.post(url, {
                    headers: updatedHeaders,
                    table_name,
                });
            } catch (e: any) {
                const message = e.response ? e.response.data.message : "";
                if (message) {
                    setNotification({
                        open: true,
                        type: "warning",
                        message,
                    });
                }
            } finally {
                setIsUpdateHeaders(false);
            }
        };

        useEffect(() => {
            if (isUpdateHeaders && table_name && table_name !== "-") {
                submitUpdatedHeaders(headers);
            }
        }, [isUpdateHeaders, table_name]);

        const debouncedSubmitHeaders = useMemo(
            () =>
                debounce((updatedHeaders: Header[] = []) => {
                    setIsUpdateHeaders(true);
                }, 500),
            []
        );

        useEffect(() => {
            if (table_name && headers.length > 0) {
                debouncedSubmitHeaders(headers);
            }
        }, [headers, debouncedSubmitHeaders]);

        const handleResize = (header: any, width: any) => {
            if (width >= 120) {
                const updatedHeaders = headers.map((item) => {
                    if (header.key === item.key) {
                        return {
                            ...item,
                            width,
                        };
                    }
                    return item;
                });

                setHeaders(updatedHeaders);
            }
        };

        const pinClickHandler = async (header: Header) => {
            const updatedHeaders = headers.map((item) => {
                if (header.key === item.key) {
                    return {
                        ...item,
                        pin: !header.pin,
                    };
                }
                return item;
            });
            setHeaders(updatedHeaders);
        };

        const visibleHeaders = headers.filter((filter) => filter.visible);

        const handleDragEnd = async (result: any) => {
            if (!result.destination) {
                return;
            }

            const updatedHeaders = Array.from(headers);
            const [reorderedHeader] = updatedHeaders.splice(result.source.index, 1);
            updatedHeaders.splice(result.destination.index, 0, reorderedHeader);
            setHeaders(updatedHeaders);
        };

        const handleSort = (column: string, direction: SortDirections) => {
            setRequest((prev: Dictionary<string>) => {
                if (direction) {
                    return {
                        ...prev,
                        sort: direction === "asc" ? column : `-${column}`,
                    };
                }
                return {
                    ...prev,
                    sort: "",
                };
            });
            if (column) {
                setSortColumn(column);
                setSortDirection(direction);
            } else {
                setSortColumn(null);
                setSortDirection(direction);
            }
        };

        useEffect(() => {
            if (originalHeaders.length > 0) {
                setHeaders(originalHeaders);
            }
        }, [originalHeaders]);

        useEffect(() => {
            setSortedRows(rows);
        }, [rows]);

        const downloadFileAction = async (url: string) => {
            try {
                const response = await axios.post(url, {});
                const urlT = `data:application/pdf;base64,${response.data}`;
                const link = document.createElement("a");
                link.href = urlT;
                const filename = response.headers["content-disposition"].split("filename=")[1];
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                setTimeout(() => {
                    link.click();
                    document.body.removeChild(link);
                }, 500);
            } catch (error: any) {
                console.log(error.message);
            }
        };
        
        // const downloadFileAction = async (url: string) => {
        //     try {
        //         const response = await axios.post(url, { responseType: "arraybuffer" });
        //         const blob = new Blob([response.data], { type: "application/pdf" });
        //         const urlT = URL.createObjectURL(blob);
        //         const link = document.createElement("a");
        //         link.href = urlT;
        //         const contentDisposition = response.headers["content-disposition"];
        //         const filename = contentDisposition ? contentDisposition.split("filename=")[1] : "file.pdf";
        //         link.setAttribute("download", filename);
        //         document.body.appendChild(link);
        //         setTimeout(() => {
        //             link.click();
        //             document.body.removeChild(link);
        //             URL.revokeObjectURL(urlT); // Clean up the URL object
        //         }, 500);
        //     } catch (error: any) {
        //         console.log(error.message);
        //     }
        // };

        const putIntoSavingsHandler = async (invoiceId: number) => {
            try {
                await axios.post(`clients/${params.id || 0}/invoices/${invoiceId}/savings/`);
                window.location.reload();
            } catch (error: any) {
                console.log(error.message);
            }
        };

        const handleAction = async (action: string, row: Row | string, event: React.MouseEvent | ChangeEvent) => {
            event.stopPropagation();
            if (action === "delete") {
                deleteRow((row as Row).id);
            }
            if (action === "edit") {
                navigate(`${pathname}/${(row as Row).id}/edit`);
            }
            if (action === "download") {
                await downloadFileAction(row as string);
            }
            if (action === "upload") {
                setUploadFile({
                    openModal: true,
                    url: row as string,
                });
            }
            if (action === "confirm") {
                confirmRowElement((row as Row).id);
            }
            if (action === "decline") {
                declineRowElement((row as Row).id);
            }
            if (action === "select") {
                handleRowSelection((row as Row).id);
            }
            if (action === "view") {
                navigate(`${pathname}/${(row as Row).id}`);
            }
            if (action === "savings") {
                await putIntoSavingsHandler((row as Row).id);
            }
        };

        const handleSelectAllRows = () => {
            if (selectedRows.length === 0) {
                setSelectedRows(sortedRows.map((row) => row.id));
            } else {
                setSelectedRows([]);
            }
        };

        const handleRowSelection = (rowId: number) => {
            if (selectedRows.includes(rowId)) {
                setSelectedRows(selectedRows.filter((id) => id !== rowId));
            } else {
                setSelectedRows([...selectedRows, rowId]);
            }
        };

        const handleViewClientDetails = (row: Row, e: any) => {
            navigate(`${pathname}/${row.id}`);
            e.stopPropagation();
        };

        const deleteRow = (rowId: number) => {
            const url = `${pathname}/${rowId}`;
            deleteClient(url)
                .then((res) => {
                    const newRows = sortedRows.filter((row) => row.id !== rowId);
                    setSortedRows(newRows);
                    setNotification({
                        open: true,
                        message: res.message,
                    });
                })
                .catch((message) => {
                    setNotification({
                        open: true,
                        message,
                        type: "error",
                    });
                });
        };

        const colPinLeft = 0;
        const renderHeaders = useMemo(
            () => (
                <RenderColumns
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    handleSort={handleSort}
                    tableActions={tableActions}
                    selectedRows={selectedRows}
                    visibleHeaders={headers}
                    handleResize={handleResize}
                    pinClickHandler={pinClickHandler}
                    colPinLeft={colPinLeft}
                    handleSelectAllRows={handleSelectAllRows}
                />
            ),
            [headers, sortDirection, tableActions, sortColumn, selectedRows]
        );

        const renderRows = useMemo(() => {
            const tempRow: { value: number } = { value: 0 };
            const page = Number(searchParams.get("page"));
            let size: number | string | null = searchParams.get("size")
            let rowNumber = 0;
            if (size !== "all") {
                size = Number(size);
                rowNumber = (page - 1) * size;
            }

            return sortedRows.map((row, row_index) => {
                rowNumber += 1;
                const rowPinLeft = 0;
                return (
                    <RenderRow
                        table_name={table_name}
                        sortedRows={sortedRows}
                        row={row}
                        row_index={row_index}
                        tableActions={tableActions}
                        tempRow={tempRow}
                        rowNumber={rowNumber}
                        selectedRows={selectedRows}
                        visibleHeaders={visibleHeaders}
                        handleAction={handleAction}
                        rowPinLeft={rowPinLeft}
                        handleViewClientDetails={handleViewClientDetails}
                        rowDense={rowDense}
                    />
                );
            });
        }, [sortedRows, headers, rowDense, selectedRows]);

        return (
            <Box sx={{ position: "relative" }}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <TableContainer
                        sx={{
                            opacity: loading ? ".5" : "1",
                            pointerEvents: loading ? "none" : "auto",
                            position: "relative",
                        }}
                    >
                        <Table
                            sx={{
                                borderCollapse: "separate",
                                borderSpacing: rowDense === thickRow ? "0 4px" : "0 2px",
                            }}
                        >
                            <TableHead
                                sx={{
                                    position: "sticky",
                                    top: "0",
                                    zIndex: 12,
                                    borderBottom: "2px solid rgba(0, 0, 0, 0.20)",
                                    mb: ".75rem",
                                    "&::after": {
                                        content: "'-'",
                                        display: "block",
                                        lineHeight: "8px",
                                        textIndent: "-99999px",
                                    },
                                }}
                            >
                                {renderHeaders}
                            </TableHead>
                            <TableBody>
                                {Object.keys(total).length > 0 && rows.length > 0 && (
                                    <TableRow
                                        sx={{
                                            position: "relative",
                                            cursor: tableActions.view ? "pointer" : "",
                                            backgroundColor: "#fff",
                                            ":hover": {
                                                backgroundColor: "var(--main-purple-hover-color)",
                                            },
                                        }}
                                    >
                                        <TableCell
                                            rowSpan={1}
                                            sx={{
                                                ...defaultCellStyle,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    p: ".75rem",
                                                    borderRight: "1px solid var(--main-divider-color)",
                                                    height: "24px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: "#3A3F48",
                                                        fontSize: "1rem",
                                                        fontWeight: "500",
                                                    }}
                                                />
                                            </Box>
                                        </TableCell>
                                        {visibleHeaders.map((header, index) => (
                                            <TableCell
                                                key={index}
                                                sx={{
                                                    ...defaultCellStyle,
                                                    height: rowDense,
                                                    maxHeight: rowDense,
                                                    width: Number(header.width),
                                                    minWidth: 120,
                                                    ...(header.pin
                                                        ? // TODO: Pin left var
                                                        {
                                                            ...pinnedColumn,
                                                        }
                                                        : {}),
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: Number(header.width),
                                                        minWidth: 120,
                                                        p: "0 .75rem",
                                                        minHeight: 24,
                                                    }}
                                                >
                                                    {index === 0 && (
                                                        <Typography
                                                            sx={{
                                                                fontWeight: "600",
                                                            }}
                                                        >
                                                            Total
                                                        </Typography>
                                                    )}
                                                    <Typography
                                                        sx={{
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        {total[header.key] !== undefined && total[header.key] !== null
                                                            ? total[header.key].toLocaleString("ru-RU", {
                                                                maximumFractionDigits: 3,
                                                            })
                                                            : ""}
                                                    </Typography>
                                                </Box>
                                                <Divider
                                                    sx={{
                                                        position: "absolute",
                                                        top: "25%",
                                                        right: "0",
                                                        height: "24px",
                                                        margin: "auto 0",
                                                    }}
                                                    orientation="vertical"
                                                />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                )}
                                {renderRows.length > 0 && renderRows}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DragDropContext>
                {!loading && sortedRows.length === 0 && (
                    <Box
                        sx={{
                            display: "flex",
                            p: "1rem 0",
                            width: "100%",
                            align: "center",
                            justifyContent: "center",
                            background: "#fff",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "var(--main-light-text-color)",
                            }}
                        >
                            {t("no_data")}.
                        </Typography>
                    </Box>
                )}
                {allCounts > 10 && sortedRows.length > 0 && <TablePagination allCounts={allCounts} request={request} setRequest={setRequest} />}
                {loading && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: "20%",
                            left: "40%",
                            width: "300px",
                            height: "300px",
                            zIndex: "123",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Loading />
                    </Box>
                )}
                {uploadFile.openModal && (
                    <Suspense fallback={<Loading />}>
                        <UploadFileModal fileProps={uploadFile} setUploadFile={setUploadFile} />
                    </Suspense>
                )}
            </Box>
        );
    }
);

export default CustomTable;
