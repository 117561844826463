import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import BankForm from "../../components/BanksForm";
import { AuthContext } from "../../../../../../../../../../context/AuthContext";
import ContentContainer from "../../../../../../../../../../components/ContentContainer/ContentContainer";
import { TopBarContext } from "../../../../../../../../../../context/TopBarContext";
import CreateButton from "../../../../../../../../../../UI/Buttons/Actions/CreateButton";
import CloseButton from "../../../../../../../../../../UI/Buttons/Actions/CloseButton";

export type BankType = {
    name: string;
    is_active: boolean;
};

export const initialBank = {
    name: "",
    is_active: false,
};

const BanksCreate = () => {
    const [bank, setBank] = useState<BankType>(initialBank);
    const { handleAppendPath, handleSetActions } = useContext(TopBarContext);
    useEffect(() => {
        handleAppendPath("new_bank");
        handleSetActions([<CreateButton action={handleSubmitBonus} />, <CloseButton path={`/properties/llp/${id}/edit`} />]);
    }, [bank]);

    const { setNotification } = useContext(AuthContext);
    const { id } = useParams();

    const handleSubmitBonus = async () => {
        try {
            const res = await axios.post(`/properties/llp/${id}/banks/create/`, bank);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.message,
                type: "error",
            });
        }
    };

    return (
        <ContentContainer>
            <BankForm bank={bank} setBank={setBank} />
        </ContentContainer>
    );
};

export default BanksCreate;
