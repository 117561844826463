import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../../../../../context/AuthContext";
import { TopBarContext } from "../../../../../../context/TopBarContext";

const SupplierLayoutPage = () => {
    const { appState } = useContext(AuthContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { handleChangeTabs } = useContext(TopBarContext);
    useEffect(() => {
        handleChangeTabs([
            {
                key: "supplier",
                label: "supplier",
            },
            {
                key: "bonus",
                label: "bonus",
            },
        ]);
    }, []);

    useEffect(() => {
        if (pathname.split("/").length === 4) {
            navigate(`/properties/suppliers/${id}/supplier`, { replace: true });
        }
    }, [appState]);

    return <Outlet />;
};

export default SupplierLayoutPage;
