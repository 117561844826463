import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import ContentContainer from "../../../../../components/ContentContainer/ContentContainer";
import { getValue } from "../../../services/helpers";
import CardDetails from "../../components/CardDetails";
import { CardType } from "../../types";
import { getContract } from "../../../services/fetchers";

const PartnerAgreementsPage = () => {
    const { t } = useTranslation();
    const [agreement, setAgreement] = useState<CardType[]>([]);
    const { id } = useParams();

    const agreementParser = (data: any) => [
        {
            key: "ID",
            value: data.id,
        },
        {
            key: "tds_company",
            value: getValue(data.llp),
        },
        {
            key: "link_to_agreement",
            value: getValue(data.contract_link),
            type: "url",
        },
        {
            key: "vat_payer",
            value: getValue(data.is_vat_payer),
        },
        {
            key: "start_date",
            value: getValue(data.contract_date),
        },
        {
            key: "contract_currency",
            value: getValue(data.contract_currency),
        },
        {
            key: "transfer_fee_currency",
            value: getValue(data.fee_currency),
        },
        {
            key: "type_of_transfer_fee",
            value: getValue(data.fee_type),
        },
        {
            key: "size_of_the_transfer_fee",
            value: getValue(data.fee_value),
        },
    ];

    useEffect(() => {
        if (id) {
            (async () => {
                setAgreement(agreementParser(await getContract(id, {}, null)));
            })();
        }
    }, []);

    return (
        <ContentContainer>
            <Box
                sx={{
                    display: "flex",
                    gap: ".75rem",
                    flexWrap: "wrap",
                }}
            >
                {agreement.length > 0 && <CardDetails cols={1} title={`${t("agreement")} №${agreement[0].value}`} items={agreement.slice(1)} />}
            </Box>
        </ContentContainer>
    );
};

export default PartnerAgreementsPage;
