import { Box } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";

const RemoveBadge = () => (
    <Box
        sx={{
            width: "2rem",
            height: "2rem",
            borderRadius: "50%",
            backgroundColor: "var(--main-purple-hover-color)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            cursor: "pointer",
        }}
    >
        <RemoveIcon style={{ color: "var(--main-black-color)" }} />
    </Box>
);

export default RemoveBadge;
