import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import routes from "../routes";
import MainLayout from "../layouts/MainLayout";
import { Loading } from "../components/Loading";
import LoginPage from "../pages/LoginPage";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import TopBarProvider from "../context/TopBarContext";
import PrivateRoute from "../routes/PrivateRoute";
import NotFound from "../components/Errors/NotFound";

const theme = createTheme({
    typography: {
        allVariants: {
            color: "var(--main-black-color)",
        },
    },
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: "#3A3F48",
        },
        secondary: {
            // This is green.A700 as hex.
            main: "#11cb5f",
        },
        warning: {
            main: "#538DFE",
        },
    },
});

export const App = () => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <TopBarProvider>
            <BrowserRouter>
                <Suspense fallback={<Loading />}>
                    <Routes>
                        <Route path="/login" element={<LoginPage />} />
                        <Route
                            path="/"
                            element={
                                <PrivateRoute>
                                    <MainLayout />
                                </PrivateRoute>
                            }
                        >
                            {routes}
                        </Route>
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </TopBarProvider>
    </ThemeProvider>
);

export default App;
