import React from "react";
import { Box } from "@mui/material";
import ValueInputField from "../../../../../../UI/Inputs/ValueInputField";
import { handleChangeField } from "../../../../../ClientsPage/services/helpers";
import { ReactComponent as MoneyIcon } from "../../../../../../assets/images/icons/common/moneyIcon.svg";
import { CurrencyType } from "../../types";

type CurrencyFormType = {
    currency: CurrencyType;
    setCurrency: (llp: CurrencyType) => void;
};

const Form: React.FC<CurrencyFormType> = ({ currency, setCurrency }) => (
    <Box
        sx={{
            p: "3rem",
            background: "#fff",
            maxWidth: "736px",
            gap: "1rem",
            display: "flex",
            flexWrap: "wrap",
        }}
    >
        <ValueInputField label="forms.partners.currency.name" value={currency.name} Icon={<MoneyIcon />} onChange={(e) => handleChangeField("name", e.target.value, currency, setCurrency)} />
    </Box>
);

export default Form;
