import axios from "axios";

export const getAllEntries = async (data = {}, signal: any = null) => {
    try {
        const res = await axios.post("/properties/transfer_methods/", data, {
            signal,
        });
        return await res.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
};

export const createEntry = async (data = {}) => axios.post("/properties/transfer_methods/create/", data);

export const getEntry = async (id: string | undefined) => {
    try {
        const res = await axios.get(`properties/transfer_methods/${id}/`);
        return await res.data;
    } catch (err: any) {
        console.error("Error");
        throw err;
    }
};

export const editEntry = async (id: string | undefined, data = {}) => axios.post(`properties/transfer_methods/${id}/edit/`, data);
