import React, { useContext, useEffect, useState } from "react";
import axios, { AxiosError, isAxiosError } from "axios";
import { useParams } from "react-router-dom";
import BonusForm from "../../components/BonusForm";
import { AuthContext } from "../../../../../../../../context/AuthContext";
import ContentContainer from "../../../../../../../../components/ContentContainer/ContentContainer";
import { getBonus } from "../../../../../../services/fetchers";
import { TopBarContext } from "../../../../../../../../context/TopBarContext";
import CloseButton from "../../../../../../../../UI/Buttons/Actions/CloseButton";
import EditButton from "../../../../../../../../UI/Buttons/Actions/EditButton";

export type BonusType = {
    bonus_gp: number;
    bonus_month: number;
    bonus_from_new: number;
    new_client_month_count: number;
};

export const initialBonus = {
    bonus_gp: 0,
    bonus_month: 0,
    bonus_from_new: 0,
    new_client_month_count: 0,
};

const ManagerBonusEdit = () => {
    const { setNotification, setNotFound } = useContext(AuthContext);
    const [bonus, setBonus] = useState<BonusType>(initialBonus);
    const { id, bonusId } = useParams();

    const { handleSetActions, handleAppendPath } = useContext(TopBarContext);
    useEffect(() => {
        handleSetActions([<EditButton action={handleSubmitBonus} />, <CloseButton path={`/users/managers/${id}/bonus`} />]);
    }, [bonus]);

    useEffect(() => {
        if (id && bonusId) {
            (async () => {
                try {
                    setBonus(await getBonus(id, bonusId));
                } catch (err: Error | AxiosError | unknown) {
                    if (isAxiosError(err)) {
                        if (err.response?.status === 404) {
                            setNotFound(true);
                        }
                    }
                }
            })();
        }
        handleAppendPath("bonus");
    }, [id, bonusId]);

    const handleSubmitBonus = async () => {
        try {
            const res = await axios.post(`/users/managers/${id}/bonus_table/${bonusId}/edit/`, bonus);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.message,
                type: "error",
            });
        }
    };

    return (
        <ContentContainer>
            <BonusForm bonus={bonus} setBonus={setBonus} />
        </ContentContainer>
    );
};

export default ManagerBonusEdit;
