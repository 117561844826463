import { Box, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import CachedIcon from "@mui/icons-material/Cached";
import Logo from "../../assets/images/img/logo_TDS.png";
import CustomButton from "../../UI/Buttons/CustomButton";

const NotFound = () => {
    const { pathname } = window.location;
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: ".25rem",
                backgroundColor: "var(--background-color)",
            }}
        >
            <Box>
                <img
                    src={Logo}
                    alt="logo"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    gap: ".75rem",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "6rem",
                        fontWeight: "600",
                        color: "var(--main-purple-color)",
                    }}
                >
                    404
                </Typography>
                <Typography
                    sx={{
                        fontSize: "1.125rem",
                        maxWidth: "300px",
                    }}
                >
                    <Trans>
                        {t("error.not_found", {
                            pathname,
                        })}
                    </Trans>
                </Typography>
            </Box>
            <CustomButton mode="dark" label="button.home" leftIcon={<CachedIcon />} onChange={() => window.location.replace("/")} />
        </Box>
    );
};

export default NotFound;
