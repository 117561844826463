import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import { TableDataType } from "../../../../components/TableConstructor/types";
import { Dictionary } from "../../../ClientsPage";
import ContentContainer from "../../../../components/ContentContainer/ContentContainer";
import CustomButton from "../../../../UI/Buttons/CustomButton";
import { getAllEntries } from "./services/fetchers";
import { TopBarContext } from "../../../../context/TopBarContext";
import { initialTableData } from "../../../../components/TableConstructor/constants";
import TableConstructor from "../../../../components/TableConstructor";

const TransferMethodsPage = () => {
    const { handleChangePath, handleSetActions, handleChangeTabs } = useContext(TopBarContext);
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState<Dictionary<any>>({
        periods: {
            date_from: dayjs().startOf("month"),
            date_to: dayjs().add(-1, "day"),
            currency: "",
        },
    });
    const [tableData, setTableData] = useState<TableDataType>(initialTableData);

    useEffect(() => {
        handleChangePath(["partners", "transfer_methods"]);
        handleSetActions([]);
        handleChangeTabs([]);
    }, []);

    const loadLegalEntities = async (signal: any = null) => {
        setLoading(true);
        const data = {
            ...request,
            filters: request.filters || {},
        };
        try {
            const res = await getAllEntries(data, signal);
            setTableData(res);
        } catch (e) {
            setError("Error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        (async () => {
            await loadLegalEntities(controller.signal);
        })();
        return () => controller.abort();
    }, [request]);

    const handleCreate = () => navigate("/properties/tm/create");

    return (
        <ContentContainer>
            <TableConstructor
                tableData={tableData}
                loading={loading}
                request={request}
                setRequest={setRequest}
                ActionButton={
                    <CustomButton
                        Icon={
                            <AddIcon
                                style={{
                                    color: "#fff",
                                    width: "1.25rem",
                                    height: "1.25rem",
                                    marginRight: ".5rem",
                                }}
                            />
                        }
                        label="button.add"
                        onChange={handleCreate}
                    />
                }
            />
        </ContentContainer>
    );
};

export default TransferMethodsPage;
