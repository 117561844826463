import { Box, CSSObject, Fade, IconButton, List, styled, Theme, Toolbar, Tooltip, Typography } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import appRoutes from "../../routes/appRoutes";
import SideBarItem from "./SideBarItem";
import SideBarItemCollapse from "./SideBarItemCollapse";
import { ReactComponent as LogoutIcon } from "../../assets/images/icons/sidebar/logout.svg";
import { ReactComponent as ProfileIcon } from "../../assets/images/icons/sidebar/profile.svg";
import { ReactComponent as LogoIcon } from "../../assets/images/icons/sidebar/logo_TDS.svg";
import { AuthContext } from "../../context/AuthContext";
import Localization from "../Localization";
import {logout} from "../../pages/LoginPage/services/fetchers";

const drawerWidth = 225;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "56px",
    [theme.breakpoints.up("sm")]: {
        width: "56px",
    },
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

type SideBarProps = {
    toggle: boolean;
    setToggle: (value: boolean) => void;
};

const SideBar = ({ toggle = false, setToggle }: SideBarProps) => {
    const { me, setIsLoggedIn, setMe } = useContext(AuthContext);
    const navigate = useNavigate();

    const logoutHandler = async () => {
        try {
            await logout();
            setMe({});
            setIsLoggedIn(false);
        } catch (e) {
            console.error("Try logout later!");
        }
    };

    return (
        <Drawer
            variant="permanent"
            transitionDuration={1000}
            sx={{
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    overflow: "hidden",
                    boxSizing: "border-box",
                    borderRight: "0",
                    backgroundColor: "#fff",
                    justifyContent: "space-between",
                    boxShadow: "4px 0px 12px 0px rgba(0, 0, 0, 0.08)",
                },
            }}
            open={toggle}
        >
            <List disablePadding>
                <Toolbar
                    sx={{
                        minHeight: { xs: "3.75rem" },
                        backgroundColor: "#fff",
                        gap: "1rem",
                        padding: ".625rem 1rem",
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {toggle ? (
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    flex: 1,
                                }}
                            >
                                <Fade in={toggle} timeout={500}>
                                    <LogoIcon
                                        onClick={() => navigate("/")}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    />
                                </Fade>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", gap: ".25rem" }}>
                                <Localization />
                                <MenuIcon onClick={() => setToggle(!toggle)} style={{ color: "#000", cursor: "pointer" }} />
                            </Box>
                        </>
                    ) : (
                        <MenuIcon onClick={() => setToggle(!toggle)} style={{ color: "#000", cursor: "pointer" }} />
                    )}
                </Toolbar>
                <List disablePadding sx={{ maxHeight: "83vh", overflowY: "scroll" }} className="scroll_invisible">
                    {appRoutes.map((route, index) =>
                        route?.permissions?.includes(me.role) && route.sidebarProps ? (
                            route.child && route.child.length > 0 ? (
                                <SideBarItemCollapse toggle={toggle} item={route} key={index} />
                            ) : (
                                <SideBarItem toggle={toggle} item={route} key={index} />
                            )
                        ) : null
                    )}
                </List>
                {toggle && (
                    <Typography
                        sx={{
                            textAlign: "center",
                            fontSize: ".75rem",
                            fontWeight: "400",
                            mb: "1rem",
                            color: "rgba(0, 0, 0, 0.20)",
                            p: "1.25rem 0",
                        }}
                    >
                        &copy; {me.copyright}
                    </Typography>
                )}
            </List>
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        p: ".75rem 1.125rem",
                        height: "3.125rem",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1.125rem",
                        }}
                    >
                        <ProfileIcon />
                        {toggle && (
                            <Tooltip title={me.username}>
                                <Typography
                                    sx={{
                                        color: "var(--main-black-color)",
                                        fontSize: "1rem",
                                        fontWeight: "500",
                                        overflow: "hidden",
                                        maxWidth: "71px",
                                    }}
                                >
                                    {me.username || ""}
                                </Typography>
                            </Tooltip>
                        )}
                    </Box>
                    {toggle && (
                        <IconButton
                            sx={{
                                borderRadius: "0",
                                padding: ".5rem",
                            }}
                            onClick={logoutHandler}
                        >
                            <LogoutIcon />
                        </IconButton>
                    )}
                </Box>
            </Box>
        </Drawer>
    );
};

export default SideBar;
