import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import {AuthContext} from "../../context/AuthContext";

const GoogleLayout = () => {
    const { appState } = useContext(AuthContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (pathname.split("/").length === 2) {
            navigate("/google/network_codes", { replace: true });
        }
    }, [appState]);

    return (
        <Outlet />
    )
}

export default GoogleLayout;
