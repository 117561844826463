import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const NothingFound = () => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                mt: ".75rem",
                alignItems: "center",
                p: ".75rem 0",
                flexDirection: "column",
                gap: ".75rem",
            }}
        >
            <Typography
                sx={{
                    color: "var(--main-purple-color)",
                    fontSize: "1.5rem",
                    fontWeight: "500",
                }}
            >
                {t("nothing_found")}!
            </Typography>
            <Typography sx={{ color: "var(--main-grey-color)" }}>{t("nothing_found_description")}.</Typography>
        </Box>
    );
};

export default NothingFound;
