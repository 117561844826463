import { useTranslation } from "react-i18next";
import { Box, Skeleton, Tooltip, Typography } from "@mui/material";
import { FC } from "react";

type TopBarBreadCrumbType = {
    items: string[];
};

const TopBarBreadCrumbs: FC<TopBarBreadCrumbType> = ({ items = [] }) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
            }}
        >
            {items.length > 0 &&
                items.map((item, index) => (
                    <>
                        {item ? (
                            <Tooltip key={index} title={item}>
                                <Typography
                                    sx={{
                                        fontSize: "1.25rem",
                                        color: "var(--main-black-color)",
                                        fontWeight: "500",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        wordBreak: "break-word",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {t(item)}
                                </Typography>
                            </Tooltip>
                        ) : (
                            <Skeleton width={126} height={30} />
                        )}
                        {index !== items.length - 1 && (
                            <Box
                                sx={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: "var(--main-purple-color)",
                                        width: ".5rem",
                                        height: ".5rem",
                                        borderRadius: "50%",
                                    }}
                                />
                            </Box>
                        )}
                    </>
                ))}
        </Box>
    );
};

export default TopBarBreadCrumbs;
