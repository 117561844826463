import { Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientCreateContext } from "../../../../../../context/ClientCreateContext";
import { getPlatforms } from "../../../../services/fetchers";
import PlatformForm from "../../../../components/ClientCreateForm/PlatformForm";

const PlatformsCreateController = () => {
    const { platforms, setPlatforms } = useContext(ClientCreateContext);
    const [platformList, setPlatformList] = useState([]);
    const { t } = useTranslation();
    useEffect(() => {
        (async () => setPlatformList(await getPlatforms()))();
    }, []);

    return (
        <>
            <Typography
                sx={{
                    fontSize: "1.25rem",
                    fontWeight: "500",
                    mb: "3rem",
                }}
            >
                {t("client_create.platforms")}
            </Typography>
            <PlatformForm platforms={platforms} setPlatforms={setPlatforms} platformList={platformList} />
        </>
    );
};

export default PlatformsCreateController;
