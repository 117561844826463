import React, {useContext, useEffect, useMemo, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import axios from "axios";
import {useSearchParams} from "react-router-dom";
import {Box} from "@mui/material";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import CustomButton from "../../UI/Buttons/CustomButton";
import BASE_URL from "../../data/constants";
import {generateId} from "../../utils/helpers";
import {Header, Row} from "../../components/TableConstructor/types";
import LineGraphRecharts from "./components/LineGraphRecharts";
import DraftReports from "./components/DraftReports";
import DraftReportsModal from "./components/DraftReportsModal";
import {AuthContext} from "../../context/AuthContext";
import {TopBarContext} from "../../context/TopBarContext";
import DraftButton from "../../UI/Buttons/Actions/DraftButton";
import TableFilterPanelStatistics from "../../components/TableConstructor/components/TableFilterPanelStatistics";
import Loading from "../../components/Loading";
import {thickRow} from "../../components/TableConstructor/constants";
import {ReactComponent as SaveIconWhite} from "../../assets/images/icons/saveIconWhite.svg";
import {ReactComponent as UpdateIconWhite} from "../../assets/images/icons/common/updateIcon.svg";
import CustomTable from "../../components/TableConstructor/components/CustomTable";
import NothingFound from "../../components/NothingFound";

export interface IPeriod {
    date_from: Dayjs | null;
    date_to: Dayjs | null;
    currency: string;
}

export interface IFilter {
    fieldId: string;
    key: string;
    option: string;
    value: string;
}
export interface IMetric {
    key: string;
    name: string;
    is_enabled: boolean;
    hint?: string;
}
export type IRequests = Record<string, any>;

const initialRequests = {
    periods: {
        date_from: dayjs().subtract(7, "day"),
        date_to: dayjs().add(-1, "day"),
        currency: "",
    },
    filters: [
        {
            fieldId: generateId(),
            key: "",
            option: "",
            value: "",
        },
    ],
    metrics: [],
    groups: [],
};
export interface IAggregation {
    label: string;
    keys: IMetric[];
}
export interface Previous {
    periods: IPeriod;
    metrics: IMetric[];
    groups: IMetric[];
    filters: IFilter[];
}

const CreateIconTsx = () => (
    <SaveIconWhite
        style={{
            color: "#fff",
            width: "1.25rem",
            height: "1.25rem",
            marginRight: ".5rem",
        }}
    />
);
const UpdateIconTsx = () => (
    <UpdateIconWhite
        style={{
            color: "#fff",
            width: "1.25rem",
            height: "1.25rem",
            marginRight: ".5rem",
        }}
    />
);
const ReportsPage = () => {
    const { handleSetActions, handleChangePath, handleChangeTabs } = useContext(TopBarContext);
    const { setNotification } = useContext(AuthContext);
    const [requests, setRequests] = useState<IRequests>(initialRequests);
    const [headers, setHeaders] = useState<Header[]>([]);
    const [rows, setRows] = useState<Row[] | null>(null);
    const [groupingLabel, setGroupingLabel] = useState<IMetric>({} as IMetric);
    const [tableActions, setTableActions] = useState<any>({
        period: true,
        is_report_filter: true,
        grouping: true,
        metrics: true,
    });
    const [loading, setLoading] = useState(false);
    const [allCounts, setAllCounts] = useState(0);
    const [isOpenDraftModal, setIsOpenDraftModal] = useState<boolean>(false);
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [table_name, setTableName] = useState<string>("-");
    const [rowDense, setRowDense] = useState(thickRow);
    const [total, setTotal] = useState<Record<string, number>>({});

    const [buttonProps, setButtonProps] = useState<any>({
        props: [
            {
                svg: <CreateIconTsx />,
                label: "button.create",
            },
            {
                svg: <UpdateIconTsx />,
                label: "button.update",
            },
        ],
        state: 0,
    });

    useEffect(() => {
        const labelItems = ["statistics"];
        handleChangePath(labelItems);
        handleSetActions([<DraftButton action={openOpenDraftModal} />]);
        handleChangeTabs([]);
    }, []);

    useEffect(() => {
        const requestsJson = searchParams.get("requests");
        if (requestsJson) {
            const decodedRequests = JSON.parse(requestsJson);
            setRequests(decodedRequests);
        }
    }, []);

    const openOpenDraftModal = () => {
        setIsOpenDraftModal(true);
    };

    useEffect(() => {
        setSelectedColumns(headers.map((column) => column.label));
    }, [headers]);

    const mandatoryOptions = ["date__month", "date", "date__year", "week", "period"];
    const handleUpdate = async (signal: any = null) => {
        setLoading(true);
        const data = {
            ...requests,
            periods: {
                ...requests.periods,
                date_from: dayjs(requests.periods.date_from).format("YYYY-MM-DD"),
                date_to: dayjs(requests.periods.date_to).format("YYYY-MM-DD"),
            },
        };
        try {
            const res = await axios.post(`${BASE_URL}/report/create/`, data);
            const table = await res.data;
            setHeaders(table.headers);
            setRows(table.rows);
            setGroupingLabel(table.group);
            setAllCounts(table.all_counts);
            setTotal(table.total);
            const size = table.size || 50;
            const page = table.page || 1;
            setSearchParams({
                ...searchParams,
                page,
                size,
                requests: JSON.stringify(requests),
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        } finally {
            setLoading(false);
            setButtonProps((prev: any) => ({ ...prev, state: 1 }));
        }
    };

    const saveReportHandler = async (name: string) => {
        try {
            const data = {
                name,
                template: {
                    periods: requests.periods,
                    filters: requests.filters,
                    metrics: requests.metrics,
                    groups: requests.groups,
                },
            };
            const url = "report/templates/create/";
            const res = await axios.post(url, data);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        }
    };

    const exportReportCSV = async () => {
        try {
            const data = {
                ...requests,
                periods: {
                    ...requests.periods,
                    date_from: dayjs(requests.periods.date_from).format("YYYY-MM-DD"),
                    date_to: dayjs(requests.periods.date_to).format("YYYY-MM-DD"),
                },
            };
            const url = "report/save/";
            const response = await axios.post(url, data, {
                responseType: "blob",
            });
            const reader = new FileReader();
            reader.onload = () => {
                const filename = response.headers["content-disposition"].split("filename=")[1];
                const link = window.URL.createObjectURL(
                    new Blob([JSON.parse(reader.result as string)], {
                        type: "application/json",
                    })
                );
                const a = document.createElement("a");
                a.href = link;
                a.download = filename || "test.json";
                a.click();
                URL.revokeObjectURL(link);
            };
            reader.readAsText(response.data);
        } catch (e: any) {
            console.error(e.response.data.message);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        let { sort = "" } = searchParams.has("requests") && JSON.parse(searchParams.get("requests") as string);
        sort = requests.sort || sort;
        if (requests.page || requests.size || sort) {
            (async () => handleUpdate(controller.signal))();
        }
        return () => controller.abort();
    }, [requests.sort, requests.page, requests.size]);

    const handleCloseDraftModal = () => {
        setIsOpenDraftModal(false);
    };

    const newHeaders = useMemo(() => headers.filter((header) => selectedColumns.includes(header.label)), [selectedColumns, headers]);

    return (
        <>
            <ContentContainer>
                <TableFilterPanelStatistics
                    rowDense={rowDense}
                    setRowDense={setRowDense}
                    loading={loading}
                    rows={rows || []}
                    tableActions={tableActions}
                    request={requests}
                    setRequest={setRequests}
                    ActionButton={
                        <CustomButton
                            mode={requests.groups.map((metric: IMetric) => metric.key).some((key: string) => mandatoryOptions.includes(key)) ? "warning" : "dark"}
                            Icon={buttonProps.props[buttonProps.state].svg}
                            label={buttonProps.props[buttonProps.state].label}
                            onChange={handleUpdate}
                            disabled={!requests.groups.map((metric: IMetric) => metric.key).some((key: string) => mandatoryOptions.includes(key))}
                        />
                    }
                    isStatistics
                />
                {rows &&
                    (headers.length > 0 && rows.length > 0 ? (
                        <>
                            <DraftReports loading={loading} saveReportHandler={saveReportHandler} exportReportCSV={exportReportCSV} />
                            <LineGraphRecharts loading={loading} requests={requests} rows={rows} headers={headers} groupingLabel={groupingLabel} />
                            <CustomTable
                                rowDense={rowDense}
                                table_name={table_name}
                                loading={loading}
                                allCounts={allCounts}
                                tableActions={tableActions}
                                originalHeaders={newHeaders}
                                rows={rows}
                                setRows={setRows}
                                request={requests}
                                setRequest={setRequests}
                                total={total}
                            />
                        </>
                    ) : (
                        <NothingFound />
                    ))}
                {loading && !rows && (
                    <Box
                        sx={{
                            height: "300px",
                            display: "flex",
                        }}
                    >
                        <Loading />
                    </Box>
                )}
            </ContentContainer>
            <DraftReportsModal setRequests={setRequests} open={isOpenDraftModal} handleClose={handleCloseDraftModal} />
        </>
    );
};

export default ReportsPage;
