import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import axios, { AxiosError, isAxiosError } from "axios";
import { useTranslation } from "react-i18next";
import ContentContainer from "../../../../../../../../components/ContentContainer/ContentContainer";
import { initialClientInfo, initialContract, initialPaymentDetails } from "../../../../../../../../context/ClientCreateContext";
import ContractForm from "../../../../../../components/ClientCreateForm/ContractForm";
import { getClientInfo, getCurrencies } from "../../../../../../services/fetchers";
import { backendContract, frontendContract } from "../../../../../../services/parsers";
import BASE_URL from "../../../../../../../../data/constants";
import { AuthContext } from "../../../../../../../../context/AuthContext";
import { ClientInfo } from "../../../../../../types";
import EditButton from "../../../../../../../../UI/Buttons/Actions/EditButton";
import CreateButton from "../../../../../../../../UI/Buttons/Actions/CreateButton";
import CloseButton from "../../../../../../../../UI/Buttons/Actions/CloseButton";
import { TopBarContext } from "../../../../../../../../context/TopBarContext";

const AgreementCreateOrEdit = () => {
    const { handleSetActions, handleChangePath, handleChangeTabs } = useContext(TopBarContext);
    const { setNotification, setNotFound } = useContext(AuthContext);
    const [contract, setContract] = useState(initialContract);
    const [paymentDetails, setPaymentDetails] = useState(initialPaymentDetails);
    const [currencies, setCurrencies] = useState<string[]>([]);
    const { id, contractId } = useParams();
    const navigate = useNavigate();
    const [clientInfo, setClientInfo] = useState<ClientInfo>(initialClientInfo);
    const mode = contractId ? "edit" : "create";
    const { t } = useTranslation();

    useEffect(() => {
        const labelItems = [clientInfo.name, mode === "edit" ? t("agreement") : t("new_agreement")];
        handleChangePath(labelItems);
        handleSetActions([mode === "edit" ? <EditButton action={handleEditContract} /> : <CreateButton action={handleCreateContract} />, <CloseButton path={`/clients/${id}/agreements`} />]);
        handleChangeTabs([]);
    }, [clientInfo.name, contract]);

    useEffect(() => {
        (async () => {
            const data = await getClientInfo(id);
            setClientInfo(data);
        })();
    }, [id]);

    const getContract = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/${id}/contracts/${contractId}/`);
            return await res.data;
        } catch (err: Error | AxiosError | unknown) {
            if (isAxiosError(err)) {
                if (err.response?.status === 404) {
                    setNotFound(true);
                }
            }
            return err;
        }
    };

    useEffect(() => {
        if (contractId) {
            (async () => {
                const data = await getContract();
                setContract(frontendContract(data));
                setPaymentDetails({
                    ...paymentDetails,
                    contractCurrency: data.contract_currency,
                });
            })();
        }
    }, [contractId]);

    useEffect(() => {
        (async () => setCurrencies(await getCurrencies()))();
    }, []);

    const handleGoBack = () => navigate(`/clients/${id}/agreements`);

    const handleCreateContract = async () => {
        const data = backendContract(contract, paymentDetails.contractCurrency);
        try {
            const res = await axios.post(`${BASE_URL}/clients/${id}/contracts/create/`, data);
            handleGoBack();
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        }
    };
    const handleEditContract = async () => {
        const data = backendContract(contract, paymentDetails.contractCurrency);
        try {
            const res = await axios.post(`${BASE_URL}/clients/${id}/contracts/${contractId}/edit/`, data);
            handleGoBack();
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        }
    };
    return (
        <ContentContainer>
            <Box
                sx={{
                    p: "3rem",
                    background: "#fff",
                    maxWidth: "740px",
                }}
            >
                <ContractForm contract={contract} setContract={setContract} currencies={currencies} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} />
            </Box>
        </ContentContainer>
    );
};

export default AgreementCreateOrEdit;
