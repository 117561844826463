import axios from "axios";

const getDashboard = async (data = {}, signal: any = null) => {
    try {
        const res = await axios.post("/report/dashboard/", data, { signal });
        return await res.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
};

export default getDashboard;
