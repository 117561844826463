import React, { ReactElement, useContext } from "react";
import { PERMISSIONS, scopesType } from "../../data/permission-maps";
import { AuthContext } from "../../context/AuthContext";

type Props = {
    to: scopesType;
    children: ReactElement;
};

const Restricted: React.FunctionComponent<Props> = ({ to, children }) => {
    const { me } = useContext(AuthContext);
    const isAllowedTo = PERMISSIONS[me.role].includes(to);
    if (isAllowedTo) {
        return children;
    }
    return null;
};

export default Restricted;
