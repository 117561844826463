import * as React from "react";
import { useContext } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useTranslation } from "react-i18next";
import { ClientCreateContext } from "../../context/ClientCreateContext";

const VerticalLinearStepper = () => {
    const { step, steps } = useContext(ClientCreateContext);
    const { t } = useTranslation();
    return (
        <Box sx={{ maxWidth: 312 }}>
            <Stepper
                activeStep={step - 1}
                orientation="vertical"
                sx={{
                    color: "green",
                    ".MuiStepConnector-line": {
                        minHeight: "52px",
                    },
                }}
            >
                {steps.map((item) => (
                    <Step key={item.label}>
                        <StepLabel
                            StepIconProps={{
                                sx: {
                                    "&.Mui-active": {
                                        color: "var(--main-purple-color)",
                                    },
                                    "&.Mui-completed": {
                                        color: "var(--main-purple-color)",
                                    },
                                },
                            }}
                        >
                            {t(item.label)}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default VerticalLinearStepper;
