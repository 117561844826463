import { useTranslation } from "react-i18next";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../UI/Buttons/CustomButton";
import { ReactComponent as SendIcon } from "../../assets/images/icons/common/telegramIconWhite.svg";
import FeedbackForm from "./FeedbackForm";
import useFeedbackContainerController from "./useFeedbackContainerController";

const FeedbackContainer = () => {
    const { t } = useTranslation();
    const { open, handleClose, handleOpen } = useFeedbackContainerController();
    return (
        <Box
            sx={{
                boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
                borderRadius: ".5rem",
                position: "fixed",
                bottom: "0",
                transform: "translateY(calc(100% - 42px))",
                right: "16px",
                width: 300,
                backgroundColor: "#FFF",
                zIndex: 124,
                transition: "0.3s transform ease, 0.3s opacity ease-out",
                ...(open ? { transform: "translateY(0)" } : {}),
            }}
        >
            <CustomButton
                label="feedback_form"
                onChange={handleOpen}
                Icon={<SendIcon width={14} height={14} />}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: 300,
                    textTransform: "none",
                    borderRadius: "8px 8px 0 0",
                    fontSize: "14px",
                    opacity: 1,
                    pointerEvents: "initial",
                    ...(open ? { opacity: 0, pointerEvents: "none" } : {}),
                }}
            />

            <Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        p: "8px 12px",
                        opacity: 0,
                        pointerEvents: "none",
                        ...(open ? { opacity: 1, pointerEvents: "initial" } : {}),
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "34px",
                        }}
                    >
                        {t("feedback_form")}
                    </Typography>
                    <IconButton
                        sx={{
                            borderRadius: "0",
                            padding: ".5rem",
                            color: "var(--main-black-color)",
                            position: "absolute",
                            right: 16,
                        }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <FeedbackForm handleClose={handleClose} />
            </Box>
        </Box>
    );
};
export default FeedbackContainer;
