import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import axios, { AxiosError, isAxiosError } from "axios";
import { Dayjs } from "dayjs";
import ContentContainer from "../../../../../../../../components/ContentContainer/ContentContainer";
import { initialClient, initialPaymentDetails } from "../../../../../../../../context/ClientCreateContext";
import { getClientInfo, getCurrencies } from "../../../../../../services/fetchers";
import BASE_URL from "../../../../../../../../data/constants";
import { initialSwiftData } from "../../../../../../../../data/initialData";
import { frontendPaymentDetails } from "../../../../../../services/parsers";
import DatePickerCustom from "../../../../../../../../UI/Inputs/DatePicker";
import { ClientType, PaymentDetailsType } from "../../../../../../types";
import PaymentDetailsForm from "../../../../../../components/ClientCreateForm/PaymentDetailsForm";
import { TopBarContext } from "../../../../../../../../context/TopBarContext";
import CloseButton from "../../../../../../../../UI/Buttons/Actions/CloseButton";
import { AuthContext } from "../../../../../../../../context/AuthContext";

const PaymentDetailsViewPage = () => {
    const { setNotFound } = useContext(AuthContext);
    const { handleSetActions, handleChangePath, handleChangeTabs } = useContext(TopBarContext);
    const { id, paymentId } = useParams();
    const [paymentDetails, setPaymentDetails] = useState<PaymentDetailsType>({
        ...initialPaymentDetails,
        ...initialSwiftData,
    });
    const [startDate, setStartDate] = useState<Dayjs | null | "">(null);
    const [countries, setCountries] = useState([] as string[]);
    const [currencies, setCurrencies] = useState([] as string[]);
    const [client, setClient] = useState<ClientType>(initialClient);

    useEffect(() => {
        const labelItems = [client.companyName, "client_create.payment"];
        handleChangePath(labelItems);
        handleSetActions([<CloseButton path={`/clients/${id}/payment_details`} />]);
        handleChangeTabs([]);
    }, [client.companyName]);

    const getPaymentDetails = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/${id}/payment_details/${paymentId}/`);
            return await res.data;
        } catch (err: Error | AxiosError | unknown) {
            if (isAxiosError(err)) {
                if (err.response?.status === 404) {
                    setNotFound(true);
                }
            }
            return err;
        }
    };

    useEffect(() => {
        if (paymentId) {
            (async () => {
                const res = await getPaymentDetails();
                const data = frontendPaymentDetails(res);
                setPaymentDetails(data);
                setStartDate(data.startDate || null);
            })();
        }
    }, [paymentId]);

    useEffect(() => {
        (async () => setCurrencies(await getCurrencies()))();
    }, []);

    useEffect(() => {
        (async () => {
            const data = await getClientInfo(id);
            setClient({
                ...client,
                companyType: data.organization_type,
                companyName: data.name,
            });
        })();
    }, [id]);

    return (
        <ContentContainer>
            <Box
                sx={{
                    p: "3rem",
                    background: "#fff",
                    maxWidth: "740px",
                }}
            >
                <DatePickerCustom readOnly style={{ width: "100%", marginBottom: "1rem" }} label="forms.common.start_date" value={startDate} onChange={(newValue) => setStartDate(newValue)} />
                <PaymentDetailsForm
                    readOnly
                    client={client}
                    paymentDetails={paymentDetails}
                    setPaymentDetails={setPaymentDetails}
                    currencies={currencies}
                    countries={countries}
                    setCountries={setCountries}
                />
            </Box>
        </ContentContainer>
    );
};

export default PaymentDetailsViewPage;
