import { useParams } from "react-router-dom";
import { Box, FilledInput, FormControl, InputAdornment, InputLabel, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CachedIcon from "@mui/icons-material/Cached";
import { useTranslation } from "react-i18next";
import ContentContainer from "../../../../../../components/ContentContainer/ContentContainer";
import BASE_URL from "../../../../../../data/constants";
import ClintForm from "../../../../components/ClientCreateForm";
import { ClientType, ManagerSalesType } from "../../../../types";
import { AuthContext } from "../../../../../../context/AuthContext";
import { generateId, isAllowedTo } from "../../../../../../utils/helpers";
import CustomButton from "../../../../../../UI/Buttons/CustomButton";
import { ReactComponent as EditIcon } from "../../../../../../assets/images/icons/common/edit-icon-white.svg";
import { ReactComponent as DownloadIcon } from "../../../../../../assets/images/icons/common/download.svg";
import { ReactComponent as PayoutIcon } from "../../../../../../assets/images/icons/common/payout.svg";
import Restricted from "../../../../../../components/Restricted";
import ClientAccesses from "../../../../components/ClientAccesses";
import { handleChangeField } from "../../../../services/helpers";
import DatePickerCustom from "../../../../../../UI/Inputs/DatePicker";

const ClientEditPage = () => {
    const { setNotification } = useContext(AuthContext);
    const [client, setClient] = useState<ClientType>({
        companyName: "",
        companyType: "",
        accountManagers: [],
        salesManagers: [],
        client_tabs: {},
        savings: "",
        our_deduction: "",
        savings_start_date: dayjs(),
    });
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [readOnly, setReadOnly] = useState(false);
    const { me } = useContext(AuthContext);
    const { t } = useTranslation();

    useEffect(() => {
        setReadOnly(!isAllowedTo(me.role, "canEdit"));
    }, [me.role]);

    const getClient = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/${id}/`);
            return await res.data;
        } catch (err) {
            console.error(err);
            return err;
        }
    };

    useEffect(() => {
        (async () => {
            const data = await getClient();
            setClient({
                companyName: data.name,
                companyType: data.organization_type,
                accountManagers:
                    data.managers.account_managers.length > 0
                        ? data.managers.account_managers.map((item: any) => ({
                            fieldId: item.id,
                            id: item.id,
                            account: item.user__username,
                            startDate: dayjs(item.start_date, "YYYY-MM-DD"),
                        }))
                        : [
                            {
                                fieldId: generateId(),
                                id: null,
                                account: "",
                                startDate: null,
                            },
                        ],
                salesManagers:
                    data.managers.sales_managers.length > 0
                        ? data.managers.sales_managers.map((item: any) => ({
                            fieldId: item.id,
                            id: item.id,
                            account: item.user__username,
                            startDate: dayjs(item.start_date, "YYYY-MM-DD"),
                        }))
                        : [
                            {
                                fieldId: generateId(),
                                id: null,
                                account: "",
                                startDate: null,
                            },
                        ],
                client_tabs: data.client_tabs,
                savings: data.savings,
                our_deduction: data.our_deduction,
                savings_start_date: data.savings_start_date,
            });
            setLoading(false);
        })();
    }, []);

    const handleSubmitClient = async () => {
        const accountManagers = client.accountManagers.map((item: ManagerSalesType) => ({
            id: item.id,
            manager_type: "Account manager",
            username: item.account,
            start_date: dayjs(item.startDate).format("YYYY-MM-DD"),
        }));
        const salesManagers = client.salesManagers.map((item: ManagerSalesType) => ({
            id: item.id,
            manager_type: "Sales manager",
            username: item.account,
            start_date: dayjs(item.startDate).format("YYYY-MM-DD"),
        }));
        const data = {
            name: client.companyName,
            organization_type: client.companyType,
            managers: [...accountManagers, ...salesManagers],
            client_tabs: client.client_tabs,
            savings: client.savings,
            our_deduction: client.our_deduction,
            savings_start_date: client.savings_start_date ? dayjs(client.savings_start_date).format("YYYY-MM-DD") : null,
        };
        try {
            const res = await axios.post(`${BASE_URL}/clients/${id}/edit/`, data);
            if (res.status === 200) {
                setNotification({
                    message: res.data.message,
                    duration: 3000,
                    type: "success",
                    open: true,
                });
            } else {
                throw new Error(res.data.message);
            }
        } catch (err: any) {
            setNotification({
                message: err.response.data.message,
                duration: 3000,
                type: "error",
                open: true,
            });
        }
    };

    const handleUpdateClient = async (path: string) => {
        try {
            const url = `clients/${id}/${path}/`;
            const res = await axios.get(url);
            setNotification({
                message: res.data.message,
                type: "success",
                open: true,
            });
        } catch (err: any) {
            setNotification({
                message: err.response.data.message || "",
                type: "error",
                open: true,
            });
        }
    };
    return (
        <ContentContainer>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{
                        p: "3rem",
                        background: "#fff",
                        maxWidth: "788px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <ClintForm readOnly={readOnly} client={client} setClient={setClient} mode="edit" />
                    <Typography sx={{ fontWeight: "500", mb: "1rem" }}>{t("forms.client_account.savings")}</Typography>
                    <Box sx={{ display: "flex", gap: "1rem", mb: "1rem", flexWrap: "wrap" }}>
                        <FormControl sx={{ width: "312px" }} variant="filled">
                            <InputLabel htmlFor="filled-adornment-weight">{t("forms.client_account.savings")}</InputLabel>
                            <FilledInput
                                color="warning"
                                sx={{
                                    borderRadius: "0",
                                    background: "var(--main-purple-hover-color)",
                                    "&.Mui-focused": {
                                        background: "var(--main-purple-hover-color)",
                                    },
                                    ":hover": {
                                        background: "var(--main-purple-hover-color)",
                                    },
                                }}
                                id="filled-adornment-weight"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <PayoutIcon />
                                    </InputAdornment>
                                }
                                aria-describedby="filled-weight-helper-text"
                                inputProps={{
                                    "aria-label": t("forms.client_account.savings"),
                                }}
                                type="number"
                                value={client?.savings?.toString()}
                                onChange={(e) => handleChangeField("savings", Number(e.target.value), client, setClient)}
                            />
                        </FormControl>
                        <DatePickerCustom
                            style={{
                                background: "var(--main-purple-hover-color)",
                            }}
                            label={t("forms.client_account.savings_start")}
                            value={client.savings_start_date || null}
                            onChange={(newValue) => handleChangeField("savings_start_date", newValue, client, setClient)}
                        />
                        <FormControl sx={{ width: "100%", maxWidth: "640px" }} variant="filled">
                            <InputLabel htmlFor="filled-adornment-weight">{t("forms.client_account.deduction")}</InputLabel>
                            <FilledInput
                                color="warning"
                                sx={{
                                    borderRadius: "0",
                                    background: "var(--main-purple-hover-color)",
                                    "&.Mui-focused": {
                                        background: "var(--main-purple-hover-color)",
                                    },
                                    ":hover": {
                                        background: "var(--main-purple-hover-color)",
                                    },
                                }}
                                id="filled-adornment-weight"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <PayoutIcon />
                                    </InputAdornment>
                                }
                                aria-describedby="filled-weight-helper-text"
                                inputProps={{
                                    "aria-label": t("forms.client_account.savings"),
                                }}
                                type="number"
                                value={client?.our_deduction?.toString()}
                                onChange={(e) => handleChangeField("our_deduction", Number(e.target.value), client, setClient)}
                            />
                        </FormControl>
                    </Box>
                    <Typography sx={{ fontWeight: "500", mb: "1rem" }}>{t("forms.client_account.client_data")}</Typography>
                    <Box sx={{ display: "flex", gap: "1rem", mb: "1rem" }}>
                        <CustomButton
                            leftIcon={<DownloadIcon />}
                            Icon={
                                <Tooltip title="если какой нибудь блок не выгрузился, то нажимаете pull out, заново вытянет данные по API и пересчитает их">
                                    <HelpOutlineIcon style={{ color: "#fff" }} />
                                </Tooltip>
                            }
                            label="button.pull_out"
                            onChange={() => handleUpdateClient("api_calc")}
                        />
                        <CustomButton
                            style={{
                                background: "#fff",
                                border: "1px solid var(--main-purple-color)",
                                color: "var(--main-purple-color)",
                                ":hover": {
                                    background: "#fff",
                                    border: "1px solid var(--main-purple-color)",
                                    color: "var(--main-purple-color)",
                                },
                            }}
                            leftIcon={<CachedIcon style={{ color: "var(--main-purple-color)" }} />}
                            Icon={
                                <Tooltip
                                    title={`если поменяли процент комиссии/ добавили бонус/ комиссия за перевод/ платежные данные, 
                                ТО нажимаете update, автоматический обновит подсчеты текущий и прошлый месяц`}
                                >
                                    <HelpOutlineIcon style={{ color: "var(--main-purple-color)" }} />
                                </Tooltip>
                            }
                            label="button.update"
                            onChange={() => handleUpdateClient("calc")}
                        />
                    </Box>
                    <Typography sx={{ fontWeight: "500", mb: "1rem", textTransform: "uppercase" }}>{t("invoice")}</Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "1rem", mb: "1rem" }}>
                        <CustomButton
                            style={{
                                background: "linear-gradient(90deg, #FFBF96 0%, #FE7096 100%)",
                                color: "#fff",
                                ":hover": {
                                    background: "linear-gradient(90deg, #FFBF96 0%, #FE7096 100%)",
                                    color: "#fff",
                                },
                            }}
                            Icon={<DownloadIcon />}
                            label="button.create_invoice"
                            onChange={() => handleUpdateClient("invoices/create")}
                        />
                        <CustomButton
                            style={{
                                background: "linear-gradient(90deg, #FFBF96 0%, #FE7096 100%)",
                                color: "#fff",
                                ":hover": {
                                    background: "linear-gradient(90deg, #FFBF96 0%, #FE7096 100%)",
                                    color: "#fff",
                                },
                            }}
                            Icon={<DownloadIcon />}
                            label="button.create_invoice_without_limit"
                            onChange={() => handleUpdateClient("invoices/wihout_limit")}
                        />
                        <CustomButton
                            style={{
                                background: "#fff",
                                color: "#FE7096",
                                border: "1px solid #FE7096",
                                ":hover": {
                                    background: "#fff",
                                    color: "#FE7096",
                                    border: "1px solid #FE7096",
                                },
                            }}
                            Icon={<CachedIcon style={{ color: "#FE7096" }} />}
                            label="button.update_invoice"
                            onChange={() => handleUpdateClient("invoices/recreate")}
                        />
                    </Box>
                    <ClientAccesses client={client} setClient={setClient} />
                </Box>
                <Restricted to="canEdit">
                    <CustomButton Icon={<EditIcon />} label="button.edit" onChange={handleSubmitClient} />
                </Restricted>
            </Box>
        </ContentContainer>
    );
};

export default ClientEditPage;
