import React, { FC, ReactNode } from "react";
import { Box, Modal as MuiModal } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "312px",
    borderTop: "3px solid var(--main-purple-color)",
    boxShadow: 24,
    p: "1.5rem",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "1.5rem",
    outline: "none",
};

type ModalProps = {
    open: boolean;
    handleClose: () => void;
    children: ReactNode;
};

const Modal: FC<ModalProps> = ({ open = false, handleClose = () => {}, children }) => (
    <MuiModal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>{children}</Box>
    </MuiModal>
);

export default Modal;
