import React, { useState } from "react";
import { Checkbox, Menu, MenuItem } from "@mui/material";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import { Header } from "../types";
import FilterPanelButton from "./FilterPanelButton";

interface ColumnSelectionProps {
    headers: Header[];
    setHeaders?: (headers: Header[]) => void;
}

const ColumnSelection: React.FC<ColumnSelectionProps> = ({ setHeaders = () => {}, headers }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleColumnToggleFromMenu = (column: Header) => {
        const updatedHeaders = headers.map((item) => {
            if (column.key === item.key) {
                return {
                    ...item,
                    visible: !column.visible,
                };
            }
            return item;
        });
        if (typeof setHeaders === "function") {
            setHeaders(updatedHeaders);
        }
    };

    return (
        <div>
            <FilterPanelButton Icon={<CalendarViewWeekIcon />} active={Boolean(anchorEl)} onClick={handleMenuOpen} text="columns" />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                    mt: ".125rem",
                    "& .MuiPaper-root": {
                        borderRadius: "0",
                    },
                    "& .MuiList-root": {
                        p: ".25rem 0",
                    },
                }}
            >
                {headers.map((header) => (
                    <MenuItem
                        key={header.key}
                        sx={{
                            p: "0.25rem .75rem",
                            color: "var(--main-black-color)",
                            fontWeight: "500",
                        }}
                    >
                        <Checkbox
                            sx={{
                                borderRadius: "0",
                                p: ".25rem",
                                "&.Mui-checked": {
                                    color: "var(--main-black-color)",
                                },
                            }}
                            checked={header.visible}
                            onChange={() => handleColumnToggleFromMenu(header)}
                        />
                        {header.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default ColumnSelection;
