import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ValueInputField from "../../../../../../../UI/Inputs/ValueInputField";
import { handleChangeInnerField } from "../../../../../services/helpers";
import SelectInputField from "../../../../../../../UI/Inputs/SelectInputField";
import { ReactComponent as ProfileIcon } from "../../../../../../../assets/images/icons/sidebar/profile.svg";
import { ReactComponent as LinkIcon } from "../../../../../../../assets/images/icons/client/linkIcon.svg";

const DirectorFields = ({ paymentDetails, setPaymentDetails, countries, readOnly }: any) => {
    const { director } = paymentDetails;
    const { t } = useTranslation();
    return (
        <>
            <Typography
                sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    mb: "1rem",
                }}
            >
                {t("forms.payment.director_label")}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    gap: "1rem",
                    mb: "1rem",
                    flexWrap: "wrap",
                    maxWidth: "740px",
                }}
            >
                <ValueInputField
                    readOnly={readOnly}
                    Icon={<ProfileIcon />}
                    label={t("forms.common.full_name")}
                    value={director?.fullName}
                    onChange={(e) => handleChangeInnerField("director", "fullName", e.target.value, setPaymentDetails)}
                />
                <SelectInputField
                    readOnly={readOnly}
                    value={director?.citizenship}
                    label={t("forms.payment.citizenship")}
                    items={countries}
                    onChange={(value) => handleChangeInnerField("director", "citizenship", value, setPaymentDetails)}
                />
                <ValueInputField
                    readOnly={readOnly}
                    Icon={<LinkIcon />}
                    label={t("forms.payment.passport_link")}
                    value={director?.passportScanUrl}
                    onChange={(e) => handleChangeInnerField("director", "passportScanUrl", e.target.value, setPaymentDetails)}
                />
            </Box>
        </>
    );
};

export default DirectorFields;
