import React, {useContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import dayjs from "dayjs";
import {TopBarContext} from "../../../../context/TopBarContext";
import {Dictionary} from "../../../ClientsPage";
import {TableDataType} from "../../../../components/TableConstructor/types";
import {initialTableData} from "../../../../components/TableConstructor/constants";
import ContentContainer from "../../../../components/ContentContainer/ContentContainer";
import TableConstructor from "../../../../components/TableConstructor";
import { getMoneyBySystems } from "../../services/fetchers";
import {AuthContext} from "../../../../context/AuthContext";

const MoneyPage = () => {
    const { handleChangePath, handleSetActions, handleChangeTabs } = useContext(TopBarContext);
    const { setNotification } = useContext(AuthContext);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState<Dictionary<any>>({
        periods: {
            date_from: dayjs().startOf("month"),
            date_to: dayjs().add(-1, "day"),
            currency: "",
        },
        filters: {},
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [tableData, setTableData] = useState<TableDataType>(initialTableData);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [payoutTotal, setPayoutTotal] = useState<number>(0);

    useEffect(() => {
        handleChangePath(["documents", "pages.dashboard.money"]);
        handleSetActions([]);
        handleChangeTabs([]);
    }, []);

    const loadMoney = async (signal: AbortSignal | undefined) => {
        setLoading(true);
        const data = {
            ...request,
            filters: request.filters || {},
        };
        try {
            const res = await getMoneyBySystems(data, signal);
            setTableData({
                ...res,
                table_actions: {
                    ...res.table_actions,
                    is_currency: true
                }
            });
            if (res.all_counts > res.size) {
                setSearchParams({
                    ...searchParams,
                    page: res.page || 1,
                    size: res.size || 50,
                });
            }
            setSelectedRows([]);
            setLoading(false);
        } catch (e) {
            setError("Error");
            setLoading(false);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        (async () => {
            await loadMoney(controller.signal);
        })();
        return () => controller.abort();
    }, [request]);

    const calcPayoutTotal = () => {
        let res = 0;
        tableData.rows.forEach(row => {
            if (selectedRows.includes(row.id)) {
                res += row.reward;
            }
        })
        setPayoutTotal(res);
    }

    useEffect(() => {
        calcPayoutTotal()
    }, [selectedRows])

    return (
        <ContentContainer>
            <TableConstructor
                payoutTotal={payoutTotal}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                tableData={tableData}
                loading={loading}
                request={request}
                setRequest={setRequest}
            />
        </ContentContainer>
    );
};

export default MoneyPage;
