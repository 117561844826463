import { Alert, Box, Snackbar } from "@mui/material";
import { Outlet } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import SideBar from "../components/SideBar";
import { AuthContext } from "../context/AuthContext";
import Loading from "../components/Loading";
import GlobalBar from "../components/TopBar/GlobalBar";
import FeedbackContainer from "../components/Feedback/FeedbackContainer";
import TechnicalProblemsNotification from "../components/TechnicalProblemsNotification";

const MainLayout = () => {
    const { loading, notification, setNotification } = useContext(AuthContext);
    const handleCloseNotification = () => {
        setNotification({
            ...notification,
            open: false,
        });
    };
    const [toggle, setToggle] = useState(true);
    const [contentWidth, setContentWidth] = useState(0);
    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    useEffect(() => {
        setTimeout(
            () => {
                setContentWidth(window.innerWidth - (toggle ? 225 : 56));
            },
            toggle ? 500 : 0
        );
    }, [toggle]);

    useEffect(() => {
        setContentWidth(window.innerWidth - (toggle ? 225 : 56));
    }, [windowWidth]);

    if (loading) {
        return (
            <Box
                sx={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Loading />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: "grid",
                width: "100vw",
                minHeight: "100vh",
                gridTemplateColumns: `1fr ${contentWidth}px`,
            }}
        >
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={notification.open}
                onClose={handleCloseNotification}
                key="top right"
                autoHideDuration={notification.duration || 3000}
            >
                <Alert onClose={handleCloseNotification} severity={notification.type} sx={{ width: "100%" }}>
                    {notification.message}
                </Alert>
            </Snackbar>
            <Box
                component="nav"
                sx={{
                    flexShrink: 0,
                }}
            >
                <SideBar toggle={toggle} setToggle={setToggle} />
            </Box>

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    minHeight: "100vh",
                    backgroundColor: "#F4F6F9",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                }}
            >
                <GlobalBar />
                <Outlet />
            </Box>
            <FeedbackContainer />
        </Box>
    );
};

export default MainLayout;
