export const ROLES = {
    client: "client",
    manager: "manager",
    admin: "admin",
    accountant: "accountant",
};

export type scopesType = "canCreate" | "canEdit" | "canDelete" | "canView";

export const SCOPES = {
    canCreate: "canCreate",
    canEdit: "canEdit",
    canDelete: "canDelete",
    canView: "canView",
};

export const PERMISSIONS = {
    [ROLES.client]: [SCOPES.canView],
    [ROLES.manager]: [SCOPES.canView, SCOPES.canEdit, SCOPES.canCreate, SCOPES.canDelete],
    [ROLES.admin]: [SCOPES.canView, SCOPES.canEdit, SCOPES.canCreate, SCOPES.canDelete],
    [ROLES.accountant]: [SCOPES.canView],
};
