import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { AxiosError, isAxiosError } from "axios";
import ContentContainer from "../../../../../../../../../../components/ContentContainer/ContentContainer";
import { TableDataType } from "../../../../../../../../../../components/TableConstructor/types";
import { getPlatformBlocks } from "../../../../../../../../services/fetchers";
import { Dictionary } from "../../../../../../../../index";
import TableConstructor from "../../../../../../../../../../components/TableConstructor";
import { initialTableData } from "../../../../../../../../../../components/TableConstructor/constants";
import { AuthContext } from "../../../../../../../../../../context/AuthContext";

const Blocks = () => {
    const { setNotFound } = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>("");
    const { id, platformId } = useParams();
    const [request, setRequest] = useState<Dictionary<any>>({
        periods: {
            date_from: dayjs().startOf("month"),
            date_to: dayjs().add(-1, "day"),
            currency: "",
        },
    });
    const [tableData, setTableData] = useState<TableDataType>(initialTableData);

    const loadPlatforms = async (signal: any) => {
        setLoading(true);
        const data = {
            ...request,
            filters: request.filters || {},
        };
        try {
            const res = await getPlatformBlocks(Number(id), Number(platformId), data, signal);
            setTableData(res);
            setLoading(false);
        } catch (err: Error | AxiosError | unknown) {
            if (isAxiosError(err)) {
                if (err.response?.status === 404) {
                    setNotFound(true);
                }
            }
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        (async () => {
            await loadPlatforms(controller.signal);
        })();
        return () => controller.abort();
    }, [request]);

    return (
        <ContentContainer>
            <TableConstructor tableData={tableData} loading={loading} request={request} setRequest={setRequest} isFilterPanel={false} />
        </ContentContainer>
    );
};

export default Blocks;
