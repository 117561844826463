import axios, { Axios, AxiosError } from "axios";

export const getSuppliers = async (data = {}, signal: any = null) => {
    try {
        const res = await axios.post("/properties/suppliers/", data, {
            signal,
        });
        return await res.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
};

export const getBonuses = async (data = {}, id: string | undefined = undefined, signal: any = null) => {
    try {
        const res = await axios.post(`/properties/suppliers/${id}/bonus/`, data, { signal });
        return await res.data;
    } catch (err: Axios | AxiosError | unknown) {
        return err;
    }
};

export const getBonus = async (id: string | undefined, bonusId: string) => {
    try {
        const res = await axios.get(`/properties/suppliers/${id}/bonus/${bonusId}/`);
        return await res.data;
    } catch (err: any) {
        console.error("Error");
        throw err;
    }
};
