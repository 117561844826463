import React from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ValueInputField from "../../../../../../../UI/Inputs/ValueInputField";
import { handleChangeField } from "../../../../../services/helpers";
import SelectInputField from "../../../../../../../UI/Inputs/SelectInputField";
import { ReactComponent as EmailIcon } from "../../../../../../../assets/images/icons/client/emailIcon.svg";
import { ReactComponent as LinkIcon } from "../../../../../../../assets/images/icons/client/linkIcon.svg";
import { ReactComponent as ProfileIcon } from "../../../../../../../assets/images/icons/sidebar/profile.svg";

const statements = ["Statements from Payoneer", "Statements from Bank"];
const PayoneerPaymentFields = ({ paymentDetails, setPaymentDetails, readOnly }: any) => {
    const { t } = useTranslation();
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    mb: "1rem",
                }}
            >
                {t("forms.payment.p2p_label")}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    gap: "1rem",
                    width: "100%",
                    flexWrap: "wrap",
                    mb: "1rem",
                    maxWidth: "740px",
                }}
            >
                <ValueInputField
                    readOnly={readOnly}
                    Icon={<ProfileIcon />}
                    label={t("forms.payment.name")}
                    value={paymentDetails?.receiverCompanyName}
                    onChange={(e) => handleChangeField("receiverCompanyName", e.target.value, paymentDetails, setPaymentDetails)}
                />
                <ValueInputField
                    readOnly={readOnly}
                    Icon={<EmailIcon />}
                    label={t("forms.common.e-mail")}
                    value={paymentDetails?.email}
                    onChange={(e) => handleChangeField("email", e.target.value, paymentDetails, setPaymentDetails)}
                />
                <ValueInputField
                    readOnly={readOnly}
                    Icon={<LinkIcon />}
                    label={t("forms.payment.passport_link")}
                    value={paymentDetails?.passportScanUrl}
                    onChange={(e) => handleChangeField("passportScanUrl", e.target.value, paymentDetails, setPaymentDetails)}
                />
                <SelectInputField
                    readOnly={readOnly}
                    value={paymentDetails?.fileType || ""}
                    label={t("forms.payment.select_statements")}
                    items={statements}
                    onChange={(value) => handleChangeField("fileType", value, paymentDetails, setPaymentDetails)}
                />
                <ValueInputField
                    style={{ width: "100%" }}
                    readOnly={readOnly}
                    Icon={<LinkIcon />}
                    label={t("forms.payment.address_link")}
                    value={paymentDetails?.address_link}
                    onChange={(e) => handleChangeField("address_link", e.target.value, paymentDetails, setPaymentDetails)}
                />
                {paymentDetails.fileType === "Statements from Payoneer" && (
                    <>
                        <ValueInputField
                            readOnly={readOnly}
                            style={{ width: "100%" }}
                            Icon={<LinkIcon />}
                            label={t("forms.payment.confirmation_link")}
                            value={paymentDetails?.payoneer_confirmation_link}
                            onChange={(e) => handleChangeField("payoneer_confirmation_link", e.target.value, paymentDetails, setPaymentDetails)}
                        />
                        <ValueInputField
                            readOnly={readOnly}
                            style={{ width: "100%" }}
                            Icon={<LinkIcon />}
                            label={t("forms.payment.letter_link")}
                            value={paymentDetails?.payoneer_letter_link}
                            onChange={(e) => handleChangeField("payoneer_letter_link", e.target.value, paymentDetails, setPaymentDetails)}
                        />
                    </>
                )}
                {paymentDetails.fileType === "Statements from Bank" && (
                    <ValueInputField
                        readOnly={readOnly}
                        style={{ width: "100%" }}
                        Icon={<LinkIcon />}
                        label={t("forms.payment.bank_statement")}
                        value={paymentDetails?.bank_confirmation_link}
                        onChange={(e) => handleChangeField("bank_confirmation_link", e.target.value, paymentDetails, setPaymentDetails)}
                    />
                )}
                <FormControlLabel
                    control={<Checkbox checked={paymentDetails?.statementFactChecked || false} readOnly={readOnly} />}
                    label={t("forms.payment.documents_confirm")}
                    onChange={(e) => !readOnly && handleChangeField("statementFactChecked", !paymentDetails?.statementFactChecked, paymentDetails, setPaymentDetails)}
                />
            </Box>
        </Box>
    );
};

export default PayoneerPaymentFields;
