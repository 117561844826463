import React, { useContext, useEffect, useState } from "react";
import axios, { AxiosError, isAxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { AuthContext } from "../../../../../../../../../../context/AuthContext";
import BonusForm from "../../components/BonusForm";
import ContentContainer from "../../../../../../../../../../components/ContentContainer/ContentContainer";
import { getBonus } from "../../../../../../services/fetchers";
import getSupplierInfo from "../../../../services/fetchers";
import { TopBarContext } from "../../../../../../../../../../context/TopBarContext";
import CloseButton from "../../../../../../../../../../UI/Buttons/Actions/CloseButton";
import EditButton from "../../../../../../../../../../UI/Buttons/Actions/EditButton";

export type BonusType = {
    year: number;
    month: number;
    bonus: number;
};

export const initialBonus = {
    year: Number(dayjs().year()),
    month: Number(dayjs().month()),
    bonus: 0,
};

const BonusEdit = () => {
    const [bonus, setBonus] = useState<BonusType>(initialBonus);
    const { handleAppendPath, handleSetActions } = useContext(TopBarContext);
    const { setNotification, setNotFound } = useContext(AuthContext);
    const { id, bonusId } = useParams();
    const navigate = useNavigate();
    const [supplierInfo, setSupplierInfo] = useState<{ name: string }>({
        name: "",
    });

    useEffect(() => {
        handleSetActions([<EditButton action={handleSubmitBonus} />, <CloseButton path={`/properties/suppliers/${id}/supplier`} />]);
    }, [bonus]);

    useEffect(() => {
        (async () => {
            try {
                setSupplierInfo(await getSupplierInfo(id));
            } catch (err: Error | AxiosError | unknown) {
                if (isAxiosError(err)) {
                    if (err.response?.status === 404) {
                        setNotFound(true);
                    }
                }
            }
        })();
    }, [id]);

    useEffect(() => {
        if (id && bonusId) {
            (async () => {
                try {
                    setBonus(await getBonus(id, bonusId));
                } catch (err: Error | AxiosError | unknown) {
                    if (isAxiosError(err)) {
                        if (err.response?.status === 404) {
                            setNotFound(true);
                        }
                    }
                }
            })();
            handleAppendPath("bonus");
        }
    }, [id, bonusId]);

    const handleSubmitBonus = async () => {
        try {
            const res = await axios.post(`/properties/suppliers/${id}/bonus/${bonusId}/edit/`, bonus);
            goBackHandler();
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.message,
                type: "error",
            });
        }
    };

    const goBackHandler = () => navigate(`/properties/suppliers/${id}/bonus`);

    return (
        <ContentContainer>
            <BonusForm bonus={bonus} setBonus={setBonus} />
        </ContentContainer>
    );
};

export default BonusEdit;
