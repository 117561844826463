import { Draggable, DraggableProvided, DraggableStateSnapshot, Droppable, DroppableProvided, DroppableStateSnapshot } from "react-beautiful-dnd";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { alpha, Box, Checkbox, Divider, IconButton, Menu, MenuItem, MenuProps, styled, TableCell, TableRow, TableSortLabel, Typography } from "@mui/material";
import { Resizable } from "react-resizable";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { FC, useContext, useEffect } from "react";
import { defaultCellStyle, pinnedColumn, sticky, thickRow } from "../../constants";
import { ReactComponent as PushPinActiveIcon } from "../../../../assets/images/icons/common/pin-active.svg";
import { ReactComponent as PushPinIcon } from "../../../../assets/images/icons/common/PinIcon.svg";
import { Header } from "../../types";
import { RenderColumnsType, SortDirections } from "./type";
import { AuthContext } from "../../../../context/AuthContext";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 0,
        marginTop: theme.spacing(1),
        minWidth: 180,
        // border: "1px solid #666",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
        color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

const RenderColumns: FC<RenderColumnsType> = ({
    handleSort,
    sortColumn,
    sortDirection,
    tableActions,
    selectedRows,
    handleSelectAllRows,
    visibleHeaders = [],
    handleResize,
    pinClickHandler,
    colPinLeft,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<Record<string, any>>({
        anchorEl: null,
        menus: {},
    });
    const { me } = useContext(AuthContext);
    const isClient = me.role === "client";

    useEffect(() => {
        const obj: Record<string, any> = { ...anchorEl };
        visibleHeaders.forEach((h: any) => {
            obj.menus[h.key] = false;
        });
        obj.anchorEl = null;
        setAnchorEl(obj);
    }, [visibleHeaders]);

    const handleClickActions = (key: string) => (e: React.MouseEvent) => {
        const newObj = { ...anchorEl.menus };
        newObj[key] = true;
        setAnchorEl({ anchorEl: e.currentTarget, menus: newObj });
    };
    const handlePinClick = (clickedHeader: Header) => (e: React.MouseEvent) => {
        pinClickHandler(clickedHeader);
        handleCloseActions(clickedHeader.key)(e);
    };

    const handleCloseActions = (key: string) => (e: React.MouseEvent) => {
        const newObj = { ...anchorEl.menus };
        newObj[key] = false;
        setAnchorEl({ anchorEl: null, menus: newObj });
    };

    const handleClickSort = (clickedHeader: Header, direction: SortDirections) => (e: React.MouseEvent) => {
        const column = clickedHeader.key;
        if (direction) {
            handleSort(column, direction);
        } else {
            handleSort("", direction);
        }
        handleCloseActions(clickedHeader.key)(e);
    };

    const columns = (header: Header, index: number, provided: DraggableProvided | null, snapshot: DraggableStateSnapshot | null, pinLeft: number) => (
        <Resizable key={header.key} width={Number(header.width)} height={0} axis="x" onResize={(event, { size }) => handleResize(header, size.width)}>
            <TableCell
                key={index}
                ref={provided && provided.innerRef}
                {...(provided && provided.draggableProps)}
                sx={{
                    ...defaultCellStyle,
                    border: snapshot && snapshot.isDragging ? "2px solid var(--main-purple-color)" : "none",
                    borderBottom: `2px solid ${header.pin ? "#3A3F48" : "var(--main-purple-color)"}`,
                    width: Number(header.width),
                    minWidth: 120,
                    ...(header.pin ? { ...pinnedColumn, left: pinLeft } : {}),
                }}
            >
                <Box
                    key={index}
                    {...(provided && provided.dragHandleProps)}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // width: Number(header.width),
                        width: "100%",
                        minWidth: 120,
                        p: "0 .75rem",
                        cursor: "grab",
                    }}
                >
                    <TableSortLabel active={sortColumn === header.key} direction={sortDirection === "asc" ? "asc" : "desc"} onClick={handleClickSort(header, sortDirection === "asc" ? "desc" : "asc")}>
                        <Typography
                            sx={{
                                fontSize: "1rem",
                                fontWeight: "500",
                                color: "var(--main-black-color)",
                                textWrap: "wrap",
                                display: "-webkit-box",
                                "-webkit-line-clamp": "2",
                                "-webkit-box-orient": "vertical",
                                overflow: "hidden",
                                maxHeight: "48px",
                                textOverflow: "ellipsis",
                                lineHeight: "1rem",
                            }}
                        >
                            {header.label}
                        </Typography>
                    </TableSortLabel>
                    <Box
                        key={header.key}
                        sx={{
                            display: "flex",
                            gap: ".25rem",
                            alignItems: "center",
                        }}
                    >
                        <IconButton
                            id={`fade-button-${header.key}`}
                            aria-controls={anchorEl.menus[header.key] ? `fade-menu-${header.key}` : undefined}
                            sx={{ p: "0" }}
                            aria-haspopup="true"
                            aria-expanded={anchorEl.menus[header.key] ? "true" : undefined}
                            onClick={handleClickActions(header.key)}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <StyledMenu
                            id={`fade-menu-${header.key}`}
                            MenuListProps={{
                                "aria-labelledby": `fade-button-${header.key}`,
                            }}
                            anchorEl={anchorEl.anchorEl}
                            open={anchorEl.menus[header.key]}
                            onClose={handleCloseActions(header.key)}
                        >
                            {sortColumn === header.key ? (
                                <div>
                                    {sortDirection === "asc" ? (
                                        <MenuItem key={`item-${header.key}-desc`} onClick={handleClickSort(header, "desc")} sx={{ gap: ".75rem" }}>
                                            <ArrowDownwardIcon />
                                            Sort by DESC
                                        </MenuItem>
                                    ) : (
                                        <MenuItem key={`item-${header.key}-asc`} onClick={handleClickSort(header, "asc")} sx={{ gap: ".75rem" }}>
                                            <ArrowUpwardIcon />
                                            Sort by ASC
                                        </MenuItem>
                                    )}
                                    <MenuItem key={`item-${header.key}-unsort`} onClick={handleClickSort(header, "")} sx={{ pl: "3.5rem" }}>
                                        Unsort
                                    </MenuItem>
                                </div>
                            ) : (
                                <div>
                                    <MenuItem key={`item-${header.key}-asc`} onClick={handleClickSort(header, "asc")} sx={{ gap: ".75rem" }}>
                                        <ArrowUpwardIcon />
                                        Sort by ASC
                                    </MenuItem>
                                    <MenuItem key={`item-${header.key}-desc`} onClick={handleClickSort(header, "desc")} sx={{ gap: ".75rem" }}>
                                        <ArrowDownwardIcon />
                                        Sort by DESC
                                    </MenuItem>
                                </div>
                            )}
                            {visibleHeaders.length > 6 && (
                                <div>
                                    <Divider />
                                    <MenuItem key={`item-${header.key}-pin`} onClick={handlePinClick(header)} sx={{ gap: ".75rem" }}>
                                        {header.pin ? (
                                            <PushPinActiveIcon
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                }}
                                            />
                                        ) : (
                                            <PushPinIcon
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                }}
                                            />
                                        )}
                                        Pin
                                    </MenuItem>
                                </div>
                            )}
                        </StyledMenu>
                    </Box>
                </Box>
                <Divider
                    sx={{
                        position: "absolute",
                        top: "25%",
                        right: "0",
                        height: "24px",
                        margin: "auto 0",
                    }}
                    orientation="vertical"
                />
            </TableCell>
        </Resizable>
    );

    return (
        <Droppable droppableId="columns" direction="horizontal">
            {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                <TableRow
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{
                        border: snapshot.isDraggingOver && snapshot.draggingFromThisWith ? "2px dashed #888" : "none",
                        position: "relative",
                        height: thickRow,
                        background: "var(--main-purple-hover-color)",
                        borderBottom: "2px solid var(--main-purple-color)",
                    }}
                >
                    {tableActions?.pick_row && (
                        <TableCell
                            sx={{
                                ...defaultCellStyle,
                                background: "inherit",
                                borderBottom: "2px solid var(--main-purple-color)",
                                whiteSpace: "nowrap",
                                width: "71px",
                            }}
                        >
                            <Box sx={{ minHeight: "24px", display: "flex", alignItems: "center" }}>
                                <Checkbox sx={{ mr: ".25rem" }} checked={selectedRows.length > 0} onClick={handleSelectAllRows} />
                                <p
                                    style={{
                                        borderRight: "1px solid rgba(0, 0, 0, 0.20)",
                                        height: "24px",
                                    }}
                                />
                            </Box>
                        </TableCell>
                    )}
                    <TableCell
                        sx={{
                            ...defaultCellStyle,
                            background: "inherit",
                            borderBottom: "2px solid var(--main-purple-color)",
                            whiteSpace: "nowrap",
                            width: "50px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                p: ".75rem",
                                height: "24px",
                                borderRight: "1px solid rgba(0, 0, 0, 0.20)",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                    color: "var(--main-black-color)",
                                }}
                            >
                                №
                            </Typography>
                        </Box>
                    </TableCell>
                    {visibleHeaders.map((header: any, index: number) => {
                        const pinLeft = colPinLeft;
                        if (header.pin) {
                            colPinLeft += Number(header.width);
                        }
                        if (header.visible) {
                            if (header.draggable && visibleHeaders.length > 1) {
                                return (
                                    <Draggable key={header.key} draggableId={header.key} index={index}>
                                        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => <>{columns(header, index, provided, snapshot, pinLeft)}</>}
                                    </Draggable>
                                );
                            }
                            return <>{columns(header, index, null, null, pinLeft)}</>;
                        }
                        return null;
                    })}
                    {tableActions.row_actions && (
                        <TableCell
                            sx={{
                                ...defaultCellStyle,
                                ...sticky,
                                borderBottom: "2px solid var(--main-purple-color)",
                                width: isClient ? "48px" : "75px",
                                minWidth: isClient ? "48px" : "75px",
                            }}
                        >
                            <Box
                                sx={{
                                    width: isClient ? "48px" : "75px",
                                    minWidth: isClient ? "48px" : "75px",
                                    display: "flex",
                                    alignItems: "center",
                                    height: "24px",
                                    borderLeft: "1px solid rgba(0, 0, 0, 0.20)",
                                }}
                            />
                        </TableCell>
                    )}
                </TableRow>
            )}
        </Droppable>
    );
};

export default RenderColumns;
