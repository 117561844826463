import React, { useContext, useEffect, useState } from "react";
import ContentContainer from "../../../../../../components/ContentContainer/ContentContainer";
import { TransferMethodType } from "../../types";
import { AuthContext } from "../../../../../../context/AuthContext";
import Form from "../../components/Form";
import { createEntry } from "../../services/fetchers";
import { TopBarContext } from "../../../../../../context/TopBarContext";
import CreateButton from "../../../../../../UI/Buttons/Actions/CreateButton";
import CloseButton from "../../../../../../UI/Buttons/Actions/CloseButton";

export const initialLlp = {
    name: "",
    expenses_currency: "",
    expenses_type: "",
    expenses_value: "",
    start_date: "",
    end_date: "",
    is_active: false,
};

const TransferMethodsCreate = () => {
    const { setNotification } = useContext(AuthContext);
    const [llp, setLlp] = useState<TransferMethodType>(initialLlp);
    const { handleChangePath, handleSetActions } = useContext(TopBarContext);
    useEffect(() => {
        handleChangePath(["partners", "new_transfer_method"]);
        handleSetActions([<CreateButton action={handleSubmit} />, <CloseButton path="/properties/tm" />]);
    }, [llp]);

    const handleSubmit = async () => {
        try {
            const res = await createEntry(llp);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        }
    };
    return (
        <ContentContainer>
            <Form tm={llp} setTm={setLlp} />
        </ContentContainer>
    );
};

export default TransferMethodsCreate;
