import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../CustomButton";

interface CloseButtonProps {
    path: string;
}
const CloseButton: React.FC<CloseButtonProps> = ({ path }) => {
    const navigate = useNavigate();
    return (
        <CustomButton
            Icon={<CloseIcon />}
            mode="dark"
            label="button.close"
            onChange={() => {
                navigate(path);
            }}
        />
    );
};
export default CloseButton;
