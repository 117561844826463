import { Box, IconButton, InputAdornment, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";
import { changePassword, getAuth } from "./services/fetchers";
import { AuthContext } from "../../context/AuthContext";
import CustomButton from "../../UI/Buttons/CustomButton";
import ValueInputField from "../../UI/Inputs/ValueInputField";
import { ReactComponent as ProfileIcon } from "../../assets/images/icons/sidebar/profile.svg";
import Localization from "../../components/Localization";

const LoginForm = () => {
    const { t } = useTranslation();
    const { setIsLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [showPassword, setShowPassword] = useState<any>({
        current: false,
        new: false,
        confirm: false,
    });
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [isNewUser, setIsNewUser] = useState(false);
    const handleClickShowPassword = (key: string) => {
        setShowPassword({
            ...showPassword,
            [key]: !showPassword[key],
        });
    };
    const handleLogin = () => {
        if (login !== "" && password !== "") {
            getAuth(login, password)
                .then((res) => {
                    if (res.is_new) {
                        setError("");
                        setIsNewUser(true);
                    } else {
                        setIsLoggedIn(true);
                        let pathname = "/";
                        let search = "";
                        if (location.state) {
                            pathname = location.state.from.pathname;
                            search = location.state.from.search;
                        }
                        setTimeout(() => {
                            navigate({
                                pathname,
                                search,
                            });
                        }, 0);
                    }
                })
                .catch((e) => {
                    setError(e.message);
                    setLogin("");
                    setPassword("");
                });
        }
    };
    const handleChangeLogin = (value: string) => {
        setLogin(value);
    };
    const handleChangePassword = (value: string) => {
        setPassword(value);
    };
    const handleSubmitNewPassword = () => {
        if (newPassword === confirmPassword) {
            changePassword({
                username: login,
                old_password: password,
                new_password: newPassword,
            })
                .then(res => {
                    setIsLoggedIn(true);
                    setTimeout(() => {
                        navigate(location.state.from.pathname);
                    }, 0);
                })
                .catch((err) => {
                    setError(err);
                });
        }
    };

    return (
        <Box
            sx={{
                width: "360px",
                minHeight: "281px",
                boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.08)",
                borderTop: "3px solid var(--main-purple-color)",
                p: "1.5rem",
                boxSizing: "border-box",
                backgroundColor: "#fff",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    gap: "1.5rem",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: isNewUser ? "initial" : "center",
                        alignItems: "center",
                        gap: isNewUser ? "1rem" : "0",
                        position: "relative",
                    }}
                >
                    {isNewUser && (
                        <IconButton
                            sx={{
                                p: "0",
                            }}
                            onClick={() => {
                                setIsNewUser(false);
                                setError("");
                            }}
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                    )}
                    <Typography
                        sx={{
                            fontSize: "1rem",
                            fontWeight: "500",
                            textAlign: "center",
                        }}
                    >
                        {isNewUser ? t("create_new_password") : t("personal_account")}
                    </Typography>
                    <Box sx={{ position: "absolute", right: 0 }}>
                        <Localization />
                    </Box>
                </Box>
                {error && (
                    <Typography
                        sx={{
                            color: "red",
                            fontSize: "1rem",
                            textAlign: "center",
                        }}
                    >
                        {error}
                    </Typography>
                )}
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                    }}
                >
                    {isNewUser ? (
                        <>
                            <ValueInputField
                                type={showPassword.new ? "text" : "password"}
                                Icon={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={() => handleClickShowPassword("new")} edge="end">
                                            {showPassword.new ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label={t("new_password")}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <ValueInputField
                                type={showPassword.confirm ? "text" : "password"}
                                Icon={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={() => handleClickShowPassword("confirm")} edge="end">
                                            {showPassword.confirm ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label={t("confirm_password")}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </>
                    ) : (
                        <>
                            <ValueInputField autoComplete Icon={<ProfileIcon />} label={t("login")} value={login} onChange={(e) => handleChangeLogin(e.target.value)} />
                            <ValueInputField
                                autoComplete
                                type={showPassword.current ? "text" : "password"}
                                Icon={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={() => handleClickShowPassword("current")} edge="end">
                                            {showPassword.current ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label={t("password")}
                                value={password}
                                onChange={(e) => handleChangePassword(e.target.value)}
                            />
                        </>
                    )}
                </Box>
                {isNewUser ? (
                    <CustomButton style={{ padding: "1rem 0", height: "50px" }} label="button.save" onChange={handleSubmitNewPassword} />
                ) : (
                    <CustomButton style={{ padding: "1rem 0", height: "50px" }} label="signin" onChange={handleLogin} />
                )}
            </Box>
        </Box>
    );
};

export default LoginForm;
