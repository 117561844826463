import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import BonusForm from "../../components/BonusForm";
import { AuthContext } from "../../../../../../../../context/AuthContext";
import ContentContainer from "../../../../../../../../components/ContentContainer/ContentContainer";
import { TopBarContext } from "../../../../../../../../context/TopBarContext";
import CreateButton from "../../../../../../../../UI/Buttons/Actions/CreateButton";
import CloseButton from "../../../../../../../../UI/Buttons/Actions/CloseButton";

export type BonusType = {
    bonus_gp: number;
    bonus_month: number;
    bonus_from_new: number;
    new_client_month_count: number;
};

export const initialBonus = {
    bonus_gp: 0,
    bonus_month: 0,
    bonus_from_new: 0,
    new_client_month_count: 0,
};

const ManagerBonusCreate = () => {
    const [bonus, setBonus] = useState<BonusType>(initialBonus);
    const { handleAppendPath, handleSetActions } = useContext(TopBarContext);
    useEffect(() => {
        handleAppendPath("new_bonus");
        handleSetActions([<CreateButton action={handleSubmitBonus} />, <CloseButton path={`/users/managers/${id}/bonus`} />]);
    }, [bonus]);

    const { setNotification } = useContext(AuthContext);

    const { id } = useParams();

    const handleSubmitBonus = async () => {
        try {
            const res = await axios.post(`/users/managers/${id}/bonus_table/add/`, bonus);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.message,
                type: "error",
            });
        }
    };

    return (
        <ContentContainer>
            <BonusForm bonus={bonus} setBonus={setBonus} />
        </ContentContainer>
    );
};

export default ManagerBonusCreate;
