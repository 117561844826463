import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Autocomplete, Box, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {getClientList, getPlacements, getProductByID, getTemplate} from "../../services/fetchers";
import ContentContainer from "../../../../components/ContentContainer/ContentContainer";
import InImageForm from "./InImageForm";
import {handleChangeField} from "../../../ClientsPage/services/helpers";
import {boxStyle, ProductConfigs} from "../PVW_YD/pages/PerfectVideoYDDetails";
import {TopBarContext} from "../../../../context/TopBarContext";
import formFieldsInitial from "./formFields";

const InImageDetails = () => {
    const [formFields, setFormFields] = useState({});
    const [clientList, setClientList] = useState<string[]>([]);
    const [placementList, setPlacementList] = useState<string[]>([]);
    const [configs, setConfigs] = useState<ProductConfigs>({});
    const [showAllFields, setShowAllFields] = useState(false);
    const { handleChangePath, handleSetActions } = useContext(TopBarContext);

    const { t } = useTranslation();
    const { id } = useParams();
    const mode = id ? "edit" : "create";

    useEffect(() => {
        handleChangePath(["Products", "New PVW YD"]);
    }, []);

    useEffect(() => {
        (async () => setClientList(await getClientList()))();
    }, [])
    //
    useEffect(() => {
        if (configs.client) {
            (async () => setPlacementList(await getPlacements(configs.client)))();
        }
    }, [configs.client]);

    useEffect(() => {
        (async () => {
            if (id) {
                const data = await getProductByID("in_image", id);
                setConfigs({
                    client: data.client,
                    placement_name: data.placement_name
                });
                const formData = Object.fromEntries(Object.entries(data.form).map((item: any) => {
                    if (item[1].typeSend === "object") {
                        return [
                            item[0],
                            {
                                ...item[1],
                                defaultValue: JSON.stringify(item[1].defaultValue)
                            }
                        ]
                    }
                    if (item[1].type === "select") {
                        return [
                            item[0],
                            {
                                ...item[1],
                                defaultValue: typeof item[1].defaultValue !== "string" ? JSON.stringify(item[1].defaultValue) : item[1].defaultValue
                            }
                        ]
                    }
                    return item;
                }));
                setFormFields({
                    ...formData,
                });
            } else {
                const data = await getTemplate("in_image");
                setFormFields(data);
            }
        })();
    }, [])

    const clientAndPlacementFields = () => (
        <Box sx={{display: "flex", width: "100%", gap: "1rem"}}>
            <Autocomplete
                disabled={mode === "edit"}
                value={configs.client}
                color="warning"
                disablePortal
                id="combo-box-demo"
                options={clientList}
                sx={{ width: "100%", flexBasis: "calc(50% - .5rem)" }}
                onChange={(e, value) => handleChangeField("client", value, configs, setConfigs)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        disabled={mode === "edit"}
                        InputProps={{
                            ...params.InputProps,
                            sx: {
                                borderRadius: "0",
                            },
                        }}
                        color="warning"
                        label={t("forms.client_account.select_client")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
            />
            <Autocomplete
                disabled={mode === "edit"}
                value={configs.placement_name}
                color="warning"
                disablePortal
                id="combo-box-demo"
                options={placementList}
                sx={{ width: "100%", flexBasis: "calc(50% - .5rem)" }}
                onChange={(e, value) => {
                    handleChangeField("placement_name", value, configs, setConfigs);
                    setShowAllFields(!!value);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        disabled={mode === "edit"}
                        InputProps={{
                            ...params.InputProps,
                            sx: {
                                borderRadius: "0",
                            },
                        }}
                        color="warning"
                        label={t("forms.client_account.select_placement")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
            />
        </Box>
    )

    return (
        <ContentContainer>
            <Box sx={{...boxStyle, height: "fit-content", width: "75%", flexWrap: "wrap"}}>
                <Typography sx={{fontSize: "1.5rem", width: "100%", fontWeight: "500"}}>Client and Placement</Typography>
                { mode === "edit"
                    ? Object.keys(configs).length > 0
                        ? clientAndPlacementFields()
                        : null
                    : clientAndPlacementFields()
                }
                { configs.placement_name &&
                    <InImageForm client={configs.client} placement_name={configs.placement_name} formFields={formFields} />
                }
            </Box>
        </ContentContainer>
    )
}

export default InImageDetails;