import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import axios from "axios";
import ContentContainer from "../../../../components/ContentContainer/ContentContainer";
import NotificationEntity from "../../components/NotificationEntity";
import { TopBarContext } from "../../../../context/TopBarContext";
import EmptyNotifications from "../../components/EmptyNotifications";
import Loading from "../../../../components/Loading";

type NotificationType = {
    id: number;
    title: string;
    message: string;
    date?: string;
    open?: boolean;
    isViewed?: boolean;
};

const ArchivePage = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [notifications, setNotifications] = useState<NotificationType[]>([]);
    const { handleSetActions, handleChangeTabs, handleChangePath } = useContext(TopBarContext);

    useEffect(() => {
        handleChangePath(["notifications"]);
        handleSetActions([<Box sx={{ width: "168px" }} />]);
        handleChangeTabs([
            {
                key: "notification",
                label: "notifications",
            },
            {
                key: "archive",
                label: "archive",
            },
        ]);
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get("users/notification/archive");
                const data = res.data.map((items: NotificationType) => ({
                    ...items,
                    open: false,
                }));
                setNotifications(data);
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    if (loading) {
        return (
            <Box
                sx={{
                    height: "50vh",
                    display: "flex",
                }}
            >
                <Loading />
            </Box>
        );
    }

    return (
        <ContentContainer>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "12px",
                }}
            >
                {notifications.length > 0 ? notifications.map((notification) => <NotificationEntity key={notification.id} notification={notification} isArchive />) : <EmptyNotifications />}
            </Box>
        </ContentContainer>
    );
};

export default ArchivePage;
