import { Box, IconButton, Typography } from "@mui/material";
import React, { ChangeEvent, FC, useRef, useState } from "react";
import ValueInputField from "../../UI/Inputs/ValueInputField";
import { handleChangeField } from "../../pages/ClientsPage/services/helpers";
import SelectInputField from "../../UI/Inputs/SelectInputField";
import CustomButton from "../../UI/Buttons/CustomButton";
import { ReactComponent as EditIcon } from "../../assets/images/icons/common/editIcon.svg";
import { ReactComponent as SendIcon } from "../../assets/images/icons/common/telegramIconWhite.svg";
import { ReactComponent as AttachmentsIcon } from "../../assets/images/icons/common/attachment.svg";
import useFeedbackController from "./useFeedbackController";
import { FeedbackType } from "./FeedbackType";

const FeedbackForm: FC<{ handleClose?: () => void }> = ({ handleClose = () => {} }) => {
    const { categories, sendFeedback } = useFeedbackController();
    const [feedback, setFeedback] = useState<FeedbackType>({
        category: null,
        text: null,
        file: null,
    });
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        setFeedback({
            ...feedback,
            file,
        });
    };

    return (
        <Box
            sx={{
                background: "#fff",
                gap: "1rem",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    p: "1rem 1rem 0 1rem",
                }}
            >
                <SelectInputField
                    key="type-input"
                    style={{ width: "100%" }}
                    value={feedback.category}
                    label="type_error"
                    items={categories}
                    onChange={(value) => handleChangeField("category", value, feedback, setFeedback)}
                />
                <ValueInputField
                    style={{ width: "100%" }}
                    multiline
                    label="describe_error"
                    value={feedback.text}
                    Icon={<EditIcon />}
                    onChange={(e) => handleChangeField("text", e.target.value, feedback, setFeedback)}
                />
                <input ref={inputRef} type="file" id="file" name="file" onChange={handleFileChange} style={{ display: "none" }} />
                <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                    <IconButton
                        sx={{
                            width: "42px",
                            height: "42px",
                            backgroundColor: "var(--main-purple-color)",
                            "&:hover": {
                                backgroundColor: "var(--main-purple-color)",
                            },
                        }}
                        onClick={handleClick}
                    >
                        <AttachmentsIcon />
                    </IconButton>
                    {feedback.file && (
                        <Typography
                            sx={{
                                color: "var(--main-light-text-color)",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordBreak: "break-word",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {feedback.file?.name}
                        </Typography>
                    )}
                </Box>
            </Box>
            <CustomButton
                label="submit"
                onChange={() => {
                    sendFeedback(feedback);
                    handleClose();
                }}
                style={{ textTransform: "none", width: "100%" }}
                Icon={<SendIcon />}
            />
        </Box>
    );
};
export default FeedbackForm;
