import { Box } from "@mui/material";
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";
// eslint-disable-next-line import/no-unresolved
import { Pagination, Mousewheel } from "swiper/modules";
import Card, { CardType } from "../Card";

// eslint-disable-next-line import/no-unresolved
import "swiper/css";
// eslint-disable-next-line import/no-unresolved
import "swiper/css/pagination";
import "./index.module.scss.css";

type CardItemsProps = {
    loading: boolean;
    cards: CardType[];
};

const CardItems = ({ cards, loading }: CardItemsProps) => (
    <Swiper
        slidesPerView={3}
        spaceBetween={24}
        pagination={{
            clickable: true,
        }}
        mousewheel
        modules={[Pagination, Mousewheel]}
        className="mySwiper"
    >
        {cards.length > 0 && cards.map((card, index) => (
            <SwiperSlide key={index}>
                <Card index={index} card={card} loading={loading}/>
            </SwiperSlide>
        ))}
    </Swiper>
)

export default CardItems;
