import { useTranslation } from "react-i18next";
import { Box, IconButton, Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "../../../../components/Modal";
import { AuthContext } from "../../../../context/AuthContext";
import { IRequests } from "../../index";

type DraftReportsModalProps = {
    open: boolean;
    handleClose: () => void;
    setRequests: (value: IRequests) => void;
};

type SaveReportType = {
    id: number;
    name: string;
};
const DraftReportsModal: FC<DraftReportsModalProps> = ({ setRequests, open = false, handleClose = () => {} }) => {
    const { t } = useTranslation();
    const { setNotification } = useContext(AuthContext);
    const [reportsList, setReportsList] = useState<SaveReportType[]>([]);
    const [error, setError] = useState("");
    const getReportsList = async () => {
        try {
            const url = "report/templates/";
            const res = await axios.get(url);
            setReportsList(res.data);
        } catch (e: any) {
            setError("There are no saved reports");
        }
    };
    const deleteReport = async (id: number) => {
        try {
            const url = `report/templates/${id}/delete/`;
            const res = await axios.post(url);
            const newReportsList = reportsList.filter((report) => report.id !== id);
            setReportsList(newReportsList);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (e: any) {
            setNotification({
                open: true,
                message: e.response.data.message,
                type: "error",
            });
        }
    };

    const chooseReportHandler = async (id: number) => {
        try {
            const url = `report/templates/${id}/`;
            const res = await axios.get(url);
            setRequests(res.data.template);
            handleClose();
            setNotification({
                open: true,
                message: "Report template has been activated",
                type: "success",
            });
        } catch (e: any) {
            setNotification({
                open: true,
                message: e.response.message.text,
                type: "error",
            });
        }
    };

    useEffect(() => {
        if (open && reportsList.length === 0) {
            (async () => getReportsList())();
        }
    }, [open]);
    return (
        <Modal open={open} handleClose={handleClose}>
            <Box
                sx={{
                    width: "360px",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: ".75rem",
                    }}
                >
                    <Box />
                    <Typography sx={{ fontWeight: "500", pl: ".5rem" }}>{t("drafts")}</Typography>
                    <IconButton
                        sx={{
                            p: "0",
                            borderRadius: "0",
                        }}
                        onClick={handleClose}
                    >
                        <CloseIcon style={{ color: "var(--main-black-color)" }} />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        borderBottom: "2px solid var(--main-divider-color)",
                        p: "16.5px 42px 16.5px 12px",
                        mb: ".75rem",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "500",
                            width: "100%",
                            borderRight: "1px solid var(--main-divider-color)",
                        }}
                    >
                        {t("report_name")}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: ".25rem",
                    }}
                >
                    {reportsList.length > 0 ? (
                        reportsList.map((report) => (
                            <Box
                                key={report.id}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
                                }}
                            >
                                <Box
                                    sx={{
                                        pl: ".75rem",
                                        width: "100%",
                                        cursor: "pointer",
                                        height: "42px",
                                        display: "flex",
                                        alignItems: "center",
                                        "&:hover": {
                                            backgroundColor: "var(--main-purple-hover-color)",
                                        },
                                    }}
                                    onClick={() => chooseReportHandler(report.id)}
                                >
                                    <Typography
                                        sx={{
                                            width: "100%",
                                            verticalAlign: "middle",
                                            borderRight: "1px solid var(--main-divider-color)",
                                        }}
                                    >
                                        {report.name}
                                    </Typography>
                                </Box>
                                <IconButton
                                    sx={{
                                        width: "42px",
                                        height: "42px",
                                        borderRadius: "0",
                                    }}
                                    onClick={() => deleteReport(report.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))
                    ) : (
                        <Typography
                            sx={{
                                textAlign: "center",
                                color: "var(--main-purple-color)",
                            }}
                        >
                            {t("no_saved_reports")}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default DraftReportsModal;
