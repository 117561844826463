import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

import ContentContainer from "../../../../components/ContentContainer/ContentContainer";
import { ManagerType } from "../../types";
import { AuthContext } from "../../../../context/AuthContext";
import ManagerForm from "../../components/ManagerForm";
import { TopBarContext } from "../../../../context/TopBarContext";
import CloseButton from "../../../../UI/Buttons/Actions/CloseButton";
import CreateButton from "../../../../UI/Buttons/Actions/CreateButton";

export const initialManager = {
    username: "",
    first_name: "",
    last_name: "",
    tg_login: "",
    phone_number: "",
    email: "",
    position: "",
};

const ManagerCreate = () => {
    const { setNotification } = useContext(AuthContext);
    const [manager, setManager] = useState<ManagerType>(initialManager);
    const { handleChangePath, handleSetActions } = useContext(TopBarContext);
    useEffect(() => {
        handleChangePath(["managers", "new_manager"]);
        handleSetActions([<CreateButton action={handleSubmit} />, <CloseButton path="/users/managers" />]);
    }, [manager]);

    const handleSubmit = async () => {
        try {
            const res = await axios.post("/users/managers/add/", manager);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        }
    };
    return (
        <ContentContainer>
            <ManagerForm manager={manager} setManager={setManager} readonlyUsername={false} />
        </ContentContainer>
    );
};

export default ManagerCreate;
