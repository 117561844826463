import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import axios, { AxiosError, isAxiosError } from "axios";
import { initialClientInfo, initialSystems } from "../../../../../../../../context/ClientCreateContext";
import ContentContainer from "../../../../../../../../components/ContentContainer/ContentContainer";
import { backendSystems, frontendSystems } from "../../../../../../services/parsers";
import BASE_URL from "../../../../../../../../data/constants";
import { AuthContext } from "../../../../../../../../context/AuthContext";
import { ClientInfo, SystemType } from "../../../../../../types";
import ConditionForm from "../../../../../../components/ClientCreateForm/ConditionForm";
import { getClientInfo } from "../../../../../../services/fetchers";
import EditButton from "../../../../../../../../UI/Buttons/Actions/EditButton";
import CreateButton from "../../../../../../../../UI/Buttons/Actions/CreateButton";
import CloseButton from "../../../../../../../../UI/Buttons/Actions/CloseButton";
import { TopBarContext } from "../../../../../../../../context/TopBarContext";

const FeesCreateOrEdit = () => {
    const { handleSetActions, handleChangePath, handleChangeTabs } = useContext(TopBarContext);
    const { setNotification, setNotFound } = useContext(AuthContext);
    const [systems, setSystems] = useState<SystemType[]>(initialSystems);
    const { id, conditionId } = useParams();
    const navigate = useNavigate();
    const mode = conditionId ? "edit" : "create";
    const [clientInfo, setClientInfo] = useState<ClientInfo>(initialClientInfo);

    useEffect(() => {
        const labelItems = [clientInfo.name, mode === "edit" ? "Fees" : "New Fees"];
        handleChangePath(labelItems);
        handleSetActions([mode === "edit" ? <EditButton action={handleEditCondition} /> : <CreateButton action={handleCreateCondition} />, <CloseButton path={`/clients/${id}/conditions`} />]);
        handleChangeTabs([]);
    }, [clientInfo.name, systems]);

    useEffect(() => {
        (async () => {
            const data = await getClientInfo(id);
            setClientInfo(data);
        })();
    }, [id]);

    const getCondition = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/${id}/conditions/${conditionId}/`);
            return await res.data;
        } catch (err: Error | AxiosError | unknown) {
            if (isAxiosError(err)) {
                if (err.response?.status === 404) {
                    setNotFound(true);
                }
            }
            return err;
        }
    };

    useEffect(() => {
        if (conditionId) {
            (async () => {
                const data = await getCondition();
                setSystems([frontendSystems(data)]);
            })();
        }
    }, [conditionId]);

    const handleGoBack = () => navigate(`/clients/${id}/conditions`);

    const handleCreateCondition = async () => {
        const data = backendSystems(systems[0]);
        try {
            const res = await axios.post(`${BASE_URL}/clients/${id}/conditions/create/`, data);
            handleGoBack();
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        }
    };

    const handleEditCondition = async () => {
        const data = backendSystems(systems[0]);
        try {
            const res = await axios.post(`${BASE_URL}/clients/${id}/conditions/${conditionId}/edit/`, data);
            handleGoBack();
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        }
    };

    return (
        <ContentContainer>
            <Box
                sx={{
                    p: "3rem",
                    background: "#fff",
                    maxWidth: "780px",
                }}
            >
                <ConditionForm systems={systems} setSystems={setSystems} isAddMore={false} />
            </Box>
        </ContentContainer>
    );
};

export default FeesCreateOrEdit;
