import React from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SelectInputField from "../../../../../../../UI/Inputs/SelectInputField";
import { handleChangeField, handleChangeInnerField } from "../../../../../services/helpers";
import ValueInputField from "../../../../../../../UI/Inputs/ValueInputField";
import CitizenshipFields from "../CitizenshipFields";
import AddressRegistrationFields from "../AddressRegistrationFields";
import DirectorFields from "../DirectorFields";
import FoundersFields from "../FoundersFields";
import { ReactComponent as ProfileIcon } from "../../../../../../../assets/images/icons/sidebar/profile.svg";
import { ReactComponent as CreditCardIcon } from "../../../../../../../assets/images/icons/client/creditCardIcon.svg";

const accountTypes = ["Checking", "Saving"];
const LocalPaymentFields = ({ client, paymentDetails, setPaymentDetails, countries, readOnly }: any) => {
    const { bankCredentials } = paymentDetails;
    const { t } = useTranslation();
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    mb: "1rem",
                }}
            >
                {t("forms.payments.transfer_method_label")} {paymentDetails.type}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    gap: "1rem",
                    width: "100%",
                    flexWrap: "wrap",
                    mb: "1rem",
                    maxWidth: "740px",
                }}
            >
                <SelectInputField
                    readOnly={readOnly}
                    value={bankCredentials?.receiverBankCountry || ""}
                    label={t("forms.payment.bank_country")}
                    items={countries}
                    onChange={(value) => handleChangeInnerField("bankCredentials", "receiverBankCountry", value, setPaymentDetails)}
                />
                <ValueInputField
                    readOnly={readOnly}
                    Icon={<CreditCardIcon />}
                    label={t("forms.payment.bank_name")}
                    value={bankCredentials?.receiverBankName}
                    onChange={(e) => handleChangeInnerField("bankCredentials", "receiverBankName", e.target.value, setPaymentDetails)}
                />
                <ValueInputField
                    readOnly={readOnly}
                    Icon={<CreditCardIcon />}
                    label={t("forms.payment.ACH")}
                    value={bankCredentials?.ACHRoutingNumber}
                    onChange={(e) => handleChangeInnerField("bankCredentials", "ACHRoutingNumber", e.target.value, setPaymentDetails)}
                />
                <ValueInputField
                    readOnly={readOnly}
                    Icon={<ProfileIcon />}
                    label={t("forms.payment.company_name")}
                    value={bankCredentials?.receiverCompanyName}
                    onChange={(e) => handleChangeInnerField("bankCredentials", "receiverCompanyName", e.target.value, setPaymentDetails)}
                />
                <ValueInputField
                    readOnly={readOnly}
                    Icon={<CreditCardIcon />}
                    label={t("forms.payment.account_number")}
                    value={bankCredentials?.accountNumber}
                    onChange={(e) => handleChangeInnerField("bankCredentials", "accountNumber", e.target.value, setPaymentDetails)}
                />
                <SelectInputField
                    readOnly={readOnly}
                    value={bankCredentials?.accountType || ""}
                    label={t("forms.payment.account_type")}
                    items={accountTypes}
                    onChange={(value) => handleChangeInnerField("bankCredentials", "accountType", value, setPaymentDetails)}
                />
            </Box>
            {(client.companyType === "Private Person" || client.companyType === "P.E./S.E.") && (
                <CitizenshipFields readOnly={readOnly} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} countries={countries} />
            )}
            <AddressRegistrationFields readOnly={readOnly} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} countries={countries} />
            {client.companyType === "Legal Entity" && (
                <>
                    <DirectorFields readOnly={readOnly} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} countries={countries} />
                    <FoundersFields readOnly={readOnly} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} countries={countries} />
                </>
            )}
            <FormControlLabel
                control={<Checkbox checked={paymentDetails.statementFactChecked || false} readOnly={readOnly} />}
                label={t("forms.payment.documents_confirm")}
                onChange={(e) => !readOnly && handleChangeField("statementFactChecked", !paymentDetails.statementFactChecked, paymentDetails, setPaymentDetails)}
            />
        </Box>
    );
};

export default LocalPaymentFields;
