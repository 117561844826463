import {FieldError} from "react-hook-form";
import React, {FC} from "react";
import dayjs from "dayjs";
// import DatePickerCustom from "../DatePicker";
import MultiSelect from "../../../UI/InputFields/MultiSelect";
import {Option, OptionObjectType} from "../../../services/common-types";
import FormTextField from "../../../UI/InputFields/FormTextField";
import FileField from "../../../UI/InputFields/FileField";
import SelectField from "../../../UI/InputFields/SelectField";
import CheckBoxField from "../../../UI/InputFields/CheckboxField";
import MultipleCheckBox from "../../../UI/InputFields/MultipleCheckBox";
import DatePickerCustom from "../../../UI/Inputs/DatePicker";
import ValueInputField from "../../../UI/Inputs/ValueInputField";
import SelectInputField from "../../../UI/Inputs/SelectInputField";

type InputProps = {
    label: string,
    hint: string,
    value: string | string[] | number | boolean | File,
    type: string,
    errors?: any,
    onChange: (e: any) => void,
    readOnly: boolean,
    options: Array<string | OptionObjectType | Option>,
    rules: {
        [key: string]: {
            value: boolean,
            message: string
        }
    }
}

const Input: FC<InputProps> = ({ label, value, hint, onChange, type, errors={}, ...rest }) => {
    switch (type) {
        // case "date":
        //     return (
        //         <DatePickerCustom
        //             value={value as string}
        //             onChange={(newValue: string | number | Date | dayjs.Dayjs | null | undefined) => onChange(dayjs(newValue).format("YYYY-MM-DD"))}
        //             style={{  width: "360px", paddingTop: "-8px" }}
        //             // disableBorders
        //         />
        //     )
        case "string":
            return (
                <ValueInputField
                    label={label}
                    border={false}
                    style={{ width: "100%", background: "var(--main-purple-hover-color)" }}
                    readOnly={false}
                    errors={errors || {}}
                    hint={hint}
                    value={value as string}
                    onChange={onChange}
                />
                // <FormTextField
                //     label={label}
                //     InputProps={{
                //         readOnly: rest.readOnly,
                //     }}
                //     // sx={{mb: isError ? "38px" : "24px"}}
                //     disabled={false}
                //     error={isError}
                //     id="standard-error-helper-text"
                //     helperText={errors}
                //     variant="outlined"
                //     value={value as string}
                //     onChange={onChange}
                // />
            );
        case "textarea":
            return (
                <ValueInputField
                    multiline
                    errors={errors}
                    border={false}
                    style={{ width: "100%", background: "var(--main-purple-hover-color)" }}
                    label={label}
                    value={value as string}
                    hint={hint}
                    onChange={(e) => onChange(e.target.value)}
                />
                // <FormTextField
                //     InputProps={{
                //         readOnly: rest.readOnly,
                //     }}
                //     sx={{mb: isError ? "38px" : "24px"}}
                //     error={isError}
                //     helperText={errors}
                //     id="outlined-multiline-static"
                //     label={label}
                //     multiline
                //     rows={8}
                //     defaultValue={value as any}
                //     value={value as any}
                //     onChange={onChange}
                // />
            );
        case "number":
            return (
                <ValueInputField
                    errors={errors}
                    border={false}
                    type={type}
                    style={{ width: "100%", background: "var(--main-purple-hover-color)" }}
                    label={label}
                    value={value as number}
                    hint={hint}
                    onChange={(e) => onChange(Number(e.target.value))}
                />
                // <FormTextField
                //     type="number"
                //     InputProps={{
                //         readOnly: rest.readOnly,
                //     }}
                //     // sx={{mb: isError ? "38px" : "24px"}}
                //     disabled={false}
                //     error={isError}
                //     id="standard-error-helper-text"
                //     label={label}
                //     helperText={errors}
                //     variant="standard"
                //     value={value as any}
                //     onChange={onChange}
                // />
            );
        case "checkbox":
            return (
                <CheckBoxField
                    label={label}
                    value={value as boolean}
                    onChange={onChange}
                    readOnly={rest.readOnly}
                />
            );
        case "multiple-checkboxes":
            return (
                <MultipleCheckBox
                    label={label}
                    value={value as string[]}
                    onChange={onChange}
                    options={rest.options as Array<string | OptionObjectType>}
                />
            )
        case "select":
            return (
                <SelectInputField
                    errors={errors}
                    border={false}
                    style={{ background: "var(--main-purple-hover-color)", height: "fit-content", width: "100%" }}
                    value={value as any}
                    label={label}
                    hint={hint}
                    items={rest.options as Option[]}
                    onChange={(value) => onChange(value)}
                />
                // <SelectField
                //     label={label}
                //     value={value as string}
                //     // sx={{mb: "24px"}}
                //     onChange={onChange}
                //     options={rest.options}
                //     errors={errors}
                //     readOnly={rest.readOnly}
                // />
            );
        case "file":
            return (
                <FileField
                    label="Upload File"
                    value={value}
                    onChange={(file: any) => onChange(file)}
                    errors={errors}
                />
            )
        // case "multiselect":
        //     return (
        //         <MultiSelect
        //             value={value}
        //             label="MultiSelect"
        //             rest={rest}
        //             options={rest.options}
        //             onChange={onChange}
        //             errors={errors}
        //             // {...field}
        //         />
        //     )
        default:
            return null;
    }
};

export default Input;
