import { Navigate, Outlet } from "react-router-dom";
import { FC, ReactElement, useContext } from "react";
import { AuthContext } from "../context/AuthContext";

type ProtectedRouteType = {
    permissions?: string[];
    isAllowed?: boolean;
    redirectPath?: string;
    children?: ReactElement | null;
};

const ProtectedRoute: FC<ProtectedRouteType> = ({ permissions = [], redirectPath = "/", children = null, isAllowed = false }) => {
    const { me, loading } = useContext(AuthContext);
    if (!loading && !permissions.includes(me.role)) {
        return <Navigate to={redirectPath} />;
    }

    return <>{children}</> || <Outlet />;
};

export default ProtectedRoute;
