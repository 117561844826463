import {useTranslation} from "react-i18next";
import {Box, Checkbox, FormControlLabel, FormGroup, Menu, Tooltip, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "axios";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import BASE_URL from "../../../../data/constants";
import FilterPanelButton from "../FilterPanelButton";
import {ReactComponent as CopyIcon} from "../../../../assets/images/icons/common/copyIcon.svg";
import {ReactComponent as WarningIcon} from "../../../../assets/images/icons/common/warning.svg";
import {Dictionary} from "../../../../pages/ClientsPage";
import {IAggregation, IMetric} from "../../../../pages/StatisticPage";
import Badges from "../TableFilterPanelStatistics/components/Badges";

interface IGrouping {
    request: Dictionary<any>;
    setRequest: (value: Dictionary<any>) => void;
    badges?: boolean;
}
const Grouping = ({ request, setRequest, badges = false }: IGrouping) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [groupList, setGroupList] = useState<IAggregation[]>([]);
    const { groups = {} } = request;

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleRemoveDisabled = (newMetrics: IMetric[], metricList: IAggregation[]) => {
        const disabledList: string[] = [];
        metricList.forEach((group) => {
            group.keys.forEach((option: IMetric) => {
                if (!option.is_enabled) {
                    disabledList.push(option.key);
                }
            });
        });
        // delete all IMetric in metrics array, that are in disabledList
        const updatedMetrics = newMetrics.filter((metric: IMetric) => !disabledList.includes(metric.key));
        if (newMetrics.length !== updatedMetrics.length) {
            setRequest({
                ...request,
                groups: updatedMetrics,
            });
        }
    };

    const handleSendGroups = async (data = {}) => {
        try {
            const res = await axios.post(`${BASE_URL}/report/groups/`, data);
            return await res.data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    useEffect(() => {
        (async () => {
            const res = await handleSendGroups({
                groups,
                metrics: request.metrics,
            });
            setGroupList(res);
            handleRemoveDisabled(groups, res);
        })();
    }, [request.metrics]);

    useEffect(() => {
        handleChooseParam({
            key: "date__month",
            name: t("month"),
            is_enabled: true,
        });
    }, []);

    const handleChooseParam = async (group: IMetric) => {
        let newGroup: IMetric[];
        if (groups.findIndex((item: IMetric) => item.key === group.key) !== -1) {
            newGroup = groups.filter((item: IMetric) => item.key !== group.key);
        } else {
            newGroup = [...groups, group];
        }
        setRequest({
            ...request,
            groups: newGroup,
        });
        const newGroupList = await handleSendGroups({
            groups: newGroup,
            metrics: request.metrics,
        });
        setGroupList(newGroupList);
    };
    return (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                alignItems: "flex-start",
            }}
        >
            <FilterPanelButton Icon={<CopyIcon />} active={Boolean(anchorEl)} onClick={handleMenuOpen} text="grouping" />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                    mt: ".125rem",
                    ".MuiMenu-paper": {
                        p: ".75rem",
                        width: "min-content",
                    },
                    "& .MuiPaper-root": {
                        borderRadius: "0",
                    },
                    ".MuiMenu-list": {
                        p: "0",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        width: "100%",
                    },
                }}
            >
                <FormGroup
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, auto)",
                        gap: "0.75rem",
                        boxSizing: "border-box",
                    }}
                >
                    {groupList.length > 0 &&
                        groupList.map((group, index) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "600",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {group.label}
                                </Typography>
                                {group.keys.length > 0 &&
                                    group.keys.map((item, index) => (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        checked={groups.findIndex((activeGroup: IMetric) => activeGroup.key === item.key) !== -1}
                                                        disabled={!item.is_enabled}
                                                        onChange={() => handleChooseParam(item)}
                                                    />
                                                }
                                                label={`${item.name}`}
                                            />
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {!item.is_enabled && (
                                                    <Tooltip title={t("cant_select_together_warning")}>
                                                        <WarningIcon />
                                                    </Tooltip>
                                                )}
                                                <Tooltip title={item.hint || ""}>
                                                    <HelpOutlineIcon />
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    ))}
                            </Box>
                        ))}
                </FormGroup>
            </Menu>
            {badges && <Badges filterGroup={groups} removeBadge={handleChooseParam} keyField="key" request={request} setRequest={setRequest} field="groups" />}
        </Box>
    );
};

export default Grouping;
