import axios from "axios";

export const getReferrals = async (data = {}, signal: any = null) => {
    try {
        const res = await axios.post("/users/referrals/", data, { signal });
        return await res.data;
    } catch (err: any) {
        console.error("Error");
        throw err;
    }
};

export default getReferrals;
