import { Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClientCreateContext } from "../../../../../../context/ClientCreateContext";
import ContractForm from "../../../../components/ClientCreateForm/ContractForm";

const ContractCreateController = () => {
    const { contract, setContract, paymentDetails, setPaymentDetails, currencies } = useContext(ClientCreateContext);
    const { t } = useTranslation();
    return (
        <>
            <Typography
                sx={{
                    fontSize: "1.25rem",
                    fontWeight: "500",
                    mb: "3rem",
                }}
            >
                {t("client_create.contract")}
            </Typography>
            <ContractForm contract={contract} setContract={setContract} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} currencies={currencies} />
        </>
    );
};

export default ContractCreateController;
