import { AppBar, Toolbar } from "@mui/material";
import { ReactNode } from "react";

type Props = {
    children: ReactNode;
};
const TopBarLayout = ({ children }: Props) => (
    <AppBar
        position="relative"
        sx={{
            position: "sticky",
            top: "0",
            zIndex: 123,
            width: "100%",
            backgroundColor: "#fff",
            color: "var(--main-black-color)",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
        }}
    >
        <Toolbar
            sx={{
                minHeight: { xs: "60px" },
                p: "9px .75rem",
                boxSizing: "border-box",
                justifyContent: "space-between",
            }}
        >
            {children}
        </Toolbar>
    </AppBar>
);
export default TopBarLayout;
