import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { AxiosError, isAxiosError } from "axios";
import CustomButton from "../../../../../../UI/Buttons/CustomButton";
import { TableDataType } from "../../../../../../components/TableConstructor/types";
import ContentContainer from "../../../../../../components/ContentContainer/ContentContainer";
import { Dictionary } from "../../../../../ClientsPage";
import { getBonuses } from "../../../../services/fetchers";
import { TopBarContext } from "../../../../../../context/TopBarContext";
import CloseButton from "../../../../../../UI/Buttons/Actions/CloseButton";
import { initialTableData } from "../../../../../../components/TableConstructor/constants";
import TableConstructor from "../../../../../../components/TableConstructor";
import { AuthContext } from "../../../../../../context/AuthContext";

const BonusesPage = () => {
    const { setNotFound } = useContext(AuthContext);
    const { handleSetActions } = useContext(TopBarContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState<Dictionary<any>>({
        periods: {
            date_from: dayjs().startOf("month"),
            date_to: dayjs().add(-1, "day"),
            currency: "",
        },
    });
    const [tableData, setTableData] = useState<TableDataType>(initialTableData);
    const { id } = useParams();

    useEffect(() => {
        handleSetActions([<CloseButton path="/users/managers" />]);
    }, []);

    const loadBonuses = async (signal: any = null) => {
        setLoading(true);
        const data = {
            ...request,
            filters: request.filters || {},
        };
        try {
            const res = await getBonuses(data, id, signal);
            setTableData(res);
        } catch (err: Error | AxiosError | unknown) {
            if (isAxiosError(err)) {
                if (err.response?.status === 404) {
                    setNotFound(true);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        (async () => {
            if (id) {
                await loadBonuses(controller.signal);
            }
        })();
        return () => controller.abort();
    }, [request]);

    const handleCreateBank = () => navigate(`/users/managers/${id}/bonus/create`);

    return (
        <ContentContainer>
            <TableConstructor
                tableData={tableData}
                loading={loading}
                request={request}
                setRequest={setRequest}
                ActionButton={
                    <CustomButton
                        Icon={
                            <AddIcon
                                style={{
                                    color: "#fff",
                                    width: "1.25rem",
                                    height: "1.25rem",
                                    marginRight: ".5rem",
                                }}
                            />
                        }
                        label="button.add"
                        onChange={handleCreateBank}
                    />
                }
            />
        </ContentContainer>
    );
};

export default BonusesPage;
