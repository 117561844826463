import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosError, isAxiosError } from "axios";
import ContentContainer from "../../../../../../../../components/ContentContainer/ContentContainer";
import Form from "../../../../components/Form";
import { SystemType } from "../../../../types";
import { AuthContext } from "../../../../../../../../context/AuthContext";
import { editEntry, getEntry } from "../../../../services/fetchers";
import { TopBarContext } from "../../../../../../../../context/TopBarContext";
import EditButton from "../../../../../../../../UI/Buttons/Actions/EditButton";
import CloseButton from "../../../../../../../../UI/Buttons/Actions/CloseButton";

export const initialSystem = {
    name: "",
    name_of_field: "",
};

const SystemEdit = () => {
    const { setNotification, setNotFound } = useContext(AuthContext);
    const [system, setSystem] = useState<SystemType>(initialSystem);
    const { id } = useParams();
    const { handleChangePath, handleSetActions } = useContext(TopBarContext);
    useEffect(() => {
        handleSetActions([<EditButton action={handleSubmit} />, <CloseButton path="/properties/system" />]);
    }, [system]);

    useEffect(() => {
        if (id) {
            (async () => {
                try {
                    const res: any = await Promise.all([getEntry(id)]);
                    setSystem({
                        ...res[0],
                        vat_value: Number(res[0].vat_value).toFixed(1),
                    });
                    handleChangePath(["partners", res[0].name]);
                } catch (err: Error | AxiosError | unknown) {
                    if (isAxiosError(err)) {
                        if (err.response?.status === 404) {
                            setNotFound(true);
                        }
                    }
                }
            })();
        }
    }, [id]);

    const handleSubmit = async () => {
        try {
            const res = await editEntry(id, system);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.message,
                type: "error",
            });
        }
    };

    return (
        <ContentContainer>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Form system={system} setSystem={setSystem} />
            </Box>
        </ContentContainer>
    );
};

export default SystemEdit;
