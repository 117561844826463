import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import axios, { AxiosError, isAxiosError } from "axios";
import { initialClientInfo, initialSystems } from "../../../../../../../../context/ClientCreateContext";
import ContentContainer from "../../../../../../../../components/ContentContainer/ContentContainer";
import { frontendSystems } from "../../../../../../services/parsers";
import BASE_URL from "../../../../../../../../data/constants";
import { ClientInfo, SystemType } from "../../../../../../types";
import ConditionForm from "../../../../../../components/ClientCreateForm/ConditionForm";
import { getClientInfo } from "../../../../../../services/fetchers";
import CloseButton from "../../../../../../../../UI/Buttons/Actions/CloseButton";
import { TopBarContext } from "../../../../../../../../context/TopBarContext";
import { AuthContext } from "../../../../../../../../context/AuthContext";

const FeesViewPage = () => {
    const { setNotFound } = useContext(AuthContext);
    const { handleSetActions, handleChangePath, handleChangeTabs } = useContext(TopBarContext);
    const [systems, setSystems] = useState<SystemType[]>(initialSystems);
    const { id, conditionId } = useParams();
    const [clientInfo, setClientInfo] = useState<ClientInfo>(initialClientInfo);

    useEffect(() => {
        const labelItems = [clientInfo.name, "Fees"];
        handleChangePath(labelItems);
        handleSetActions([<CloseButton path={`/clients/${id}/conditions`} />]);
        handleChangeTabs([]);
    }, [clientInfo.name]);

    useEffect(() => {
        (async () => {
            const data = await getClientInfo(id);
            setClientInfo(data);
        })();
    }, [id]);

    const getCondition = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/${id}/conditions/${conditionId}/`);
            return await res.data;
        } catch (err: Error | AxiosError | unknown) {
            if (isAxiosError(err)) {
                if (err.response?.status === 404) {
                    setNotFound(true);
                }
            }
            return err;
        }
    };

    useEffect(() => {
        if (conditionId) {
            (async () => {
                const data = await getCondition();
                setSystems([frontendSystems(data)]);
            })();
        }
    }, [conditionId]);

    return (
        <ContentContainer>
            <Box
                sx={{
                    p: "3rem",
                    background: "#fff",
                    maxWidth: "740px",
                }}
            >
                <ConditionForm readOnly systems={systems} setSystems={setSystems} isAddMore={false} />
            </Box>
        </ContentContainer>
    );
};

export default FeesViewPage;
