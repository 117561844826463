import {Box} from "@mui/material";
import React, {FC} from "react";
import DatePickerCustom from "../../../../UI/Inputs/DatePicker";
import {handleChangeField} from "../../../../pages/ClientsPage/services/helpers";
import {Dictionary} from "../../../../pages/ClientsPage";
import { ReactComponent as PeriodIcon } from "../../../../assets/images/icons/common/periodIcon.svg";


type PeriodYearMonthType = {
    request: Dictionary<any>;
    setRequest: (value: Dictionary<any>) => void;
}

const PeriodYearMonth: FC<PeriodYearMonthType> = ({request, setRequest}) => (
    <Box sx={{ display: "flex" }}>
        <Box
            sx={{
                display: "flex",
                gap: "12px",
                alignItems: "center",
                p: "6px 8px",
                fontWeight: 500,
            }}
        >
            <PeriodIcon />
            Period
        </Box>
        <DatePickerCustom
            openTo="month"
            views={["year", "month"]}
            value={request.date}
            onChange={(newValue) => handleChangeField("date", newValue, request, setRequest)}
            style={{ backgroundColor: "white", width: "224px" }}
            disableBorders
        />
    </Box>
)

export default PeriodYearMonth;
