import React from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddBadge from "../../../../../components/Badges/AddBadge";
import RemoveBadge from "../../../../../components/Badges/RemoveBadge";
import ValueInputField from "../../../../../UI/Inputs/ValueInputField";
import { ContactPersonType } from "../../../types";
import { ReactComponent as PhoneIcon } from "../../../../../assets/images/icons/common/phoneIcon.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/images/icons/common/editIcon.svg";
import { ReactComponent as LinkIcon } from "../../../../../assets/images/icons/client/linkIcon.svg";
import { ReactComponent as TelegramIcon } from "../../../../../assets/images/icons/common/telegramIcon.svg";
import { ReactComponent as PersonIcon } from "../../../../../assets/images/icons/sidebar/profile.svg";
import { ReactComponent as EmailIcon } from "../../../../../assets/images/icons/client/emailIcon.svg";
import { ReactComponent as BusinessCenterIcon } from "../../../../../assets/images/icons/client/positionIcon.svg";

interface IContactForm {
    readOnly?: boolean;
    isAddMore?: boolean;
    contactPeople: ContactPersonType[];
    setContactPeople: (contactPeople: ContactPersonType[]) => void;
}

const ContactsForm = ({ contactPeople = [], setContactPeople = () => {}, isAddMore = true, readOnly = false }: IContactForm) => {
    const { t } = useTranslation();
    const handleAddContact = () => {
        const newContacts = [
            ...(contactPeople as []),
            {
                fieldId: `id${Math.random().toString(16).slice(2)}`,
                fullName: "",
                email: "",
                position: "",
                telegramLogin: "",
                chatId: "",
                note: "",
                telegramGroupUrl: "",
                phoneNumber: "",
                mainContact: false,
            },
        ];
        setContactPeople(newContacts);
    };
    const handleRemoveContact = (fieldId: string) => {
        const newContacts = (contactPeople as ContactPersonType[]).filter((contact) => contact.fieldId !== fieldId);
        setContactPeople(newContacts);
    };
    const handleChangeContact = (fieldId: string, fieldKey: string, value: string | boolean | null) => {
        const newContacts = (contactPeople as ContactPersonType[]).map((contact) => {
            if (contact.fieldId === fieldId) {
                return {
                    ...contact,
                    [fieldKey]: value,
                };
            }
            return contact;
        });
        setContactPeople(newContacts);
    };
    return (
        <>
            {contactPeople &&
                contactPeople.length > 0 &&
                contactPeople.map((contact, index: number) => (
                    <Box key={index}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "2px solid rgba(0, 0, 0, 0.20)",
                                height: "50px",
                                alignItems: "center",
                                mb: "1rem",
                                maxWidth: "640px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                    color: "#1A1F27",
                                }}
                            >
                                {t("client_create.contact")} {index + 1}
                            </Typography>
                            {isAddMore &&
                                (index === 0 ? (
                                    <Box onClick={() => !readOnly && handleAddContact()}>
                                        <AddBadge />
                                    </Box>
                                ) : (
                                    <Box onClick={() => !readOnly && handleRemoveContact(contact.fieldId)}>
                                        <RemoveBadge />
                                    </Box>
                                ))}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                gap: "1rem",
                                mb: "1rem",
                                flexWrap: "wrap",
                                maxWidth: "740px",
                            }}
                        >
                            <ValueInputField
                                readOnly={readOnly}
                                Icon={<PersonIcon style={{ color: "#000" }} />}
                                label={t("forms.common.full_name")}
                                value={contact?.fullName || ""}
                                onChange={(e) => handleChangeContact(contact.fieldId, "fullName", e.target.value)}
                            />
                            <ValueInputField
                                readOnly={readOnly}
                                Icon={<EmailIcon style={{ color: "#000" }} />}
                                label={t("forms.common.e-mail")}
                                value={contact?.email || ""}
                                onChange={(e) => handleChangeContact(contact.fieldId, "email", e.target.value)}
                            />
                            <ValueInputField
                                readOnly={readOnly}
                                Icon={<BusinessCenterIcon style={{ color: "#000" }} />}
                                label={t("forms.common.position")}
                                value={contact?.position || ""}
                                onChange={(e) => handleChangeContact(contact.fieldId, "position", e.target.value)}
                            />
                            <ValueInputField
                                readOnly={readOnly}
                                Icon={<TelegramIcon style={{ color: "#000" }} />}
                                label={t("forms.common.tg")}
                                value={contact?.telegramLogin || ""}
                                onChange={(e) => handleChangeContact(contact.fieldId, "telegramLogin", e.target.value)}
                            />
                            <ValueInputField
                                readOnly={readOnly}
                                Icon={<TelegramIcon style={{ color: "#000" }} />}
                                label={t("forms.contacts.chat_id")}
                                value={contact?.chatId || ""}
                                onChange={(e) => handleChangeContact(contact.fieldId, "chatId", e.target.value)}
                            />
                            <ValueInputField
                                readOnly={readOnly}
                                Icon={<LinkIcon style={{ color: "#000" }} />}
                                label={t("forms.contacts.tg_channel")}
                                value={contact?.telegramGroupUrl || ""}
                                onChange={(e) => handleChangeContact(contact.fieldId, "telegramGroupUrl", e.target.value)}
                            />
                            <ValueInputField
                                readOnly={readOnly}
                                Icon={<PhoneIcon style={{ color: "#000" }} />}
                                label={t("forms.common.phone")}
                                value={contact?.phoneNumber || ""}
                                onChange={(e) => handleChangeContact(contact.fieldId, "phoneNumber", e.target.value)}
                            />
                            <ValueInputField
                                readOnly={readOnly}
                                Icon={<EditIcon style={{ color: "#000" }} />}
                                label={t("forms.contacts.note")}
                                value={contact?.note || ""}
                                onChange={(e) => handleChangeContact(contact.fieldId, "note", e.target.value)}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={contact?.mainContact || false} readOnly={readOnly} />}
                                label={t("forms.contacts.main_contact")}
                                onChange={(e) => !readOnly && handleChangeContact(contact.fieldId, "mainContact", !contact?.mainContact)}
                            />
                        </Box>
                    </Box>
                ))}
        </>
    );
};

export default ContactsForm;
