import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import axios, { AxiosError, isAxiosError } from "axios";
import { AuthContext } from "../../../../context/AuthContext";
import { getClientLLPList } from "../../../ClientsPage/services/fetchers";
import BASE_URL from "../../../../data/constants";
import ContentContainer from "../../../../components/ContentContainer/ContentContainer";
import ValueInputField from "../../../../UI/Inputs/ValueInputField";
import { handleChangeField } from "../../../ClientsPage/services/helpers";
import { TopBarContext } from "../../../../context/TopBarContext";
import EditButton from "../../../../UI/Buttons/Actions/EditButton";
import CloseButton from "../../../../UI/Buttons/Actions/CloseButton";

const PlacementEdit = () => {
    const { handleSetActions, handleChangePath, handleChangeTabs } = useContext(TopBarContext);
    const { setNotification, setNotFound } = useContext(AuthContext);
    const { id } = useParams();
    const [clientList, setClientList] = useState([]);
    const [newPlacement, setNewPlacement] = useState<any>({});
    const navigate = useNavigate();

    useEffect(() => {
        const labelItems = ["new_placements"];
        handleChangePath(labelItems);
        handleSetActions([<EditButton action={handleEditGoogleDeduction} />, <CloseButton path="/new_placements" />]);
        handleChangeTabs([]);
    }, [newPlacement]);

    useEffect(() => {
        (async () => setClientList(await getClientLLPList()))();
    }, []);

    const getGoogleDeduction = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/properties/new_placements/${id}/`);
            return await res.data;
        } catch (err: Error | AxiosError | unknown) {
            if (isAxiosError(err)) {
                if (err.response?.status === 404) {
                    setNotFound(true);
                }
            }
            return null;
        }
    };

    useEffect(() => {
        if (id) {
            (async () => {
                const data = await getGoogleDeduction();
                setNewPlacement(data);
            })();
        }
    }, [id]);

    const handleEditGoogleDeduction = async () => {
        try {
            const res = await axios.post(`${BASE_URL}/properties/new_placements/${id}/edit/`, newPlacement);
            handleGoBack();
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        }
    };

    const handleGoBack = () => navigate("/new_placements");

    return (
        <ContentContainer>
            <Box
                sx={{
                    p: "3rem",
                    background: "#fff",
                    width: "fit-content",
                    maxWidth: "740px",
                    display: "flex",
                    flexDirection: "row",
                    gap: ".75rem",
                }}
            >
                <ValueInputField readOnly label="forms.placement.name" value={newPlacement.network_code} />
                <Autocomplete
                    value={newPlacement.client}
                    color="warning"
                    disablePortal
                    id="combo-box-demo"
                    options={clientList}
                    sx={{ width: 312 }}
                    onChange={(e, value) => handleChangeField("client", value, newPlacement, setNewPlacement)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                sx: {
                                    borderRadius: "0",
                                },
                            }}
                            color="warning"
                            label="Select a client"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                />
            </Box>
        </ContentContainer>
    );
};

export default PlacementEdit;
