import axios, { AxiosError, isAxiosError } from "axios";
import BASE_URL from "../../../data/constants";

export const getCurrencies = async () => {
    try {
        const res = await axios.get(`${BASE_URL}/properties/currency_list/`);
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};
export const getManagers = async () => {
    try {
        const res = await axios.get(`${BASE_URL}/properties/manager_list/`);
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getUserList = async () => {
    try {
        const res = await axios.get(`${BASE_URL}/properties/user_list/`);
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};
export const getTransferTypes = async () => {
    try {
        const res = await axios.get(`${BASE_URL}/properties/payment_method_list/`);
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};
export const getSystemList = async () => {
    try {
        const res = await axios.get(`${BASE_URL}/properties/system_list/`);
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};
export const getPlatforms = async () => {
    try {
        const res = await axios.get(`${BASE_URL}/properties/placement_list/`);
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getClientLLPList = async () => {
    try {
        const res = await axios.get(`${BASE_URL}/properties/client_list/`);
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getCountryList = async () => {
    try {
        const res = await axios.get(`${BASE_URL}/properties/country_list/`);
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getBankList = async () => {
    try {
        const res = await axios.get(`${BASE_URL}/properties/bank_list/`);
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getLegalEntityList = async () => {
    try {
        const res = await axios.get(`${BASE_URL}/properties/llp_list/`);
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getClients = async (data: any, signal: any) => {
    try {
        const res = await axios.post(`${BASE_URL}/clients/`, data, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getPaymentDetails = async (id: number | string, data: any, signal: any) => {
    try {
        const res = await axios.post(`${BASE_URL}/clients/${id}/payment_details/`, data, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};
export const getConditions = async (id: number | string | undefined, data: any, signal: any) => {
    try {
        const res = await axios.post(`${BASE_URL}/clients/${id}/conditions/`, data, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getContacts = async (id: number | string, data: any, signal: any) => {
    try {
        const res = await axios.post(`${BASE_URL}/clients/${id}/contacts/`, data, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getContract = async (id: number | string, data: any, signal: any) => {
    try {
        const res = await axios.post(`${BASE_URL}/clients/${id}/contracts/`, data, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getInvoices = async (id: number, data: any, signal: any) => {
    try {
        const res = await axios.post(`${BASE_URL}/clients/${id}/invoices/`, data, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getClientPlatforms = async (id: number, data: any, signal: any) => {
    try {
        const res = await axios.post(`${BASE_URL}/clients/${id}/platforms/`, data);
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getPlatformBlocks = async (id: number, platformId: number, data: any, signal: any) => {
    try {
        const res = await axios.post(`${BASE_URL}/clients/${id}/platforms/${platformId}/blocks/`, data, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const deleteClient = async (pathname: string) => {
    try {
        const res = await axios.get(`${BASE_URL}${pathname}/delete/`);
        return await res.data;
    } catch (e: any) {
        throw new Error(e.response.data.message);
    }
};

export const getClientInfo = async (id: number | string | undefined) => {
    try {
        const res = await axios.get(`${BASE_URL}/clients/${id}/info/`);
        return await res.data;
    } catch (err: Error | AxiosError | unknown) {
        return err;
    }
};

export const getPositionList = async () => {
    try {
        const res = await axios.get(`${BASE_URL}/properties/position_list/`);
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};
