import { createContext, ReactNode, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ClientInfo, ClientType, ContactPersonType, Contract, PaymentDetailsType, PerfectSolutionAccountType, Platform, SystemType } from "../../pages/ClientsPage/types";
import { getCurrencies } from "../../pages/ClientsPage/services/fetchers";
import { generateId } from "../../utils/helpers";
import { initialFounder } from "../../data/initialData";

type Steps = {
    step: number;
    label: string;
};
const originalSteps: Steps[] = [
    {
        step: 1,
        label: "client_create.client",
    },
    {
        step: 2,
        label: "client_create.account",
    },
    {
        step: 3,
        label: "client_create.payment",
    },
    {
        step: 4,
        label: "client_create.contact",
    },
    {
        step: 5,
        label: "client_create.contract",
    },
    {
        step: 6,
        label: "client_create.condition",
    },
    {
        step: 7,
        label: "client_create.platforms",
    },
];

type Props = {
    draftData: any;
    children: ReactNode;
};
export type Previous = {
    value: PaymentDetailsType | {};
};
export type ClientCreateContextType = {
    steps: Steps[];
    step: number;
    setStep: (step: (prevStep: number) => number) => void;
    formData: any;
    setFormData: (formData: any) => void;
    client: ClientType;
    setClient: (value: ClientType) => void;
    clientAccount: PerfectSolutionAccountType;
    setClientAccount: (value: PerfectSolutionAccountType) => void;
    paymentDetails: PaymentDetailsType;
    setPaymentDetails: (value: PaymentDetailsType) => void;
    countries: string[];
    setCountries: (value: string[]) => void;
    contactPeople: ContactPersonType[];
    setContactPeople: (value: ContactPersonType[]) => void;
    contract: Contract;
    setContract: (value: Contract) => void;
    currencies: string[];
    setCurrencies: (value: string[]) => void;
    systems: SystemType[];
    setSystems: (value: SystemType[]) => void;
    platforms: Platform[];
    setPlatforms: (value: Platform[]) => void;
};

export const ClientCreateContext = createContext<ClientCreateContextType>({} as ClientCreateContextType);

const initialFormData = {
    client: {
        companyName: "",
        companyType: "",
        accountManagers: [
            {
                fieldId: `id${Math.random().toString(16).slice(2)}`,
                account: "",
                startDate: null,
            },
        ],
        salesManagers: [
            {
                account: "",
                startDate: null,
            },
        ],
    },
    clientAccount: {
        username: "",
    },
    paymentDetails: {
        contractCurrency: "",
        type: "",
        transferMethod: {},
    },
    contactPeople: [
        {
            full_name: "",
            email: "",
            position: "",
            telegramLogin: "",
            telegramGroupUrl: "",
            phoneNumber: "",
            mainContact: false,
        },
    ],
    contract: {
        clientContractType: "",
    },
    systems: [
        {
            systemName: "",
            accountId: 0,
            accountCurrency: "",
            moneyReceiver: "",
            isBonus: false,
            perfectSolutionCommission: "",
        },
    ],
    platforms: [],
};
export const initialClient: ClientType = {
    companyName: "",
    companyType: "Legal Entity",
    accountManagers: [
        {
            fieldId: `id${Math.random().toString(16).slice(2)}`,
            account: "",
            startDate: null,
        },
    ],
    salesManagers: [
        {
            fieldId: `id${Math.random().toString(16).slice(2)}`,
            account: "",
            startDate: null,
        },
    ],
};
export const initialClientInfo: ClientInfo = {
    id: "",
    name: "",
    organization_type: "",
};
const initialAccount: PerfectSolutionAccountType = {
    username: "",
    client_user_is_create: false,
};
export const initialPaymentDetails: PaymentDetailsType = {
    contractCurrency: "",
    type: "",
    startDate: null,
    founders: initialFounder,
};
export const initialContactPeople: ContactPersonType[] = [
    {
        fieldId: generateId(),
        fullName: "",
        email: "",
        position: "",
        telegramLogin: "",
        chatId: "",
        telegramGroupUrl: "",
        phoneNumber: "",
        note: "",
        mainContact: false,
    },
];
export const initialContract: Contract = {
    clientContractType: "",
    startContractDate: null,
};
export const initialSystems: SystemType[] = [
    {
        fieldId: `id${Math.random().toString(16).slice(2)}`,
        systemName: "",
        moneyReceiver: "",
        date_from: null,
        isBonus: "",
        typeOfAgencyCommission: "",
    },
];
export const initialPlatforms: Platform[] = [
    {
        fieldId: `id${Math.random().toString(16).slice(2)}`,
        name: "",
    },
];
const ClientCreateProvider = ({ draftData, children }: Props) => {
    const [searchParams] = useSearchParams();
    const [step, setStep] = useState(Number(searchParams.get("step")) || 1);
    const [currencies, setCurrencies] = useState([] as string[]);
    const [formData, setFormData] = useState(initialFormData);
    const [client, setClient] = useState(initialClient);
    const [clientAccount, setClientAccount] = useState<PerfectSolutionAccountType>(initialAccount);
    const [paymentDetails, setPaymentDetails] = useState(initialPaymentDetails);
    const [contactPeople, setContactPeople] = useState(initialContactPeople);
    const [contract, setContract] = useState(initialContract);
    const [systems, setSystems] = useState(initialSystems);
    const [platforms, setPlatforms] = useState(initialPlatforms);
    const [countries, setCountries] = useState([] as string[]);
    const [steps, setSteps] = useState(originalSteps);

    useEffect(() => {
        if (client.companyType === "Bonus" || client.companyType === "Piggy bank") {
            const filteredSteps = originalSteps.filter((item) => item.step !== 2 && item.step !== 3 && item.step !== 5 && item.step !== 6);
            setSteps(filteredSteps);
            return;
        }
        setSteps(originalSteps);
    }, [client.companyType]);

    useEffect(() => {
        (async () => setCurrencies(await getCurrencies()))();
    }, []);

    useEffect(() => {
        if (draftData && Object.keys(draftData).length > 0) {
            setClient(draftData.client);
            setClientAccount(draftData.clientAccount);
            setPaymentDetails(draftData.paymentDetails);
            setContactPeople(draftData.contactPeople);
            setContract(draftData.contract);
            setSystems(draftData.systems);
            setPlatforms(draftData.platforms);
        }
    }, [draftData]);
    return (
        <ClientCreateContext.Provider
            value={
                {
                    step,
                    setStep,
                    formData,
                    setFormData,
                    client,
                    setClient,
                    clientAccount,
                    setClientAccount,
                    paymentDetails,
                    setPaymentDetails,
                    contactPeople,
                    setContactPeople,
                    contract,
                    setContract,
                    systems,
                    setSystems,
                    platforms,
                    setPlatforms,
                    countries,
                    setCountries,
                    currencies,
                    setCurrencies,
                    steps,
                } as ClientCreateContextType
            }
        >
            {children}
        </ClientCreateContext.Provider>
    );
};

export default ClientCreateProvider;
