import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import axios, { AxiosError, isAxiosError } from "axios";
import { useParams } from "react-router-dom";
import ContentContainer from "../../../../../../components/ContentContainer/ContentContainer";
import ManagerForm from "../../../../components/ManagerForm";
import { ManagerType } from "../../../../types";
import { AuthContext } from "../../../../../../context/AuthContext";
import { TopBarContext } from "../../../../../../context/TopBarContext";
import EditButton from "../../../../../../UI/Buttons/Actions/EditButton";
import CloseButton from "../../../../../../UI/Buttons/Actions/CloseButton";

export const initialManager = {
    username: "",
    first_name: "",
    last_name: "",
    tg_login: "",
    phone_number: "",
    email: "",
    position: "",
};

const ManagerEdit = () => {
    const { setNotification, setNotFound } = useContext(AuthContext);
    const [manager, setManager] = useState<ManagerType>(initialManager);
    const { id } = useParams();

    const getLlp = async () => {
        try {
            const res = await axios.get(`/users/managers/${id}/`);
            return await res.data;
        } catch (err: Error | AxiosError | unknown) {
            if (isAxiosError(err)) {
                if (err.response?.status === 404) {
                    setNotFound(true);
                }
            }
            return null;
        }
    };

    const { handleChangePath, handleSetActions } = useContext(TopBarContext);
    useEffect(() => {
        handleSetActions([<EditButton action={handleSubmit} />, <CloseButton path="/users/managers" />]);
    }, [manager]);

    useEffect(() => {
        if (id) {
            (async () => {
                const res: any = await Promise.all([getLlp()]);
                setManager({
                    ...res[0],
                });
                handleChangePath([`${res[0].last_name} ${res[0].first_name}`]);
            })();
        }
    }, [id]);

    const handleSubmit = async () => {
        try {
            const res = await axios.post(`/users/managers/${id}/edit/`, manager);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.message,
                type: "error",
            });
        }
    };

    return (
        <ContentContainer>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <ManagerForm manager={manager} setManager={setManager} readonlyUsername />
            </Box>
        </ContentContainer>
    );
};

export default ManagerEdit;
