import React, { useContext, useEffect, useState } from "react";
import axios, { AxiosError, isAxiosError } from "axios";
import { useParams } from "react-router-dom";
import BankForm from "../../components/BanksForm";
import { AuthContext } from "../../../../../../../../../../context/AuthContext";
import ContentContainer from "../../../../../../../../../../components/ContentContainer/ContentContainer";
import { getBank } from "../../../../../../services/fetchers";
import { TopBarContext } from "../../../../../../../../../../context/TopBarContext";
import CloseButton from "../../../../../../../../../../UI/Buttons/Actions/CloseButton";
import EditButton from "../../../../../../../../../../UI/Buttons/Actions/EditButton";

export type BankType = {
    name: string;
    is_active: boolean;
};

export const initialBank = {
    name: "",
    is_active: false,
};

const BanksEdit = () => {
    const { setNotification, setNotFound } = useContext(AuthContext);
    const [bank, setBank] = useState<BankType>(initialBank);
    const { id, bankId } = useParams();

    const { handleSetActions, handleAppendPath } = useContext(TopBarContext);
    useEffect(() => {
        handleSetActions([<EditButton action={handleSubmitBonus} />, <CloseButton path={`/properties/llp/${id}/edit`} />]);
    }, [bank]);

    useEffect(() => {
        if (id && bankId) {
            (async () => {
                try {
                    setBank(await getBank(id, bankId));
                } catch (err: Error | AxiosError | unknown) {
                    if (isAxiosError(err)) {
                        if (err.response?.status === 404) {
                            setNotFound(true);
                        }
                    }
                }
            })();
        }
        handleAppendPath(`${bank.name}`);
    }, [id, bankId]);

    const handleSubmitBonus = async () => {
        try {
            const res = await axios.post(`/properties/llp/${id}/banks/${bankId}/edit/`, bank);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.message,
                type: "error",
            });
        }
    };

    return (
        <ContentContainer>
            <BankForm bank={bank} setBank={setBank} />
        </ContentContainer>
    );
};

export default BanksEdit;
