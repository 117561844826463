import { ReactElement, ReactNode, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

type Props = {
    state?: string;
    children: ReactNode;
};

const PageWrapper = ({ state = "", children }: Props) => {
    const { setAppState } = useContext(AuthContext);
    useEffect(() => {
        if (state) {
            setAppState(state);
        }
    }, [state, children]);
    return <>{children}</>;
};

export default PageWrapper;
