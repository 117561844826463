import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import "./assets/styles/index.scss";
import AuthProvider from "./context/AuthContext";
import ErrorBoundary from "./classes/ErrorBoundary";
import Error from "./components/Errors/Error";

import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Suspense fallback={<div>Loading...</div>}>
        <AuthProvider>
            <ErrorBoundary fallback={<Error />}>
                <App />
            </ErrorBoundary>
        </AuthProvider>
    </Suspense>
);

reportWebVitals();
