import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import ValueInputField from "../../../../../../UI/Inputs/ValueInputField";
import { handleChangeField } from "../../../../../ClientsPage/services/helpers";
import { ReactComponent as ProfileIcon } from "../../../../../../assets/images/icons/sidebar/profile.svg";
import SelectInputField from "../../../../../../UI/Inputs/SelectInputField";
import { ReactComponent as MoneyIcon } from "../../../../../../assets/images/icons/common/moneyIcon.svg";
import { LegalEntityType } from "../../types";
import { getCountryList } from "../../../../../ClientsPage/services/fetchers";

type LegalEntityFormType = {
    llp: LegalEntityType;
    setLlp: (llp: LegalEntityType) => void;
};

const convertationMethods = [
    {
        name: "Central Bank RF",
        value: "ЦБ РФ",
    },
    {
        name: "Central Bank KZ",
        value: "ЦБ КЗ",
    },
];

const LegalEntityForm: React.FC<LegalEntityFormType> = ({ llp, setLlp }) => {
    const [countries, setCountries] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            const res = await Promise.all([getCountryList()]);
            setCountries(res[0]);
        })();
    }, []);
    return (
        <Box
            sx={{
                p: "3rem",
                background: "#fff",
                maxWidth: "736px",
                gap: "1rem",
                display: "flex",
                flexWrap: "wrap",
            }}
        >
            <ValueInputField label="forms.partners.supplier.name" value={llp.name} Icon={<ProfileIcon />} onChange={(e) => handleChangeField("name", e.target.value, llp, setLlp)} />
            <SelectInputField value={llp.country} label="forms.partners.supplier.country" items={countries} onChange={(value) => handleChangeField("country", value, llp, setLlp)} />
            <ValueInputField
                label="forms.partners.supplier.VAT"
                value={llp.vat_value}
                type="number"
                Icon={<MoneyIcon />}
                onChange={(e) => handleChangeField("vat_value", Number(e.target.value), llp, setLlp)}
            />
            <ValueInputField label="forms.partners.supplier.BIN" value={llp.business_id} Icon={<MoneyIcon />} onChange={(e) => handleChangeField("business_id", e.target.value, llp, setLlp)} />
            <SelectInputField
                value={llp.convertation_method}
                label="forms.partners.supplier.er"
                items={convertationMethods}
                onChange={(value) => handleChangeField("convertation_method", value, llp, setLlp)}
            />
            <FormControlLabel
                sx={{ width: "100%" }}
                control={
                    <Checkbox
                        sx={{
                            "&.Mui-checked": {
                                color: "var(--main-black-color)",
                            },
                        }}
                        checked={llp.is_vat_payer}
                    />
                }
                label={t("forms.partners.supplier.isVAT")}
                onChange={(e) => {
                    handleChangeField("is_vat_payer", !llp.is_vat_payer, llp, setLlp);
                }}
            />
        </Box>
    );
};

export default LegalEntityForm;
