import i18next from "i18next";
import { createContext, ReactNode, useEffect, useState } from "react";
import axios from "axios";
import { AlertColor } from "@mui/material";
import getMe from "../../utils/fetchers";
import NotFound from "../../components/Errors/NotFound";

type AuthContextType = {
    loading: boolean;
    me: any;
    setMe: (me: any) => void;
    appState: string;
    setAppState: (value: string) => void;
    isLoggedIn: boolean;
    setIsLoggedIn: (value: boolean) => void;
    notification: Notification;
    setNotification: (value: Notification) => void;
    setNotFound: (value: boolean) => void;
};
type Props = {
    children: ReactNode;
};
interface Notification {
    open: boolean;
    type?: AlertColor;
    message: string;
    duration?: number;
}
export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

axios.defaults.baseURL = "https://reports-backend.tds.bid";
axios.defaults.withCredentials = true;

const AuthProvider = ({ children }: Props) => {
    const [me, setMe] = useState({});
    const [appState, setAppState] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [notification, setNotification] = useState<Notification>({
        open: false,
        type: "info",
        message: "",
        duration: 3000,
    });
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        axios.interceptors.request.use((config) => {
            config.headers["Accept-Language"] = i18next.language;
            return config;
        });
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response.status === 401) {
                    setIsLoggedIn(false);
                }
                return Promise.reject(error);
            }
        );
        if (Object.keys(me).length === 0) {
            (async () => {
                setLoading(true);
                try {
                    const res = await getMe();
                    setMe(res);
                    setIsLoggedIn(true);
                } catch (err) {
                    console.error("Error getting:", err)
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [isLoggedIn]);

    if (notFound) {
        return <NotFound />;
    }

    return (
        <AuthContext.Provider
            value={{
                setNotFound,
                loading,
                me,
                setMe,
                appState,
                setAppState,
                isLoggedIn,
                setIsLoggedIn,
                notification,
                setNotification,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
