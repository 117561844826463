import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import axios, { AxiosError, isAxiosError } from "axios";
import { useParams } from "react-router-dom";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import ContentContainer from "../../../../../../../../components/ContentContainer/ContentContainer";
import { ReactComponent as ProfileIcon } from "../../../../../../../../assets/images/icons/sidebar/profile.svg";
import { ReactComponent as MoneyIcon } from "../../../../../../../../assets/images/icons/common/moneyIcon.svg";
import ValueInputField from "../../../../../../../../UI/Inputs/ValueInputField";
import SelectInputField from "../../../../../../../../UI/Inputs/SelectInputField";
import { getBankList, getCurrencies, getLegalEntityList, getSystemList } from "../../../../../../../ClientsPage/services/fetchers";
import DatePickerCustom from "../../../../../../../../UI/Inputs/DatePicker";
import { handleChangeField } from "../../../../../../../ClientsPage/services/helpers";
import { AuthContext } from "../../../../../../../../context/AuthContext";
import { TopBarContext } from "../../../../../../../../context/TopBarContext";
import EditButton from "../../../../../../../../UI/Buttons/Actions/EditButton";
import CloseButton from "../../../../../../../../UI/Buttons/Actions/CloseButton";

export type Supplier = {
    account: string;
    ad_system: string;
    bank_recipient: string | null;
    created_at: Dayjs | string | null;
    credentials?: {
        system: string;
        token: string;
    };
    currency_acceptance: string;
    currency_account: string;
    id: string;
    is_active: boolean;
    is_bonus: boolean;
    llp: string;
    vat: string;
    vat_value: number | string;
};

export const initialSupplier: Supplier = {
    account: "",
    ad_system: "",
    bank_recipient: "",
    created_at: null,
    credentials: {
        system: "",
        token: "",
    },
    currency_acceptance: "",
    currency_account: "",
    id: "",
    is_active: false,
    is_bonus: false,
    llp: "",
    vat: "",
    vat_value: "",
};

const vatList = ["On top", "Included", "No VAT"];

const SupplierPage = () => {
    const { setNotification, setNotFound } = useContext(AuthContext);
    const [supplier, setSupplier] = useState<Supplier>(initialSupplier);
    const [legalEntityList, setLegalEntityList] = useState([]);
    const [bankList, setBankList] = useState([]);
    const [systemList, setSystemList] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const { id } = useParams();
    const { handleChangePath, handleSetActions } = useContext(TopBarContext);
    const { t } = useTranslation();

    useEffect(() => {
        handleSetActions([<EditButton action={handleSubmitSupplier} />, <CloseButton path="/properties/suppliers" />]);
    }, [supplier]);

    const getSupplier = async () => {
        try {
            const res = await axios.get(`properties/suppliers/${id}/`);
            return await res.data;
        } catch (err: Error | AxiosError | unknown) {
            if (isAxiosError(err)) {
                if (err.response?.status === 404) {
                    setNotFound(true);
                }
            }
            throw new Error(t("error.supplier"));
        }
    };

    useEffect(() => {
        if (id) {
            (async () => {
                const res: any = await Promise.all([getSupplier(), getBankList(), getLegalEntityList(), getSystemList(), getCurrencies()]);
                setSupplier({
                    ...res[0],
                    vat_value: Number(res[0].vat_value).toFixed(1),
                });
                handleChangePath(["partners", res[0].account]);
                setBankList(res[1]);
                setLegalEntityList(res[2]);
                setSystemList(res[3]);
                setCurrencies(res[4]);
            })();
        }
    }, [id]);

    const handleSubmitSupplier = async () => {
        try {
            const res = await axios.post(`properties/suppliers/${id}/edit/`, supplier);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.message,
                type: "error",
            });
        }
    };

    return (
        <ContentContainer>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{
                        p: "3rem",
                        background: "#fff",
                        maxWidth: "736px",
                        gap: "1rem",
                        display: "flex",
                        flexWrap: "wrap",
                    }}
                >
                    <ValueInputField
                        label="forms.partners.supplier.name"
                        value={supplier.account}
                        Icon={<ProfileIcon />}
                        onChange={(e) => handleChangeField("account", e.target.value, supplier, setSupplier)}
                    />
                    <SelectInputField value={supplier.ad_system} label="Ad system" items={systemList} onChange={(value) => handleChangeField("ad_system", value, supplier, setSupplier)} />
                    <SelectInputField
                        value={supplier.currency_acceptance}
                        label="forms.partners.supplier.acceptance"
                        items={currencies}
                        onChange={(value) => handleChangeField("currency_acceptance", value, supplier, setSupplier)}
                    />
                    <SelectInputField
                        value={supplier.currency_account}
                        label="forms.partners.supplier.account"
                        items={currencies}
                        onChange={(value) => handleChangeField("currency_account", value, supplier, setSupplier)}
                    />
                    <SelectInputField
                        value={supplier.llp}
                        label="forms.partners.supplier.receiving_le"
                        items={legalEntityList}
                        onChange={(value) => handleChangeField("llp", value, supplier, setSupplier)}
                    />
                    <SelectInputField value={supplier.vat} label="forms.partners.supplier.options" items={vatList} onChange={(value) => handleChangeField("vat", value, supplier, setSupplier)} />
                    <ValueInputField
                        label="forms.partners.supplier.sVAT"
                        value={supplier.vat_value}
                        type="number"
                        Icon={<MoneyIcon />}
                        onChange={(e) => handleChangeField("vat_value", Number(e.target.value), supplier, setSupplier)}
                    />
                    <SelectInputField
                        value={supplier.bank_recipient}
                        label="forms.partners.supplier.reward"
                        items={bankList}
                        onChange={(value) => handleChangeField("bank_recipient", value, supplier, setSupplier)}
                    />
                    <DatePickerCustom label={t("forms.common.start_date")} value={supplier.created_at} onChange={(value) => handleChangeField("created_at", value, supplier, setSupplier)} />
                    <FormControlLabel
                        sx={{ width: "100%" }}
                        control={
                            <Checkbox
                                sx={{
                                    "&.Mui-checked": {
                                        color: "var(--main-black-color)",
                                    },
                                }}
                                checked={supplier.is_active}
                            />
                        }
                        label="forms.partners.supplier.isActive"
                        onChange={(e) => {
                            handleChangeField("is_active", !supplier.is_active, supplier, setSupplier);
                        }}
                    />
                    <FormControlLabel
                        sx={{ width: "100%" }}
                        control={
                            <Checkbox
                                sx={{
                                    "&.Mui-checked": {
                                        color: "var(--main-black-color)",
                                    },
                                }}
                                checked={supplier.is_bonus}
                            />
                        }
                        label="forms.partners.supplier.isBonus"
                        onChange={(e) => {
                            handleChangeField("is_bonus", !supplier.is_bonus, supplier, setSupplier);
                        }}
                    />
                </Box>
            </Box>
        </ContentContainer>
    );
};

export default SupplierPage;
