import React from "react";
import { Box } from "@mui/material";
import PercentIcon from "@mui/icons-material/Percent";
import ValueInputField from "../../../../../../../../../../UI/Inputs/ValueInputField";
import { ReactComponent as DatePickerIcon } from "../../../../../../../../../../assets/images/icons/common/dateIcon.svg";
import { handleChangeField } from "../../../../../../../../../ClientsPage/services/helpers";
import { BonusType } from "../../pages/BonusCreate";

type BonusFormType = {
    bonus: BonusType;
    setBonus: (bonus: BonusType) => void;
};

const BonusForm: React.FC<BonusFormType> = ({ bonus, setBonus }) => (
    <Box
        sx={{
            p: "3rem",
            background: "#fff",
            maxWidth: "736px",
            gap: "1rem",
            display: "flex",
            flexWrap: "wrap",
        }}
    >
        <ValueInputField type="number" Icon={<DatePickerIcon />} label="year" value={bonus.year} onChange={(e) => handleChangeField("year", Number(e.target.value), bonus, setBonus)} />
        <ValueInputField type="number" Icon={<DatePickerIcon />} label="month" value={bonus.month} onChange={(e) => handleChangeField("month", Number(e.target.value), bonus, setBonus)} />
        <ValueInputField
            style={{ width: "100%" }}
            Icon={<PercentIcon style={{ color: "var(--main-black-color)" }} />}
            label="bonus"
            type="number"
            value={bonus.bonus}
            onChange={(e) => handleChangeField("bonus", Number(e.target.value), bonus, setBonus)}
        />
    </Box>
);

export default BonusForm;
