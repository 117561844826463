import axios from "axios";

const getMe = async () => {
    try {
        const res = await axios.get("/users/me/");
        return await res.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
};

export default getMe;
