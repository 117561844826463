import { Route } from "react-router-dom";
import { ReactNode } from "react";
import { RouteType } from "./config";
import appRoutes from "./appRoutes";
import PageWrapper from "../layouts/PageWrapper";
import ProtectedRoute from "./ProtectedRoute";

const generateRoute = (routes: RouteType[]): ReactNode =>
    routes.map((route, index) =>
        route.index ? (
            <Route
                index
                key={index}
                path={route.path}
                element={
                    <ProtectedRoute permissions={route.permissions}>
                        <PageWrapper state={route.state}>{route.element}</PageWrapper>
                    </ProtectedRoute>
                }
            />
        ) : (
            <Route
                path={route.path}
                element={
                    <ProtectedRoute permissions={route.permissions}>
                        <PageWrapper state={route.child ? undefined : route.state}>{route.element}</PageWrapper>
                    </ProtectedRoute>
                }
                key={index}
            >
                {route.child && generateRoute(route.child)}
            </Route>
        )
    );

const routes: ReactNode = generateRoute(appRoutes);
export default routes;
