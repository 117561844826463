import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {FC} from "react";

const PayoutTotal: FC<{payoutTotal: number}> = ({payoutTotal=0}) => {
    const { t } = useTranslation();
    return (
        <Box sx={{display: "flex", gap: ".25rem", alignItems: "center", ml: ".75rem"}}>
            <Typography sx={{fontWeight: "500", fontSize: "1.25rem"}}>
                {t("payout_total")}:
            </Typography>
            <Typography sx={{fontWeight: "500", pt: "3px"}}>
                {payoutTotal.toLocaleString()}
            </Typography>
        </Box>
    )
}

export default PayoutTotal;