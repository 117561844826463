import { Box, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../../UI/Inputs/DatePicker";
import AddBadge from "../../../../components/Badges/AddBadge";
import RemoveBadge from "../../../../components/Badges/RemoveBadge";
import { ClientType, ManagerSalesType } from "../../types";
import { getManagers } from "../../services/fetchers";
import ValueInputField from "../../../../UI/Inputs/ValueInputField";
import SelectInputField from "../../../../UI/Inputs/SelectInputField";
import { ReactComponent as ProfileIcon } from "../../../../assets/images/icons/sidebar/profile.svg";

const entities = [
    {
        name: "Private Person",
        value: "Private Person",
    },
    {
        name: "Legal Entity",
        value: "Legal Entity",
    },
    {
        name: "P.E./S.E.",
        value: "P.E./S.E.",
    },
    {
        name: "Bonus",
        value: "Bonus",
    },
    {
        name: "Piggy bank",
        value: "Piggy bank",
    },
];
export type ClientTypeKeys = keyof ClientType;

type ClientFormProps = {
    readOnly?: boolean;
    mode?: "create" | "edit" | "view";
    client: ClientType;
    setClient: (client: ClientType) => void;
};

const ClientForm: FC<ClientFormProps> = ({ mode = "create", readOnly = false, client, setClient }) => {
    const [managers, setManagers] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        (async () => setManagers(await getManagers()))();
    }, []);

    const handleAddManager = (accountType: ClientTypeKeys = "accountManagers") => {
        const newManagers = [
            ...(client[accountType] as []),
            {
                id: null,
                fieldId: `id${Math.random().toString(16).slice(2)}`,
                account: "",
                startDate: null,
            },
        ];
        handleChangeField(accountType, newManagers);
    };
    const handleRemoveManager = (accountType: ClientTypeKeys = "accountManagers", fieldId: string = "") => {
        const newManagers = (client[accountType] as ManagerSalesType[]).filter((manager) => manager.fieldId !== fieldId);
        handleChangeField(accountType, newManagers);
    };
    const handleChangeManager = (accountType: ClientTypeKeys = "accountManagers", fieldId: string = "", fieldKey: string = "", value: string | Dayjs | null = null) => {
        const newManagers = (client[accountType] as ManagerSalesType[]).map((manager) => {
            if (manager.fieldId === fieldId) {
                return {
                    ...manager,
                    [fieldKey]: value,
                };
            }
            return manager;
        });
        handleChangeField(accountType, newManagers);
    };
    const handleChangeField = (key: ClientTypeKeys, newValue: any) => {
        setClient({
            ...client,
            [key]: newValue,
        });
    };
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    gap: "1rem",
                    flexWrap: "wrap",
                    mb: "1rem",
                }}
            >
                <ValueInputField readOnly={readOnly} Icon={<ProfileIcon />} value={client.companyName} label={t("client_name")} onChange={(e) => handleChangeField("companyName", e.target.value)} />
                <SelectInputField readOnly={readOnly} value={client.companyType} label={t("type_of_organization")} items={entities} onChange={(value) => handleChangeField("companyType", value)} />
                <Typography
                    sx={{
                        fontSize: "1rem",
                        fontWeight: "500",
                        width: "100%",
                    }}
                >
                    {t("account_manager_list")}
                </Typography>
                {client &&
                    client.accountManagers &&
                    client.accountManagers.map((manager: any, index: number) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                width: "740px",
                                gap: "1rem",
                            }}
                        >
                            <SelectInputField
                                readOnly={readOnly}
                                value={manager.account}
                                label={t("account_manager")}
                                items={managers}
                                onChange={(value) => handleChangeManager("accountManagers", manager.fieldId, "account", value)}
                            />
                            <DatePickerCustom
                                readOnly={readOnly}
                                label={t("forms.common.start_date")}
                                value={manager.startDate}
                                onChange={(newValue) => handleChangeManager("accountManagers", manager.fieldId, "startDate", newValue)}
                            />
                            {!readOnly &&
                                (index === 0 ? (
                                    <Box
                                        onClick={() => handleAddManager("accountManagers")}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <AddBadge />
                                    </Box>
                                ) : (
                                    <Box
                                        onClick={() => handleRemoveManager("accountManagers", manager.fieldId)}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <RemoveBadge />
                                    </Box>
                                ))}
                        </Box>
                    ))}
                <Typography
                    sx={{
                        fontSize: "1rem",
                        fontWeight: "500",
                        width: "100%",
                    }}
                >
                    {t("sales_manager_list")}
                </Typography>
                {client &&
                    client.salesManagers &&
                    client.salesManagers.map((manager: any, index: number) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                width: "740px",
                                gap: "1rem",
                            }}
                        >
                            <SelectInputField
                                readOnly={readOnly}
                                value={manager.account}
                                label={t("sales_manager")}
                                items={managers}
                                onChange={(value) => handleChangeManager("salesManagers", manager.fieldId, "account", value)}
                            />
                            <DatePickerCustom
                                readOnly={readOnly}
                                label={t("forms.common.start_date")}
                                value={manager.startDate}
                                onChange={(newValue) => handleChangeManager("salesManagers", manager.fieldId, "startDate", newValue)}
                            />
                            {!readOnly &&
                                (index === 0 ? (
                                    <Box
                                        onClick={() => handleAddManager("salesManagers")}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <AddBadge />
                                    </Box>
                                ) : (
                                    <Box
                                        onClick={() => handleRemoveManager("salesManagers", manager.fieldId)}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <RemoveBadge />
                                    </Box>
                                ))}
                        </Box>
                    ))}
            </Box>
        </>
    );
};

export default ClientForm;
