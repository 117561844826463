import axios from "axios";

export const getLegalEntities = async (data = {}, signal: any = null) => {
    try {
        const res = await axios.post("/properties/llp/", data, { signal });
        return await res.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
};

export const getBanks = async (data = {}, id: string | undefined = "", signal: any = null) => {
    try {
        const res = await axios.post(`/properties/llp/${id}/banks/`, data, {
            signal,
        });
        return await res.data;
    } catch (err: any) {
        console.error("Error");
        throw err;
    }
};

export const getBank = async (id: string | undefined, bankId: string) => {
    try {
        const res = await axios.post(`/properties/llp/${id}/banks/${bankId}/`);
        return await res.data;
    } catch (err: any) {
        console.error("Error");
        throw err;
    }
};
