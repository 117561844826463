import React, { FC } from "react";
import {
    FormControlLabel,
    Checkbox,
    Typography,
} from "@mui/material";
import {parseLabelKey} from "../../utils/helpers";
import {OptionObjectType} from "../../services/common-types";

type MultipleCheckBoxProps = {
    label: string;
    value: string[];
    onChange: (value: string[]) => void;
    options: Array<string | OptionObjectType>;
};

const MultipleCheckBox: FC<MultipleCheckBoxProps> = ({
    label,
    value,
    onChange,
    options,
}) => {
    const handleToggle = (optionValue: string) => {
        const currentIndex = value.indexOf(optionValue);
        const newValue = [...value];

        if (currentIndex === -1) {
            newValue.push(optionValue);
        } else {
            newValue.splice(currentIndex, 1);
        }

        onChange(newValue);
    };

    return (
        <>
            <Typography sx={{ fontWeight: "500", mb: "1rem" }}>{label}</Typography>
            {options && options.length > 0 ? (
                options.map((option, index) => {
                    const { label, key } = parseLabelKey(option)
                    return (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    checked={value.includes(key)}
                                    onChange={() => handleToggle(key)}
                                />
                            }
                            label={label}
                        />
                    );
                })
            ) : (
                <Typography sx={{ py: ".5rem", fontSize: "1.25rem", fontWeight: 500 }}>
                    No data
                </Typography>
            )}
        </>
    );
};

export default MultipleCheckBox;
