import React from "react";
import CustomButton from "../CustomButton";
import { ReactComponent as SaveIconWhite } from "../../../assets/images/icons/saveIconWhite.svg";

interface CreateButtonProps {
    action: () => void;
}

const CreateButton: React.FC<CreateButtonProps> = ({ action }) => <CustomButton Icon={<SaveIconWhite />} label="button.save" onChange={action} />;
export default CreateButton;
