import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { TopBarContext } from "../../../../context/TopBarContext";
import { TableDataType } from "../../../../components/TableConstructor/types";
import { initialTableData } from "../../../../components/TableConstructor/constants";
import ContentContainer from "../../../../components/ContentContainer/ContentContainer";
import TableConstructor from "../../../../components/TableConstructor";
import CustomButton from "../../../../UI/Buttons/CustomButton";
import { getBlocks } from "../../services/fetchers";

export interface Dictionary<T> {
    [key: string]: T;
}

export const RecWidgetPage = () => {
    const { handleChangePath, handleSetActions, handleChangeTabs } = useContext(TopBarContext);
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState<Dictionary<any>>({
        periods: {
            date_from: dayjs().startOf("month"),
            date_to: dayjs().add(-1, "day"),
            currency: "",
        },
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [tableData, setTableData] = useState<TableDataType>(initialTableData);

    useEffect(() => {
        handleChangePath(["Products", "RecWidget"]);
        handleSetActions([]);
        handleChangeTabs([]);
    }, []);

    const loadBlocks = async (signal: any = null) => {
        setLoading(true);
        const data = {
            ...request,
            filters: request.filters || {},
        };
        try {
            const res = await getBlocks("RecWidget", data, signal);
            setTableData(res);
            if (res.all_counts > res.size) {
                setSearchParams({
                    ...searchParams,
                    page: res.page || 1,
                    size: res.size || 50,
                });
            }
            setLoading(false);
        } catch (e) {
            setError("Error");
            setLoading(false);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        (async () => {
            await loadBlocks(controller.signal);
        })();
        return () => controller.abort();
    }, [request]);

    const handleCreateUser = () => {
        navigate("create");
    };
    return (
        <ContentContainer>
            <TableConstructor
                tableData={tableData}
                loading={loading}
                request={request}
                setRequest={setRequest}
                ActionButton={
                    <CustomButton
                        Icon={
                            <AddIcon
                                style={{
                                    color: "#fff",
                                    width: "1.25rem",
                                    height: "1.25rem",
                                    marginRight: ".5rem",
                                }}
                            />
                        }
                        label="button.add"
                        onChange={handleCreateUser}
                    />
                }
            />
        </ContentContainer>
    );
};
