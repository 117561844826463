import { useTranslation } from "react-i18next";
import { Box, Menu, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import BASE_URL from "../../../../data/constants";
import ValueInputField from "../../../../UI/Inputs/ValueInputField";
import AddBadge from "../../../Badges/AddBadge";
import RemoveBadge from "../../../Badges/RemoveBadge";
import { generateId } from "../../../../utils/helpers";
import FilterPanelButton from "../FilterPanelButton";
import { ReactComponent as FilterIcon } from "../../../../assets/images/icons/table/filter.svg";
import { Dictionary } from "../../../../pages/ClientsPage";
import { IFilter } from "../../../../pages/StatisticPage";
import Badges from "../TableFilterPanelStatistics/components/Badges";

interface IFilterItems {
    [key: string]: {
        column: string;
        options: string[];
        filters?: string[];
    };
}

interface IReportFilter {
    request: Dictionary<any>;
    setRequest: (req: Dictionary<any>) => void;
    badges?: boolean;
}
const ReportFilter = ({ request, setRequest, badges = false }: IReportFilter) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filterItems, setFilterItems] = useState<IFilterItems>({});
    const { filters = {} } = request;

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        (async () => {
            const res = await axios.post(`${BASE_URL}/report/filters/`);
            setFilterItems(res.data);
        })();
    }, []);

    const handleAddFilter = () => {
        const newFilters = [
            ...filters,
            {
                fieldId: generateId(),
                key: "",
                option: "",
                value: "",
            },
        ];
        setRequest({
            ...request,
            filters: newFilters,
        });
    };

    useEffect(() => {
        if (filters.length === 0) {
            handleAddFilter();
        }
    }, [filters]);

    const handleRemoveFilter = (filter: Record<string, any>) => {
        const newFilters = (filters as IFilter[]).filter((contact) => contact.fieldId !== filter.fieldId);
        setRequest({ ...request, filters: newFilters });
    };

    const handleChangeFilter = (fieldId: string, fieldKey: string, value: string) => {
        const newFilters = (filters as IFilter[]).map((filter) => {
            if (filter.fieldId === fieldId) {
                switch (fieldKey) {
                    case "key":
                        return {
                            fieldId: filter.fieldId,
                            key: value,
                            option: "",
                            value: "",
                        };
                    case "option":
                        return {
                            fieldId: filter.fieldId,
                            key: filter.key,
                            option: value,
                            value: "",
                        };
                    case "value":
                        return {
                            ...filter,
                            value,
                        };
                    default:
                        return {
                            ...filter,
                            [fieldKey]: value,
                        };
                }
            }
            return filter;
        });
        setRequest({ ...request, filters: newFilters });
    };

    return (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                alignItems: "flex-start",
            }}
        >
            <FilterPanelButton Icon={<FilterIcon />} active={Boolean(anchorEl)} onClick={handleMenuOpen} text="filters" />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                    mt: ".125rem",
                    ".MuiMenu-paper": {
                        p: "1.5rem",
                    },
                    "& .MuiPaper-root": {
                        borderRadius: "0",
                    },
                    "& .MuiList-root": {
                        p: ".25rem 0",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                    },
                }}
            >
                {Object.keys(filterItems).length > 0 &&
                    filters.length > 0 &&
                    filters.map((filter: any, filterIndex: number) => (
                        <Box
                            key={filterIndex}
                            sx={{
                                display: "flex",
                                width: "100%",
                                gap: "1rem",
                            }}
                        >
                            <TextField
                                id="outlined-select-Columns"
                                select
                                label={t("columns")}
                                value={filter.key}
                                sx={{
                                    width: "312px",
                                }}
                                color="warning"
                                InputLabelProps={{
                                    color: "primary",
                                }}
                                InputProps={{
                                    sx: {
                                        borderRadius: "0",
                                    },
                                }}
                                onChange={(e) => (e.target.value === "clear" ? handleChangeFilter(filter.fieldId, "key", "") : handleChangeFilter(filter.fieldId, "key", e.target.value))}
                            >
                                <MenuItem key="clear" value="clear">
                                    {t("clear_selection")}
                                </MenuItem>
                                {Object.entries(filterItems).map((filterItem, index: number) => (
                                    <MenuItem key={index} value={filterItem[0]}>
                                        {filterItem[1].column}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {filter.key && (
                                <TextField
                                    id="outlined-select-Columns"
                                    select
                                    label={t("select_from")}
                                    value={filter.option}
                                    sx={{
                                        width: "312px",
                                    }}
                                    color="warning"
                                    InputLabelProps={{
                                        color: "primary",
                                    }}
                                    InputProps={{
                                        sx: {
                                            borderRadius: "0",
                                        },
                                    }}
                                    onChange={(e) => (e.target.value === "clear" ? handleChangeFilter(filter.fieldId, "option", "") : handleChangeFilter(filter.fieldId, "option", e.target.value))}
                                >
                                    <MenuItem key="clear" value="clear">
                                        {t("clear_selection")}
                                    </MenuItem>
                                    {filterItems[filter.key].options.map((option, index: number) => (
                                        <MenuItem key={index} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            {filter.key &&
                                filter.option &&
                                (filterItems[filter.key]?.filters ? (
                                    <TextField
                                        id="outlined-select-Columns"
                                        select
                                        label={t("select_from")}
                                        value={filter.value}
                                        sx={{
                                            width: "312px",
                                        }}
                                        color="warning"
                                        InputLabelProps={{
                                            color: "primary",
                                        }}
                                        InputProps={{
                                            sx: {
                                                borderRadius: "0",
                                            },
                                        }}
                                        onChange={(e) => (e.target.value === "clear" ? handleChangeFilter(filter.fieldId, "value", "") : handleChangeFilter(filter.fieldId, "value", e.target.value))}
                                    >
                                        <MenuItem key="clear" value="clear">
                                            {t("clear_selection")}
                                        </MenuItem>
                                        {filterItems[filter.key]?.filters?.map((option, index: number) => (
                                            <MenuItem key={index} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                ) : (
                                    <ValueInputField Icon={<SearchIcon />} label="filter" value={filter.value} onChange={(e) => handleChangeFilter(filter.fieldId, "value", e.target.value)} />
                                ))}
                            {filterIndex === 0 ? (
                                <Box
                                    onClick={handleAddFilter}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                >
                                    <AddBadge />
                                </Box>
                            ) : (
                                <Box
                                    onClick={() => handleRemoveFilter(filter)}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                >
                                    <RemoveBadge />
                                </Box>
                            )}
                        </Box>
                    ))}
            </Menu>
            {badges && <Badges filterGroup={filters} removeBadge={handleRemoveFilter} keyField="fieldId" request={request} setRequest={setRequest} field="filters" mappingList={filterItems} />}
        </Box>
    );
};

export default ReportFilter;
