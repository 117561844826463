import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import PartnerAccountPage from "../partner/pages/AccountPage";
import ClientEditPage from "../pages/ClientViewPage/pages/ClientEditPage";

const AccountView = () => {
    const { me } = useContext(AuthContext);

    if (Object.keys(me).length > 0 && me.role === "client") {
        return <PartnerAccountPage />;
    }
    return <ClientEditPage />;
};

export default AccountView;
