import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { ReactComponent as NotificationNote } from "../../../assets/images/icons/notification-icon.svg";

const EmptyNotifications = () => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                mt: "1.25rem",
                display: "flex",
                gap: ".75rem",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <NotificationNote />
            <Typography sx={{ fontWeight: "500" }}>{t("notification.no_notifications")}</Typography>
            <Typography sx={{ color: "var(--main-divider-color)" }}>{t("notification.no_notifications_or_archived")}.</Typography>
        </Box>
    );
};

export default EmptyNotifications;
