import { Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import axios from "axios";
import { useParams } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";
import BASE_URL from "../../../../../../data/constants";
import ValueInputField from "../../../../../../UI/Inputs/ValueInputField";
import ContentContainer from "../../../../../../components/ContentContainer/ContentContainer";
import CustomButton from "../../../../../../UI/Buttons/CustomButton";
import { AuthContext } from "../../../../../../context/AuthContext";
import Restricted from "../../../../../../components/Restricted";

const AccountPage = () => {
    const { setNotification } = useContext(AuthContext);
    const [username, setUsername] = useState("");
    const { t } = useTranslation();
    const { id } = useParams();
    const getAccount = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/${id}/login_data/`);
            return await res.data;
        } catch (err) {
            console.log(err);
            return err;
        }
    };

    useEffect(() => {
        (async () => setUsername((await getAccount()).username))();
    }, []);

    const handleResetPassword = async () => {
        try {
            const url = `clients/${id}/reset_password/`;
            const res = await axios.get(url);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: unknown) {
            setNotification({
                open: true,
                message: t("error.reset_error"),
                type: "error",
            });
        }
    };

    return (
        <ContentContainer>
            <Box
                sx={{
                    p: "3rem",
                    background: "#fff",
                    width: "fit-content",
                    display: "flex",
                    gap: "1rem",
                }}
            >
                <ValueInputField Icon={<PersonIcon />} label="login" value={username} />
                <Restricted to="canEdit">
                    <CustomButton style={{ height: "56px" }} Icon={<RefreshIcon style={{ color: "white" }} />} label="button.reset_password" onChange={handleResetPassword} />
                </Restricted>
            </Box>
        </ContentContainer>
    );
};

export default AccountPage;
