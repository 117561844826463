import { useTranslation } from "react-i18next";
import {Box, Button, ButtonGroup} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { DateView } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import DatePickerCustom from "../../../../UI/Inputs/DatePicker";
import { handleChangeInnerField } from "../../../../utils/helpers";
import { ReactComponent as PeriodIcon } from "../../../../assets/images/icons/common/periodIcon.svg";
import { Dictionary } from "../../../../pages/ClientsPage";
import IconSelectInputFieldTransparent from "../../../../UI/Inputs/IconSelectInputFieldTransparent";
import { getCurrencies } from "../../../../pages/ClientsPage/services/fetchers";
import { ReactComponent as MoneyIcon } from "../../../../assets/images/icons/common/moneyIcon.svg";

interface IPeriod {
    views?: DateView[] | null;
    request: Dictionary<any>;
    setRequest: (value: Dictionary<any>) => void;
    isStatistics?: boolean;
}

const hotKeys = [{
    key: "today",
    label: "Today",
},{
    key: "yesterday",
    label: "Yesterday",
},{
    key: "week",
    label: "Last 7 Days",
}, {
    key: "last_month",
    label: "Last Month",
}]

const Period = ({ views = null, request, setRequest, isStatistics = false }: IPeriod) => {
    const { t } = useTranslation();
    const { periods } = request;
    const [activeHotKey, setActiveHotKey] = useState("");
    const [currencies, setCurrencies] = useState<string[]>([]);

    const changePeriodHotKey = (key: string) => {
        switch (key) {
            case "today":
                const today = dayjs();
                handleChangeInnerField("periods", "date_from", today, setRequest)
                handleChangeInnerField("periods", "date_to", today, setRequest)
                break;
            case "yesterday":
                const yesterday = dayjs().add(-1, "day");
                handleChangeInnerField("periods", "date_from", yesterday, setRequest)
                handleChangeInnerField("periods", "date_to", yesterday, setRequest)
                break;
            case "week":
                handleChangeInnerField("periods", "date_from", dayjs().add(-7, "day"), setRequest)
                handleChangeInnerField("periods", "date_to", dayjs().add(-1, "day"), setRequest)
                break;
            case "last_month":
                handleChangeInnerField("periods", "date_from", dayjs().add(-1, "month").startOf("month"), setRequest)
                handleChangeInnerField("periods", "date_to", dayjs().add(-1, "month").endOf("month"), setRequest)
                break;
            default:
                break;
        }
        setActiveHotKey(key);
    }

    useEffect(() => {
        if (currencies.length === 0) {
            (async () => setCurrencies(await getCurrencies()))();
        }
    }, []);

    return (
        <Box sx={{ display: "flex", gap: "12px" }}>
            <Box
                sx={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                    p: "6px 8px",
                    fontWeight: 500,
                }}
            >
                <PeriodIcon />
                {t("period")}
            </Box>
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    gap: {xs: ".5rem", md: "1rem"},
                    alignItems: "center",
                    color: "#00000066",
                }}
            >
                <ButtonGroup size="large" aria-label="Large button group" sx={{width: {xs: "100%", md: "initial"}}}>
                    {hotKeys.map(hotKey => (
                        <Button
                            disableRipple
                            onClick={() => changePeriodHotKey(hotKey.key)}
                            key={hotKey.key}
                            sx={{
                                "&:hover": {
                                    border: "1px solid #538DFE",
                                },
                                border: "1px solid #538DFE",
                                flex: {xs: hotKey.key !== "today" ? 1 : "", md: "auto"},
                                // eslint-disable-next-line max-len
                                color: activeHotKey === hotKey.key ? "white" :"#2196F3", background: activeHotKey === hotKey.key ? "#2196F3 !important" : "transparent", px: {xs: ".25rem", md: ".5rem"},
                                height: {xs: "40px", md: "auto"}, textTransform: "none"}}
                        >
                            {hotKey.label}
                        </Button>
                    ))}
                </ButtonGroup>
                <Box sx={{display: "flex", flexWrap: "wrap", gap: ".25rem", alignItems: "center"}}>
                    <DatePickerCustom
                        {...(views ? { views } : {})}
                        value={periods.date_from}
                        onChange={(newValue) => handleChangeInnerField("periods", "date_from", newValue, setRequest)}
                        style={{ backgroundColor: "white", width: "175px", minWidth: "150px" }}
                        disableBorders
                    />
                    <ArrowForwardIcon />
                    <DatePickerCustom
                        {...(views ? { views } : {})}
                        value={periods.date_to}
                        onChange={(newValue) => handleChangeInnerField("periods", "date_to", newValue, setRequest)}
                        style={{ backgroundColor: "white", width: "175px", minWidth: "150px" }}
                        disableBorders
                    />
                </Box>
                {isStatistics ? (
                    <IconSelectInputFieldTransparent
                        value={request.periods.currency}
                        label="Currency2"
                        items={currencies}
                        defaultValue="USD"
                        onChange={(value: string) => handleChangeInnerField("periods", "currency", value, setRequest)}
                        icon={<MoneyIcon style={{ marginRight: "6px" }} />}
                    />
                ) : null}
            </Box>
        </Box>
    );
};

export default Period;
