import React, { FC, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import { Autocomplete, Box, Collapse, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { Control, Controller, DeepRequired, FieldErrorsImpl, FieldValues, GlobalError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SelectInputField from "../../../../../../UI/Inputs/SelectInputField";
import { boxStyle, FormType, ProductConfigs } from "./index";
import ValueInputField from "../../../../../../UI/Inputs/ValueInputField";
import AddBadge from "../../../../../../components/Badges/AddBadge";
import RemoveBadge from "../../../../../../components/Badges/RemoveBadge";
import { generateId } from "../../../../../../utils/helpers";
import { handleChangeField } from "../../../../../ClientsPage/services/helpers";

type PVWConfigsFormProps = {
    mode: "edit" | "create";
    errors: Partial<FieldErrorsImpl<DeepRequired<FieldValues>>> & { root?: Record<string, GlobalError> & GlobalError };
    control: Control<FieldValues, any>;
    dynamicForm: FormType;
    setDynamicForm: (value: FormType) => void;
    configs: ProductConfigs;
    setConfigs: (configs: ProductConfigs) => void;
    mobileTemplates: string[];
    desktopTemplates: string[];
    clientList: string[];
    placementList: string[];
};

const PVWConfigsForm: FC<PVWConfigsFormProps> = ({
    control,
    errors = {},
    dynamicForm,
    setDynamicForm,
    placementList = [],
    clientList = [],
    mobileTemplates = [],
    desktopTemplates = [],
    configs,
    setConfigs,
    mode = "create",
}) => {
    const [isShowMore, setIsShowMore] = useState({
        desktop: false,
        mobile: false,
    });
    const lang = localStorage.getItem("lng");
    const { t } = useTranslation();

    const handleAddFeedData = () => {
        const newFeedData: any[] = [
            ...(dynamicForm.main.feed_data.fields as any[]),
            {
                fieldId: generateId(),
                YT_ID: {
                    defaultValue: "",
                    hint: { en: "", ru: "" },
                    rules: { required: true },
                    title: { en: "YT ID", ru: "YT ID" },
                    type: "string",
                    typeSend: "string",
                },
                href: {
                    defaultValue: "",
                    hint: { en: "", ru: "" },
                    rules: { required: true },
                    title: { en: "URL to show rec page", ru: "URL для перехода" },
                    type: "string",
                    typeSend: "string",
                },
                start_time: {
                    defaultValue: "",
                    hint: { en: "", ru: "" },
                    rules: { required: true },
                    title: { en: "Start time", ru: "Время начала" },
                    type: "number",
                    typeSend: "integer",
                },
            },
        ];
        setDynamicForm({
            ...dynamicForm,
            main: {
                ...dynamicForm.main,
                feed_data: { ...dynamicForm.main.feed_data, fields: newFeedData },
            },
        });
    };

    const handleRemoveFeedData = (fieldId: string) => {
        const newFeedData = (dynamicForm.main.feed_data.fields as any[]).filter((item) => item.fieldId !== fieldId);
        setDynamicForm({
            ...dynamicForm,
            main: {
                ...dynamicForm.main,
                feed_data: { ...dynamicForm.main.feed_data, fields: newFeedData },
            },
        });
    };

    // eslint-disable-next-line react/no-unstable-nested-components
    const Input = ({ label, value, hint, onChange, type, ...rest }: any) => {
        switch (type) {
            case "string":
                return (
                    <ValueInputField
                        errors={rest.errors}
                        border={false}
                        style={{ width: "100%", background: "var(--main-purple-hover-color)" }}
                        label={label}
                        value={value}
                        hint={hint}
                        onChange={(e) => onChange(e.target.value)}
                    />
                );
            case "textarea":
                return (
                    <TextField
                        id="outlined-multiline-static"
                        label={label}
                        sx={{ width: "100%", borderRadius: "0 !important", borderColor: "var(--blue) !important" }}
                        multiline
                        rows={8}
                        defaultValue={value}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                    />
                );
            case "number":
                return (
                    <ValueInputField
                        errors={rest.errors}
                        border={false}
                        type={type}
                        style={{ width: "100%", background: "var(--main-purple-hover-color)" }}
                        label={label}
                        value={value}
                        hint={hint}
                        onChange={(e) => onChange(Number(e.target.value))}
                    />
                );
            case "checkbox":
                return (
                    <FormControl sx={{ width: "100%" }}>
                        <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontWeight: "500" }}>
                            {label}
                        </FormLabel>
                        <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" value={value} onChange={(event, value) => onChange(value === "true")}>
                            <FormControlLabel value control={<Radio color="warning" />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio color="warning" />} label="No" />
                        </RadioGroup>
                    </FormControl>
                );
            case "select":
                return (
                    <SelectInputField
                        errors={rest.errors}
                        border={false}
                        style={{ background: "var(--main-purple-hover-color)", height: "fit-content", width: "100%" }}
                        value={value}
                        label={label}
                        hint={hint}
                        items={rest.options}
                        onChange={(value) => onChange(value)}
                    />
                );
            default:
                return null;
        }
    };

    const formInputs = (dynamicForm: FormType) =>
        Object.keys(dynamicForm).map((e: any): any => {
            const field = dynamicForm[e];
            const { type, defaultValue, rules, order } = field;
            const label = field.title && field.title[lang || "en"];
            const fieldHint = field.hint && Object.keys(field.hint).length > 0 ? field.hint[lang || "en"] : "";
            if (type === "array") {
                return field.fields.map((item: FormType, index: number) => (
                    <section key={index} style={{ order: e === "feed_data" ? order + 1 : order }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "2px solid rgba(0, 0, 0, 0.20)",
                                height: "50px",
                                alignItems: "center",
                                mb: "1rem",
                                maxWidth: "640px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                    color: "#1A1F27",
                                }}
                            >
                                Feed data {index + 1}
                            </Typography>
                            {index === 0 ? (
                                <Box onClick={handleAddFeedData}>
                                    <AddBadge />
                                </Box>
                            ) : (
                                <Box onClick={() => handleRemoveFeedData(item.fieldId)}>
                                    <RemoveBadge />
                                </Box>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "1rem",
                                flexWrap: "wrap",
                            }}
                        >
                            <section key="YT_ID" style={{ flexBasis: "calc(50% - .5rem)", height: "fit-content" }}>
                                <Controller
                                    name={`YT_ID-${item.fieldId || "123"}`}
                                    control={control}
                                    rules={type !== "checkbox" ? item.YT_ID.rules : {}}
                                    defaultValue={item.YT_ID.defaultValue}
                                    render={({ field }) => (
                                        <Input
                                            errors={errors[`YT_ID-${item.fieldId}`] || {}}
                                            label={item.YT_ID.title && item.YT_ID.title[lang || "en"]}
                                            value={field.value}
                                            onChange={field.onChange}
                                            {...item.YT_ID}
                                            hint={fieldHint}
                                        />
                                    )}
                                />
                            </section>
                            <section key="href" style={{ flexBasis: "calc(50% - .5rem)", height: "fit-content" }}>
                                <Controller
                                    name={`href-${item.fieldId || "123"}`}
                                    control={control}
                                    rules={type !== "checkbox" ? item.href.rules : {}}
                                    defaultValue={item.href.defaultValue}
                                    render={({ field }) => (
                                        <Input
                                            errors={errors[`href-${item.fieldId}`] || {}}
                                            label={item.href.title && item.href.title[lang || "en"]}
                                            value={field.value}
                                            onChange={field.onChange}
                                            {...item.href}
                                            hint={fieldHint}
                                        />
                                    )}
                                />
                            </section>
                            <section key="start_time" style={{ flexBasis: "calc(50% - .5rem)", height: "fit-content" }}>
                                <Controller
                                    name={`start_time-${item.fieldId || "123"}`}
                                    control={control}
                                    rules={type !== "checkbox" ? item.start_time.rules : {}}
                                    defaultValue={item.start_time.defaultValue}
                                    render={({ field }) => (
                                        <Input
                                            errors={errors[`start_time-${item.fieldId}`] || {}}
                                            label={item.start_time.title && item.start_time.title[lang || "en"]}
                                            value={field.value}
                                            onChange={field.onChange}
                                            {...item.start_time}
                                            hint={fieldHint}
                                        />
                                    )}
                                />
                            </section>
                        </Box>
                    </section>
                ));
            }
            return (
                <section key={e} style={{ flexBasis: "calc(50% - .5rem)", height: "fit-content", order }}>
                    <Controller
                        name={e}
                        control={control}
                        rules={type !== "checkbox" ? rules : {}}
                        defaultValue={defaultValue}
                        render={({ field }) => <Input errors={errors[e] || {}} label={label} value={field.value} onChange={field.onChange} {...dynamicForm[e]} hint={fieldHint} />}
                    />
                </section>
            );
        });

    let showMoreOrder = 30;
    if (dynamicForm.main.feed_data && dynamicForm.main.feed_data.order) {
        showMoreOrder = dynamicForm.main.feed_data.order;
    }

    return (
        <form style={{ ...boxStyle, width: "60%", flexWrap: "wrap" }}>
            <Autocomplete
                value={configs.client}
                color="warning"
                disablePortal
                id="combo-box-demo"
                options={clientList}
                sx={{ width: "100%", flexBasis: "calc(50% - .5rem)" }}
                onChange={(e, value) => handleChangeField("client", value, configs, setConfigs)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        disabled={mode === "edit"}
                        InputProps={{
                            ...params.InputProps,
                            sx: {
                                borderRadius: "0",
                            },
                        }}
                        color="warning"
                        label={t("forms.client_account.select_client")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
            />
            <Autocomplete
                value={configs.placement_name}
                color="warning"
                disablePortal
                id="combo-box-demo"
                options={placementList}
                sx={{ width: "100%", flexBasis: "calc(50% - .5rem)" }}
                onChange={(e, value) => handleChangeField("placement_name", value, configs, setConfigs)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        disabled={mode === "edit"}
                        InputProps={{
                            ...params.InputProps,
                            sx: {
                                borderRadius: "0",
                            },
                        }}
                        color="warning"
                        label={t("forms.client_account.select_placement")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
            />
            <SelectInputField
                border={false}
                style={{ background: "var(--main-purple-hover-color)", height: "fit-content", width: "100%", flexBasis: "calc(50% - .5rem)" }}
                value={configs.template_mobile}
                label="forms.product.mobile_template"
                items={mobileTemplates}
                onChange={(value) => handleChangeField("template_mobile", value, configs, setConfigs)}
            />
            <SelectInputField
                border={false}
                style={{ background: "var(--main-purple-hover-color)", height: "fit-content", width: "100%", flexBasis: "calc(50% - .5rem)" }}
                value={configs.template_desktop}
                label="forms.product.desktop_template"
                items={desktopTemplates}
                onChange={(value) => handleChangeField("template_desktop", value, configs, setConfigs)}
            />
            {formInputs(dynamicForm.main)}
            {Object.keys(dynamicForm.additional).length > 0 && (
                <Box sx={{ order: showMoreOrder, width: "100%" }}>
                    <Typography
                        onClick={() => setIsShowMore({ ...isShowMore, desktop: !isShowMore.desktop })}
                        sx={{ cursor: "pointer", width: "350px", fontWeight: "500", display: "flex", gap: ".5rem", alignItems: "center" }}
                    >
                        {t("pages.product.more_desktop")}
                        {isShowMore.desktop ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Typography>
                    <Collapse
                        in={isShowMore.desktop}
                        timeout={200}
                        sx={{
                            pt: "1.25rem",
                            ".MuiCollapse-wrapperInner": {
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "1rem",
                            },
                        }}
                    >
                        {formInputs(dynamicForm.additional)}
                    </Collapse>
                </Box>
            )}
            {Object.keys(dynamicForm.additional_mobile).length > 0 && (
                <Box sx={{ order: showMoreOrder, width: "100%" }}>
                    <Typography
                        onClick={() => setIsShowMore({ ...isShowMore, mobile: !isShowMore.mobile })}
                        sx={{ cursor: "pointer", width: "350px", fontWeight: "500", display: "flex", gap: ".5rem", alignItems: "center" }}
                    >
                        {t("pages.product.more_mobile")}
                        {isShowMore.mobile ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Typography>
                    <Collapse
                        in={isShowMore.mobile}
                        timeout={200}
                        sx={{
                            pt: "1.25rem",
                            ".MuiCollapse-wrapperInner": {
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "1rem",
                            },
                        }}
                    >
                        {formInputs(dynamicForm.additional_mobile)}
                    </Collapse>
                </Box>
            )}
        </form>
    );
};

export default PVWConfigsForm;
