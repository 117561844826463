import { Box, Typography } from "@mui/material";
import React, {useContext} from "react";
import { Navigate, useLocation } from "react-router-dom";
import LoginForm from "./LoginForm";
import {AuthContext} from "../../context/AuthContext";

const LoginPage = () => {
    const {me, isLoggedIn} = useContext(AuthContext);
    const location = useLocation();
    if (Object.keys(me).length > 0 && isLoggedIn) {
        return <Navigate to="/" replace state={{ from: location }} />;
    }
    return (
        <Box
            sx={{
                backgroundColor: "#D7DDE9",
                width: "100%",
                height: "100vh",
                display: "flex",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    width: "360px",
                    m: "0 auto",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        mb: "1.5rem",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "4rem",
                            fontWeight: "500",
                            color: "var(--main-purple-color)",
                        }}
                    >
                        TDS
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: "4rem",
                            fontWeight: "500",
                            color: "#1A1F27",
                        }}
                    >
                        Group
                    </Typography>
                </Box>
                <LoginForm />
            </Box>
        </Box>
    );
};

export default LoginPage;
