import {Box, Skeleton, Typography} from "@mui/material";
import {ReactComponent as EllispeIcon} from "../../../../assets/images/icons/common/ellipse1.svg";
import {ReactComponent as GraphIcon} from "../../../../assets/images/icons/common/graphIcon.svg";

export type CardType = {
    label?: string;
    value: number;
    color?: string;
    status?: string;
};
const Card = ({ index, card, loading = true }: { index: number; loading: boolean; card: CardType }) => {
    const { label = "", value = 0, color = "black", status } = card;
    return (
        <Box
            key={index}
            sx={{
                p: "3rem",
                display: "flex",
                flexDirection: "column",
                background: color,
                width: "100%",
                height: "100%",
                minHeight: "220px",
                position: "relative",
                gap: "1.5rem",
                overflow: "hidden",
                boxSizing: "border-box"
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    zIndex: "123",
                    right: "-82px",
                    top: "8px",
                }}
            >
                <EllispeIcon />
            </Box>
            <Box
                sx={{
                    position: "absolute",
                    zIndex: "124",
                    right: "-34px",
                    bottom: "-140px",
                }}
            >
                <EllispeIcon style={{ width: "233px", height: "233px" }} />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    sx={{
                        color: "#fff",
                        fontSize: "1.25rem",
                        fontWeight: "400",
                    }}
                >
                    {loading ? <Skeleton variant="rectangular" width={140} height={30} /> : label}
                </Typography>
                <GraphIcon />
            </Box>
            <Typography
                sx={{
                    color: "#fff",
                    fontSize: "2.25rem",
                    fontWeight: "500",
                }}
            >
                {loading ? <Skeleton width={50} height={54} /> : value.toLocaleString()}
            </Typography>
            <Typography
                sx={{
                    color: "#fff",
                    fontSize: "1rem",
                    fontWeight: "500",
                }}
            >
                {loading ? <Skeleton width={100} height={24} /> : status}
            </Typography>
        </Box>
    );
};

export default Card;
