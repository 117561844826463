import { Box, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { FC } from "react";
import { ReactComponent as NotificationNote } from "../../../assets/images/icons/notification-icon.svg";

type NotificationType = {
    id: number;
    title: string;
    message: string;
    date?: string;
    open?: boolean;
    isViewed?: boolean;
};

type NotificationEntityProps = {
    notification: NotificationType;
    isArchive?: boolean;
    closeHandler?: Function | null;
};

const NotificationEntity: FC<NotificationEntityProps> = ({ notification, isArchive = false, closeHandler = null }) => {
    let formattedDate;
    if (notification.date) {
        formattedDate = dayjs(notification.date).format("DD MMM YYYY");
    }
    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                p: 4,
                backgroundColor: "white",
                gap: "24px",
            }}
        >
            <Box sx={{ width: 48, height: 48 }}>
                <NotificationNote />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    width: "100%",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>{notification.title}</Typography>
                    {!isArchive && closeHandler && (
                        <Button onClick={() => closeHandler(notification.id)}>
                            <CloseIcon />
                        </Button>
                    )}
                </Box>
                <Typography>{notification.message}</Typography>
                <Typography sx={{ display: "flex", alignSelf: "flex-end" }}>{formattedDate}</Typography>
            </Box>
        </Box>
    );
};
export default NotificationEntity;
