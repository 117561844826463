export const boxStyle = {
    padding: "3rem",
    background: "#fff",
    maxWidth: "736px",
    gap: "1rem",
    display: "flex",
    height: "100%",
};

export const actionBtnStyle = {
    padding: "9px 24px",
    background: "var(--button-background-color)",
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
    border: "none",
    cursor: "pointer",
};

export const t = {}

