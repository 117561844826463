import { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PaymentDetailsForm from "../../../../components/ClientCreateForm/PaymentDetailsForm";
import { ClientCreateContext } from "../../../../../../context/ClientCreateContext";

const PaymentDetailsCreateController = () => {
    const { client, paymentDetails, setPaymentDetails, countries, setCountries, currencies } = useContext(ClientCreateContext);
    const { t } = useTranslation();
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: "1.25rem",
                    fontWeight: "500",
                    mb: "3rem",
                }}
            >
                {t("client_create.payment")}
            </Typography>
            <PaymentDetailsForm client={client} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} countries={countries} setCountries={setCountries} currencies={currencies} />
        </Box>
    );
};

export default PaymentDetailsCreateController;
