import axios from "axios";
import BASE_URL from "../../../data/constants";

export const getNetworkCodes = async (data: Record<string, any> = {}, signal: any = null) => {
    try {
        const res = await axios.post(`${BASE_URL}/properties/new_network_codes/`, data, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getGoogleDeductions = async (data: Record<string, any> = {}, signal: any = null) => {
    try {
        const res = await axios.post(`${BASE_URL}/properties/new_google_deductions/`, data, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};
