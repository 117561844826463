import {Box, Typography} from "@mui/material";
import {useFieldArray, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useMemo, useState} from "react";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {useTranslation} from "react-i18next";
import Fields from "../../components/Fields";
import CloseButton from "../../../../UI/Buttons/Actions/CloseButton";
import {TopBarContext} from "../../../../context/TopBarContext";
import {actionBtnStyle} from "../../services/constants";
import {createProductFormData, editProductFormData,} from "../../services/fetchers";
import {AuthContext} from "../../../../context/AuthContext";
import {generateId} from "../../../../utils/helpers";

const InImageForm = ({formFields, client, placement_name}: any) => {
    const {
        control,
        handleSubmit,
        getValues,
        watch,
        reset,
        unregister,
        formState: { errors },
    } = useForm<any>({
        defaultValues: {} as any
    });
    const {
        fields: feedDataFields, append: feedDataAppend, remove: feedDataRemove
    } = useFieldArray({
        control,
        name: "feed_data"
    });
    const {
        fields: adPlatformsFields, append: adPlatformsAppend, remove: adPlatformsRemove
    } = useFieldArray({
        control,
        name: "ad_platforms"
    });
    const {
        fields: adPlatformsNonRfFields, append: adPlatformsNonRfAppend, remove: adPlatformsNonRfRemove
    } = useFieldArray({
        control,
        name: "ad_platforms_non_rf"
    });
    const { setNotification } = useContext(AuthContext);
    const { handleSetActions } = useContext(TopBarContext);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { id } = useParams();
    const mode = id ? "edit" : "create";
    const formValues = getValues();
    const overlay_platform = watch("overlay_platform");
    const type_height_half_img_desktop = watch("type_height_half_img_desktop");
    const type_height_half_img_mobile = watch("type_height_half_img_mobile");
    const enable_hb = watch("enable_hb");

    useEffect(() => {
        const ActionButton = (
            <button style={{ ...actionBtnStyle, textTransform: "uppercase" }} type="submit" onClick={handleSubmit(onSubmit)}>
                <Typography sx={{ fontWeight: "500", color: "#fff" }}>{t(mode === "edit" ? "button.edit" : "button.save")}</Typography>
                {mode === "edit" ? <EditIcon style={{ color: "#fff" }} /> : <SaveIcon style={{ color: "#fff" }} />}
            </button>
        );
        handleSetActions([ActionButton, <CloseButton path="/products/in_image" />]);
    }, [client, placement_name]);

    useEffect(() => {
        Object.keys(formFields).forEach(key => {
            if (Object.hasOwn((formFields as any)[key], "dependencies")) {
                (formFields as any)[key].dependencies.forEach((dependency: any) => {
                    if ((formValues as any)[dependency.fieldKey] !== undefined) {
                        if (Object.hasOwn(dependency, "exclude") && dependency.exclude) {
                            if (dependency.value === (formValues as any)[dependency.fieldKey]) {
                                if (!dependency.rendered) {
                                    unregister(key as any);
                                    dependency.rendered = true;
                                } else {
                                    dependency.rendered = false;
                                }
                            }
                        } else if (dependency.value !== (formValues as any)[dependency.fieldKey]) {
                            if (!dependency.rendered) {
                                unregister(key as any);
                                dependency.rendered = true;
                            } else {
                                dependency.rendered = false;
                            }
                        }
                    } else {
                        dependency.rendered = false;
                    }
                })
            }
        })
    }, [formValues])

    // eslint-disable-next-line consistent-return
    const onSubmit = async (data: any) => {
        console.log("onSubmit", data);
        if (loading) {
            return null;
        }
        try {
            setLoading(true);
            let isOk = true;
            Object.keys(formFields).forEach(fieldKey => {
                if (isOk) {
                    const field = formFields[fieldKey];
                    if (field.typeSend === "object") {
                        if (fieldKey in data) {
                            try {
                                data[fieldKey] = JSON.parse(data[fieldKey]);
                            } catch (e) {
                                setNotification({
                                    type: "error",
                                    message: `С полем ${field.title.ru} произошла ошибка!`,
                                    open: true,
                                });
                                isOk = false
                            }
                        }
                    }
                }
            })

            if (!isOk) {
                return null;
            }
            const backendData = {
                ...data,
                client,
                placement_name
            }
            let res;
            if (mode === "create") {
                res = await createProductFormData("in_image", backendData);
            } else {
                res = await editProductFormData("in_image", id, backendData);
            }
            setNotification({
                type: "success",
                message: res.message,
                open: true,
            });
            navigate("/products/in_image");
        } catch (err: any) {
            setNotification({
                type: "error",
                message: err.message,
                open: true,
            });
        } finally {
            setLoading(false);
        }
    }

    const fields = useMemo(() => ({
        ...formFields,

    }), [formFields])

    const general = Object.fromEntries(Object.entries(fields).filter((field: any) => field[1].category === "general"));
    const desktop = Object.fromEntries(Object.entries(fields).filter((field: any) => field[1].category === "desktop"));
    const mobile = Object.fromEntries(Object.entries(fields).filter((field: any) => field[1].category === "mobile"));
    return (
        <>
            <form
                style={{pointerEvents: loading ? "none" : "auto", opacity: loading ? ".4" : "1", display: "flex", flexWrap: "wrap"}}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Box sx={{display: "flex", gap: "1rem", flexWrap: "wrap", p: "0", order: 1, mb: "1rem"}}>
                    <Typography sx={{fontSize: "1.5rem", width: "100%", fontWeight: "500"}}>General fields</Typography>
                    <Fields formFields={general} errors={errors} formValues={formValues} control={control} />
                </Box>
                <Box sx={{display: "flex", gap: "1rem", flexWrap: "wrap", p: "0", order: 2, mb: "1rem"}}>
                    <Typography sx={{fontSize: "1.5rem", width: "100%", fontWeight: "500"}}>Desktop fields</Typography>
                    <Fields formFields={desktop} errors={errors} formValues={formValues} control={control} />
                </Box>
                <Box sx={{display: "flex", gap: "1rem", flexWrap: "wrap", p: "0", order: 3, mb: "1rem"}}>
                    <Typography sx={{fontSize: "1.5rem", width: "100%", fontWeight: "500"}}>Mobile fields</Typography>
                    <Fields formFields={mobile} errors={errors} formValues={formValues} control={control} />
                </Box>
            </form>
        </>
    )
}

export default InImageForm;