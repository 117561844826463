import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import PartnerPaymentDetails from "../partner/pages/PaymentDetails";
import PaymentDetailsPage from "../pages/ClientViewPage/pages/PaymentDetailsPage";

const PaymentDetailsView = () => {
    const { me } = useContext(AuthContext);

    if (Object.keys(me).length > 0 && me.role === "client") {
        return <PartnerPaymentDetails />;
    }
    return <PaymentDetailsPage />;
};

export default PaymentDetailsView;
