import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Box } from "@mui/material";
import BASE_URL from "../../../data/constants";
import { TopBarContext } from "../../../context/TopBarContext";
import AccountCard from "./components/AccountCard";
import Loading from "../../../components/Loading";
import ContentContainer from "../../../components/ContentContainer/ContentContainer";
import { AccountShortCard } from "./types";

const PartnerAccountsPage = () => {
    const { handleChangePath, handleSetActions, handleChangeTabs } = useContext(TopBarContext);
    const [accounts, setAccounts] = useState<AccountShortCard[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleChangePath(["my_accounts"]);
        handleSetActions([]);
        handleChangeTabs([]);
    }, []);

    const getAccounts = async () => {
        try {
            setLoading(true);
            const res = await axios.post(`${BASE_URL}/clients/`, {});
            const data = await res.data;
            setAccounts(data);
        } catch (e: unknown) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            await getAccounts();
        })();
    }, []);

    return (
        <ContentContainer>
            <Box
                sx={{
                    display: "flex",
                    gap: ".75rem",
                    flexWrap: "wrap",
                }}
            >
                {accounts.length > 0 && accounts.map((account) => <AccountCard account={account} />)}
            </Box>
            {loading && (
                <Box
                    sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Loading />
                </Box>
            )}
        </ContentContainer>
    );
};

export default PartnerAccountsPage;
