import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../../../context/AuthContext";
import { TopBarContext } from "../../../../context/TopBarContext";
import CloseButton from "../../../../UI/Buttons/Actions/CloseButton";
import BASE_URL from "../../../../data/constants";

const tabs = [
    {
        tab: "Client",
        key: "client",
        label: "client",
    },
    {
        tab: "Account",
        key: "account_details",
        label: "account",
    },
    {
        tab: "Contacts",
        key: "contacts",
        label: "contacts",
    },
    {
        tab: "Payment_Details",
        key: "payment_details",
        label: "payment_details",
    },

    {
        tab: "Agreements",
        key: "agreements",
        label: "agreements",
    },
    {
        tab: "Fees",
        key: "conditions",
        label: "fees",
    },
    {
        tab: "Placements",
        key: "platforms",
        label: "placements",
    },
    {
        tab: "Invoices",
        key: "invoices",
        label: "invoices",
    },
];

export interface IClientInfo {
    id: string;
    name: string;
    organization_type: string;
    client_tabs?: Record<string, boolean>;
}

const ClientPageLayout = () => {
    const { appState, me, setNotFound } = useContext(AuthContext);
    const { handleChangePath, handleChangeTabs, handleSetActions } = useContext(TopBarContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [clientInfo, setClientInfo] = useState<IClientInfo>({} as IClientInfo);
    const [activeTabs, setActiveTabs] = useState<Record<string, any>[]>([]);

    const getClientInfo = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/${id}/info/`);
            return res.data;
        } catch (err: any) {
            if (err.response.status === 404) {
                setNotFound(true);
            }
            return err;
        }
    };

    useEffect(() => {
        (async () => setClientInfo(await getClientInfo()))();
    }, [id]);

    useEffect(() => {
        let newTabs = tabs;
        if (me.role === "client") {
            newTabs = tabs.filter((tab) => {
                if (tab.key === "account_details") {
                    return null;
                }
                if (clientInfo.client_tabs && clientInfo.client_tabs[tab.tab]) {
                    return tab;
                }
                return null;
            });
        }
        setActiveTabs(newTabs);
        handleChangeTabs(newTabs);
        handleChangePath([clientInfo?.name || ""]);
        handleSetActions([<CloseButton path="/clients" />]);
    }, [clientInfo]);

    useEffect(() => {
        if (pathname.split("/").length === 3) {
            navigate(`/clients/${id}/client`, { replace: true });
        }
    }, [appState]);

    useEffect(() => {
        const pathArr = pathname.split("/");
        if (activeTabs.length > 0 && activeTabs.findIndex((item) => item.key === pathArr[pathArr.length - 1]) === -1) {
            navigate(`/clients/${id}/${activeTabs[0]?.key}`);
        }
    }, [pathname, activeTabs]);

    return <Outlet />;
};

export default ClientPageLayout;
