import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import TopBarCreate from "../../components/TopBars/TopBarCreate";
import { AuthContext } from "../../../../context/AuthContext";
import BASE_URL from "../../../../data/constants";
import ClientCreateProvider from "../../../../context/ClientCreateContext";
import ContentContainer from "../../../../components/ContentContainer/ContentContainer";
import DraftModal from "../../components/DraftModal";
import Loading from "../../../../components/Loading";
import ClientCreateForm from "./components/ClientCreateStepper";
import { TopBarContext } from "../../../../context/TopBarContext";

const ClientCreatePage = () => {
    const { setShow } = useContext(TopBarContext);
    const { setNotification } = useContext(AuthContext);
    const [openDraftModal, setOpenDraftModal] = useState(true);
    const [loading, setLoading] = useState(true);
    const [draftData, setDraftData] = useState({});
    const handleClose = () => {
        setOpenDraftModal(false);
    };

    const checkDraftClient = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/draft/check/`);
            return await res.data;
        } catch (e: any) {
            console.log(e.message);
            return e;
        }
    };

    useEffect(() => {
        setShow(false);
        (async () => {
            const res = await checkDraftClient();
            if (res.draft) {
                setOpenDraftModal(true);
            } else {
                setOpenDraftModal(false);
            }
            setLoading(false);
        })();
    }, []);

    const getDraft = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/draft/`);
            return await res.data;
        } catch (e: any) {
            console.error(e.message);
            return e;
        }
    };
    const deleteDraft = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/draft/delete/`);
            const data = await res.data;
            setNotification({
                open: true,
                type: "success",
                message: data.message,
                duration: 3000,
            });
        } catch (e: any) {
            setNotification({
                open: true,
                type: "error",
                message: e.message,
                duration: 3000,
            });
        }
    };
    const handleClickContinue = async () => {
        setDraftData(await getDraft());
        handleClose();
    };

    const handleClickRemoveDraft = async () => {
        await deleteDraft();
        handleClose();
    };
    return !loading ? (
        <ClientCreateProvider draftData={draftData}>
            <TopBarCreate />
            <ContentContainer>
                <ClientCreateForm />
            </ContentContainer>
            <DraftModal openDraftModal={openDraftModal} handleClickContinue={handleClickContinue} handleClickRemoveDraft={handleClickRemoveDraft} handleClose={handleClose} />
        </ClientCreateProvider>
    ) : (
        <Loading />
    );
};

export default ClientCreatePage;
