import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useParams } from "react-router-dom";
import BASE_URL from "../../../../../data/constants";
import ContentContainer from "../../../../../components/ContentContainer/ContentContainer";
import { getValue } from "../../../services/helpers";
import CardDetails from "../../components/CardDetails";
import { CardType } from "../../types";

const PartnerAccountPage = () => {
    const { t } = useTranslation();
    const [account, setAccount] = useState<CardType[]>([]);
    const { id } = useParams();

    const getClient = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/${id}/`);
            return await res.data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const accountParser = (data: any) => [
        {
            key: "client_name",
            value: getValue(data.name),
        },
        {
            key: "type_of_organization",
            value: getValue(data.organization_type),
        },
        {
            key: "telegram_group",
            value: getValue(data.tg_link),
        },
        {
            key: "account_manager",
            value: [
                {
                    key: "full_name",
                    value: getValue(data.account_manager.user__username),
                },
                {
                    key: "telegram_manager",
                    value: getValue(data.account_manager.user__tg_login),
                },
                {
                    key: "email_manager",
                    value: getValue(data.account_manager.user__email),
                },
            ],
        },
        {
            key: "sales_manager",
            value: [
                {
                    key: "full_name",
                    value: getValue(data.sales_managers && data?.sales_managers?.user__username),
                },
                {
                    key: "telegram_manager",
                    value: getValue(data.sales_managers && data?.sales_managers?.user__tg_login),
                },
                {
                    key: "email_manager",
                    value: getValue(data.sales_managers && data.sales_managers.user__email),
                },
            ],
        },
    ];

    useEffect(() => {
        if (id) {
            (async () => {
                setAccount(accountParser(await getClient()));
            })();
        }
    }, []);

    return (
        <ContentContainer>
            <CardDetails cols={1} title={t("client")} items={account} />
        </ContentContainer>
    );
};

export default PartnerAccountPage;
