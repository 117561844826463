import React, {useContext, useEffect, useState} from "react";
import CheckIcon from "@mui/icons-material/Check";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {useSearchParams} from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import {TopBarContext} from "../../../../context/TopBarContext";
import {Dictionary} from "../../../ClientsPage";
import {TableDataType} from "../../../../components/TableConstructor/types";
import {initialTableData} from "../../../../components/TableConstructor/constants";
import ContentContainer from "../../../../components/ContentContainer/ContentContainer";
import TableConstructor from "../../../../components/TableConstructor";
import CustomButton from "../../../../UI/Buttons/CustomButton";
import getInvoicesForPayout from "../../services/fetchers";
import {AuthContext} from "../../../../context/AuthContext";
import {ReactComponent as ExportIcon} from "../../../../assets/images/icons/common/export.svg";

const PayoutPage = () => {
    const { handleChangePath, handleSetActions, handleChangeTabs } = useContext(TopBarContext);
    const { setNotification } = useContext(AuthContext);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState<Dictionary<any>>({
        periods: {
            date_from: dayjs().startOf("month"),
            date_to: dayjs().add(-1, "day"),
            currency: "",
        },
        filters: {},
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [tableData, setTableData] = useState<TableDataType>(initialTableData);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [payoutTotal, setPayoutTotal] = useState<number>(0);

    useEffect(() => {
        handleChangePath(["pages.dashboard.payout"]);
        handleSetActions([]);
        handleChangeTabs([]);
    }, []);

    const loadClients = async (signal: AbortSignal | undefined) => {
        setLoading(true);
        const data = {
            ...request,
            filters: request.filters || {},
        };
        try {
            const res = await getInvoicesForPayout(data, signal);
            setTableData(res);
            if (res.all_counts > res.size) {
                setSearchParams({
                    ...searchParams,
                    page: res.page || 1,
                    size: res.size || 50,
                });
            }
            setSelectedRows([]);
            setLoading(false);
        } catch (e) {
            setError("Error");
            setLoading(false);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        (async () => {
            await loadClients(controller.signal);
        })();
        return () => controller.abort();
    }, [request]);

    const submitInvoicesPayout = async (url: string) => {
        try {
            const res = await axios.post(url, {
                invoice_ids: selectedRows,
            });
            await loadClients(undefined);
            setNotification({
                open: true,
                type: "success",
                message: res.data.message,
            });
        } catch (e: any) {
            setNotification({
                open: true,
                type: "error",
                message: e.response.data.message,
            });
        }
    };

    const calcPayoutTotal = () => {
        let res = 0;
        tableData.rows.forEach(row => {
            if (selectedRows.includes(row.id)) {
                res += row.reward;
            }
        })
        setPayoutTotal(res);
    }

    useEffect(() => {
        calcPayoutTotal()
    }, [selectedRows])

    const handleConfirmPayout = async () => {
        const url = "documents/payout/pay_complete/";
        await submitInvoicesPayout(url);
    };

    const handleCancelPayout = async () => {
        const url = "documents/payout/pay_cancel/";
        await submitInvoicesPayout(url);
    };

    const handleExportCSV = async () => {
        try {
            const url = "documents/payout/save/";
            const response = await axios.post(url, {invoice_ids: selectedRows}, {
                responseType: "blob",
            });
            const reader = new FileReader();
            reader.onload = () => {
                const filename = response.headers["content-disposition"].split("filename=")[1];
                const link = window.URL.createObjectURL(
                    new Blob([JSON.parse(reader.result as string)], {
                        type: "application/json",
                    })
                );
                const a = document.createElement("a");
                a.href = link;
                a.download = filename || "test.json";
                a.click();
                URL.revokeObjectURL(link);
            };
            reader.readAsText(response.data);
        } catch (e: any) {
            setNotification({
                type: "error",
                open: true,
                message: e.message
            })
        }
    };

    const ActionButtons = [
        <CustomButton mode="dark" Icon={<ExportIcon />} label="button.export" onChange={handleExportCSV} />,
        <CustomButton Icon={<CheckIcon />} label="button.payout" onChange={handleConfirmPayout} />,
        <CustomButton
            Icon={
                <DeleteOutlineIcon
                    style={{
                        width: "1.25rem",
                        height: "1.25rem",
                        marginRight: ".5rem",
                    }}
                />
            }
            mode="dark"
            label="button.cancel"
            onChange={handleCancelPayout}
        />,
    ];

    return (
        <ContentContainer>
            <TableConstructor
                payoutTotal={payoutTotal}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                tableData={tableData}
                loading={loading}
                request={request}
                setRequest={setRequest}
                ActionButton={ActionButtons}
            />
        </ContentContainer>
    );
};

export default PayoutPage;
