import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import {AuthContext} from "../../context/AuthContext";
import {TopBarContext} from "../../context/TopBarContext";

const ReferralSystemLayoutPage = () => {
    const { appState } = useContext(AuthContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { handleChangeTabs, handleSetActions } = useContext(TopBarContext);
    useEffect(() => {
        handleChangeTabs([
            {
                key: "details",
                label: "details",
            },
            {
                key: "my-referrals",
                label: "my-referrals",
            },
        ]);
        handleSetActions([]);
    }, []);

    useEffect(() => {
        if (pathname.split("/").length === 2) {
            navigate("/referral-system/details", { replace: true });
        }
    }, [appState]);

    return <Outlet />;
};

export default ReferralSystemLayoutPage;
