import { Box, createTheme, ThemeProvider } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { ClientCreateContext } from "../../../../../../context/ClientCreateContext";
import VerticalLinearStepper from "../../../../../../components/VerticalStepper";
import ClientCreateController from "../ClientCreateController";
import ContactsCreateController from "../ContactsCreateController";
import ContractCreateController from "../ContractCreateController";
import ConditionsCreateController from "../ConditionsCreateController";
import PlatformsCreateController from "../PlatformsCreateController";
import PaymentDetailsCreateController from "../PaymentDetailsCreateController";
import ClientAccountForm from "../../../../components/ClientCreateForm/ClientAccountForm";

const ClientCreateForm = () => {
    const { step, client } = useContext(ClientCreateContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const CurrentStepComponent = useMemo(() => {
        setSearchParams({ step: String(step || 1) });
        switch (true) {
            case step === 1:
                return <ClientCreateController />;
            case step === 2 && client.companyType !== "Bonus" && client.companyType !== "Piggy bank":
                return <ClientAccountForm />;
            case step === 3 && client.companyType !== "Bonus" && client.companyType !== "Piggy bank":
                return <PaymentDetailsCreateController />;
            case step === 4:
                return <ContactsCreateController />;
            case step === 5 && client.companyType !== "Bonus" && client.companyType !== "Piggy bank":
                return <ContractCreateController />;
            case step === 6 && client.companyType !== "Bonus" && client.companyType !== "Piggy bank":
                return <ConditionsCreateController />;
            case step === 7:
                return <PlatformsCreateController />;
            default:
                return <ClientCreateController />;
        }
    }, [step, client.companyType]);
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "#fff",
                p: "3rem",
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "640px",
                }}
            >
                {CurrentStepComponent}
            </Box>
            <VerticalLinearStepper />
        </Box>
    );
};

export default ClientCreateForm;
