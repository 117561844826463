import { useTranslation } from "react-i18next";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { thickRow, thinRow } from "../../constants";

type DensePaddingProps = {
    rowDense: number;
    setRowDense: (value: number) => void;
};

const DensePadding: FC<DensePaddingProps> = ({ rowDense = thickRow, setRowDense = (value: number) => {} }) => {
    const { t } = useTranslation();
    const checked = rowDense !== thickRow;

    const changeDenseHandler = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setRowDense(checked ? thinRow : thickRow);
    };

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Switch
                        checked={checked}
                        onChange={changeDenseHandler}
                        sx={{
                            ".MuiSwitch-thumb": {
                                color: checked ? "var(--main-purple-color)" : "#fff",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                backgroundColor: "var(--main-purple-color)",
                            },
                        }}
                    />
                }
                label={t("dense_padding")}
            />
        </FormGroup>
    );
};

export default DensePadding;
