import { Box } from "@mui/material";
import React, { useCallback } from "react";
import ColumnSelection from "../ColumnSelection";
import ColumnFilter from "../ColumnFilter";
import Period from "../Period";
import ReportFilter from "../ReportFilter";
import Metrics from "../Metrics";
import Grouping from "../Grouping";
import DensePadding from "../DensePadding";
import { thickRow } from "../../constants";
import PayoutTotal from "../PayoutTotal";
import PeriodYearMonth from "../PeriodYearMonth";
import PickCurrency from "../PickCurrency/PickCurrency";

const TableFilterPanel = ({
    setHeaders = () => {},
    rowDense = thickRow,
    setRowDense = () => {},
    loading = false,
    rows = [],
    tableActions = {},
    newHeaders = [],
    headers = [],
    request = {},
    setRequest = () => {},
    ActionButton = null,
    payoutTotal=0
}: any) => {
    const renderActionButtons = useCallback(() => {
        if (tableActions.add && ActionButton) {
            if (Array.isArray(ActionButton) && ActionButton.length > 0) {
                return ActionButton.map((btn) => btn);
            }
            return (
                // <Restricted to={'canCreate'}>
                ActionButton
                // </Restricted>
            );
        }
        return null;
    }, [ActionButton]);
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: ".75rem",
                opacity: loading ? ".5" : "1",
                pointerEvents: loading ? "none" : "auto",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "2px",
                    alignItems: "center",
                }}
            >
                {tableActions.period && <Period setRequest={setRequest} request={request} />}
                {tableActions.period_year_month && <PeriodYearMonth request={request} setRequest={setRequest} />}
                {tableActions.is_column_selection && <ColumnSelection headers={headers} setHeaders={setHeaders} />}
                {tableActions.is_filter && <ColumnFilter headers={newHeaders} setRequest={setRequest} request={request} />}
                {rows.length >= 14 && <DensePadding setRowDense={setRowDense} rowDense={rowDense} />}
                {tableActions.payout_total && <PayoutTotal payoutTotal={payoutTotal} />}
                {tableActions.is_report_filter && <ReportFilter setRequest={setRequest} request={request} />}
                {tableActions.grouping && <Grouping setRequest={setRequest} request={request} />}
                {tableActions.metrics && <Metrics setRequest={setRequest} request={request} />}
                {tableActions.is_currency && <PickCurrency request={request} setRequest={setRequest} />}
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".25rem",
                }}
            >
                {renderActionButtons()}
            </Box>
        </Box>
    );
};

export default TableFilterPanel;
