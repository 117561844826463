import { Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClientCreateContext } from "../../../../../../context/ClientCreateContext";
import ContactsForm from "../../../../components/ClientCreateForm/ContactsForm";

const ContactsCreateController = () => {
    const { contactPeople, setContactPeople } = useContext(ClientCreateContext);
    const { t } = useTranslation();
    return (
        <>
            <Typography
                sx={{
                    fontSize: "1.25rem",
                    fontWeight: "500",
                    mb: "3rem",
                }}
            >
                {t("client_create.contacts")}
            </Typography>
            <ContactsForm contactPeople={contactPeople} setContactPeople={setContactPeople} />
        </>
    );
};

export default ContactsCreateController;
