import React from "react";
import CustomButton from "../CustomButton";
import { ReactComponent as DraftIconBlack } from "../../../assets/images/icons/draftIconBlack.svg";

interface DraftButtonProps {
    action: () => void;
}

const DraftButton: React.FC<DraftButtonProps> = ({ action }) => <CustomButton mode="dark" Icon={<DraftIconBlack />} label="button.draft" onChange={action} />;
export default DraftButton;
