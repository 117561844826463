import { MenuItem, TextField, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { DeepRequired, FieldErrorsImpl, FieldValues, GlobalError } from "react-hook-form";
import {Option} from "../../../services/common-types";

interface Props {
    errors?: (Partial<FieldErrorsImpl<DeepRequired<FieldValues>>> & { root?: Record<string, GlobalError> & GlobalError }) | Record<string, any>;
    value: string | number | boolean | null | undefined;
    label: string;
    style?: React.CSSProperties;
    items: Option[] | string[];
    readOnly?: boolean;
    onChange?: (value: string) => void;
    border?: boolean;
    hint?: string;
}
const SelectInputField = ({ errors = {}, border = true, value = "", label, style = {}, items, onChange = (e) => {}, readOnly = false, hint = "" }: Props) => {
    const { t } = useTranslation();
    const lang = localStorage.getItem("lng");

    const defaultStyle = {
        width: "312px",
    };
    const isError = Object.keys(errors).length > 0;
    const textField = (
        <TextField
            error={isError}
            helperText={(errors?.message && errors?.message[lang || "en"]) || ""}
            id={`outlined-select-${label}`}
            select
            label={t(label)}
            value={value}
            sx={{
                ...defaultStyle,
                ...style,
            }}
            color="warning"
            InputLabelProps={{
                color: "primary",
            }}
            FormHelperTextProps={{
                sx: {
                    backgroundColor: "#fff",
                    m: "0",
                    width: "100%",
                },
            }}
            InputProps={{
                readOnly,
                sx: {
                    borderRadius: "0",
                    ".MuiOutlinedInput-notchedOutline": {
                        borderWidth: border ? "1px" : "0px",
                    },
                    ".Mui-focused": {
                        borderWidth: "1px",
                    },
                    ".MuiFormHelperText-root": {
                        backgroundColor: "#fff",
                        m: "0",
                        width: "100%",
                    },
                },
            }}
            onChange={(e) => (e.target.value === "clear" ? onChange("") : onChange(e.target.value))}
        >
            <MenuItem key="clear" value="clear">
                {t("clear_selection")}
            </MenuItem>
            {items.map((option: Option | string, index: number) => {
                let value = typeof option === "object" ? option.value : option;
                const name = typeof option === "object" ? option.name : option;
                if (!value) {
                    value = name;
                }
                return (
                    <MenuItem key={index} value={value as string | number}>
                        {name}
                    </MenuItem>
                );
            })}
        </TextField>
    );

    if (hint) {
        return <Tooltip title={hint}>{textField}</Tooltip>;
    }
    return textField;
};

export default SelectInputField;
