import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { TableDataType } from "../../../../../../components/TableConstructor/types";
import { getPaymentDetails } from "../../../../services/fetchers";
import CustomButton from "../../../../../../UI/Buttons/CustomButton";
import ContentContainer from "../../../../../../components/ContentContainer/ContentContainer";
import { Dictionary } from "../../../../index";
import { initialTableData } from "../../../../../../components/TableConstructor/constants";
import TableConstructor from "../../../../../../components/TableConstructor";

const PaymentDetailsPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>("");
    const { id } = useParams();
    const [request, setRequest] = useState<Dictionary<any>>({
        periods: {
            date_from: dayjs().startOf("month"),
            date_to: dayjs().add(-1, "day"),
            currency: "",
        },
    });
    const { t } = useTranslation();
    const [tableData, setTableData] = useState<TableDataType>(initialTableData);

    const loadPaymentDetails = async (signal: any) => {
        setLoading(true);
        const data = {
            ...request,
            filters: request.filters || {},
        };
        try {
            const res = await getPaymentDetails(Number(id), data, signal);
            setTableData(res);
            setLoading(false);
        } catch (e) {
            setError(t("error.self"));
            setLoading(false);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        (async () => {
            await loadPaymentDetails(controller.signal);
        })();
        return () => controller.abort();
    }, [request]);

    const handleCreateUser = () => {
        navigate(`/clients/${id}/payment_details/create`);
    };

    return (
        <ContentContainer>
            <TableConstructor
                tableData={tableData}
                loading={loading}
                request={request}
                setRequest={setRequest}
                ActionButton={
                    <CustomButton
                        Icon={
                            <AddIcon
                                style={{
                                    color: "#fff",
                                    width: "1.25rem",
                                    height: "1.25rem",
                                    marginRight: ".5rem",
                                }}
                            />
                        }
                        label="button.add"
                        onChange={handleCreateUser}
                    />
                }
            />
        </ContentContainer>
    );
};

export default PaymentDetailsPage;
