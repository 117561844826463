import React from "react";
import { Box } from "@mui/material";
import ValueInputField from "../../../../../../../../UI/Inputs/ValueInputField";
import { handleChangeField } from "../../../../../../../ClientsPage/services/helpers";
import { BonusType } from "../../pages/BonusCreate";
import { ReactComponent as MoneyIcon } from "../../../../../../../../assets/images/icons/common/moneyIcon.svg";

type BankFormType = {
    bonus: BonusType;
    setBonus: (bonus: BonusType) => void;
};

const BonusForm: React.FC<BankFormType> = ({ bonus, setBonus }) => (
    <Box
        sx={{
            p: "3rem",
            background: "#fff",
            maxWidth: "736px",
            gap: "1rem",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
        }}
    >
        <ValueInputField
            label="forms.manager.gp_bonus"
            value={bonus.bonus_gp}
            type="number"
            Icon={<MoneyIcon />}
            onChange={(e) => handleChangeField("bonus_gp", Number(e.target.value), bonus, setBonus)}
        />
        <ValueInputField
            label="forms.manager.monthly_bonus"
            value={bonus.bonus_month}
            type="number"
            Icon={<MoneyIcon />}
            onChange={(e) => handleChangeField("bonus_month", Number(e.target.value), bonus, setBonus)}
        />
        <ValueInputField
            label="forms.manager.bonus_from_new"
            value={bonus.bonus_from_new}
            type="number"
            Icon={<MoneyIcon />}
            onChange={(e) => handleChangeField("bonus_from_new", Number(e.target.value), bonus, setBonus)}
        />
        <ValueInputField
            label="forms.manager.new_client_month_count"
            value={bonus.new_client_month_count}
            type="number"
            Icon={<MoneyIcon />}
            onChange={(e) => handleChangeField("new_client_month_count", Number(e.target.value), bonus, setBonus)}
        />
    </Box>
);

export default BonusForm;
