import { useTranslation } from "react-i18next";
import { FC, useMemo } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { CardType } from "../../types";
import { ReactComponent as MainContactIcon } from "../../../../../assets/images/icons/common/main.svg";

type CardDetailsProps = {
    main?: boolean;
    index?: number;
    cols: number;
    title: string;
    items: CardType[];
};

const CardDetails: FC<CardDetailsProps> = ({ main = false, index = 0, cols = 1, title = "", items }) => {
    const { t } = useTranslation();
    const renderRow = (idx: number, key: string, value: any, type: string = "string") => (
        <Box
            key={idx}
            sx={{
                display: "flex",
                gap: ".75rem",
            }}
        >
            <Typography sx={{ flex: "0 0 50%", color: "var(--main-light-text-color)" }}>{t(key)}</Typography>
            {type === "url" ? (
                <Link to={value} target="_blank" style={{ wordBreak: "break-word" }}>
                    <Typography
                        sx={{
                            color: "blue",
                            display: "-webkit-box",
                            "-webkit-line-clamp": "2",
                            "-webkit-box-orient": "vertical",
                            overflow: "hidden",
                            maxHeight: "48px",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {value}
                    </Typography>
                </Link>
            ) : (
                <Typography sx={{ flex: "0 0 50%", wordBreak: "break-word" }}>{value}</Typography>
            )}
        </Box>
    );

    const flex = useMemo(() => {
        switch (cols) {
            case 1:
                return "100%";
            case 2:
                return "calc(50% - 12px)";
            case 3:
                return "calc(33.33% - 12px)";
            default:
                return "";
        }
    }, [cols]);

    return (
        <Box
            key={index}
            sx={{
                backgroundColor: "#fff",
                p: "2rem",
                maxWidth: "589px",
                width: "100%",
                flex,
            }}
        >
            <Typography
                sx={{
                    fontSize: "1.25rem",
                    fontWeight: "500",
                    display: "flex",
                    gap: ".25rem",
                    alignItems: "center",
                }}
            >
                {title}
                {main && <MainContactIcon />}
            </Typography>
            <Divider sx={{ backgroundColor: "#9747FF3D", m: "1.5rem 0" }} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                }}
            >
                {items.length > 0 &&
                    items.map((item, cardIndex) => {
                        if (Array.isArray(item.value)) {
                            return (
                                <>
                                    <Typography sx={{ fontWeight: "500" }}>{t(item.key)}</Typography>
                                    {item.value.length > 0 && item.value.map((el, itemIndex) => renderRow(itemIndex, el.key, el.value, item.type))}
                                </>
                            );
                        }
                        return renderRow(cardIndex, item.key, item.value, item.type);
                    })}
            </Box>
        </Box>
    );
};

export default CardDetails;
