import React, { useContext, useEffect, useState } from "react";
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { RouteType } from "../../routes/config";
import SideBarItem from "./SideBarItem";
import { AuthContext } from "../../context/AuthContext";

type Props = {
    item: RouteType;
    toggle: boolean;
};

const SideBarItemCollapse = ({ item, toggle = false }: Props) => {
    const { t } = useTranslation();
    const { appState, me } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (appState.includes(item.state)) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [appState]);
    const sideBarText = typeof item.sidebarProps?.displayText === "object" ? item.sidebarProps?.displayText[me.role] : item.sidebarProps?.displayText;
    return item.sidebarProps ? (
        <>
            <ListItemButton
                onClick={() => setOpen(!open)}
                sx={{
                    p: "1rem 1.125rem",
                    gap: "1.125rem",
                    position: "relative",
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: "auto",
                    }}
                >
                    {item.sidebarProps.icon && item.sidebarProps.icon}
                </ListItemIcon>
                <ListItemText sx={{ m: 0 }} disableTypography primary={<Typography fontFamily="inherit">{t(sideBarText)}</Typography>} />
                {!toggle ? (
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: "-.65rem",
                            left: "1.125rem",
                        }}
                    >
                        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Box>
                ) : open ? (
                    <ExpandLessIcon />
                ) : (
                    <ExpandMoreIcon />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto">
                <List sx={{ p: "0" }}>
                    {item.child &&
                        item.child.map((route, index) =>
                            route?.permissions?.includes(me.role) && route.sidebarProps ? (
                                route.child && route.child.length > 0 ? (
                                    <SideBarItemCollapse toggle={toggle} item={route} key={index} />
                                ) : (
                                    <SideBarItem toggle={toggle} item={route} key={index} inside />
                                )
                            ) : null
                        )}
                </List>
            </Collapse>
        </>
    ) : null;
};

export default SideBarItemCollapse;
