// eslint-disable-next-line import/prefer-default-export
export const initialCards = [
    {
        label: "Viewable Impressions",
        value: 0,
        color: "linear-gradient(90deg, #B4ABC3 0%, #865DC9 100%)",
        status: "",
    },
    {
        label: "Revenue",
        value: 0,
        color: "linear-gradient(90deg, rgba(144, 202, 249, 0.69) 0%, #047EDF 100%)",
        status: "",
    },
    {
        label: "CPM",
        value: 0,
        color: "linear-gradient(90deg, #FFBF96 0%, #FE7096 100%)",
        status: "",
    },
];
