import { Outlet, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";

const NotificationPageLayout = () => {
    const { appState } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/notifications/notification", { replace: true });
    }, [appState]);

    return <Outlet />;
};

export default NotificationPageLayout;
