import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import ValueInputField from "../../../../../../UI/Inputs/ValueInputField";
import { handleChangeField } from "../../../../../ClientsPage/services/helpers";
import { ReactComponent as ProfileIcon } from "../../../../../../assets/images/icons/sidebar/profile.svg";
import SelectInputField from "../../../../../../UI/Inputs/SelectInputField";
import { ReactComponent as MoneyIcon } from "../../../../../../assets/images/icons/common/moneyIcon.svg";
import { TransferMethodType } from "../../types";
import { getCurrencies } from "../../../../../ClientsPage/services/fetchers";
import DatePickerCustom from "../../../../../../UI/Inputs/DatePicker";

type TransferMethodFormType = {
    tm: TransferMethodType;
    setTm: (tm: TransferMethodType) => void;
};

const transferFeeType = ["Fix", "%"];

const Form: React.FC<TransferMethodFormType> = ({ tm, setTm }) => {
    const [currencyList, setCurrencyList] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            const res = await Promise.all([getCurrencies()]);
            setCurrencyList(res[0]);
        })();
    }, []);
    return (
        <Box
            sx={{
                p: "3rem",
                background: "#fff",
                maxWidth: "736px",
                gap: "1rem",
                display: "flex",
                flexWrap: "wrap",
            }}
        >
            <ValueInputField label="transfer_method" value={tm.name} Icon={<ProfileIcon />} onChange={(e) => handleChangeField("name", e.target.value, tm, setTm)} />
            <SelectInputField label="forms.partners.tm.fee_type" value={tm.expenses_type} items={transferFeeType} onChange={(value) => handleChangeField("expenses_type", value, tm, setTm)} />
            <SelectInputField label="forms.partners.tm.currency" value={tm.expenses_currency} items={currencyList} onChange={(value) => handleChangeField("expenses_currency", value, tm, setTm)} />
            <ValueInputField
                label="forms.partners.tm.cost"
                value={tm.expenses_value}
                type="number"
                Icon={<MoneyIcon />}
                onChange={(e) => handleChangeField("expenses_value", Number(e.target.value), tm, setTm)}
            />
            <DatePickerCustom label={t("forms.common.start_date")} value={tm.start_date} onChange={(value) => handleChangeField("start_date", dayjs(value).format("YYYY-MM-DD"), tm, setTm)} />
            <DatePickerCustom label={t("forms.common.end_date")} value={tm.end_date} onChange={(value) => handleChangeField("end_date", dayjs(value).format("YYYY-MM-DD"), tm, setTm)} />
            {/* <ValueInputField */}
            {/*    label={'Transaction costs'} */}
            {/*    value={tm.expenses_value} */}
            {/*    type={"number"} */}
            {/*    Icon={<MoneyIcon />} */}
            {/*    onChange={(e) => handleChangeField('expenses_value', e.target.value, tm, setTm)} */}
            {/* /> */}
            <FormControlLabel
                control={
                    <Checkbox
                        sx={{
                            "&.Mui-checked": {
                                color: "var(--main-black-color)",
                            },
                        }}
                        checked={tm.is_active || false}
                    />
                }
                label={t("forms.partners.tm.active")}
                onChange={(e) => handleChangeField("is_active", !tm.is_active, tm, setTm)}
            />
        </Box>
    );
};

export default Form;
