import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import ContentContainer from "../../../../../components/ContentContainer/ContentContainer";
import { getValue } from "../../../services/helpers";
import CardDetails from "../../components/CardDetails";
import { CardType } from "../../types";
import { getContacts } from "../../../services/fetchers";

const PartnerContactsPage = () => {
    const { t } = useTranslation();
    const [contacts, setContacts] = useState<CardType[][]>([]);
    const { id } = useParams();

    const contactsParser = (data: any) => {
        const res: any = [];
        data.forEach((contact: any) => {
            res.push([
                {
                    key: "Main",
                    value: contact.main_contact,
                },
                {
                    key: "full_name",
                    value: getValue(contact.full_name),
                },
                {
                    key: "email",
                    value: getValue(contact.email),
                },
                {
                    key: "job_position",
                    value: getValue(contact.position),
                },
                {
                    key: "telegram",
                    value: getValue(contact.tg_login),
                },
                {
                    key: "telegram_group",
                    value: getValue(contact.tg_link),
                },
                {
                    key: "phone_number",
                    value: getValue(contact.phone_number),
                },
            ]);
        });
        return res;
    };

    useEffect(() => {
        if (id) {
            (async () => {
                setContacts(contactsParser(await getContacts(id, {}, null)));
            })();
        }
    }, []);

    return (
        <ContentContainer>
            <Box
                sx={{
                    display: "flex",
                    gap: ".75rem",
                    flexWrap: "wrap",
                }}
            >
                {contacts.length > 0 && contacts.map((contact, index) => <CardDetails main={contact[0].value} cols={3} title={`${t("contact")} ${index + 1}`} items={contact.slice(1)} />)}
            </Box>
        </ContentContainer>
    );
};

export default PartnerContactsPage;
