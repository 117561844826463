import { useTranslation } from "react-i18next";
import { Box, Button, Menu, MenuItem, Pagination, Typography } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Dictionary } from "../../../../pages/ClientsPage";

interface ITablePagination {
    allCounts: number;
    request: Dictionary<any>;
    setRequest: (params: Dictionary<any>) => void;
}
const TablePagination = ({ allCounts, request, setRequest }: ITablePagination) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [anchorElPerPageMenu, setAnchorElPerPageMenu] = useState<null | HTMLElement>(null);
    const openRowsPerPage = Boolean(anchorElPerPageMenu);
    const page = Number(searchParams.get("page") || 1);

    const querySize = searchParams.get("size") || 50;
    let size = querySize;
    let count = 1;
    if (querySize !== "all") {
        size = Number(size);
        count = Math.ceil(allCounts / size);
    }

    const handleClickOpenPerPage = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElPerPageMenu(event.currentTarget);
    };
    const handleClosePerPage = () => {
        setAnchorElPerPageMenu(null);
    };
    const handleChangeRowsPerPage = (value: number | string) => {
        if (size !== value) {
            setRequest((prev: Dictionary<any>) => ({
                ...prev,
                size: value,
            }));
        }
        handleClosePerPage();
    };
    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setRequest((prev: Dictionary<any>) => ({
            ...prev,
            page: value,
        }));
    };

    return (
        <Box
            sx={{
                display: "flex",
                m: ".75rem 0 1.25rem 0",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: ".5rem",
                    alignItems: "center",
                }}
            >
                <Typography>{t("rows_per_page")}:</Typography>
                <Button
                    id="rowsPerPage-button"
                    aria-controls={openRowsPerPage ? "rowsPerPage-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openRowsPerPage ? "true" : undefined}
                    endIcon={openRowsPerPage ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    sx={{
                        padding: "4px",
                        minWidth: "auto",
                    }}
                    onClick={handleClickOpenPerPage}
                >
                    {size}
                </Button>
                <Menu
                    id="rowsPerPage-menu"
                    MenuListProps={{
                        "aria-labelledby": "rowsPerPage-button",
                    }}
                    anchorEl={anchorElPerPageMenu}
                    open={openRowsPerPage}
                    sx={{
                        "& .MuiPaper-root": {
                            mt: "1rem",
                        },
                    }}
                    onClose={handleClosePerPage}
                >
                    {[10, 25, 50, 100, "all"].map((item, index) => (
                        <MenuItem key={index} onClick={() => handleChangeRowsPerPage(item)}>
                            {item}
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
            {allCounts > size && <Pagination disabled={count === 1} count={count} page={page} onChange={handleChangePage} />}
        </Box>
    );
};

export default TablePagination;
