import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import ValueInputField from "../../../../../../UI/Inputs/ValueInputField";
import { handleChangeField } from "../../../../../ClientsPage/services/helpers";
import SelectInputField from "../../../../../../UI/Inputs/SelectInputField";
import { ReactComponent as MoneyIcon } from "../../../../../../assets/images/icons/Currency rate.svg";
import { CurrencyRatesType } from "../../types";
import { getCurrencies } from "../../../../../ClientsPage/services/fetchers";
import DatePickerCustom from "../../../../../../UI/Inputs/DatePicker";

type CurrencyRatesFormType = {
    cr: CurrencyRatesType;
    setCr: (cr: CurrencyRatesType) => void;
};

const Form: React.FC<CurrencyRatesFormType> = ({ cr, setCr }) => {
    const [currencies, setCurrencies] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            const res = await Promise.all([getCurrencies()]);
            setCurrencies(res[0]);
        })();
    }, []);
    return (
        <Box
            sx={{
                p: "3rem",
                background: "#fff",
                maxWidth: "736px",
                gap: "1rem",
                display: "flex",
                flexWrap: "wrap",
            }}
        >
            <SelectInputField value={cr.currency_from} label="forms.partners.cr.from" items={currencies} onChange={(value) => handleChangeField("currency_from", value, cr, setCr)} />
            <SelectInputField value={cr.currency_to} label="forms.partners.cr.to" items={currencies} onChange={(value) => handleChangeField("currency_to", value, cr, setCr)} />
            <DatePickerCustom label={t("forms.partners.cr.date")} value={cr.date} onChange={(value) => handleChangeField("date", dayjs(value).format("YYYY-MM-DD"), cr, setCr)} />
            <ValueInputField label="forms.partners.cr.cost" value={cr.cost} type="number" Icon={<MoneyIcon />} onChange={(e) => handleChangeField("cost", Number(e.target.value), cr, setCr)} />
        </Box>
    );
};

export default Form;
