import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../../../../../context/AuthContext";

const CountriesLayout = () => {
    const { appState } = useContext(AuthContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (pathname.split("/").length === 4) {
            navigate(`/properties/countries/${id}/edit`, { replace: true });
        }
    }, [appState]);

    return <Outlet />;
};

export default CountriesLayout;
