import React, { FC, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { Autocomplete, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { Control, Controller, DeepRequired, FieldErrorsImpl, FieldValues, GlobalError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { boxStyle, FormType, ProductConfigs } from "./index";
import ValueInputField from "../../../../../UI/Inputs/ValueInputField";
import SelectInputField from "../../../../../UI/Inputs/SelectInputField";
import {handleChangeField} from "../../../../ClientsPage/services/helpers";

type PerfectAdUnitConfigsFormProps = {
    mode: "edit" | "create";
    errors: Partial<FieldErrorsImpl<DeepRequired<FieldValues>>> & { root?: Record<string, GlobalError> & GlobalError };
    control: Control<FieldValues, any>;
    dynamicForm: FormType;
    setDynamicForm: (value: FormType) => void;
    configs: ProductConfigs;
    setConfigs: (configs: ProductConfigs) => void;
    clientList: string[];
    placementList: string[];
};

const PerfectAdUnitConfigsForm: FC<PerfectAdUnitConfigsFormProps> = ({
    control,
    errors = {},
    dynamicForm,
    placementList = [],
    clientList = [],
    configs,
    setConfigs,
    mode = "create",
}) => {
    const [isShowMore, setIsShowMore] = useState({
        desktop: false,
        mobile: false,
    });
    const lang = localStorage.getItem("lng");
    const { t } = useTranslation();

    // eslint-disable-next-line react/no-unstable-nested-components
    const Input = ({ label, value, hint, onChange, type, ...rest }: any) => {
        switch (type) {
            case "string":
                return (
                    <ValueInputField
                        errors={rest.errors}
                        border={false}
                        style={{ width: "100%", background: "var(--main-purple-hover-color)" }}
                        label={label}
                        value={value}
                        hint={hint}
                        onChange={(e) => onChange(e.target.value)}
                    />
                );
            case "textarea":
                return (
                    <TextField
                        id="outlined-multiline-static"
                        label={label}
                        sx={{ width: "100%", borderRadius: "0 !important", borderColor: "var(--blue) !important" }}
                        multiline
                        rows={8}
                        defaultValue={value}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                    />
                );
            case "number":
                return (
                    <ValueInputField
                        errors={rest.errors}
                        border={false}
                        type={type}
                        style={{ width: "100%", background: "var(--main-purple-hover-color)" }}
                        label={label}
                        value={value}
                        hint={hint}
                        onChange={(e) => onChange(Number(e.target.value))}
                    />
                );
            case "checkbox":
                return (
                    <FormControl sx={{ width: "100%" }}>
                        <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontWeight: "500" }}>
                            {label}
                        </FormLabel>
                        <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" value={value} onChange={(event, value) => onChange(value === "true")}>
                            <FormControlLabel value control={<Radio color="warning" />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio color="warning" />} label="No" />
                        </RadioGroup>
                    </FormControl>
                );
            case "select":
                return (
                    <SelectInputField
                        errors={rest.errors}
                        border={false}
                        style={{ background: "var(--main-purple-hover-color)", height: "fit-content", width: "100%" }}
                        value={value}
                        label={label}
                        hint={hint}
                        items={rest.options}
                        onChange={(value) => onChange(value)}
                    />
                );
            default:
                return null;
        }
    };

    const formInputs = (dynamicForm: FormType) =>
        Object.keys(dynamicForm).map((e: any): any => {
            const field = dynamicForm[e];
            const { type, defaultValue, rules, order } = field;
            const label = field.title && field.title[lang || "en"];
            const fieldHint = field.hint && Object.keys(field.hint).length > 0 ? field.hint[lang || "en"] : "";
            return (
                <section key={e} style={{ flexBasis: "calc(50% - .5rem)", height: "fit-content", order }}>
                    <Controller
                        name={e}
                        control={control}
                        rules={type !== "checkbox" ? rules : {}}
                        defaultValue={defaultValue}
                        render={({ field }) => <Input errors={errors[e] || {}} label={label} value={field.value} onChange={field.onChange} {...dynamicForm[e]} hint={fieldHint} />}
                    />
                </section>
            );
        });

    return (
        <form style={{ ...boxStyle, width: "60%", flexWrap: "wrap" }}>
            <Autocomplete
                value={configs.client}
                color="warning"
                disablePortal
                id="combo-box-demo"
                options={clientList}
                sx={{ width: "100%", flexBasis: "calc(50% - .5rem)" }}
                onChange={(e, value) => handleChangeField("client", value, configs, setConfigs)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        disabled={mode === "edit"}
                        InputProps={{
                            ...params.InputProps,
                            sx: {
                                borderRadius: "0",
                            },
                        }}
                        color="warning"
                        label={t("forms.client_account.select_client")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
            />
            <Autocomplete
                value={configs.placement_name}
                color="warning"
                disablePortal
                id="combo-box-demo"
                options={placementList}
                sx={{ width: "100%", flexBasis: "calc(50% - .5rem)" }}
                onChange={(e, value) => handleChangeField("placement_name", value, configs, setConfigs)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        disabled={mode === "edit"}
                        InputProps={{
                            ...params.InputProps,
                            sx: {
                                borderRadius: "0",
                            },
                        }}
                        color="warning"
                        label={t("forms.client_account.select_placement")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
            />
            {formInputs(dynamicForm.main)}
        </form>
    );
};

export default PerfectAdUnitConfigsForm;
