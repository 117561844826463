import { Box, Typography } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/img/logo_TDS.png";
import CustomButton from "../../UI/Buttons/CustomButton";

const Error = () => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: ".75rem",
                backgroundColor: "var(--background-color)",
            }}
        >
            <Box>
                <img src={Logo} alt="logo" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
            </Box>
            <Typography
                sx={{
                    fontSize: "1.125rem",
                }}
            >
                {t("error.unexpected")}
            </Typography>
            <CustomButton label="Reload" leftIcon={<CachedIcon />} onChange={() => window.location.reload()} />
        </Box>
    );
};

export default Error;
