import React from "react";

export default class ErrorBoundary extends React.Component<any, any> {
    // eslint-disable-next-line react/state-in-constructor
    state = { hasError: false };

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        const { children, fallback } = this.props;
        const { hasError } = this.state;
        if (hasError) {
            return fallback;
        }
        return children;
    }
}
