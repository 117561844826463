import React, { FC, useEffect, useState } from "react";
import TableFilterPanel from "./components/TableFilterPanel";
import { thickRow } from "./constants";
import { TableConstructorProps } from "./types";
import CustomTable from "./components/CustomTable";

const TableConstructor: FC<TableConstructorProps> = ({
    tableData = {
        headers: [],
        rows: [],
        table_name: "",
        total: {},
    },
    loading = false,
    request,
    setRequest,
    ActionButton = null,
    isFilterPanel = true,
    selectedRows = [],
    setSelectedRows = () => {},
    payoutTotal=0
}) => {
    const [rowDense, setRowDense] = useState<number>(thickRow);
    const [headers, setHeaders] = useState(tableData.headers);

    const { rows, table_name, all_counts, table_actions, total } = tableData;

    useEffect(() => {
        if (tableData.headers.length > 0) {
            setHeaders(tableData.headers);
        }
    }, [tableData.headers]);

    return (
        <>
            {isFilterPanel && (
                <TableFilterPanel
                    payoutTotal={payoutTotal}
                    rowDense={rowDense}
                    setRowDense={setRowDense}
                    rows={rows}
                    loading={loading}
                    tableActions={table_actions}
                    request={request}
                    headers={headers}
                    setHeaders={setHeaders}
                    newHeaders={headers}
                    setRequest={setRequest}
                    ActionButton={ActionButton}
                />
            )}
            <CustomTable
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                rowDense={rowDense}
                table_name={table_name}
                loading={loading}
                allCounts={all_counts}
                originalHeaders={headers}
                rows={rows}
                tableActions={table_actions}
                request={request}
                setRequest={setRequest}
                total={total}
            />
        </>
    );
};

export default TableConstructor;
