import React from "react";
import { Box } from "@mui/material";

const ContentContainer = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => (
    <Box
        sx={{
            p: ".75rem",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
        }}
    >
        {children}
    </Box>
);

export default ContentContainer;
