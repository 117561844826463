import { Box, Button, Modal, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../../UI/Buttons/CustomButton";

interface DraftModalProps {
    openDraftModal: boolean;
    handleClose: () => void;
    handleClickContinue: () => void;
    handleClickRemoveDraft: () => void;
}

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "312px",
    borderTop: "3px solid var(--main-purple-color)",
    boxShadow: 24,
    p: "1.5rem",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "1.5rem",
    outline: "none",
};
const DraftModal = ({ openDraftModal, handleClose, handleClickContinue, handleClickRemoveDraft }: DraftModalProps) => {
    const { t } = useTranslation();
    return (
        <Modal open={openDraftModal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Typography
                    sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "var(--main-black-color)",
                        textAlign: "center",
                    }}
                >
                    {t("warning.saved_client")}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        gap: ".5rem",
                    }}
                >
                    <CustomButton mode="warning" onChange={handleClickContinue} label={t("warning.yes")} />
                    <Button onClick={handleClickRemoveDraft}>{t("warning.no")}</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DraftModal;
