import { useContext, useEffect, useState } from "react";
import { getFeedbackCategories, postFeedback } from "./api/fetchers";
import { FeedbackType } from "./FeedbackType";
import { AuthContext } from "../../context/AuthContext";

const useFeedbackController = () => {
    const [categories, setCategories] = useState<string[]>([""]);
    const { setNotification } = useContext(AuthContext);

    useEffect(() => {
        getFeedbackCategories().then((data) => {
            setCategories(data);
        });
    }, [setCategories]);

    const getFormData = (object: Record<string, any>) =>
        Object.keys(object).reduce((formData, key) => {
            if (object[key]) {
                formData.append(key, object[key]);
            }
            return formData;
        }, new FormData());

    const sendFeedback = (feedback: FeedbackType) => {
        const formData = getFormData(feedback);
        postFeedback(formData)
            .then((response) => {
                setNotification({
                    open: true,
                    message: response.data.message,
                    type: "success",
                });
            })
            .catch((error) => {
                setNotification({
                    open: true,
                    message: error.response.data.message,
                    type: "error",
                });
            });
    };

    return { categories, sendFeedback };
};

export default useFeedbackController;
