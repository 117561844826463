import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

import ContentContainer from "../../../../../../components/ContentContainer/ContentContainer";
import { LegalEntityType } from "../../types";
import { AuthContext } from "../../../../../../context/AuthContext";
import LegalEntityForm from "../../components/LegalEntityForm";
import { TopBarContext } from "../../../../../../context/TopBarContext";
import CloseButton from "../../../../../../UI/Buttons/Actions/CloseButton";
import CreateButton from "../../../../../../UI/Buttons/Actions/CreateButton";

export const initialLlp = {
    name: "",
    business_id: "",
    country: "",
    is_vat_payer: false,
    vat_value: 0,
    bank_receive: "",
    convertation_method: "",
};

const LegalEntityCreate = () => {
    const { setNotification } = useContext(AuthContext);
    const [llp, setLlp] = useState<LegalEntityType>(initialLlp);
    const { handleChangePath, handleSetActions } = useContext(TopBarContext);
    useEffect(() => {
        handleChangePath(["partners", "new_le"]);
        handleSetActions([<CreateButton action={handleSubmit} />, <CloseButton path="/properties/llp" />]);
    }, [llp]);

    const handleSubmit = async () => {
        try {
            const res = await axios.post("/properties/llp/create/", llp);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        }
    };
    return (
        <ContentContainer>
            <LegalEntityForm llp={llp} setLlp={setLlp} />
        </ContentContainer>
    );
};

export default LegalEntityCreate;
