import { createContext, ReactNode, useState } from "react";

export const TopBarContext = createContext<TopBarContextType>({} as TopBarContextType);

interface TopBarContextType {
    path: Array<string>;
    backPath: string;
    tabs: any;
    handleChangePath: (items: Array<string>) => void;
    handleChangeBackPath: (path: string) => void;
    handleChangeTabs: (items: any) => void;
    actions: any;
    handleSetActions: any;
    show: any;
    setShow: any;
    handleAppendPath: (item: string) => void;
}
interface TopBarProps {
    children: ReactNode;
}
const TopBarProvider = ({ children }: TopBarProps) => {
    const [path, setPath] = useState<Array<string>>([""]);
    const [backPath, setBackPath] = useState<string>(""); // TODO: delete
    const [tabs, setTabs] = useState<any>([]);
    const [actions, setActions] = useState<any>([]);
    const [show, setShow] = useState<any>(true);

    const handleChangePath = (items: Array<string>): void => {
        setPath(items);
        setShow(true);
    };
    const handleChangeBackPath = (item: string): void => {
        setBackPath(item);
        setShow(true);
    };
    const handleChangeTabs = (items: any): void => {
        setTabs(items);
        setShow(true);
    };

    const handleSetActions = (items: any): void => {
        setActions(items);
        setShow(true);
    };

    const handleAppendPath = (item: string): void => {
        if (!path.includes(item)) {
            setPath([...path, item]);
        }
    };
    return (
        <TopBarContext.Provider
            value={{
                path,
                tabs,
                handleChangePath,
                handleChangeTabs,
                actions,
                handleSetActions,
                show,
                setShow,
                backPath,
                handleChangeBackPath,
                handleAppendPath,
            }}
        >
            {children}
        </TopBarContext.Provider>
    );
};

export default TopBarProvider;
