import React, { useContext, useEffect, useState } from "react";
import ContentContainer from "../../../../../../components/ContentContainer/ContentContainer";
import { CurrencyRatesType } from "../../types";
import { AuthContext } from "../../../../../../context/AuthContext";
import Form from "../../components/Form";
import { createEntry } from "../../services/fetchers";
import { TopBarContext } from "../../../../../../context/TopBarContext";
import CreateButton from "../../../../../../UI/Buttons/Actions/CreateButton";
import CloseButton from "../../../../../../UI/Buttons/Actions/CloseButton";

export const initialCr = {
    currency_from: "",
    currency_to: "",
    date: "",
    cost: 0,
};

const CurrencyRatesCreate = () => {
    const { setNotification } = useContext(AuthContext);
    const [cr, setCr] = useState<CurrencyRatesType>(initialCr);
    const { handleChangePath, handleSetActions } = useContext(TopBarContext);
    useEffect(() => {
        handleChangePath(["partners", "new_cr"]);
        handleSetActions([<CreateButton action={handleSubmit} />, <CloseButton path="/properties/cr" />]);
    }, [cr]);

    const handleSubmit = async () => {
        try {
            const res = await createEntry(cr);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        }
    };
    return (
        <ContentContainer>
            <Form cr={cr} setCr={setCr} />
        </ContentContainer>
    );
};

export default CurrencyRatesCreate;
