import axios from "axios";
import BASE_URL from "../../../../../../../../../../../data/constants";

const getFees = async (id: number | string | undefined, placementId: number | string | undefined, data: any, signal: any) => {
    try {
        const res = await axios.post(`${BASE_URL}/clients/${id}/platforms/${placementId}/conditions/`, data, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export default getFees;
