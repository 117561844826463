import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { PerfectSolutionAccountType } from "../../../types";
import { ClientCreateContext } from "../../../../../context/ClientCreateContext";
import { mockUserList } from "../../../../../data/mockData";
import { getUserList } from "../../../services/fetchers";
import ValueInputField from "../../../../../UI/Inputs/ValueInputField";
import CustomButton from "../../../../../UI/Buttons/CustomButton";
import SelectInputField from "../../../../../UI/Inputs/SelectInputField";
import { ReactComponent as ProfileIcon } from "../../../../../assets/images/icons/sidebar/profile.svg";

type ClientAccountKeys = keyof PerfectSolutionAccountType;
const ClientAccountForm = () => {
    const { clientAccount, setClientAccount } = useContext(ClientCreateContext);
    const [userList, setUserList] = useState(mockUserList);
    const [isNewUser, setIsNewUser] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        (async () => setUserList(await getUserList()))();
    }, []);
    useEffect(() => {
        if (clientAccount.client_user_is_create) {
            setIsNewUser(true);
        }
    }, []);
    const handleChangeField = (key: ClientAccountKeys, newValue: any) => {
        setClientAccount({
            ...clientAccount,
            [key]: newValue,
        });
    };
    const handleCreateNewUser = () => {
        setIsNewUser(true);
        setClientAccount({
            ...clientAccount,
            username: "",
            client_user_is_create: true,
        });
    };
    const handleChooseCurrentUser = () => {
        setIsNewUser(false);
        setClientAccount({
            ...clientAccount,
            username: "",
            client_user_is_create: false,
        });
    };
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: "1.25rem",
                    fontWeight: "500",
                    mb: "3rem",
                }}
            >
                {t("client_create.account")}
            </Typography>
            <Box>
                {!isNewUser ? (
                    <>
                        <SelectInputField
                            style={{ marginBottom: "1rem" }}
                            value={clientAccount.username}
                            label="forms.client_account.select_username"
                            items={userList}
                            onChange={(value) => handleChangeField("username", value)}
                        />
                        <Box>
                            <CustomButton label={t("button.create")} Icon={<AddIcon style={{ color: "#fff" }} />} onChange={handleCreateNewUser} />
                        </Box>
                    </>
                ) : (
                    <>
                        <ValueInputField
                            style={{ marginBottom: "1rem" }}
                            Icon={<ProfileIcon />}
                            label={t("forms.common.username")}
                            value={clientAccount.username}
                            onChange={(e) => handleChangeField("username", e.target.value)}
                        />
                        <Box>
                            <CustomButton
                                Icon={
                                    <AddIcon
                                        style={{
                                            color: "var(--main-black-color)",
                                        }}
                                    />
                                }
                                label={t("select")}
                                onChange={handleChooseCurrentUser}
                                mode="dark"
                            />
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default ClientAccountForm;
