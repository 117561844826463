import { Autocomplete, Box, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "../../../types";
import AddBadge from "../../../../../components/Badges/AddBadge";
import RemoveBadge from "../../../../../components/Badges/RemoveBadge";

interface IPlatformForm {
    readOnly?: boolean;
    isAddMore?: boolean;
    platforms: Platform[];
    setPlatforms: (platforms: Platform[]) => void;
    platformList: string[];
}
const PlatformForm = ({ platforms, setPlatforms, platformList, isAddMore = true, readOnly = false }: IPlatformForm) => {
    const { t } = useTranslation();
    const handleAddPlatform = () => {
        const newPlatforms = [
            ...(platforms as Platform[]),
            {
                fieldId: `id${Math.random().toString(16).slice(2)}`,
                name: "",
            },
        ];
        setPlatforms(newPlatforms);
    };
    const handleRemovePlatform = (fieldId: string) => {
        const newPlatforms = (platforms as Platform[]).filter((platform) => platform.fieldId !== fieldId);
        setPlatforms(newPlatforms);
    };
    const handleChangePlatform = (fieldId: string, fieldKey: string, value: string | null) => {
        const newPlatforms = (platforms as Platform[]).map((platform) => {
            if (platform.fieldId === fieldId) {
                return {
                    ...platform,
                    [fieldKey]: value,
                };
            }
            return platform;
        });
        setPlatforms(newPlatforms);
    };
    return (
        <Box
            sx={{
                display: "flex",
                gap: "1rem",
                flexWrap: "wrap",
                mb: "1rem",
            }}
        >
            {platforms.length > 0 &&
                platforms.map((platform, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: "flex",
                            gap: "1rem",
                        }}
                    >
                        <Autocomplete
                            value={platform.name}
                            color="warning"
                            disablePortal
                            id="combo-box-demo"
                            options={platformList}
                            sx={{ width: 312 }}
                            onChange={(e, value) => handleChangePlatform(platform.fieldId, "name", value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        readOnly,
                                        sx: {
                                            borderRadius: "0",
                                        },
                                    }}
                                    color="warning"
                                    label={t("forms.client_account.select_placement")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                        {isAddMore &&
                            (index === 0 ? (
                                <Box
                                    onClick={() => !readOnly && handleAddPlatform()}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                >
                                    <AddBadge />
                                </Box>
                            ) : (
                                <Box
                                    onClick={() => !readOnly && handleRemovePlatform(platform.fieldId)}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                >
                                    <RemoveBadge />
                                </Box>
                            ))}
                    </Box>
                ))}
        </Box>
    );
};

export default PlatformForm;
