import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import axios, { AxiosError, isAxiosError } from "axios";
import { AuthContext } from "../../../../../../../../../../context/AuthContext";
import BASE_URL from "../../../../../../../../../../data/constants";
import { getClientLLPList } from "../../../../../../../../services/fetchers";
import ContentContainer from "../../../../../../../../../../components/ContentContainer/ContentContainer";
import CustomButton from "../../../../../../../../../../UI/Buttons/CustomButton";
import { ReactComponent as EditIcon } from "../../../../../../../../../../assets/images/icons/common/edit-icon-white.svg";
import { isAllowedTo } from "../../../../../../../../../../utils/helpers";
import Restricted from "../../../../../../../../../../components/Restricted";
import SelectInputField from "../../../../../../../../../../UI/Inputs/SelectInputField";
import commTypes from "../../../../constants";
import { handleChangeField } from "../../../../../../../../services/helpers";

export type PlatformEditType = {
    id?: number | null;
    name: string | null;
    commission_type: string;
};

const PlatformEdit = () => {
    const { setNotification } = useContext(AuthContext);
    const { id, platformId } = useParams();
    const [placement, setPlacement] = useState<PlatformEditType>({
        id: null,
        name: "",
        commission_type: "",
    });
    const [clientList, setClientList] = useState<string[]>([]);
    const [readOnly, setReadOnly] = useState(false);
    const { me, setNotFound } = useContext(AuthContext);

    useEffect(() => {
        setReadOnly(!isAllowedTo(me.role, "canEdit"));
    }, [me.role]);

    useEffect(() => {
        (async () => setClientList(await getClientLLPList()))();
    }, []);

    const getPlatform = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/${id}/platforms/${platformId}/`);
            return await res.data;
        } catch (err: Error | AxiosError | unknown) {
            if (isAxiosError(err)) {
                if (err.response?.status === 404) {
                    setNotFound(true);
                }
            }
            return err;
        }
    };

    useEffect(() => {
        if (platformId) {
            (async () => {
                const data = await getPlatform();
                setPlacement({
                    id: data.id,
                    name: data.name,
                    commission_type: data.commission_type,
                });
            })();
        }
    }, [platformId]);

    const handleEditPlatform = async () => {
        try {
            const res = await axios.post(`${BASE_URL}/clients/${id}/platforms/${platformId}/edit/`, placement);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        }
    };

    return (
        <ContentContainer>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{
                        p: "3rem",
                        background: "#fff",
                        maxWidth: "736px",
                        gap: "1rem",
                        display: "flex",
                        flexWrap: "wrap",
                    }}
                >
                    <Autocomplete
                        value={placement.name}
                        color="warning"
                        disablePortal
                        id="combo-box-demo"
                        options={clientList}
                        sx={{ width: 312 }}
                        onChange={(e, value) => handleChangeField("name", value, placement, setPlacement)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    sx: {
                                        borderRadius: "0",
                                    },
                                }}
                                color="warning"
                                label="forms.client_account.select_client"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                    <SelectInputField
                        value={placement.commission_type}
                        label="forms.common.commission_type"
                        items={commTypes}
                        onChange={(value) => handleChangeField("commission_type", value, placement, setPlacement)}
                    />
                </Box>
                <Restricted to="canEdit">
                    <CustomButton Icon={<EditIcon />} label="button.edit" onChange={handleEditPlatform} />
                </Restricted>
            </Box>
        </ContentContainer>
    );
};

export default PlatformEdit;
