import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import PartnerAgreementsPage from "../partner/pages/AgreementsPage";
import AgreementPage from "../pages/ClientViewPage/pages/AgreementPage";

const AgreementsView = () => {
    const { me } = useContext(AuthContext);

    if (Object.keys(me).length > 0 && me.role === "client") {
        return <PartnerAgreementsPage />;
    }
    return <AgreementPage />;
};

export default AgreementsView;
