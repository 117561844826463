import React from "react";
import CustomButton from "../CustomButton";
import { ReactComponent as EditIcon } from "../../../assets/images/icons/common/edit-icon-white.svg";
import Restricted from "../../../components/Restricted";

interface EditButtonProps {
    action: () => void;
}
const EditButton: React.FC<EditButtonProps> = ({ action }) => (
    <Restricted to="canEdit">
        <CustomButton Icon={<EditIcon />} label="button.edit" onChange={action} />
    </Restricted>
);
export default EditButton;
