import { Box, Divider, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as GazonIcon } from "../../../../assets/images/icons/common/gazon-og.svg";

type CheckCardProps = {
    bill: any;
};

const CheckCard: FC<CheckCardProps> = ({ bill }) => {
    const isAdmin = Object.hasOwn(bill, "gp");
    const { t } = useTranslation();
    const rowStyle = {
        display: "flex",
        justifyContent: "space-between",
        p: "0 .5rem",
        gap: "1rem",
        mb: "1.5rem",
    };
    return (
        <Box
            sx={{
                p: "3rem",
                backgroundColor: "#fff",
                minHeight: "152px",
                position: "relative",
                m: "6.5px 0",
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "-16.5px",
                    left: "0",
                    zIndex: "123",
                    overflow: "hidden",
                    width: "100%",
                }}
            >
                <GazonIcon />
            </Box>
            <Box
                sx={{
                    position: "absolute",
                    bottom: "-16.5px",
                    left: "0",
                    zIndex: "123",
                    transform: "rotate(180deg)",
                    overflow: "hidden",
                    width: "100%",
                }}
            >
                <GazonIcon />
            </Box>
            <Box sx={rowStyle}>
                <Typography sx={{ maxWidth: "200px", fontSize: "1.25rem" }}>{t("pages.dashboard.google_deduction")}</Typography>
                <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>
                    {bill.deduction.toLocaleString("ru-RU", {
                        maximumFractionDigits: 2,
                    })}
                </Typography>
            </Box>
            {isAdmin && (
                <Box sx={rowStyle}>
                    <Typography sx={{ maxWidth: "200px", fontSize: "1.25rem" }}>{t("pages.dashboard.transfer_expenses")}</Typography>
                    <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>
                        {bill.transfer_cost.toLocaleString("ru-RU", {
                            maximumFractionDigits: 2,
                        })}
                    </Typography>
                </Box>
            )}
            <Box sx={rowStyle}>
                <Typography sx={{ maxWidth: "200px", fontSize: "1.25rem" }}>{t("pages.dashboard.transfer_commission")}</Typography>
                <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>
                    {bill.transfer_commission.toLocaleString("ru-RU", {
                        maximumFractionDigits: 2,
                    })}
                </Typography>
            </Box>
            <Divider
                sx={{
                    m: ".75rem 0",
                    borderStyle: "dashed",
                    borderBottomWidth: "1px",
                    borderColor: "var(--main-black-color)",
                }}
            />
            {isAdmin ? (
                <>
                    <Box sx={rowStyle}>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "700" }}>{t("pages.dashboard.total_gp")}</Typography>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>
                            {bill.gp.toLocaleString("ru-RU", {
                                maximumFractionDigits: 2,
                            })}
                        </Typography>
                    </Box>
                    <Box sx={rowStyle}>
                        <Typography sx={{ maxWidth: "200px", fontSize: "1.25rem" }}>{t("pages.dashboard.translation_costs")}</Typography>
                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>
                            {bill.payout_fact.toLocaleString("ru-RU", {
                                maximumFractionDigits: 2,
                            })}
                        </Typography>
                    </Box>
                </>
            ) : (
                <Box sx={rowStyle}>
                    <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>{t("pages.dashboard.payout")}</Typography>
                    <Typography sx={{ fontSize: "1.25rem", fontWeight: "500" }}>
                        {bill.payout.toLocaleString("ru-RU", {
                            maximumFractionDigits: 2,
                        })}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default CheckCard;
