import React, { FC } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../pages/ProductsPage/components/input-file.scss";

type FileFieldProps = {
    label: string;
    value: string | number | File | any;
    onChange: (file: File | null) => void;
    errors?: any;
};

const FileField: FC<FileFieldProps> = ({ label, value, onChange, errors={} }) => {
    const lang = localStorage.getItem("lng");
    const isError = Object.keys(errors).length > 0;
    const fileName = value ? value.name : "";

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {files} = event.target;
        if (files && files[0]) {
            onChange(files[0]);
        }
    };

    const handleDeleteClick = () => {
        onChange(null);
    };
    return (
        <>
            <Typography sx={{ fontWeight: "500", fontSize: "1rem", mb: "1rem" }}>
                {label}
            </Typography>
            {!fileName ? (
                <label htmlFor="file-upload" className="input-file" style={{ borderColor: isError ? "red" : "" }}>
                    <input
                        id="file-upload"
                        type="file"
                        name="file"
                        style={{ width: "100%", height: "42px" }}
                        onChange={handleFileChange}
                    />
                    <span className="input-file-btn" style={{ color: isError ? "red" : "" }}>Choose a file</span>
                </label>
            ) : (
                <Box sx={{ width: "100%", display: "flex", gap: "1rem", alignItems: "center", overflow: "hidden" }}>
                    <UploadFileIcon color="info" />
                    <Box sx={{ display: "flex", width: "90%", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {fileName}
                        </Typography>
                        <IconButton onClick={handleDeleteClick}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            )}
            {isError && <Typography color="#d32f2f" fontSize=".75rem">{errors?.message[lang || "ru"]}</Typography>}
        </>
    );
};

export default FileField;
