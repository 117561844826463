import { Badge } from "@mui/material";
import React, { useContext } from "react";
import { ReactComponent as NotificationIcon } from "../../assets/images/icons/common/bell.svg";
import { AuthContext } from "../../context/AuthContext";

const NotificationIconBadged = () => {
    const { me } = useContext(AuthContext);
    return (
        <Badge
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            color="warning"
            badgeContent={me.notifications_count || 0}
        >
            <NotificationIcon />
        </Badge>
    );
};
export default NotificationIconBadged;
