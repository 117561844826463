import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { AxiosError } from "axios";
import { AuthContext } from "../../../../../../../../context/AuthContext";
import CloseButton from "../../../../../../../../UI/Buttons/Actions/CloseButton";
import { TopBarContext } from "../../../../../../../../context/TopBarContext";
import { IClientInfo } from "../../../../index";
import { getClientInfo } from "../../../../../../services/fetchers";

const tabs = [
    {
        key: "edit",
        label: "platform",
    },
    {
        key: "blocks",
        label: "blocks",
    },
    {
        key: "conditions",
        label: "fees",
    },
];

const PlatformViewLayout = () => {
    const { handleChangePath, handleChangeTabs, handleSetActions } = useContext(TopBarContext);
    const [clientInfo, setClientInfo] = useState<IClientInfo>({} as IClientInfo);
    const { appState, me, setNotFound } = useContext(AuthContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { id, platformId } = useParams();
    const isClient = me.role === "client";

    useEffect(() => {
        (async () => {
            try {
                const res = await getClientInfo(id);
                if (res.response?.status === 404) {
                    setNotFound(true);
                }
                setClientInfo(res);
            } catch (err: Error | AxiosError | unknown) {
                console.error("Error");
            }
        })();
    }, [id]);

    useEffect(() => {
        let newTabs = tabs;
        if (isClient) {
            newTabs = tabs.filter((tab) => tab.key !== "edit");
        }
        handleChangeTabs(newTabs);
        handleChangePath([clientInfo?.name || "", "placements"]);
        handleSetActions([<CloseButton path={`/clients/${id}/platforms`} />]);
    }, [clientInfo]);

    useEffect(() => {
        if (pathname.split("/").length === 5) {
            navigate(`/clients/${id}/platforms/${platformId}/blocks`, {
                replace: true,
            });
        }
    }, [appState]);

    return <Outlet />;
};

export default PlatformViewLayout;
