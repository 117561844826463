import { PaymentDetailsKeys, PaymentDetailsType } from "../types";

export const handleChangeInnerField = (key: PaymentDetailsKeys, fieldKey: string, value: string | number | boolean, setState: (state: any) => void) => {
    // @ts-ignore
    setState((prevValue: PaymentDetailsType) => ({
        ...prevValue,
        [key]: {
            // @ts-ignore
            ...(typeof prevValue[key] === "object" ? prevValue[key] : {}),
            [fieldKey]: value,
        },
    }));
};

export const handleChangeField = (key: any, value: any, state: any, setState: (item: any) => void) => {
    setState({
        ...state,
        [key]: value,
    });
};

export const getValue = (value: string | number | null) => value || "-";
