import axios from "axios";

export const getFeedbackCategories = async () => {
    try {
        return (await axios.get("properties/tickets/category_list/")).data;
    } catch (err: any) {
        console.log(err);
        return [""];
    }
};

export const postFeedback = async (feedback: FormData) => {
    try {
        return await axios.post("properties/tickets/create/", feedback, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
};
