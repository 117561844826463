import axios from "axios";

export async function getBlocks(productName = "", data = {}, signal: any = null) {
    try {
        const res = await axios.post(`/products/?product_name=${productName}`, data, { signal });
        return await res.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}

export async function getTemplate(templateName = "") {
    try {
        const response = await axios.post("/products/templates/default/", { name: templateName });
        return response.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}

export async function getTemplatesList(productName = "", templateType = "") {
    try {
        const response = await axios.get(`products/templates/${templateType}/?product_name=${productName}`);
        return response.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}

export async function createProduct(productName = "", data = {}) {
    try {
        const response = await axios.post(`products/create/?product_name=${productName}`, data);
        return response.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}
export async function editProduct(productName = "", id = "", data = {}) {
    try {
        const response = await axios.post(`products/${id}/edit/?product_name=${productName}`, data);
        return response.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}
export async function deleteProduct(productName = "", id = "") {
    try {
        const response = await axios.post(`products/${id}/delete/?product_name=${productName}`);
        return response.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}

export async function getProductByID(productName = "", id = "") {
    try {
        const response = await axios.get(`products/${id}/?product_name=${productName}`);
        return response.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}
export async function getClientList() {
    try {
        const response = await axios.get("properties/client_list/");
        return response.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}
export async function getPlacements(clientName = "") {
    try {
        const response = await axios.post("properties/client_placement_list/", { client_name: clientName });
        return response.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}

export async function createProductFormData(productName = "", data = {}) {
    try {
        const response = await axios.post(`products/create/?product_name=${productName}`, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return response.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}

export async function editProductFormData(productName = "", id = "", data = {}) {
    try {
        const response = await axios.post(`products/${id}/edit/?product_name=${productName}`, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return response.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}