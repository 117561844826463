import React from "react";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import SelectInputField from "../../../../../../../UI/Inputs/SelectInputField";
import {handleChangeInnerField} from "../../../../../services/helpers";
import ValueInputField from "../../../../../../../UI/Inputs/ValueInputField";
import {ReactComponent as WebIcon} from "../../../../../../../assets/images/icons/client/webIcon.svg";
import {ReactComponent as ApartmentIcon} from "../../../../../../../assets/images/icons/client/appartmentIcon.svg";
import {ReactComponent as GeoPositionIcon} from "../../../../../../../assets/images/icons/client/geoPositionIcon.svg";
import {ReactComponent as LinkIcon} from "../../../../../../../assets/images/icons/client/linkIcon.svg";

const AddressRegistrationFields = ({ paymentDetails, setPaymentDetails, countries, readOnly }: any) => {
    const { receiverAddress } = paymentDetails;
    const { t } = useTranslation();
    return (
        <>
            <Typography
                sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    mb: "1rem",
                }}
            >
                {t("forms.payment.address_label")}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    gap: "1rem",
                    flexWrap: "wrap",
                    mb: "1rem",
                    maxWidth: "740px",
                }}
            >
                <SelectInputField
                    readOnly={readOnly}
                    label={t("forms.common.country")}
                    value={receiverAddress?.country}
                    items={countries}
                    onChange={(value) => handleChangeInnerField("receiverAddress", "country", value, setPaymentDetails)}
                />
                <ValueInputField
                    readOnly={readOnly}
                    Icon={<WebIcon />}
                    label={t("forms.payment.state")}
                    value={receiverAddress?.region}
                    onChange={(e) => handleChangeInnerField("receiverAddress", "region", e.target.value, setPaymentDetails)}
                />
                <ValueInputField
                    readOnly={readOnly}
                    Icon={<ApartmentIcon />}
                    label={t("forms.common.city")}
                    value={receiverAddress?.city}
                    onChange={(e) => handleChangeInnerField("receiverAddress", "city", e.target.value, setPaymentDetails)}
                />
                <ValueInputField
                    readOnly={readOnly}
                    Icon={<GeoPositionIcon />}
                    label={t("forms.common.address")}
                    value={receiverAddress?.address}
                    onChange={(e) => handleChangeInnerField("receiverAddress", "address", e.target.value, setPaymentDetails)}
                />
                <ValueInputField
                    readOnly={readOnly}
                    Icon={<GeoPositionIcon />}
                    label={t("forms.payment.ZIP")}
                    value={receiverAddress?.postalCode}
                    onChange={(e) => handleChangeInnerField("receiverAddress", "postalCode", e.target.value, setPaymentDetails)}
                />
                <ValueInputField
                    readOnly={readOnly}
                    style={{ width: "100%" }}
                    Icon={<LinkIcon />}
                    label={t("forms.payment.articles_link")}
                    value={receiverAddress?.bankSpravkaUrl}
                    onChange={(e) => handleChangeInnerField("receiverAddress", "bankSpravkaUrl", e.target.value, setPaymentDetails)}
                />
            </Box>
        </>
    );
};

export default AddressRegistrationFields;
