import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";

const AddBadge = () => (
    <Box
        sx={{
            width: "2rem",
            height: "2rem",
            borderRadius: "50%",
            background: "var(--button-background-color)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            cursor: "pointer",
        }}
    >
        <AddIcon style={{ color: "#fff" }} />
    </Box>
);

export default AddBadge;
