import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import axios, { AxiosError, isAxiosError } from "axios";
import { ClientInfo, ContactPersonType } from "../../../../../../types";
import { initialClientInfo, initialContactPeople } from "../../../../../../../../context/ClientCreateContext";
import ContactsForm from "../../../../../../components/ClientCreateForm/ContactsForm";
import ContentContainer from "../../../../../../../../components/ContentContainer/ContentContainer";
import { backendContacts, frontendContacts } from "../../../../../../services/parsers";
import BASE_URL from "../../../../../../../../data/constants";
import { AuthContext } from "../../../../../../../../context/AuthContext";
import { getClientInfo } from "../../../../../../services/fetchers";
import { TopBarContext } from "../../../../../../../../context/TopBarContext";
import EditButton from "../../../../../../../../UI/Buttons/Actions/EditButton";
import CreateButton from "../../../../../../../../UI/Buttons/Actions/CreateButton";
import CloseButton from "../../../../../../../../UI/Buttons/Actions/CloseButton";

const ContactsCreateOrEdit = () => {
    const { handleSetActions, handleChangePath, handleChangeTabs } = useContext(TopBarContext);
    const { setNotification, setNotFound } = useContext(AuthContext);
    const [contactPeople, setContactPeople] = useState<ContactPersonType[]>(initialContactPeople);
    const { id, contactId } = useParams();
    const navigate = useNavigate();
    const [clientInfo, setClientInfo] = useState<ClientInfo>(initialClientInfo);
    const mode = contactId ? "edit" : "create";

    useEffect(() => {
        const labelItems = [clientInfo.name, mode === "edit" ? "Contact" : "New Contact"];
        handleChangePath(labelItems);
        handleSetActions([mode === "edit" ? <EditButton action={handleEditContact} /> : <CreateButton action={handleCreateContact} />, <CloseButton path={`/clients/${id}/contacts`} />]);
        handleChangeTabs([]);
    }, [clientInfo.name, contactPeople]);

    useEffect(() => {
        (async () => {
            const data = await getClientInfo(id);
            setClientInfo(data);
        })();
    }, [id]);

    const getContacts = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/${id}/contacts/${contactId}/`);
            return await res.data;
        } catch (err: Error | AxiosError | unknown) {
            if (isAxiosError(err)) {
                if (err.response?.status === 404) {
                    setNotFound(true);
                }
            }
            return err;
        }
    };

    useEffect(() => {
        if (contactId) {
            (async () => {
                const data = await getContacts();
                setContactPeople(frontendContacts([data]));
            })();
        }
    }, [contactId]);

    const handleGoBack = () => navigate(`/clients/${id}/contacts`);

    const handleCreateContact = async () => {
        const data = backendContacts(contactPeople[0]);
        try {
            const res = await axios.post(`${BASE_URL}/clients/${id}/contacts/create/`, data);
            handleGoBack();
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        }
    };

    const handleEditContact = async () => {
        const data = backendContacts(contactPeople[0]);
        try {
            const res = await axios.post(`${BASE_URL}/clients/${id}/contacts/${contactId}/edit/`, data);
            handleGoBack();
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        }
    };

    return (
        <ContentContainer>
            <Box
                sx={{
                    p: "3rem",
                    background: "#fff",
                    maxWidth: "740px",
                }}
            >
                <ContactsForm isAddMore={false} contactPeople={contactPeople} setContactPeople={setContactPeople} />
            </Box>
        </ContentContainer>
    );
};

export default ContactsCreateOrEdit;
