import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import ValueInputField from "../../../../../../UI/Inputs/ValueInputField";
import { handleChangeField } from "../../../../../ClientsPage/services/helpers";
import { ReactComponent as CountryIcon } from "../../../../../../assets/images/icons/Countries.svg";
import SelectInputField from "../../../../../../UI/Inputs/SelectInputField";
import { CountryType } from "../../types";
import { getCurrencies } from "../../../../../ClientsPage/services/fetchers";

type CountryFormType = {
    country: CountryType;
    setCountry: (country: CountryType) => void;
};

const Form: React.FC<CountryFormType> = ({ country, setCountry }) => {
    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        (async () => {
            const res = await Promise.all([getCurrencies()]);
            setCurrencies(res[0]);
        })();
    }, []);
    return (
        <Box
            sx={{
                p: "3rem",
                background: "#fff",
                maxWidth: "736px",
                gap: "1rem",
                display: "flex",
                flexWrap: "wrap",
            }}
        >
            <ValueInputField label="forms.partners.country.name" value={country.name} Icon={<CountryIcon />} onChange={(e) => handleChangeField("name", e.target.value, country, setCountry)} />
            <SelectInputField value={country.currency} label="forms.partners.country.currency" items={currencies} onChange={(value) => handleChangeField("currency", value, country, setCountry)} />
        </Box>
    );
};

export default Form;
