import axios from "axios";

export const getManagers = async (data = {}, signal: any = null) => {
    try {
        const res = await axios.post("/users/managers/", data, { signal });
        return await res.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
};

export const getBonuses = async (data = {}, id: string | undefined = "", signal: any = null) => {
    try {
        const res = await axios.post(`/users/managers/${id}/bonus_table/`, data, { signal });
        return await res.data;
    } catch (err: any) {
        console.error("Error");
        throw err;
    }
};

export const getBonus = async (id: string | undefined, bankId: string) => {
    try {
        const res = await axios.post(`/users/managers/${id}/bonus_table/${bankId}/`);
        return await res.data;
    } catch (err: any) {
        console.error("Error");
        throw err;
    }
};
