import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import axios, { AxiosError, isAxiosError } from "axios";
import { useTranslation } from "react-i18next";
import ContentContainer from "../../../../../../../../components/ContentContainer/ContentContainer";
import { initialClientInfo, initialContract, initialPaymentDetails } from "../../../../../../../../context/ClientCreateContext";
import ContractForm from "../../../../../../components/ClientCreateForm/ContractForm";
import { frontendContract } from "../../../../../../services/parsers";
import BASE_URL from "../../../../../../../../data/constants";
import { ClientInfo } from "../../../../../../types";
import { getClientInfo } from "../../../../../../services/fetchers";
import { TopBarContext } from "../../../../../../../../context/TopBarContext";
import CloseButton from "../../../../../../../../UI/Buttons/Actions/CloseButton";
import { AuthContext } from "../../../../../../../../context/AuthContext";

const AgreementViewPage = () => {
    const { setNotFound } = useContext(AuthContext);
    const { t } = useTranslation();
    const { handleSetActions, handleChangePath, handleChangeTabs } = useContext(TopBarContext);
    const [contract, setContract] = useState(initialContract);
    const [paymentDetails, setPaymentDetails] = useState(initialPaymentDetails);
    const [currencies, setCurrencies] = useState<string[]>([]);
    const { id, contractId } = useParams();
    const [clientInfo, setClientInfo] = useState<ClientInfo>(initialClientInfo);

    useEffect(() => {
        const labelItems = [clientInfo.name, t("agreement")];
        handleChangePath(labelItems);
        handleSetActions([<CloseButton path={`/clients/${id}/agreements`} />]);
        handleChangeTabs([]);
    }, [clientInfo.name]);

    useEffect(() => {
        (async () => {
            const data = await getClientInfo(id);
            setClientInfo(data);
        })();
    }, [id]);

    const getContract = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/${id}/contracts/${contractId}/`);
            return await res.data;
        } catch (err: Error | AxiosError | unknown) {
            if (isAxiosError(err)) {
                if (err.response?.status === 404) {
                    setNotFound(true);
                }
            }
            return err;
        }
    };

    useEffect(() => {
        if (contractId) {
            (async () => {
                const data = await getContract();
                setContract(frontendContract(data));
                setPaymentDetails({
                    ...paymentDetails,
                    contractCurrency: data.contract_currency,
                });
                setCurrencies([data.contract_currency]);
            })();
        }
    }, [contractId]);

    return (
        <ContentContainer>
            <Box
                sx={{
                    p: "3rem",
                    background: "#fff",
                    maxWidth: "740px",
                }}
            >
                <ContractForm readOnly contract={contract} setContract={setContract} currencies={currencies} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} />
            </Box>
        </ContentContainer>
    );
};

export default AgreementViewPage;
