import { ChangeEvent, FC } from "react";
import { Box, FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ClientType } from "../../types";

type ClientAccessesProps = {
    client: ClientType;
    setClient: (client: ClientType) => void;
};

const ClientAccesses: FC<ClientAccessesProps> = ({ client = { client_tabs: {} }, setClient }) => {
    const { t } = useTranslation();

    const changeTabAccess = (e: ChangeEvent<HTMLInputElement>, key: string, checked: boolean) => {
        setClient({
            ...(client as ClientType),
            client_tabs: {
                ...client.client_tabs,
                [key]: checked,
            },
        });
    };
    const { client_tabs = {} } = client;
    return (
        <Box>
            <Typography sx={{ fontWeight: "500" }}>{t("forms.client_account.client_access")}</Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", columnGap: "1rem", rowGap: ".5rem" }}>
                {Object.keys(client_tabs).length > 0 &&
                    Object.entries(client_tabs).map((access, index) => (
                        <FormGroup key={index}>
                            <FormControlLabel
                                sx={{
                                    justifyContent: "flex-end",
                                    ml: "0",
                                }}
                                control={
                                    <Switch
                                        checked={access[1]}
                                        onChange={(e, checked) => changeTabAccess(e, access[0], checked)}
                                        sx={{
                                            ".MuiSwitch-thumb": {
                                                color: access[1] ? "var(--main-purple-color)" : "#fff",
                                            },
                                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                                backgroundColor: "var(--main-purple-color)",
                                            },
                                        }}
                                    />
                                }
                                labelPlacement="start"
                                label={access[0]}
                            />
                        </FormGroup>
                    ))}
            </Box>
        </Box>
    );
};

export default ClientAccesses;
