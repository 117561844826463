export const initialSwiftBankCredentials = {
    receiverBankCountry: "",
    receiverBankName: "",
    receiverBankSwift: "",
    receiverCompanyName: "",
    accountNumber: "",
};

export const initialCitizenship = {
    citizenship: "",
    passportScanUrl: "",
};

export const initialReceiverRegistration = {
    country: "",
    region: "",
    city: "",
    address: "",
    postalCode: "",
    bankSpravkaUrl: "",
};

export const initialDirector = {
    fullName: "",
    citizenship: "",
    passportScanUrl: "",
};

export const initialFounder = [
    {
        fieldId: `id${Math.random().toString(16).slice(2)}`,
        fullName: "",
        citizenship: "",
        passportScanUrl: "",
    },
];

export const initialSwiftData = {
    bankCredentials: initialSwiftBankCredentials,
    receiverCitizenship: initialCitizenship,
    receiverAddress: initialReceiverRegistration,
    director: initialDirector,
    founders: initialFounder,
    statementFactChecked: false,
};

export const initialPayoneerData = {
    receiverCompanyName: "",
    statementFactChecked: false,
    address_link: "",
};

export const initialLocalBankCredentials = {
    receiverBankCountry: "",
    receiverBankName: "",
    receiverBankSwift: "",
    receiverCompanyName: "",
    accountNumber: "",
    ACHRoutingNumber: "",
    accountType: "",
};

export const initialLocalData = {
    bankCredentials: initialLocalBankCredentials,
    receiverCitizenship: initialCitizenship,
    receiverAddress: initialReceiverRegistration,
    director: initialDirector,
    founders: initialFounder,
    statementFactChecked: false,
};
export const initialWMZorUSDTdata = {
    accountNumberWMZorUSDT: "",
};
