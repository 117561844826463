import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import axios from "axios";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ContentContainer from "../../../../components/ContentContainer/ContentContainer";
import NotificationEntity from "../../components/NotificationEntity";
import { TopBarContext } from "../../../../context/TopBarContext";
import CustomButton from "../../../../UI/Buttons/CustomButton";
import EmptyNotifications from "../../components/EmptyNotifications";
import Loading from "../../../../components/Loading";
import { AuthContext } from "../../../../context/AuthContext";

type NotificationType = {
    id: number;
    title: string;
    message: string;
    date?: string;
    open?: boolean;
    isViewed?: boolean;
};

const NotificationPage = () => {
    const { t } = useTranslation();
    const { me, setMe } = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [notifications, setNotifications] = useState<NotificationType[]>([]);
    const [error, setError] = useState<string>("");
    const { handleChangePath, handleSetActions, handleChangeTabs } = useContext(TopBarContext);

    useEffect(() => {
        handleChangePath(["notifications"]);
        handleChangeTabs([
            {
                key: "notification",
                label: "notifications",
            },
            {
                key: "archive",
                label: "archive",
            },
        ]);
    }, []);

    useEffect(() => {
        handleSetActions([
            <CustomButton
                mode="dark"
                disabled={notifications.length === 0}
                label={t("archive_all")}
                onChange={handleDeleteAll}
                Icon={<DeleteOutlineIcon />}
                style={{ backgroundColor: "#FFF", color: "#3A3F48" }}
            />,
        ]);
    }, [notifications]);

    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get("users/notification/");
                const data = res.data.map((items: NotificationType) => ({
                    ...items,
                    open: false,
                }));
                setNotifications(data);
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleDeleteAll = async () => {
        try {
            await axios.get("users/notification/archive_all/");
            setNotifications([]);
        } catch (e: any) {
            console.error(e);
            setError(e.response.data.message);
        }
    };

    const readNotificationHandler = async (id: number) => {
        try {
            await axios.post(`users/notification/${id}/`, { id });
            const newNotifications = notifications.filter((item) => item.id !== id);
            setNotifications(newNotifications);
            setMe({
                ...me,
                notifications_count: newNotifications.length,
            });
        } catch (e: any) {
            console.error(e);
            setError(e.response.data.message);
        }
    };

    if (loading) {
        return (
            <Box
                sx={{
                    height: "50vh",
                    display: "flex",
                }}
            >
                <Loading />
            </Box>
        );
    }

    return (
        <ContentContainer>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "12px",
                }}
            >
                {notifications.length > 0 ? (
                    notifications.map((notification) => <NotificationEntity key={notification.id} notification={notification} isArchive={false} closeHandler={readNotificationHandler} />)
                ) : (
                    <EmptyNotifications />
                )}
            </Box>
        </ContentContainer>
    );
};

export default NotificationPage;
