import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CustomButton from "../../../../../UI/Buttons/CustomButton";
import { AccountShortCard } from "../../types";

type AccountCardProps = {
    account: AccountShortCard;
};

const AccountCard: FC<AccountCardProps> = ({ account }) => {
    const navigate = useNavigate();
    const { id, name, organization_type } = account;

    const viewDetailsHandler = () => {
        navigate(`/clients/${account.id}`);
    };

    return (
        <Box
            key={id}
            sx={{
                p: "2rem",
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
                backgroundColor: "#fff",
                maxWidth: "390px",
                width: "100%",
            }}
        >
            <Typography
                sx={{
                    fontSize: "2rem",
                    fontWeight: "500",
                }}
            >
                {name}
            </Typography>
            <Typography
                sx={{
                    color: "var(--main-grey-color)",
                    fontSize: "1.25rem",
                }}
            >
                {organization_type}
            </Typography>
            <CustomButton
                style={{
                    height: "52px",
                    justifyContent: "space-between",
                }}
                Icon={<MoreHorizIcon style={{ color: "#fff" }} />}
                label="more"
                onChange={viewDetailsHandler}
            />
        </Box>
    );
};

export default AccountCard;
