import React, {FC, useEffect, useState} from "react";
import {handleChangeInnerField} from "../../../../utils/helpers";
import IconSelectInputFieldTransparent from "../../../../UI/Inputs/IconSelectInputFieldTransparent";
import {getCurrencies} from "../../../../pages/ClientsPage/services/fetchers";
import {Dictionary} from "../../../../pages/ClientsPage";
import { ReactComponent as MoneyIcon } from "../../../../assets/images/icons/common/moneyIcon.svg";

type PickCurrencyProps = {
    request: Dictionary<any>;
    setRequest: (value: Dictionary<any>) => void;
}

const PickCurrency: FC<PickCurrencyProps> = ({request, setRequest}) => {

    const [currencies, setCurrencies] = useState<string[]>([]);

    useEffect(() => {
        if (currencies.length === 0) {
            (async () => setCurrencies(await getCurrencies()))();
        }
    }, []);
    
    return (
        <IconSelectInputFieldTransparent
            value={request.periods.currency}
            label="Currency2"
            items={currencies}
            defaultValue="USD"
            onChange={(value: string) => handleChangeInnerField("periods", "currency", value, setRequest)}
            icon={<MoneyIcon style={{ marginRight: "6px" }} />}
        />
    )
}

export default PickCurrency;